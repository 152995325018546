import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LogoutUser, checkUser } from "../utils/Auth/LoginSlices";
import { Navigate, useNavigate } from "react-router-dom";
import { Loader, Skeleton } from "@mantine/core";


const AuthProtected = (props) => {
    
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isAuth,setAuth] = useState(false);
const {Data,isLoading,successMsg,hasError,ErrorMsg} = useSelector((state) => state.LoginSlices);
//   const { userProfile, loading, token } = useProfile();
useEffect(() => {
    if (!Data) {
       history("/Auth/login")
    }
    else{
        setAuth(true);
    }
  }, [Data]);
  useEffect(()=>{
    if (!Data) {
        dispatch(checkUser());
        }
},[]);
  /*
    redirect is un-auth access protected routes via url
    */

//   if (Data == null) {
//     return (
//       <Navigate to={{ pathname: "/Auth/login", state: { from: props.location } }} />
//     );
//   }
if(!isAuth){
    return <>
    <Skeleton height={50} circle mb="xl" />
    <Skeleton height={8} radius="xl" />
    <Skeleton height={8} mt={6} radius="xl" />
    <Skeleton height={8} mt={6} width="70%" radius="xl" />
  </>
}

  return <>{props.children}</>;
};


export { AuthProtected };