import { Button, Grid, Paper, Text } from '@mantine/core'
import React from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'

const AuditorPassword = (props) => {

    return (
        <>
            <Paper shadow="md" radius="md" p="xs" withBorder>

                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={3}>
                            <Text size={12}>Old Password</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                            <PLTextBox

                                setEdit={(e) => {

                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={3}>
                            <Text size={12}>New Password</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                            <PLTextBox

                                setEdit={(e) => {

                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Grid.Col span={3}>
                            <Text size={12}>ReType Password</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                            <PLTextBox

                                setEdit={(e) => {

                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button>OK</Button>
                    </Grid.Col>
                </Grid>

            </Paper>
        </>
    )
}

export default AuditorPassword