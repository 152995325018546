import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import GlobalClass from '../GlobalClass';
import gensetting from '../gensetting';
import { GetAccountList } from './AccountListSlice';
import Store from '../store';


export const GetM24PriceList = createAsyncThunk(
    'M24PriceList/GetM24PriceList',
    async (data) => {
        // console.log('PriceList Data', data)
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": data ? data.action : 'A',
                    "cCode": data ? data.code : '',
                    "cOPara": ""
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM24Ent + "?pa=" + param)
            // console.log('response', response)
            return response.data.data;
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error }
        }
    }
)

export const DeletePriceData = createAsyncThunk(
    'M24PriceList/DeletePriceData',
    async (obj) => {
        GlobalClass.Notify(
            "info",
            "Deleting",
            "Please wait while we process your data"
        );
        // console.log('M24PriceList/DeletePriceData', obj)
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": obj.action ? obj.action : 'A',
                    "cCode": obj.code ? obj.code : '',
                    "cOPara": '',
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM24Ent + "?pa=" + param)
            if (response.data.status === "SUCCESS") {
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                Promise.resolve(
                    GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
                    .then(() => Store.dispatch(GetAccountList({
                        id: "00960001",
                        name: "Price List",
                        p0: "MT",
                        p1: "T_M24",
                        p2: "",
                        p3: "",
                        type: "A",
                        pagination: PaginationObj["00960001"]
                    })))
            } else {
                GlobalClass.Notify(
                    "error",
                    response?.data?.status,
                    response?.data?.message
                );
            }
            return response.data
        } catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
        }
    }
)
export const M25List = createAsyncThunk(
    'M24PriceList/M25List',
    async (data) => {
        // console.log('data M25List=>>', data)
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "",
                    "cCode": data ? data.code : '',
                    "cOPara": ""
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.M25List + "?pa=" + param)
            // console.log('M25List response', response.data?.data)
            return response.data?.data
        } catch (error) {
            GlobalClass.Notify("error", "Error", "Fail", error.message)
            console.error(error)
            return { error }
        }
    }
)

const PriceListSlice = createSlice({
    name: 'M24PriceList',
    initialState: {
        M24PriceListData: [],
        M25PriceListData: [],
        selectedInputVal: [],
        selectedRowData: [],
        headerData: {
            partyData: [],
            productData: [],
        },
        groupHeaderData: {
            partyData: [],
            productData: [],
            groupData: [],
        },
        cityHeaderData: {
            partyData: [],
            productData: [],
            cityData: [],
            groupData: []
        },
        multiSelectHeaderData: {
            partyData: [],
            productData: [],
            cityData: [],
            partyGroupData: [],
            productGroupData: [],
            categoryData: [],
            areaData: [],
        },
        categoryHeaderData: {
            categoryData: [],
            productData: []
        },
        rateHeaderData: {
            partyData: [],
            productData: []
        },
        isLoading: false,
        hasError: false,
        successMsg: ''
    },
    reducers: {
        SelectedRow: (state, action) => {
            state.selectedRowData = action.payload
        },
        SelectedInputVal: (state, action) => {
            state.selectedInputVal = action.payload
        },
        GetHeaderData: (state, action) => {
            state.headerData = action.payload
        },
        getGroupHeaderData: (state, action) => {
            state.groupHeaderData = action.payload
        },
        getCityHeaderData: (state, action) => {
            state.cityHeaderData = action.payload
        },
        getCategoryHeaderData: (state, action) => {
            state.categoryHeaderData = action.payload
        },
        getRateHeaderData: (state, action) => {
            state.rateHeaderData = action.payload
        },
        GetMultiSelectHeaderData: (state, action) => {
            state.multiSelectHeaderData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetM24PriceList.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetM24PriceList.fulfilled, (state, action) => {
                state.M24PriceListData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetM24PriceList.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
            .addCase(DeletePriceData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(DeletePriceData.fulfilled, (state, action) => {
                state.successMsg = action.payload.status
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(DeletePriceData.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
            .addCase(M25List.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(M25List.fulfilled, (state, action) => {
                // console.log('M25List action.payload==>', action.payload)
                state.M25PriceListData = action.payload
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(M25List.rejected, (state, action) => {
                state.hasError = true;
                state.isLoading = false;
            })
    }
});

export const { SelectedRow, SelectedInputVal, GetHeaderData, getGroupHeaderData, getCityHeaderData, getCategoryHeaderData, getRateHeaderData, GetMultiSelectHeaderData } = PriceListSlice.actions
export default PriceListSlice.reducer