import React, { useEffect, useRef, useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { Button, Grid, Group } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import GlobalClass from '../../utils/GlobalClass';

const OSTableUserField = (props) => {

    const { T02UF, T02ObjSend, setT02Objsend,DrawerOBJ,setDrawerOBJ} = props;
    
  const userFieldRef = useRef(null);

  const [UserFieldObj, SetUserFieldObj]=useState(null)

    useEffect(()=>{
        if(T02ObjSend){
            SetUserFieldObj(T02ObjSend)
        }
    },[T02ObjSend])

    useEffect(() => {
    
        const formElement = userFieldRef.current;
        if (formElement) {
          formElement.addEventListener('keypress', (e)=>GlobalClass.formNextInput(e,userFieldRef));
        }
        return () => {
          if (formElement) {
            formElement.removeEventListener('keypress', (e)=>GlobalClass.formNextInput(e,userFieldRef));
          }
        };
      }, [userFieldRef]);

    const UserFieldRender = () => {
        let InputField;
        return (
            T02UF?.map((t,index) => {
                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    autoFocus = {index == 0 ? true : false}
                                    onKeyDown={(e)=>{
                                        GlobalClass.formNextInput(e,userFieldRef);
                                      }}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                autoFocus = {index == 0 ? true : false}
                                onKeyDown={(e)=>{
                                    GlobalClass.formNextInput(e,userFieldRef);
                                  }}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                autoFocus = {index == 0 ? true : false}
                                onKeyDown={(e)=>{
                                    GlobalClass.formNextInput(e,userFieldRef);
                                  }}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                autoFocus = {index == 0 ? true : false}
                                onKeyDown={(e)=>{
                                    GlobalClass.formNextInput(e,userFieldRef);
                                  }}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }

    const SaveTableUserField = () => {
        // console.log("UserFieldObj",UserFieldObj);
        Promise.resolve(setT02Objsend(UserFieldObj)).then(() =>(setDrawerOBJ({...DrawerOBJ,open:false})))
    }

    // console.log("T02UF=>", T02UF);
    return (<div ref={userFieldRef}>
        <Grid gutter={4}>
            {
                UserFieldRender()
            }
            <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button
                    onKeyDown={(e)=>{
                        if(e.key =="Enter"){
                          e.preventDefault();
                          SaveTableUserField();
                        }
                      }} 
                    onClick={() => SaveTableUserField()}>Save</Button>
                </Group>
            </Grid.Col>
        </Grid>

    </div>)
}

export default OSTableUserField;