import {
  Button,
  Center,
  Divider,
  Drawer,
  Grid,
  Group,
  Modal,
  Paper,
  Table,
  Text,
  Textarea,
} from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useState } from "react";
import useWindowDimensions from "../../utils/UseWindowDimensions";
import PrintReportForm from "./PrintReportForm";

export default function VoucherListModal(props) {
    const [opened, setOpened] = useState(false);
  console.log(props, "props--");
  const { height } = useWindowDimensions();
  let columns = [
    {
      accessor: "Field ID", // Use 'accessor' instead of 'accessorKey'
      header: "Field ID",
      Cell: ({ row }) => {
        // console.log(row.original.PrName, "getVal");
        return row?.original?.PrName;
      },
    },
    {
      accessor: "Description", // Convert index to string
      header: "Description",
      Cell: ({ row }) => {
        // console.log(row.original, "row.orginal");
        // return (
        //   <Button onClick={() => generateBill(row?.original)}>
        //     Generate Bill
        //   </Button>
        // );
      },

      maxSize: 100,
    },
  ];

  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: [],
    columns: columns,
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    initialState: {
      density: "xs",
      expanded: true,
    },
    enableBottomToolbar: false, //pagination remove
    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableContainerProps: {
      sx: { height: height * 0.7 },
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {},
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      height: 50,
    }),
  });
  function printFn(){
    setOpened(true)
  }
  return (
    <>
     <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          title={<Text fw={700}>Report Print</Text>}
          padding="xl"
          size="50%"
          position="right"
          // open="true"
        >
          <PrintReportForm  />
        </Modal>
      <Paper shadow="md" radius="md" p="xs" withBorder>
        <Grid style={{ marginTop: "1px", padding: "0px" }}>
          <Grid.Col span={12} style={{ display: "flex" }}>
            <Grid.Col span={12}>
              <MantineReactTable table={table} />
              <Center mt={5}>
                <Group>
                  <Button size="xs"  
                //   onClick={filterFn}
                  >
                    Filter
                  </Button>
                  <Button size="xs" onClick={printFn}>
                    Print
                  </Button>
                </Group>
              </Center>
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
}
