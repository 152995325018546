import { Button, Drawer, Grid, Group, Modal, Text, Textarea } from "@mantine/core";
import React, { useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import FieldValueList from "./FieldValueList";

export default function FormatForm(props) {
  console.log(props, "FormatForm")
  const [opened, setOpened] = useState(false);
  const { obj } = props;
  let p0 = obj?.BtnPnlObj?.p0
  function fieldVal() {
    setOpened(true);
  }
  console.log(obj.BtnPnlObj.p0
    , "obj?.p0-")
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<Text fw={700}>Format</Text>}
        padding="xl"
        size="lg"
        position="right"
        // open="true"
      >
        <FieldValueList/>
      </Modal>
      <Grid gutter={4}>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>Format Name</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLTextBox
            // value={group?.[h?.S13F02]}
            // setEdit={(e) => {
            //   //console.log(e)
            //   setGroup({ ...group, [h?.S13F02]: e.target.value });
            // }}
            />
          </Grid.Col>
        </Grid.Col>
        {p0 == "S"  ? (
          ""
        ) : (
          <>
                {p0 == "W" ? "": <> <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
         
         <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>CC</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>BCC</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Subject</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col></> }
            {/* <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
         
         <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>CC</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>BCC</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Subject</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLTextBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col> */}
            <Grid.Col
              span={8}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Grid.Col span={6}>
                <Text style={{ fontSize: 12 }}>Format List</Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <PLComboBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>File Type</Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <PLComboBox
                // value={group?.[h?.S13F02]}
                // setEdit={(e) => {
                //   //console.log(e)
                //   setGroup({ ...group, [h?.S13F02]: e.target.value });
                // }}
                />
              </Grid.Col>
            </Grid.Col>
          </>
        )}

        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>
              {p0 == "S" ? "SMS Text" : "Message"}
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <Textarea
            // value={group?.[h?.S13F02]}
            // setEdit={(e) => {
            //   //console.log(e)
            //   setGroup({ ...group, [h?.S13F02]: e.target.value });
            // }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: p0 == "W" ? "space-between" : "flex-end",
          }}
        >
          {/* <Group spacing={"xs"} position="center" mt={5} mb={5}> */}
            {/* <Button
              size="xs"
            //   onClick={() => callCustomCol()}
              // disabled={activeBtn ? false : true}
            >
              Custom Column
            </Button> */}
            <Button
              size="xs"
              onClick={() => fieldVal()}
              mr={3}
              // disabled={activeBtn ? true : false}
              // className={classes.button}
            >
              Field Value
            </Button>
            <Button
              size="xs"
              //   onClick={() => callSummary()}
              // disabled={activeBtn ? true : false}
              // className={classes.button}
            >
              Save
            </Button>
          {/* </Group> */}
        </Grid.Col>
      </Grid>
    </>
  );
}
