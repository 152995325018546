import { createSlice } from "@reduxjs/toolkit";

// GSTEntryPageID

const GSTEntryPageIDSlice = createSlice({
  name: "GSTEntryPageID",
  initialState: {
    pageID: "",
  },
  reducers: {
    getPageId: (state, action) => {
      console.log(action.payload, "GSTEntryPageID..payload");
      state.pageID = action.payload;
    },
  },
});

export const { getPageId } = GSTEntryPageIDSlice.actions;

export default GSTEntryPageIDSlice.reducer;
