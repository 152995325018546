import { Button, Grid, Text } from '@mantine/core'
import React from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLNumberBox from '../../PlusComponent/PLNumberBox'

const ChangeMRPSalesRateForm = (props) => {
    const {obj} = props
  return (
    <>
    <Grid gutter={4}>
        <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Grid.Col span={3}>
                    <Text size={12}>Product</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLDataGrid 
                    setEdit={(e) => {

                    }}
                    />
                </Grid.Col>
            </Grid.Col>
    </Grid>

    <Grid gutter={4}>
        <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Grid.Col span={3}>
                    <Text size={12}>Batch</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLDataGrid 
                    setEdit={(e) => {
                        
                    }}
                    />
                </Grid.Col>
            </Grid.Col>
    </Grid>

    <Grid gutter={4}>
        <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Grid.Col span={3}>
                    <Text size={12}>MRP</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLNumberBox 
                    setEdit={(e) => {
                        
                    }}
                    />
                </Grid.Col>
            </Grid.Col>
    </Grid>

    <Grid gutter={4}>
        <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Grid.Col span={3}>
                    <Text size={12}>Sales Rate</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLNumberBox 
                    setEdit={(e) => {
                        
                    }}
                    />
                </Grid.Col>
            </Grid.Col>
    </Grid>

    <Grid gutter={4}>
            <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Button>OK</Button>
            </Grid.Col>
        </Grid>
    </>
  )
}

export default ChangeMRPSalesRateForm