import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import axios from "axios";
import Store from "../store";
import { ModalDelete } from "../slices/ModalSlice";
import { GetAccountList, GetTMFormat } from "../slices/AccountListSlice";

export const GetNarrationData = createAsyncThunk(
    "NarrationSlice/GetNarrationData",
    async (obj) => {
        console.log("NarrationSlice/GetNarrationData obj=>", obj);
        try {
            let param = JSON.stringify({
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": obj?.p0,
                "cCode": obj?.id ? obj?.id : "",
                "cOPara": obj?.S11F01,
            });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM35NA + "?pa=" + param);
            if (obj?.p0 == "D") {
                if (response?.data?.status == "SUCCESS") {
                    Promise.resolve(GlobalClass.Notify(
                        "success",
                        "Delete",
                       "Delete successfully"
                    )).then(() => Store?.dispatch(GetAccountList({
                        "id": "P_M35",
                        "name": "",
                        "type": "A",
                        "p0": "P_M35",
                        "p1": "P_M35",
                        "p2": "",
                        "p3": "",
                        "cWhere":`M35.FIELD02 LIKE '%${obj?.S11F01 ?? ""}%'`,
                        "CoPara": obj?.S11F01
                    }))).then(()=>Store?.dispatch(GetTMFormat({
                        "id": "P_M35",
                        "name": "",
                        "type": "A",
                        "p0": "P_M35",
                        "p1": "P_M35",
                        "p2": "",
                        "p3": ""
                    })))
                } else {
                    console.log("response?.data FAIL ==>", response?.data)
                    GlobalClass.Notify(
                        "error",
                        "FAIL",
                        "Fail to Delete"
                    )
                }
            }
            return response?.data?.data;
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)

export const PostNarrationData = createAsyncThunk(
    "NarrationSlice/PostNarrationData",
    async (obj) => {
        console.log("NarrationSlice/PostNarrationData obj=>", obj);
        try {
            let param = {
                "CorpID": gensetting.getcorpid(),
                "cUser": gensetting.getcuser(),
                "cPass": gensetting.getcpass(),
                "CmpNo": gensetting.getcmpno(),
                "cAction": obj?.OBJ?.p0,
                "cCode": "",
                "cOPara": obj?.OBJ?.S11F01,
                "cSData": JSON.stringify(obj?.data),
            };
            const response = await axios.post(GlobalClass.ApiUrl + GlobalClass.PostM35NA, param);
            console.log("response?.data ==>", response?.data);

            if (response?.data?.status == "SUCCESS") {
                Promise.resolve(GlobalClass.Notify(
                    "success",
                    obj?.OBJ?.p0 == "A" ? "Added" : "Edited",
                    obj?.OBJ?.p0 == "A" ? "Added successfully" : "Edited successfully"
                )).then(() => Store?.dispatch(ModalDelete(obj?.OBJ?.index))).then(() => Store?.dispatch(GetAccountList({
                    "id": "P_M35",
                    "name": "",
                    "type": "A",
                    "p0": "P_M35",
                    "p1": "P_M35",
                    "p2": "",
                    "p3": "",
                    "cWhere":`M35.FIELD02 LIKE '%${obj?.OBJ?.S11F01 ?? ""}%'`,
                    "CoPara": obj?.OBJ?.S11F01
                }))).then(() => Store?.dispatch(GetTMFormat({
                    "id": "P_M35",
                    "name": "",
                    "type": "A",
                    "p0": "P_M35",
                    "p1": "P_M35",
                    "p2": "",
                    "p3": ""
                })))
            } else {
                console.log("response?.data FAIL ==>", response?.data)
                GlobalClass.Notify(
                    "error",
                    obj?.OBJ?.p0 == "A" ? "Added" : "Edited",
                    obj?.OBJ?.p0 == "A" ? "Fail to Add" : "Fail to Edited"
                )
            }
            return response?.data;
        } catch (error) {
            console.error(error);
            return { error };
        }
    }
)


const NarrationSlice = createSlice({
    name: "NarrationSlice",
    initialState: {
        NarrationData: [],
        isLoading: false,
        hasError: false,
        NarrationDataPostStatus: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetNarrationData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetNarrationData.fulfilled, (state, action) => {
                state.NarrationData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetNarrationData.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
            })
            .addCase(PostNarrationData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(PostNarrationData.fulfilled, (state, action) => {
                console.log("action.payload", action.payload);
                state.NarrationDataPostStatus = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(PostNarrationData.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true;
            })

    }
})

export default NarrationSlice.reducer;