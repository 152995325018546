import '../global.js'
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import '../App.css';
//Report designer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-designer.min';
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/office-365/bold.reports.all.min.css';
import '@boldreports/javascript-reporting-controls/Content/office-365/bold.reportdesigner.min.css';
//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';
import { Alert } from '@mantine/core';
import { GetAddCmpEnt } from '../utils/slices/UserListSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import GlobalClass from '../utils/GlobalClass.jsx';
import gensetting from '../utils/gensetting.js';
import { IconAlertCircle } from '@tabler/icons-react';
function ReportViewerTest(props) {
  const [ReportData,setReportData]=useState();
  const [isLoading,setIsLoading] = useState(false)
  const [Error,setError]=useState("");
  var viewerStyle = { 'height': "92vh", 'width': "98vw"};
  const toLowerCaseKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(toLowerCaseKeys);
    } else if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        acc[key.toLowerCase()] = toLowerCaseKeys(obj[key]);
        return acc;
      }, {});
    }
    return obj;
  };
  // if(props.Data){
    
  //   let jsonData =  {
  //     "PARTY_DETAIL": {
  //           "in000001": "HD FIRE PROTECT PVT. LTD."
  //       }
  // };
  // formattedData = props.Data.map(baseItem => {
  //     let section = jsonData[baseItem.Name?.trim()?.toUpperCase()];
  //     return {
  //       value: section ? (Array.isArray(section) ? toLowerCaseKeys(section) : (section!={}? [toLowerCaseKeys(section)]:[])) : [],
  //       name: baseItem.Name?.trim()?.toLowerCase()
  //     }; 
  // });
  // console.log("formattedData",formattedData);
  // }
  useEffect(()=>{
    setIsLoading(true);
    fetch(GlobalClass.ApiUrl+'Vouformat/GetS92Src',{
      method:'post',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cCode: props?.cCode,
        cOPara: props?.cOPara,
        cSData: "",
        cAction:""
      })
    }).then((res)=>res.json())
    .then((e)=>{
      if(e.status == "SUCCESS"){
        var dt = e?.data?.xmlsrc
        var grp = e?.data?.dataset
        let formattedData = grp.map(baseItem => {
            let section = dt[baseItem?.trim()?.toUpperCase()];
            return {
              value: section ? (Array.isArray(section) ? toLowerCaseKeys(section) : (section!={}? [toLowerCaseKeys(section)]:[])) : [],
              name: baseItem?.trim()?.toLowerCase()
            }; 
          });
          console.log("formattedData",formattedData);
        
        setReportData(formattedData);
        setIsLoading(false);
      }
      else{
        setError(e?.message);
        setIsLoading(false);
      } 
    }).catch((e)=>{
      setError("Network Error Occured!!");
      setIsLoading(false);
    })
  },[])
  let param = {
    CorpID: gensetting.getcorpid(),
    cUser: gensetting.getcuser(),
    cPass: gensetting.getcpass(),
    CmpNo: gensetting.getcmpno(),
    cCode: props?.cOPara,
  };
  function onExportProgressChanged(e){
    console.log("exportReport",e);
    
  }
  const pageSettings = {
    paperSize: "A4",
    orientation: "Portrait",
    width: "8.27in", // 210mm in inches
    height: "11.69in", // 297mm in inches
    margins: {
      left: 0.8,
      right: 0.8,
      top: 0.1,
      bottom: 0.1,
    },
  };
  function onExportItemClick(event) {
    console.log("onExportItemClick",event);
    
    event.fileName =  "Demo";
}
  return (
    <div style={viewerStyle}>
      {isLoading?
    GlobalClass.RenderLoader('dots'):
    Error?(<Alert icon={<IconAlertCircle size="1rem" />} title={Error} color="red"></Alert>):
     (ReportData && ReportData?.length > 0 && <BoldReportViewerComponent
        id="reportviewer-container"
        reportServiceUrl={GlobalClass.ApiUrl + 'ReportViewer'}
        processingMode={"Local"}
        reportPath={param}
        dataSources={ReportData}
        pageSettings={pageSettings}
        // pageSettings={{ paperSize: ej.ReportViewer.PaperSize[AdvanceFormData.PaperSize] }}
        isResponsive={true}
        printMode={true}
        fitToPage={true}
        // enablePageBreaks={false}
      // ajaxBeforeLoad = {onAjaxRequest}
      // toolbarRenderMode={ej.ReportViewer?.ToolbarRenderMode?.Classic}
      exportProgressChanged = {onExportProgressChanged}
      ExportItemClick= {onExportItemClick}
      >
      </BoldReportViewerComponent>)}
    </div>
  );
}

export default ReportViewerTest;