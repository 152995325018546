import { Button, Grid, Group, Text } from "@mantine/core";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import { useEffect, useState } from "react";

export default function TCSUserField(props) {

    const { state, setState, OBJ, UFM21, DrawerOBJ, setDrawerOBJ } = props;

    const [M21, setM21] = useState([]);

    useEffect(() => {
        setM21(state?.M21)
    }, [state])

    const seprate = (e) => {
        var paraArr = e.split('~C~');
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr
    }

    const saveBtn = () => {
        setState((prevState) => ({
            ...prevState,
            M21: M21
        }))
        setDrawerOBJ({ ...DrawerOBJ, open: false })
        props.setFocus()
    }

    return (
        <>
            {
                UFM21 && UFM21.length > 0 ? UFM21?.map(item => {
                    return <Grid gutter={4}>
                        {
                            item?.S13F14 === 'PLPOPUP' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDataGrid position=""
                                        width="500px"
                                        name={item?.S13F02}
                                        value={M21?.[item.S13F02]}
                                        p1={seprate(item?.S13F09)[1]}
                                        valexpr="FIELD01"
                                        dispexpr={"FIELD01"}//FIELD02 not there in event object
                                        setEdit={(e) => {
                                            console.log("e", e);
                                            setM21({ ...M21, [item.S13F02]: e.FIELD01 })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'TEXTBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox position=""
                                        name={item?.S13F02}
                                        value={M21?.[item.S13F02]}
                                        setEdit={(e) => {
                                            setM21({ ...M21, [item.S13F02]: e.target.value })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'NUMBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLNumberBox
                                        name={item?.S13F02}
                                        value={M21?.[item.S13F02]}
                                        setEdit={(e) => {
                                            setM21({ ...M21, [item.S13F02]: e })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'COMBOBOX' &&
                            <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLComboBox
                                        name={item?.S13F02}
                                        value={M21?.[item.S13F02]}
                                        copno={'val1'}
                                        dispexpr="DisplayMember"
                                        valexpr="ValueMember"
                                        setEdit={(e) => {
                                            console.log(e);
                                            setM21({ ...M21, [item.S13F02]: e })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'DATEPICKER' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDateBox
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'TIMEPICKER' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLDateBox
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                        {
                            item?.S13F14 === 'MEMOBOX' && item?.S13F21 === 'N' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        name={item?.S13F02}
                                        value={M21?.[item.S13F02]}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'MEMOBOX' && item?.S13F21 === 'H' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        name={item?.S13F02}
                                        value={M21?.[item.S13F02]}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'FILEUPLOAD' && item?.S13F21 === 'I' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        name={item?.S13F02}
                                        value={M21?.[item.S13F02]}
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }

                        {
                            item?.S13F14 === 'FILEUPLOAD' && item?.S13F21 === 'A' && <Grid.Col span={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 0 }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: 12 }}>{item?.S13F07}:</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                    <PLTextBox
                                        setEdit={(e) => {
                                            console.log(e);
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        }
                    </Grid>
                }) : []
            }
            <Group spacing={'xs'} position="center" display={'flex'}>
                <Button
                    size='sm' style={{ width: 100 }}
                    onClick={saveBtn}
                >
                    Ok
                </Button>
            </Group>
        </>

    )

}