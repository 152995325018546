import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const TickerDetail = (props) => {
    const { value, setEditSend, obj } = props
    // console.log('obj ticker=>>',obj)
    const TickertDetailData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    // console.log('tickerData==>>', TickertDetailData)
    const {isLoading} = useSelector((state) => state.CompanySetupPopUp)
    const P_TICKERDT = useSelector((state) => state.CompanySetupPopUp.CmpSetUpPopUpData?.P_TICKERDT)
    const P_TICKERDTDT = useSelector((state) => state.CompanySetupPopUp.CmpSetUpPopUpData?.P_TICKERDTDT)

    const dispatch = useDispatch()

    const [multiSelectVal, setMultiSelectVal] = useState([])

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    useEffect(() => {
        if (multiSelectVal && multiSelectVal.length > 0) {
            let Arr = [];
            Arr = multiSelectVal.map((m) => {
                return m.TICF01
            })
            return setEditSend(Arr.toString())
        }
    }, [multiSelectVal])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                TickertDetailData &&
                <PLDataGrid
                dropdownPosition={true}
                    // width={"500px"}
                    dispexpr={"TICF02"}
                    valexpr={"TICF01"}
                    value={value}
                    TmData={P_TICKERDT}
                    data={P_TICKERDTDT}
                    isMulti={true}
                    setEdit={(e) => {
                        // setEditSend(e.TICF01)
                        let val = [];
                        if (e?.length > 0) {
                            e?.map((m) => {
                                val.push(m.original)
                            })
                            // console.log('val=>', val);
                            return setMultiSelectVal(val)
                        }
                    }}
                />
            }
        </div>
    )
}

export default TickerDetail