import { Grid, Text } from "@mantine/core";
import React, { forwardRef, useEffect, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { SaveProductData } from "../../utils/TransactionSlices/AutoBillSlice";
import Store from "../../utils/store";
import GlobalClass from "../../utils/GlobalClass";

const ProductMaster = forwardRef((props, ref) => {
  const [restField, setRestField] = useState({
    groupName1: "A",
    catName: "A",
    prdData: "A",
  });
  const [multiField, setMultiField] = useState({
    mGroupName1: "",
    mCatName: "",
    mPrtData: "",
  });

  const [multiCombo, setMultiCombo] = useState({
    gN: false,
    cN: false,
    pD: false,
  });
  const [accData, setAccData] = useState([]);

  let fieldData = props.data;
  // console.log(fieldData, "fieldData--");
  const M21 = fieldData?.P_M21DT?.jData;
  const M32G = fieldData?.P_M32GDT?.jData;
  const M32C = fieldData?.P_M32CDT?.jData;
  useEffect(() => {
    if (multiCombo.gN || multiCombo.cN || multiCombo.pD) {
      // console.log(M21, "inEff multiCombo", restField.groupName1);
      let accumulatedData = [];
      M21?.map((v) => {
        if (multiCombo.gN) {
          for (let index = 0; index < multiField.mGroupName1.length; index++) {
            const element = multiField.mGroupName1[index];
            if (v?.FIELD11 == element) {
              accumulatedData.push(v);
            }
          }
        }
        if (multiCombo.cN) {
          for (let index = 0; index < multiField.mCatName.length; index++) {
            const element = multiField.mCatName[index];
            // console.log(element, "Inmap");
            if (v?.FIELD12 == element) {
              accumulatedData.push(v);
            }
          }
        }
      });
      setAccData(accumulatedData);
    }
  }, [fieldData, multiCombo]);
  useEffect(() => {
    if (multiCombo.gN || multiCombo.cN) {
      // setfieldSelected({ ...fieldSelected, pD: "S" });
      if (Array.isArray(multiField.mPrtData))
        Store.dispatch(SaveProductData(multiField.mPrtData));
    } else {
      Store.dispatch(SaveProductData(""));
    }
  }, [multiField.mPrtData]);

  function renderCombo({ field, data }) {
    return (
      <>
        {data?.length > 0 ? (
          <>
            <PLComboBox
              multiple={true}
              dispexpr="FIELD02"
              valexpr="FIELD01"
              data={data}
              setEdit={(e) => {
                // console.log(e, "restField");
                setMultiField({ ...multiField, [field]: e });
              }}
            />
          </>
        ) : (
          <>
          <PLComboBox
              disabled={true}
            />
          </>
        )}
      </>
    );
  }

  // console.log(accData, "accData", fieldData);
  return (
    <>
      {/* <Grid> */}
      <Grid.Col
        span={12}
        style={{ display: "flex", alignItems: "center", padding: 0 }}
      >
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={3}>
            <Text style={{ fontSize: 12 }}>Group Name</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <PLComboBox
              // ref={ref}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, ref);
              }}
              value={restField?.groupName1}
              dispexpr={props.dispexpr}
              valexpr={props.valexpr}
              data={fieldData?.COP25}
              setEdit={(e) => {
                // console.log(e, "restField");
                setRestField({ ...restField, groupName1: e });
                if (e == "S") {
                  setMultiCombo({ ...multiCombo, gN: true });
                } else {
                  setMultiCombo({ ...multiCombo, gN: false });
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={3}>
            <Text style={{ fontSize: 12 }}>Category Name</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <PLComboBox
            // ref={ref}
            onKeyDown={(e) => {
              GlobalClass.formNextInput(e, ref);
            }}
              value={restField?.catName}
              dispexpr={props.dispexpr}
              valexpr={props.valexpr}
              data={fieldData?.COP25}
              setEdit={(e) => {
                // console.log(e, "restField");
                setRestField({ ...restField, catName: e });
                if (e == "S") {
                  setMultiCombo({ ...multiCombo, cN: true });
                } else {
                  setMultiCombo({ ...multiCombo, cN: false });
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{ display: "flex", alignItems: "center", padding: 0 }}
      >
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          {multiCombo.gN ? (
            <>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Select Groups</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  {renderCombo({ field: "mGroupName1", data: M32G })}
                </Grid.Col>
              </Grid.Col>
            </>
          ) : (
            ""
          )}
        </Grid.Col>
        {/* <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            padding: 0,
          }}
        > */}
        {multiCombo.cN ? (
          <>
            <Grid.Col
              span={6}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Select Categories</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                {renderCombo({ field: "mCatName", data: M32C })}
              </Grid.Col>
            </Grid.Col>
          </>
        ) : (
          ""
        )}
      </Grid.Col>
      {/* </Grid.Col> */}
      <Grid.Col
        span={12}
        style={{ display: "flex", alignItems: "center", padding: 0 }}
      >
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={3}>
            <Text style={{ fontSize: 12 }}>Product</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <PLComboBox
            // ref={ref}
            onKeyDown={(e) => {
              GlobalClass.formNextInput(e, ref);
            }}
              value={restField?.prdData}
              dispexpr={props.dispexpr}
              valexpr={props.valexpr}
              data={fieldData?.COP25}
              setEdit={(e) => {
                setRestField({ ...restField, prdData: e });
                if (e == "S") {
                  setMultiCombo({ ...multiCombo, pD: true });
                } else {
                  setMultiCombo({ ...multiCombo, pD: false });
                }
              }}
            />{" "}
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      {multiCombo.pD ? (
        <>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              padding: 0,
            }}
          >
            <Grid.Col span={3}>
              <Text style={{ fontSize: 12 }}>Select Products</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              {renderCombo({
                field: "mPrtData",
                data: accData,
              })}
            </Grid.Col>
          </Grid.Col>
        </>
      ) : (
        ""
      )}

      {/* </Grid> */}
    </>
  );
});
export default ProductMaster;
