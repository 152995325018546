import React from "react";
import Store from "../../utils/store";
import { Text } from "@mantine/core";
// import { notifications } from "@mantine/notifications";
import { ModalFunction } from "../../utils/slices/ModalSlice";
// import OpeningStockEditForm from "./OpeningStockEditForm";
import OpeningStockAddForm from "./OpeningStockForm";
// import { GetOpeningStockEnt } from "../../utils/TransactionSlices/openingStockEntSlice";

// import { GetOpeningStockTM } from "../../utils/TransactionSlices/openingStockSliceTM";
// import { GetOpeningStockGen } from "../../utils/TransactionSlices/openingStockSliceGen";
import { setToggleButton } from "../../utils/TransactionSlices/openingStockEntSlice";
// import { useSelector } from "react-redux";
import GlobalClass from "../../utils/GlobalClass";
import { TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";

export function VouEntAct(props) {
  const data = JSON.parse(props);
  if (data.id && data?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position:"right",
        MBody: (i) => <OpeningStockAddForm obj={data} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (data?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position:"right",
        MBody: (i) => <OpeningStockAddForm obj={data} index={i} />,
        // MBody: (i) => <OpeningStockForm/>,
        onClickCloseButton: () => {
          Store.dispatch(setToggleButton(false));
        },
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (data?.id && data?.p0 == "D") {
    if (window.confirm("Are you sure to delete selected data?")) {
       Store.dispatch(TransactionDeleteApi(data));
    } else {
      return null;
    }
  }
}
