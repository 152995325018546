import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAutoNumGenData, GetAutoNumTMFormat } from '../../../utils/slices/CompanySetUpPopUpSlice'
import { createColumnHelper } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import GlobalClass from '../../../utils/GlobalClass'
import { Grid } from '@mantine/core'
import ButtonPanel from '../../../PlusComponent/ButtonPanel'

const WaterMarkListModal = (props) => {
  const { data, obj } = props
  // console.log("WaterMarkListModal props", props)
  const isLoading = useSelector((state) => state.CompanySetupPopUp?.isLoading)
  const WaterMarkListTMData = useSelector((state) => state.CompanySetupPopUp?.cmpSetUpTMData)
  const WaterMarkListGenData = useSelector((state) => state.CompanySetupPopUp?.cmpSetUpGenData?.jData)
  const dispatch = useDispatch()

  const [columns, setColumns] = useState([])
  const [WMK, setWMK] = useState([])
  const [rowSelection, setRowSelection] = useState({});
  // console.log('rowSelection=>>', rowSelection)

  useEffect(() => {
    if (data) {
      Promise.resolve(dispatch(GetAutoNumGenData(data))).then(() => dispatch(GetAutoNumTMFormat(data)))
    }
  }, [data])

  useEffect(() => {
    if (WaterMarkListGenData) {
      setWMK(WaterMarkListGenData)
    }
  }, [WaterMarkListGenData])

  const columnHelper = createColumnHelper()

  useEffect(() => {
    if (WaterMarkListTMData && WaterMarkListTMData?.oDCFrmt && WaterMarkListTMData?.oDCFrmt?.aDCol) {
      var cols = [];
      WaterMarkListTMData?.oDCFrmt?.aDCol.map((v, i) => {
        cols.push(
          columnHelper.accessor(v.DispExpr, {
            header: v?.ColCap,
            enableColumnFilter: v.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: v.ColWidth,
            Cell: ({ cell }) => {
              var val = cell.getValue()
              return val
            }
          })
        )
      })
      setColumns(cols)
    }
  }, [WaterMarkListTMData])

  const simulateEnterKeyPress = () => {
    // Get the row element using the rowIndex
    const rowElement = document.querySelector(`table`);
    console.log('rowElement',rowElement)
    if (rowElement) {

      // Create a new KeyboardEvent with the "Enter" key
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        bubbles: true,
        cancelable: true,
      });

      // Dispatch the event on the row element
      rowElement.dispatchEvent(event);
    }
  }

  const table = useMantineReactTable({
    data: WMK ?? [],
    columns,
    enableColumnOrdering: true,
    enableGlobalFilter: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableRowDragging: false,
    enableStickyHeader: true,
    enableColumnDragging: false,
    mantineTableContainerProps: { sx: { maxHeight: '600px' } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    enableColumnActions: false,
    state: {
      density: "0px",
      rowSelection
    },
    enableRowVirtualization: false,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () =>
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        })),
      onDoubleClick: () => {
        Promise.resolve(
          setRowSelection((prev) => ({
            [row.id]: true
          }))
        ).then(() => {
          simulateEnterKeyPress()
        })
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    })
  })

  useEffect(() => {
    console.log("WaterMarkListGenData", WaterMarkListGenData)
    console.log("WaterMarkListTMData", WaterMarkListTMData)
  }, [WaterMarkListGenData, WaterMarkListTMData])

  return (
    <>
      {
        isLoading === true ? GlobalClass.RenderLoader("dots") :
          <>
            <Grid gutter={4}>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>
            </Grid>

            <Grid gutter={4}>
              <Grid.Col span={12}>
                <ButtonPanel
                  data={WaterMarkListTMData?.oS60?.DTHKey}
                  Skey={table?.getSelectedRowModel()?.flatRows[0]}
                  from={props?.obj?.id}
                />
              </Grid.Col>
            </Grid>
          </>
      }
    </>
  )
}

export default WaterMarkListModal