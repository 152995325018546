// ContextMenu.js
import { ScrollArea } from '@mantine/core';
import React from 'react';

const ContextMenu = ({ x, y, options, onClose }) => {
    
  return (
    <ScrollArea 
    className='contextmenu' 
    style={{
        position: 'absolute',
        top: y,
        left: x,
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0,0,0,0.3)',
        zIndex: 99999999,
        borderRadius:5,
        transition:'ease-in-out'
        // maxHeight:200
      }} 
      offsetScrollbars scrollbarSize={2} scrollHideDelay={0} h={200}
      onMouseLeave={onClose} >
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => {
            option.onClick();
            onClose();
          }}
          className='menu'
          style={{
            padding: '8px 16px',
            cursor: 'pointer',
            borderBottom: '1px solid #ddd',
            
          }}
        >
          {option.label}
        </div>
      ))}
      </ScrollArea>
  );
};

export default ContextMenu;
