import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const PlusM525 = (props) => {
    const { obj, value, setEditSend } = props
    const PLUSM525Data = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    console.log('PLUSM525Data', PLUSM525Data)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_M45E = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M45E)
    const P_M45EDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M45EDT)
    const dispatch = useDispatch()

    const [multiSelectVal, setMultiSelectVal] = useState([])

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    useEffect(() => {
        if (multiSelectVal && multiSelectVal.length > 0) {
            let Arr = [];
            Arr = multiSelectVal.map((m) => {
                return m.FIELD13
            })
            return setEditSend(Arr.toString())
        }
    }, [multiSelectVal])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader('dots') :
                    <PLDataGrid
                        dropdownPosition={true}
                        // width={"500px"}
                        valexpr={"FIELD13"}
                        TmData={P_M45E}
                        data={P_M45EDT?.jData}
                        isMulti={true}
                        setEdit={(e) => {
                            // console.log("e====>",e)
                            // setEditSend(e.FIELD13)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    val.push(m.original)
                                })
                                // console.log('val=>', val);
                                return setMultiSelectVal(val)
                            }
                        }}
                        value={value}
                    />
            }
        </div>
    )
}

export default PlusM525