import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GlobalClass from "../../../utils/GlobalClass";
import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Popover,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import PLDataGrid from "../../../PlusComponent/PLDataGrid";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLDateBox from "../../../PlusComponent/PLDateBox";
import PLNumberBox from "../../../PlusComponent/PLNumberBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import {
  BatchLogAddRemoveFun,
  TransactionApi,
} from "../../../utils/TransactionSlices/Transaction";
import { createColumnHelper } from "@tanstack/react-table";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { DataSaveLoading } from "../../../utils/slices/DataSaveLoadingSlice";
import gensetting from "../../../utils/gensetting";
import { ModalDelete } from "../../../utils/slices/ModalSlice";
import Store from "../../../utils/store";
import { GetAccountList, GetTMFormat } from "../../../utils/slices/AccountListSlice";
import { modals } from "@mantine/modals";
import CDExpenseEntry from "./CDExpenseEntry";
import OriginalInvoiceDetailTable from "./OriginalInvoiceDetailTable";
import CDUserField from "./CDUserField";
import ExpenseEntryForm from "../../SaleEntry/ExpenseEntryForm";
import { GetExpenseData, GetInvTypeDropDown } from "../../../utils/TransactionSlices/TSalesSlices";
import PLNarration from "../../../PlusComponent/PLNarration";
import DataGrid from "../../../components/DataGrid";
import CDUserFieldEntry from "./CDUserFieldEntry";
import { AssignItemDefault, MidCalc, RecalcFun, RetTaxAcc } from "../../SaleEntry/AssignItemDefaultFun";
import PLTextarea from "../../../PlusComponent/PLTextarea";

const CreditDebitNoteForm = (props) => {
  const { obj } = props;
  console.log("CreditDebitNoteForm props", obj);
  const formRef = useRef();
  const spoilerControlRef = useRef();
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.Transaction
  );
  const CreditDebitData = useSelector(
    (state) => state.Transaction?.TransactionData
  );
  console.log('CreditDebitData=>>', CreditDebitData)

  const { oYear } = useSelector((state) => state?.Language?.General);
  console.log('oYear=>>', oYear)

  var obj1 = {
    ...obj, "id": "P_T41",
    "p0": "P_T41",
    "p1": "P_T41",
    "p2": "TFORM0000001"
  }

  const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV);
  // const M01PRT = useSelector((state) => state.Transaction?.TransactionData?.M01PRT);
  const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11);
  const T02UF = useSelector((state) => state.Transaction?.TransactionData?.T02UF);
  const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
  const T02 = useSelector((state) => state.Transaction?.TransactionData?.T02);
  const T92 = useSelector((state) => state.Transaction?.TransactionData?.T92);
  const T92DT = useSelector((state) => state.Transaction?.TransactionData?.T92DT);
  const UFT41H = useSelector((state) => state.Transaction?.TransactionData?.UFT41H);
  const dtUFT11 = useSelector((state) => state.Transaction?.TransactionData?.dtUFT11);
  const T02ENT = useSelector((state) => state.Transaction?.TransactionData?.T02ENT);
  const M01P = useSelector((state) => state.Transaction?.TransactionData?.M01P);
  const M01PDT = useSelector((state) => state.Transaction?.TransactionData?.M01PDT);
  const M01D = useSelector((state) => state.Transaction?.TransactionData?.M01D);
  const M01DDT = useSelector((state) => state.Transaction?.TransactionData?.M01DDT);
  const M01SP = useSelector((state) => state.Transaction?.TransactionData?.M01SP);
  const M01SPDT = useSelector((state) => state.Transaction?.TransactionData?.M01SPDT);
  const COP75 = useSelector((state) => state.Transaction?.TransactionData?.COP75);
  const COP1 = useSelector((state) => state.Transaction?.TransactionData?.COP1);
  const COPV52 = useSelector((state) => state.Transaction?.TransactionData?.COPV52);
  const P_T41 = useSelector((state) => state.Transaction?.TransactionData?.P_T41);
  const P_T41DT = useSelector((state) => state.Transaction?.TransactionData?.P_T41DT);
  const dtExp_I = useSelector((state) => state.Transaction?.TransactionData?.dtExp_I);
  const M45IDT = useSelector((state) => state.Transaction?.TransactionData?.M45IDT);
  const M45I = useSelector((state) => state.Transaction?.TransactionData?.M45I);
  const PLDATA = useSelector((state) => state.Transaction?.TransactionData?.PLDATA);
  const lEditVou = useSelector((state) => state.Transaction?.TransactionData?.lEditVou);
  const ExpenseData = useSelector((state) => state?.TSalesSlices?.ExpenseData)
  console.log('ExpenseData', ExpenseData)
  const InvTypeDropDown = useSelector(
    (state) => state?.TSalesSlices?.InvTypeDropDown
  );
  const {
    isLoading: AccountisLoading,
    AccountListData: accList,
    TMFormatData: tmList
  } = useSelector((state) => state.AccountList);
  console.log('tmList', tmList, "accList", accList)

  const dispatch = useDispatch();

  const [InvTableData, setInvTableData] = useState([]);
  const [T02Data, setT02Data] = useState([]);
  const [T41Obj, setT41Obj] = useState({
    T92F00: "",
    FIELD04NM: "",
    FIELD05NM: "",
    FIELD01: "",
    FIELD02: "",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "0",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "HC",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    UBILTO: "",
    UGSTR: "",
    USALM: "",
    UDISP: "",
    UPAYT: "",
    UPR: "",
    UNAME: "",
    UOP: "",
    UTRA: "",
    UODP: "",
    UREMA: "",
    UEMAIL: "",
    URA: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UUASDF05: "",
    UUBAER: "",
    UUUBAER: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    UUASWER: "",
    UUUASWER: "",
    UCRE: "",
    UCRE2: "",
    UDCNO: "",
    UDE: "",
    UDEL: "",
    UDT: "",
    ULEN: "",
    ULRD: "",
    UOD: "",
    UODD: "",
    USAL: 0,
    UTR: "",
    UUUASD09: "",
    UUJA: "",
    UUUJBOS: "",
    UUUJOB: "",
    UUSALM: "",
    UUUOS: "",
    EA00000016: "",
    ED00000016: 0,
    EP00000016: 0,
    UREST6: "",
    UUPRODC: "",
    UUPRDID: "",
    UUTPR: "",
    UUPRTR: "",
    UUJBWID: "",
    UUJWTRID: "",
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: true,
    lMTaxEnt: false,
    T92F02: "",
    CFRMTITLE: "Add Credit Note (With Stock)",
    BTOBEFF: "C",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [T02Obj, setT02Obj] = useState({
    GRPNM: "",
    CATNM: "",
    F03NM: "",
    F20NM: "",
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: "",
    FIELD05: "",
    FIELD06: 0.0,
    FIELD07: 0.0,
    FIELD08: 0.0,
    FIELD09: 0.0,
    FIELD10: 0.0,
    FIELD11: "",
    FIELD12: "",
    FIELD56: "",
    FIELD13: "",
    FIELD14: "",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: "",
    FIELD19: "",
    FIELD20: "",
    FIELD21: 0.0,
    FIELD22: 0.0,
    FIELD23: 0.0,
    FIELD24: "",
    FIELD25: 0.0,
    FIELD26: "",
    FIELD27: "",
    FIELD28: false,
    FIELD29: 0.0,
    FIELD30: "",
    FIELD31: 0.0,
    FIELD32: 0.0,
    FIELD33: 0.0,
    FIELD41: "",
    FIELD50: 0.0,
    FIELD51: "",
    FIELD52: 0.0,
    FIELD71: "",
    FIELD81: 0.0,
    FIELD82: "",
    FIELD83: "",
    FIELD84: "",
    FIELD85: "",
    FIELD86: "",
    FIELD61: 0.0,
    FIELD62: 0.0,
    FIELD63: "",
    FIELD64: 0.0,
    FIELD65: "",
    FIELD66: 0.0,
    FIELD67: 0.0,
    FIELD72: "",
    FIELD68: "",
    FIELD69: "",
    FIELD53: "",
    FIELD54: 0.0,
    FIELD55: 0.0,
    FIELD091: 1.0,
    FIELD58: "",
    FIELD57: "",
    FIELD60: 0.0,
    T41PVT: "",
    FIELD87: 0.0,
    FIELD88: "",
    UGRO: 0.0,
    URATE: 0.0,
    UMUR: 0.0,
    UPE: 0.0,
    UAP: 0.0,
    URE: "",
    UISSUERA: 0.0,
    FLDUNQ: 0,
    FLDAED: "",
    FLDBRC: "",
    UUDIS: "",
    UUDISAAA: "",
    UUGROAAA: "",
    UUGR01: "",
    UUGRO: "",
    IA00000001: "",
    IA00000002: "",
    IA00000003: "",
    IA00000004: "",
    IA00000005: "",
    IA00000006: "",
    IA00000007: "",
    IA00000008: "",
    IA00000009: "",
    IA00000010: "",
    IA00000011: "",
    IA00000012: "",
    IA00000013: "",
    IA00000014: "",
    IA00000015: "",
    IA00000016: "",
    IA00000017: "",
    IA00000018: "",
    IA00000019: "",
    IA00000020: "",
    IA00000021: "",
    IA00000022: "",
    IA00000023: "",
    ID00000001: 0.0,
    ID00000002: 0.0,
    ID00000003: 0.0,
    ID00000004: 0.0,
    ID00000005: 0.0,
    ID00000006: 0.0,
    ID00000007: 0.0,
    ID00000008: 0.0,
    ID00000009: 0.0,
    ID00000010: 0.0,
    ID00000011: 0.0,
    ID00000012: 0.0,
    ID00000013: 0.0,
    ID00000014: 0.0,
    ID00000015: 0.0,
    ID00000016: 0.0,
    ID00000017: 0.0,
    ID00000018: 0.0,
    ID00000019: 0.0,
    ID00000020: 0.0,
    ID00000021: 0.0,
    ID00000022: 0.0,
    ID00000023: 0.0,
    IP00000001: 0.0,
    IP00000002: 0.0,
    IP00000003: 0.0,
    IP00000004: 0.0,
    IP00000005: 0.0,
    IP00000006: 0.0,
    IP00000007: 0.0,
    IP00000008: 0.0,
    IP00000009: 0.0,
    IP00000010: 0.0,
    IP00000011: 0.0,
    IP00000012: 0.0,
    IP00000013: 0.0,
    IP00000014: 0.0,
    IP00000015: 0.0,
    IP00000016: 0.0,
    IP00000017: 0.0,
    IP00000018: 0.0,
    IP00000019: 0.0,
    IP00000020: 0.0,
    IP00000021: 0.0,
    IP00000022: 0.0,
    IP00000023: 0.0,
    IT00000001: "",
    IT00000002: "",
    IT00000003: "",
    IT00000004: "",
    IT00000005: "",
    IT00000006: "",
    IT00000007: "",
    IT00000008: "",
    IT00000009: "",
    IT00000010: "",
    IT00000011: "",
    IT00000012: "",
    IT00000013: "",
    IT00000014: "",
    IT00000015: "",
    IT00000016: "",
    IT00000017: "",
    IT00000018: "",
    IT00000019: "",
    IT00000020: "",
    IT00000021: "",
    IT00000022: "",
    IT00000023: "",
    UDIS: 0.0,
    UUOS: "",
    UT76: "",
    USERFLD: "",
    USERFLDB: "2",
    SRDETAIL: "",
    EXPDUMMY: " ",
    BARCODE: "",
    OLDPRD: "",
    OLDQTY: 0.0,
    OQTY2: 0.0,
    OLDRATE: 0.0,
    LADDNEW: "Y",
    PRICELIST: " ",
    _BATSTOCK: " ",
    _LOCWISE: " ",
    LDUALSTK: " ",
    SRNOSTK: " ",
    MRPSTK: " ",
    RORI: " ",
    ORDDUMMY: " ",
    SRIDUMMY: " ",
    SRRDUMMY: " ",
    SRIDUMMYF: " ",
    SRRDUMMYF: " ",
    SRISSCD: "",
    OLDSRNO: "",
    SCNOCL: "",
    SCNOCL1: "",
    MANUDATE: "",
    EXPDATE: "",
    _EXPTOTALAMT: 0.0,
  });
  const [columns, setColumns] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [visibleField, setVisibleField] = useState({
    revisedVou: "",
    salesAccount: "",
    deliveryAt: "",
    applicableField: "",
  });
  const [NarrationData, setNarrationData] = useState([]);
  const [userFieldHideShow, setUserFieldHideShow] = useState("")
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  let DRA = {
    title: "",
    size: "md",
    body: "",
    open: false,
  }
  const [DrawerOBJ, setDrawerOBJ] = useState(DRA)
  const [FIELD01Value, setFIELD01value] = useState("");
  const [pagecCode, setPagecCode] = useState("");
  const [selectedProductObj, setSelectedProductObj] = useState({});
  const [rowSelection, setRowSelection] = useState({})
  const [tableExpenseVal, setTableExpenseVal] = useState(0);
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true
  })
  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    "Group": "",
    "Catogary": "",
    "Product": ""
  })
  const [InvAPIStatus, setInvAPIStatus] = useState(false)
  const [textBox, setTextBox] = useState(false)
  const [TableExpense, setTableExpense] = useState([]);
  const [LocationObj, setLocationObj] = useState(null)
  const [BatchIssueObj, setBatchIssueObj] = useState()
  const [recieptIssueVal, setRecieptIssueVal] = useState(null)
  const [cRorI, setcRorI] = useState(null)
  const [ExpenseArray, setExpenseArray] = useState({
    dtExp: null,
    dtExpDef: null,
    dtExp_I: null,
    dtExpDef_I: null,
    M01SP: null,
    M01SPDT: null
  })
  const [ExpenseArrayT02, setExpenseArrayT02] = useState([])
  const [ExpenseArrayT41, setExpenseArrayT41] = useState([])

  const pr = useRef();

  // Expense parameter
  const dtM14DT = Store.getState().Transaction?.TransactionData?.dtM14;
  const dtM51DT = Store.getState().Transaction?.TransactionData?.dtM51;
  const dtM52DT = Store.getState().Transaction?.TransactionData?.dtM52;
  const dtM46DT = Store.getState().Transaction?.TransactionData?.dtM46;

  function parseDateString(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Months are 0-indexed
    const day = dateString.substring(6, 8);

    return new Date(year, month, day);
  }

  // Example date string
  const dateString = T41Obj?.FIELD02;
  const date = parseDateString(dateString);

  // Step 2: Format the Date object
  function formatDate(date) {
    return date.toString(); // Converts to a string in the format you need
  }

  // Get formatted date string
  const formattedDate = formatDate(date);
  // console.log("formattedDate",formattedDate);

  useEffect(() => {
    if (formattedDate) {
      setSelectedDay(formattedDate)
    }
  }, [formattedDate])

  // for hide and visible field
  useEffect(() => {
    if (
      OS11?.S11F01 === "HC" &&
      oYear?.OSC?._MTAXPURC === "Y" &&
      oYear?.OSC?._MTAXSALE === "Y"
    ) {
      setT41Obj({ ...T41Obj, lMTaxEnt: true });
    }

    if (T41Obj?.lMTaxEnt && oYear && OS11) {
      setVisibleField({
        ...visibleField,
        revisedVou: oYear?.OSC?._REVISEVOU,
        salesAccount: T41Obj?.lMTaxEnt,
        deliveryAt: oYear?._ECOMDLADD,
        applicableField: oYear?.OSC?._GR1RTTAX,
      });
    }
  }, [OS11, oYear, T41Obj?.lMTaxEnt]);

  useEffect(() => {
    if (oYear) {
      setUserFieldHideShow(oYear?.OSC?._USERFIELD)
    }
  }, [oYear])

  // console.log('JSON.stringify(T02) === JSON.stringify(T02Data)',JSON.stringify(T02) === JSON.stringify(T02Data))
  // console.log('JSON.stringify(T41) === JSON.stringify(T41Obj)',JSON.stringify(T41) === JSON.stringify(T41Obj))

  useEffect(() => {
    console.log('T41Obj', T41Obj)
    console.log('T02Obj ', T02Obj)
    console.log('cRorI', cRorI)
    console.log('T02Data', T02Data)
    console.log('BillSeriesHideShow', BillSeriesHideShow)
    console.log('DrawerOBJ', DrawerOBJ)
    console.log('tableEditingRow', tableEditingRow)
    // console.log('recieptIssueVal', recieptIssueVal)
    console.log('selectedProductObj', selectedProductObj)
  }, [T41Obj, T02Obj, T02Data, selectedProductObj, recieptIssueVal, cRorI, BillSeriesHideShow, DrawerOBJ, tableEditingRow])

  useEffect(() => {
    if (obj.page == "03510342") {
      setPagecCode("HCHC")
    } else if (obj.page == "03540342") {
      setPagecCode("HDHD")
    }
  }, [obj.page])

  useEffect(() => {
    if (obj) {
      Promise.resolve(dispatch(TransactionApi(obj))).then(() => setInvAPIStatus(true))
        .then(() => {
          Promise.resolve(dispatch(GetAccountList({
            "id": "T_M32G",
            "name": "",
            "type": "A",
            "p0": "T_M32G",
            "p1": "T_M32G",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetTMFormat({
            "id": "T_M32G",
            "name": "",
            "type": "A",
            "p0": "T_M32G",
            "p1": "T_M32G",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetAccountList({
            "id": "T_M32C",
            "name": "",
            "type": "A",
            "p0": "T_M32C",
            "p1": "T_M32C",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetTMFormat({
            "id": "T_M32C",
            "name": "",
            "type": "A",
            "p0": "T_M32C",
            "p1": "T_M32C",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetTMFormat({
            "name": "",
            "type": "A",
            "id": "P_T41",
            "p0": "P_T41",
            "p1": "P_T41",
            "p2": "TFORM0000001",
          })))
        })
      // .then(() => invTypeApiCall()).then(() => dispatch(GetAccountList({
      //   "id": "P_M21",
      //   "name": "",
      //   "type": "A",
      //   "p0": "P_M21",
      //   "p1": "P_M21",
      //   "p2": "",
      //   "p3": ""
      // }))).then(() => dispatch(GetTMFormat({
      //   "id": "P_M21",
      //   "name": "",
      //   "type": "A",
      //   "p0": "P_M21",
      //   "p1": "P_M21",
      //   "p2": "",
      //   "p3": ""
      // })))
    }
  }, [obj]);

  useEffect(() => {
    setT02Obj({
      ...T02Obj,
      FIELD27: priceListParemeter?.priceListCode
    })
  }, [priceListParemeter])

  // Receipt/Issue value set
useEffect(() => {
if(oYear?.OSC?._CDINITEM == "N"){
  Promise.resolve().then(() => setT02Obj({
    ...T02Obj,
    FIELD05:cRorI ?? ""
  }))
}
},[cRorI, oYear])

  useEffect(() => {
    if (oYear?.OSC?._CDINITEM == "N") {
      console.log("Condition one");
      Promise.resolve().then(() => {
        setT02Obj({
          ...T02Obj,
          // FIELD05: cRorI,
          FIELD07: cRorI === "C" ? selectedProductObj?.FIELD21 : cRorI === "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22 ?? 0
        })
      })
    } else {
      console.log("else condition")
      setT02Obj({
        ...T02Obj,
        FIELD07: T02Obj?.FIELD05 === "C" ? selectedProductObj?.FIELD21 : T02Obj?.FIELD05 === "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22 ?? 0,
      })
    }
  }, [oYear, cRorI, selectedProductObj])

  useEffect(() => {
    if (OS11?.S11F22) {
      let ReceiptOrIssue = ("R".includes(OS11?.S11F22) || "C".includes(OS11?.S11F22)) ? "C" : "D";
      setcRorI(ReceiptOrIssue)
    }
  }, [OS11])

  // Location value set
  // useEffect(() => {
  //   if (LocationObj) {
  //     Promise.resolve().then(() => {
  //       setT02Obj({
  //         ...T02Obj,
  //         FIELD20: LocationObj?.FIELD01 ?? "",
  //         F20NM: LocationObj?.FIELD02 ?? ""
  //       })
  //     })
  //   }
  // }, [LocationObj, T02Obj?.FIELD20])

  useEffect(() => {

    Promise.resolve().then(() => {
      const newT02Obj = {
        ...T02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? "",
        FIELD41: BatchIssueObj?.FIELD02 ?? ''
      };
      setT02Obj(newT02Obj);
    });
  }, [BatchIssueObj, T02Obj?.FIELD11])

  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
    }
  }, [BillSeriesHideShow.BillNoVal])

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError, isLoading]);

  useEffect(() => {
    if (T02) {
      // const updatedT02 = T02.slice(0, -1);
      const updatedT02 = T02?.filter((v, i) => v?.FIELD03 !== "")
      setT02Data(updatedT02);
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0]);
      }
    }
  }, [T02]);

  useEffect(() => {
    if (T41) {
      setT41Obj(T41);
    }
  }, [T41]);

  // useEffect(() => {
  //   if (ExpenseData) {
  //     console.log("t02Obj==>", T02Obj);
  //     let newObj = {};
  //     Object.keys(T02Obj).map((t) => {
  //       ExpenseData?.dtExp_I?.map((e) => {
  //         if (('IA' + e?.FIELD54 || 'ID' + e?.FIELD54 || 'IP' + e?.FIELD54 || 'IT' + e?.FIELD54) != t) {
  //           newObj = { ...newObj, ['IA' + e?.FIELD54]: "", ['ID' + e?.FIELD54]: "", ['IP' + e?.FIELD54]: "", ['IT' + e?.FIELD54]: "" }
  //         }
  //       })
  //     })
  //     setT02Obj({ ...T02Obj, ...newObj })
  //   }
  // }, [ExpenseData])

  function parseStringToObjectArray() {
    const segments = T41Obj.FIELD55.split('~R~');

    const objectsArray = segments.map(segment => {
      // Remove leading and trailing '
      const cleanedSegment = segment.replace(/^'(.*)'$/, '$1');

      const keyValuePairs = cleanedSegment.split('~C~');
      console.log('keyValuePairs', keyValuePairs)

      const obj = {
        VouNo: keyValuePairs[1].replace(/^''|''$/g, ''),
        Date: keyValuePairs[2].replace(/^''|'$/g, '')
      };
      console.log('obj of create obj', obj)
      return obj;
    });
    return setInvTableData(objectsArray);
  }

  useEffect(() => {
    if (obj?.p0 == "E" && T41Obj?.FIELD48 == '' && T41Obj?.FIELD55 !== '') {
      parseStringToObjectArray();
      setTextBox(true)
    } else {
      setTextBox(false)
    }
  }, [obj, T41Obj?.FIELD48, T41Obj?.FIELD55])

  useEffect(() => {
    console.log('InvTableData', InvTableData)
    console.log('textBox', textBox)
  }, [InvTableData, textBox])

  useEffect(() => {
    if (InvAPIStatus == true && obj.p0 == "E" && CreditDebitData?.T41) {
      invTypeApiCall()
    }
  }, [CreditDebitData?.T41, obj, InvAPIStatus])

  const invTypeApiCall = () => {
    console.log('pagecCode', pagecCode)
    // if (obj.p0 == "E") {
    dispatch(GetInvTypeDropDown(CreditDebitData?.T41?.FIELD13));
    dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: CreditDebitData?.T41?.FIELD13 }))
    setInvAPIStatus(false)
    // }
  }

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const seperate1 = (e) => {
    console.log('e=====>', e)
    let val = []
    if (T41Obj?.FIELD55 !== '') {
      const rows = e.split('~R~')
      console.log('row', rows)
      const parsedData = rows.map(row => row.split('~C~'));
      console.log('parsedData', parsedData);
      if (parsedData && parsedData.length > 0) {
        parsedData.map((v, i) => {
          const cleanValue1 = v[0]?.replace(/^''|''$/g, '');
          const cleanValue2 = v[1]?.replace(/^''|''$/g, '');

          if (T41Obj?.FIELD48 !== '') {
            console.log('cleanValue1', cleanValue1)
            val.push(cleanValue1);
          } else {
            console.log('cleanValue2', cleanValue2)
            val.push(cleanValue2);
          }
        });
      }
      console.log('val', val.join(','))
      return val.join(',')
    }
  }

  let stringOfNames;

  useEffect(() => {
    if (SelectedNarrationData.length >= 0) {
      const stringifyArray = SelectedNarrationData.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  // expense Array Set
  useEffect(() => {

    console.log("ExpenseData =>", ExpenseData);

    setExpenseArray({
      ...ExpenseArray,
      dtExp: ExpenseData?.dtExp,
      dtExpDef: ExpenseData?.dtExpDef,
      dtExp_I: ExpenseData?.dtExp_I,
      dtExpDef_I: ExpenseData?.dtExpDef_I,
      M01SP: ExpenseData?.M01SP,
      M01SPDT: ExpenseData?.M01SPDT?.jData
    })
  }, [ExpenseData])

  let ExpenseObj = {
    OS11,
    OSV,
    oYear,
    M01SP: ExpenseArray?.M01SP,
    M01SPDT: ExpenseArray?.M01SPDT,
    dtM14DT,
    dtM51DT,
    dtM52DT,
    dtM46DT,
    dtExp: ExpenseArray?.dtExp,
    dtExpDef: ExpenseArray?.dtExpDef,
    dtExp_I: ExpenseArray?.dtExp_I,
    dtExpDef_I: ExpenseArray?.dtExpDef_I,
    InvTypeDropDown,
    ItemCode: selectedProductObj?.FIELD03,
    selectedProductObj,
    T02Obj: { ...T02Obj, VorC: T41Obj?.FIELD45 },
    T41Obj,
    T02Data
  }

  useEffect(() => {
    // InvTypeDropDown
    console.log("ExpenseArray useEffect=>", ExpenseArray)
    if (selectedProductObj && T02Obj?.FIELD03) {
      // let ExpenseObj = { OS11, OSV, oYear, M01SP: ExpenseArray?.M01SP, M01SPDT: ExpenseArray?.M01SPDT, dtM14DT, dtM51DT, dtM52DT, dtM46DT, dtExp: ExpenseArray?.dtExp, dtExpDef: ExpenseArray?.dtExpDef, dtExp_I: ExpenseArray?.dtExp_I, dtExpDef_I: ExpenseArray?.dtExpDef_I, InvTypeDropDown, ItemCode: selectedProductObj?.FIELD03, selectedProductObj, T02Obj, T41Obj }

      console.log('RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj) =>', RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj));

      Promise.resolve().then(() => { return RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj) }).then((res) => console.log("RetTaxAcc response =>", res))

      Promise.resolve(setT02Obj({
        ...T02Obj,
        FIELD16: RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj)
      })).then(() => {
        // setTimeout(() => {
        Promise.resolve().then(() => setExpenseArrayT02(AssignItemDefault(ExpenseObj).dtExpArr)).then(() => setT02Obj(AssignItemDefault(ExpenseObj).T02Object))
        // }, 500)
      })
    }
  }, [selectedProductObj, T02Obj?.FIELD03, T02Obj?.FIELD16])

  useEffect(() => {
    // FIELD45: e.FIELD20, FIELD13
    if (T02Obj?.FIELD16) {
      Promise.resolve().then(() => {
        return RecalcFun(T41Obj, "", false, "", ExpenseObj)
      }).then((res) => {
        console.log("Footer Expense Response =>", res)
        // Promise.resolve(SetFieldObj(res?.T02CalAarray)).then(() => setDtExpTableField(res?.ExpenseCalArray))
        Promise.resolve(setExpenseArrayT41(res?.ExpenseCalArray)).then(() => setT41Obj(res?.T02CalAarray))
      })
    }

  }, [T02Obj?.FIELD16])

  useEffect(() => {
    if (T02Data) {
      Promise.resolve().then(() => { return MidCalc(ExpenseObj) }).then((res) => {
        console.log("MidCalc(ExpenseObj).oT41D =>", res)
        setT41Obj(res?.T41Object)
        return RecalcFun(res?.T41Object, "", false, "", ExpenseObj)
      }).then((res) => {
        console.log("RecalcFun response =>", res);
      })

    }
  }, [T02Data])

  const handleSubmit = () => {
    if (obj.p0 == "E" && ((JSON.stringify(T02) === JSON.stringify(T02Data)) && (JSON.stringify(T41) === JSON.stringify(T41Obj)))) {
      Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
      return;
    }
    dispatch(DataSaveLoading(true));
    var JData = { T41: { ...T41Obj }, T02: T02Data };
    console.log('jdata', JData)
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0,
      cCode:
        obj.p0 == "E"
          ? obj.id
          : pagecCode,
      cSData: JSON.stringify(JData),
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0,
      cCode:
        obj.p0 == "E"
          ? obj.id
          : pagecCode,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() =>
              GlobalClass.Notify(
                "info",
                obj.p0 == "A" ? "Adding" : "Editing",
                "Please wait while we process your data"
              )
            )
            .then(() => {
              if (obj.p0 == "A") {
                Promise.resolve(dispatch(TransactionApi(obj))).then(() => setInvAPIStatus(true))
                  .then(() => {
                    Promise.resolve(dispatch(GetAccountList({
                      "id": "T_M32G",
                      "name": "",
                      "type": "A",
                      "p0": "T_M32G",
                      "p1": "T_M32G",
                      "p2": "",
                      "p3": ""
                    }))).then(() => dispatch(GetTMFormat({
                      "id": "T_M32G",
                      "name": "",
                      "type": "A",
                      "p0": "T_M32G",
                      "p1": "T_M32G",
                      "p2": "",
                      "p3": ""
                    }))).then(() => dispatch(GetAccountList({
                      "id": "T_M32C",
                      "name": "",
                      "type": "A",
                      "p0": "T_M32C",
                      "p1": "T_M32C",
                      "p2": "",
                      "p3": ""
                    }))).then(() => dispatch(GetTMFormat({
                      "id": "T_M32C",
                      "name": "",
                      "type": "A",
                      "p0": "T_M32C",
                      "p1": "T_M32C",
                      "p2": "",
                      "p3": ""
                    }))).then(() => dispatch(GetTMFormat({
                      "name": "",
                      "type": "A",
                      "id": "P_T41",
                      "p0": "P_T41",
                      "p1": "P_T41",
                      "p2": "TFORM0000001",
                    })))
                  })
              } else {
                dispatch(ModalDelete(props.index))
              }
            })
            .then(() => {
              if (obj.page === "03510342") {
                dispatch(
                  GetAccountList({
                    id: "03510342",
                    name: "Credit Note",
                    p0: "MT",
                    p1: "TXNVOULISTHC",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["03510342"],
                  })
                );
              } else if (obj.page === "03540342") {
                dispatch(
                  GetAccountList({
                    id: "03540342",
                    name: "Debit Note",
                    p0: "MT",
                    p1: "TXNVOULISTHD",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["03540342"],
                  })
                );
              }
            })
            .then(() =>
              GlobalClass.Notify(
                "success",
                obj.p0 == "A" ? "Added" : "Edited",
                obj.p0 == "A" ? "Added successfully" : "Edited successfully"
              )
            ).then(() => Store.dispatch(GetInvTypeDropDown(null)))
        } else {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)));
      });
  };

  const NextIndexFun = (index) => {
    const nextInd = index + 1;
    console.log("nextInd=>", nextInd)
    const nextInput = document.getElementById(`T02Ent_${nextInd}`)
    console.log("nextInput=>", nextInput)

    if (nextInput) {
      nextInput.focus()
    }
  }

  const columnHelper = createColumnHelper();

  const Summary = (obj, i, tag) => {
    // let stype = "sum";
    var value = "";

    switch (obj?.S53F03) {
      case "Product Name":
        value = (
          <Text py={2}>
            {" "}
            Total Entry :{" "}
            {/* {selectedRow ? TotalRow + "/" + (selectedRow?.index) : TotalRow} */}
            {/* {TotalRow == 0 ? 0 : TotalRow} */}
          </Text>
        );
        break;
      case "Qty":
        value = QtySum;
        break;
      case "F. Qty":
        value = T02Data?.reduce((a, b) => (a = a + b?.FIELD81), 0);
        break;
      case "Amount":
        value = rowSum;
        break;
      case "Qty-2":
        value = Qty2Sum;
        break;
      default:
        value = null;
        break;
    }
    return value;
  };

  let receiptIssueVal = null;
  const renderInputBox = (uID, v, c, cellid, index) => {
    if (oYear?.OSC?._CDINITEM == "N") {
      receiptIssueVal = cRorI
    }
    if (c?.S53F07 == 0) {
      return <Button
        id={`T02Ent_${index}`}
        variant="default"
        onFocus={() => {
          document.activeElement.blur()
          console.log("Test=>", `T02Ent_${index}`)
          HiddenButtonField(uID, v, c, cellid, index)
        }}
        onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
        style={{ padding: "0px", margin: "0px" }}
      />
    } else {
      let setInput;
      switch (c?.S53F14) {
        case "PLPOPUP":
          if (c.S53F02 == "E000T02F03") { // Product Name
            setInput = <PLDataGrid
              ref={pr}
              id={`T02Ent_${index}`}
              width={'500px'}
              // autoFocus={true}
              zIndex={1010}
              position={"bottom"}
              value={{ value: T02Obj?.[c?.S53F04], label: T02Obj?.F03NM == "" ? "Select" : T02Obj?.F03NM }}
              p1={seperate(c.S53F15)[1]}
              cWhere={`M21.FIELD11 LIKE '%${T02Obj?.GRPCD ?? ""}%' AND M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}
              // TmData={tmList["P_M21"]?.cSTID}
              // data={accList["P_M21"]}
              setEdit={(e) => {
                console.log("Product Name PLDataGrid", e);
                Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01, F03NM: e?.FIELD02, VorC: T41Obj?.FIELD45, FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28 }))
                  .then(() => setSelectedProductObj(e))
                  .then(() => setPriceListParemeter({ ...priceListParemeter, productCode: e.FIELD01 }))
                  .then(() => setFIELD01value(e.FIELD01))
                  .then(() => {
                    if (T02Obj?.FIELD05 == "D" && selectedProductObj?.FIELD51 !== "N") {
                      console.log('if condition')
                      setTimeout(() => {
                        NextIndexFun(index + 1)
                      }, 500)
                    } else {
                      console.log('else condition')
                      setTimeout(() => {
                        NextIndexFun(index + 2)
                      }, 500)
                    }
                  })
              }}
            />
          } else if (c.S53F02 === "E00T02F20") { // Location
            setInput = (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                value={{ value: T02Obj[c?.S53F04], label: T02Obj?.F20NM == "" ? "Select" : T02Obj?.F20NM }}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log('DataGrid object=>>', e)
                  Promise.resolve(setLocationObj(e))
                    .then(() => {
                      setT02Obj({
                        ...T02Obj,
                        F20NM: e?.FIELD02,
                        FIELD20: e?.FIELD01
                      })
                    })
                    .then(() => {
                      if (selectedProductObj?.FIELD51 === "N" && dtUFT11?.length > 0) {
                        setTimeout(() => {
                          NextIndexFun(index)
                        }, 500)
                      } else {
                        setTimeout(() => {
                          NextIndexFun(index + 1)
                        }, 500)
                      }
                    })
                }}
              />
            );
          } else {
            setInput = (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                value={T02Obj[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log('DataGrid object=>>', e)
                  if (c.S53F02 == "E00T02F20") {
                    console.log("Location==>", e);
                    Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 })).then(() => {
                      // setLocationObj(e)
                      if (selectedProductObj?.FIELD51 === "N") {
                        setTimeout(() => {
                          NextIndexFun(index)
                        }, 500)
                      } else if (typeof PLDATA !== "string") {
                        setTimeout(() => {
                          NextIndexFun(index + 1)
                        }, 500)
                      } else {
                        setTimeout(() => {
                          NextIndexFun(index + 2)
                        }, 500)
                      }
                    })
                  }
                }}
              />
            );
          }
          break;
        case "COMBOBOX":
          if (c.S53F02 == "E00T02RORI") {  // Receipt/Issue
            setInput = <PLComboBox
              cmbid={`T02Ent_${index}`}
              value={T02Obj?.[c?.S53F04]}
              copno={seperate(c.S53F15)[1]}
              dispexpr={"DisplayMember"}
              valexpr={"ValueMember"}
              setEdit={(e) => {
                console.log("COMBOBOX Reciept/Issue ==>", e);
                Promise.resolve(setRecieptIssueVal(e))
                  .then(() => setT02Obj({ ...T02Obj, [c?.S53F04]: e.value }))
                  .then(() => {
                    if (selectedProductObj?.FIELD51 == "Y" && T02Obj?.FIELD05 == "D" && oYear?.OSC?._BATCHSTOC == "Y") {
                      console.log('if condi')
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    } else if (oYear?.OSC?._BATCHSTOC == "N") {
                      console.log('else cond')
                      setTimeout(() => {
                        NextIndexFun(index + 1)
                      }, 500)
                    } else {
                      setTimeout(() => {
                        NextIndexFun(index + 2)
                      }, 500)
                    }
                  }
                  )
                // FIELD07: T02Obj.FIELD05 == "C" ? M21.FIELD21 : T02Obj.FIELD05 == "R" ? M21.FIELD21 : M21.FIELD22
              }}
            />
          } else {
            setInput = (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                // initiallyOpened={initiallyOpened}
                value={T02Obj[c?.S53F04]}
                copno={seperate(c.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })).then(() => {
                    setTimeout(() => {
                      NextIndexFun(index)
                    }, 500)
                  })
                }}
              />
            );
          }
          break;
        case "TEXTBOX":
          if (c.S53F02 == "E00T02F41") { // Batch Receipt
            setInput = (
              <>
                <PLTextBox
                  disabled={selectedProductObj?.FIELD51 === "N" || T02Obj?.FIELD05 == "D" ? true : false}
                  id={`T02Ent_${index}`}
                  value={T02Obj[c?.S53F04]}
                  onKeyDown={(e) => {
                    if (e.key == "Tab") {
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    }
                  }}
                  setEdit={(e) => {
                    setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                  }}
                />
              </>
            );
          } else {
            setInput = (
              <PLTextBox
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    setTimeout(() => {
                      NextIndexFun(index)
                    }, 500)
                  }
                }}
              />
            );
          }
          break;
        case "NUMBOX":
          if (c?.S53F02 == "E00T02F06") { // Qty
            setInput = (
              <PLNumberBox
                // disabled={selectedProductObj?.FIELD59 == "N" ? true : false}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    if (selectedProductObj?.FIELD51 === "Y" && T02Obj?.FIELD05 == "C" && oYear?.OSC?._BATCHSTOC == "Y") {
                      console.log('if Condition')
                      NextIndexFun(index + 1)
                    } else if (oYear?.OSC?._BATCHSTOC == "N") {
                      console.log('else if')
                      NextIndexFun(index)
                    } else if (selectedProductObj?.FIELD51 === "N" && oYear?.OSC?._BATCHSTOC == "Y") {
                      console.log('else condi')
                      NextIndexFun(index)
                    } else if (oYear?.OSC?._LOCATIONW == "Y") {
                      NextIndexFun(index + 1)
                    } else {
                      NextIndexFun(index + 2)
                    }
                  }
                }}
              />
            );
          } else {
            setInput = (
              <PLNumberBox
                decimalSeparator={c.S53F02 == "E00T02F08" || c.S53F02 == "E00T02F07" ? true : false}
                precision={c.S53F02 == "E00T02F08" || c.S53F02 == "E00T02F07" ? 3 : 0}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                disabled={c.S53F02 == "E00T02F21" && c?.S53F02 == "E00T02F22" && selectedProductObj?.FIELD59 == "N" ? true : false} // Qty - 2 and conversion
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab" || e.key == "Enter") {
                    if (c.S53F02 == "E00T02F08") { // Amount
                      if (T02Obj.FIELD08 == 0 || InvTypeDropDown == null) {
                        console.log('amount if')
                        if (tableEditingRow == null) {
                          console.log("Add Product Call")
                          if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                          } else {
                            Promise.resolve().then(() => handleCreateUser()).then(() =>
                              setTimeout(() => {
                                if (obj.p0 == "E") {
                                  setT02Obj(T02?.[T02?.length - 1]);
                                } else {
                                  setT02Obj(T02?.[0])
                                }
                                table.setCreatingRow(true)
                              }, 500)
                            )
                          }
                        } else {
                          console.log("Edit Product Call")
                          if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                          } else {
                            Promise.resolve().then(() => handleEditSave())
                              .then(() => table.setEditingRow(null))
                              .then(() => {
                                setTimeout(() => {
                                  if (obj.p0 == "E") {
                                    setT02Obj(T02?.[T02?.length - 1]);
                                  } else {
                                    setT02Obj(T02?.[0])
                                  }
                                  table.setCreatingRow(true)
                                }, 500)
                              })
                          }
                        }
                      }
                    } else {
                      console.log('amount else ')
                      NextIndexFun(index - 1)
                    }
                  }
                }}
              // onKeyUp={(e) => {
              //   console.log("Enter key Press", e)
              //   if (c.S53F02 == "E00T02F08") { // Amount
              //     if (e.key == "Enter") {
              //       if (tableEditingRow == null) {
              //         console.log("Add Product Call")
              //         if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
              //           GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
              //         } else {
              //           Promise.resolve(handleCreateUser()).then(() => table.setCreatingRow(true))
              //         }
              //       } else {
              //         console.log("Edit Product Call")
              //         if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
              //           GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
              //         } else {
              //           return Promise.resolve(handleEditSave()).then(() => table.setCreatingRow(true))
              //         }
              //       }
              //     }
              //   }
              // }}
              />
            );
          }
          break;
        default:
          setInput = null;
          break;
      }
      return setInput;
    }
  };

  const HiddenButtonField = (uID, v, c, cellid, index) => {
    switch (c.S53F02) {
      case "E00ORDDUM": // Dummy Order 
        setDrawerOBJ({
          ...DRA,
          title: <Text fw={700}>{c.S53F03}</Text>,
          body: <>
            <Text>{c.S53F03}</Text>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
              <Button
                onClick={() => {
                  // setDrawerObj({ ...DrawerObj, open: false })
                  Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => {
                    if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" && T02Obj?.FIELD05 == "D" && oYear?.OSC?._BATCHSTOC == "Y") {
                      // setTimeout(() => {
                      NextIndexFun(index)
                      // }, 500)
                    } else if (selectedProductObj?.FIELD59 !== "N" && oYear?.OSC?._BATCHSTOC == "N") {
                      // setTimeout(() => {
                      NextIndexFun(index)
                      // }, 500)
                    } else {
                      // setTimeout(() => {
                      NextIndexFun(index + 1)
                      // }, 500)
                    }
                  })
                }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => {
                      if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" && T02Obj?.FIELD05 == "D" && oYear?.OSC?._BATCHSTOC == "Y") {
                        // setTimeout(() => {
                        NextIndexFun(index)
                        // }, 500)
                      } else if (selectedProductObj?.FIELD59 !== "N" && oYear?.OSC?._BATCHSTOC == "N") {
                        // setTimeout(() => {
                        NextIndexFun(index)
                        // }, 500)
                      } else {
                        // setTimeout(() => {
                        NextIndexFun(index + 1)
                        // }, 500)
                      }
                    })
                  }
                }}
              >Ok</Button>
            </div>
          </>,
          open: true,
          size: "sm",
          position: "right"
        })
        break;
      case "E00T02F11": // Batch Issue
        console.log("T02Obj?.FIELD05=>", T02Obj?.FIELD05);
        if (T02Obj?.FIELD05 == "D" && selectedProductObj?.FIELD51 !== "N") {
          return setDrawerOBJ({
            ...DRA,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              console.log('close button')
              Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <PLDataGrid
                autoFocus={true}
                // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                id={`T02Ent_${index}`}
                cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                width={"500px"}
                value={T02Obj?.[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                // p1={'P_M21'}
                setEdit={(e) => {
                  setBatchIssueObj(e)
                }}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false }))
                      .then(() => {
                        if (T02UF?.length > 0) {
                          // setTimeout(() => {
                          NextIndexFun(index)
                          // }, 500)
                        } else if (T02UF?.length == 0) {
                          NextIndexFun(index + 1)
                        } else if (selectedProductObj?.FIELD59 !== "N" && oYear?.OSC?._DUALSTOCK == "Y") {
                          // setTimeout(() => {
                          NextIndexFun(index)
                          // }, 500)
                        } else {
                          // setTimeout(() => {
                          NextIndexFun(index + 1)
                          // }, 500)
                        }
                      })
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false }))
                        .then(() => {
                          if (T02UF?.length > 0) {
                            // setTimeout(() => {
                            NextIndexFun(index)
                            // }, 500)
                          } else if (selectedProductObj?.FIELD59 !== "N" && oYear?.OSC?._DUALSTOCK == "Y") {
                            // setTimeout(() => {
                            NextIndexFun(index)
                            // }, 500)
                          } else {
                            // setTimeout(() => {
                            NextIndexFun(index + 1)
                            // }, 500)
                          }
                        })
                    }
                  }}
                >OK</Button>
              </div>
            </>,
            open: true,
            size: "40%",
            position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00USERFLD": // User FIeld
        console.log('userField')
        if (T02UF && T02UF?.length > 0) {
          setDrawerOBJ({
            ...DRA, title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              console.log('close button')
              // setDrawerObj(dra);
              Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => {
                if (selectedProductObj?.FIELD59 == "N") {
                  console.log('if condition')
                  // setTimeout(() => {
                  NextIndexFun(index + 2)
                  // }, 500)
                } else {
                  console.log('else condition')
                  // setTimeout(() => {
                  NextIndexFun(index)
                  // }, 500)
                }
              })
            },
            body: <>
              <CDUserFieldEntry
                T02UF={T02UF}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve()
                    .then(() => setT02Obj(e))
                    .then(() => {
                      setTimeout(() => {
                        if (selectedProductObj?.FIELD59 == "N") {
                          console.log('if condition')
                          // setTimeout(() => {
                          NextIndexFun(index + 2)
                          // }, 500)
                        } else {
                          console.log('else condition')
                          // setTimeout(() => {
                          NextIndexFun(index)
                          // }, 500)
                        }
                      }, 500)
                    })
                }}
                DrawerObjSend={DrawerOBJ}
                setDrawerObjSend={(e) => {
                  setDrawerOBJ(e)
                }}
              />
            </>, open: true, size: "sm", position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00UFBAT": // Batch User FIeld
        console.log('batch User Field')
        if (selectedProductObj?.FIELD51 === "N" && dtUFT11 && dtUFT11.length > 0) {
          setDrawerOBJ({
            ...DRA, title: <Text fw={700}>Batch User FIeld</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <CDUserField
                dtUFT11={dtUFT11}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve()
                    .then(() => setT02Obj(e))
                    .then(() => {
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    })
                }}
                DrawerObjSend={DrawerOBJ}
                setDrawerObjSend={(e) => {
                  setDrawerOBJ(e)
                }}
              />
            </>, open: true, size: "sm", position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00T02F23": //Pricelist
        console.log('priceList')
        if (selectedProductObj?.FIELD53 == "N") {
          // setTimeout(() => {
          NextIndexFun(index)
          // }, 500)
        } else {
          setDrawerOBJ({
            ...DRA,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              console.log('close button')
              // setDrawerObj(dra);
              Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <PLComboBox
                initiallyOpened={true}
                cmbid={`T02Ent_${index}`}
                data={typeof PLDATA == "string" ? [{}] : PLDATA}
                value={T02Obj?.FIELD27}
                dispexpr={"FIELD02"}
                valexpr={"FIELD01"}
                setEdit={(e) => {
                  console.log("Pricelist OBJ=>", e);
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                    if (T41Obj.FIELD16 == "C") {
                      setPriceListParemeter({
                        ...priceListParemeter,
                        accountCode: T41Obj.FIELD31,
                        priceListCode: e,
                      });
                    } else {
                      setPriceListParemeter({
                        ...priceListParemeter,
                        accountCode: T41Obj.FIELD04,
                        priceListCode: e,
                      });
                    }
                  })
                }}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false }))
                      .then(() => {
                        // setTimeout(() => {
                        if (T02UF?.length > 0) {
                          NextIndexFun(index)
                        } else {
                          NextIndexFun(index + 1)
                        }
                        // }, 500)
                      })
                  }}
                  onKeyDown={(e) => {
                    // e.stopPropagation();
                    if (e.key == "Enter") {
                      Promise.resolve()
                        .then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
                        .then(() => {
                          setTimeout(() => {
                            if (T02UF?.length > 0) {
                              NextIndexFun(index)
                            } else {
                              NextIndexFun(index + 1)
                            }
                          }, 500)
                        })
                    }
                  }}
                >OK</Button>
              </div>
            </>,
            open: true,
            size: "sm",
            position: "right"
          })
        }
        break;
      case "E00EXPDUM": // Itemwise Expense
        console.log('itemwise expense')
        if (T02Obj.FIELD08 > 0 && InvTypeDropDown != null) {
          setDrawerOBJ({
            ...DRA, title: <Text fw={700}>Expense Entry</Text>,
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerOBJ({ ...DrawerOBJ, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <ExpenseEntryForm
                ExpenseObj={ExpenseObj}
                ProductObj={selectedProductObj}
                isExpense={true}
                expenseArr={ExpenseArrayT02?.length > 0 ? ExpenseArrayT02 : ExpenseData?.dtExp_I}
                // expenseArr={ExpenseData?.dtExp_I}
                expenseArrDef={ExpenseData?.dtExpDef_I}
                // M01PData={M01PDT}
                M01PData={ExpenseData?.M01SPDT?.jData}
                // M01Format={M01P}
                M01Format={ExpenseData?.M01SP}
                valueSaveObj={T02Obj}
                T41ObjSend={T41Obj}
                TableExpenseArr={TableExpense}
                onChange={(e) => {
                  console.log("TableExpenseEntry Object=>", e);
                  // setSelectedTaxAcc(e.FIELD16)

                  if (tableEditingRow == null) {
                    console.log("Add Product Call")
                    if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                      GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                    } else {
                      Promise.resolve(handleCreateUser(e)).then(() => handleNullT02Obj()).then(() => table.setCreatingRow(true))
                    }
                  } else {
                    console.log("Edit Product Call")
                    if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                      GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                    } else {
                      Promise.resolve().then(() => handleEditSave(e))
                        .then(() => {
                          setTimeout(() => {
                            table.setEditingRow(null)
                          }, 500)
                        })
                        .then(() => {
                          setTimeout(() => {
                            if (obj.p0 == "E") {
                              setT02Obj(T02?.[T02?.length - 1]);
                            } else {
                              setT02Obj(T02?.[0])
                            }
                            table.setCreatingRow(true)
                          }, 500)
                        })

                    }
                  }

                  setT41Obj(prevFieldObj => {
                    // Use functional update to avoid overwriting previous state
                    return ExpenseData?.dtExp?.reduce((updatedFieldObj, d) => {
                      // Only update if the corresponding IA key exists in FieldObj
                      if (d?.FIELD54) {
                        return { ...updatedFieldObj, ['EA' + d.FIELD54]: e?.FIELD16 };
                      } else {
                        return updatedFieldObj;
                      }
                    }, { ...prevFieldObj }); // Spread previous state to avoid modifying it directly
                  })
                }}
                DrawerOBJ={DrawerOBJ}
                setDrawerOBJ={(e) => setDrawerOBJ(e)}
              />
            </>, open: true, size: "50%", position: "right"
          })
        }
        break;
      default:
        break;
    }
  }

  const rowSum = T02Data.reduce((a, b) => {
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD08 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD08 || 0);
    }
    return a;
  }, 0)

  let QtySum = T02Data.reduce((a, b) => (a = a + b?.FIELD33), 0);
  let Qty2Sum = T02Data.reduce((a, b) => (a = a + b?.FIELD21), 0);

  // Columns
  useEffect(() => {
    if (T02ENT) {
      let cols = [];
      // cols.push(
      //   columnHelper.accessor('GRPCD', {
      //     header: "Product Group",
      //     id: "Product_Group",
      //     size: 150,
      //     Edit: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       return <PLDataGrid
      //         autoFocus={true}
      //         width={"500px"}
      //         TmData={tmList["T_M32G"]?.cSTID}
      //         // data={accList["T_M32G"]}
      //         value={{ value: T02Obj?.GRPCD, label: T02Obj?.GRPNM }}
      //         setEdit={(e) => {
      //           console.log("Product Group Obj=>", e);
      //           setGroupCatogaryProduct({ ...GroupCatogaryProduct, Group: e?.FIELD01 })
      //           setT02Obj({ ...T02Obj, GRPCD: e?.FIELD01, GRPNM: e?.FIELD02 })
      //         }}
      //       />
      //     },
      //     Cell: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       return T02Data[row.id]?.GRPNM
      //     },
      //   })
      // )
      cols.push(
        columnHelper.accessor("CATCD", {
          header: "Product Category",
          id: "Product_Category",
          size: 150,
          Edit: ({ cell, row }) => {
            let val = cell.getValue();
            return <PLDataGrid
              // autoFocus={true}
              width={"500px"}
              TmData={tmList["T_M32C"]?.cSTID}
              // data={accList["T_M32C"]}
              value={{ value: T02Obj?.CATCD, label: T02Obj?.CATNM }}
              setEdit={(e) => {
                console.log("Product Category Obj=>", e);
                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                Promise.resolve(setT02Obj({ ...T02Obj, CATCD: e?.FIELD01, CATNM: e?.FIELD02 }))
                  .then(() => {
                    pr?.current?.handlePopoverToggle();
                  });
              }}
            />
          },
          Cell: ({ cell, row }) => {
            let val = cell.getValue();
            return T02Data[row.id]?.CATNM

          },
        })
      )
      T02ENT?.filter((v, i) => v?.S53F00 === "C").map((v, i) => {
        cols.push(
          columnHelper.accessor(v.S53F04, {
            header: v.S53F07 == 0 ? "" : v.S53F03,

            id: v.S53UID,
            size: v.S53F07 == 0 ? 0 : 150,
            Edit: ({ cell, row }) => {
              let val = cell.getValue();
              return renderInputBox(v.S53UID, val, v, cell?.id, i);
            },
            Cell: ({ cell, row }) => {
              // console.log("cell.getValue()", cell.getValue())
              let val = cell.getValue();
              // console.log("T02Data[i]=>",T02Data[row.id]);
              if (v.S53F03 == "Product Name") {
                return T02Data[row.id]?.F03NM ?? T02Data[row.id]?.PrdNM
                // return "Test"
              } else if (v.S53F02 == "E00T02F20") {
                return T02Data[row.id]?.F20NM
              }
              return v.S53F07 == 0 ? "" : val
            },
            Footer: () => <div>{v.S53F02 == "E000T02F03" ? `Total Entry :${table?.getSelectedRowModel()?.flatRows.length > 0 ? (parseInt(table?.getSelectedRowModel()?.flatRows[0]?.index) + 1) + "/" : ""}${T02Data?.length}` : Summary(v, i, "c")}</div>,
          })
        );
      });
      return setColumns(cols);
    }
  }, [T02ENT, T02Obj, T02, accList, tmList, selectedProductObj, GroupCatogaryProduct, T02Data]);

  // console.log('T02Data=>>', T02Data)

  const UFT41HRenderField = (e) => {
    //S13F02 Save UserField
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            // console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={T41Obj?.[h?.S13F02]}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={T41Obj?.[h?.S13F02]}
                        copno={seperate(h.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        value={new Date(T41Obj?.[h?.S13F02]).getTime()}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        value={new Date(T41Obj?.[h?.S13F02]).getTime()}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLNumberBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDataGrid
                        width={"500px"}
                        value={T41Obj?.[f?.S13F02]}
                        // p0={seprate(f.S13F09)[0]}
                        p1={seperate(f.S13F09)[1]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLComboBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        value={new Date(T41Obj?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        value={new Date(T41Obj?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07} :</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    }
  };

  // Quantity Calculation
  useEffect(() => {
    if (T02Obj?.FIELD21 !== null && T02Obj?.FIELD22 !== null) {
      setT02Obj({ ...T02Obj, FIELD33: T02Obj?.FIELD21 * T02Obj?.FIELD22 });
    }
  }, [T02Obj?.FIELD21, T02Obj?.FIELD22]);

  // Amount Calculation
  useEffect(() => {
    // if (T02Obj?.FIELD33 !== null && T02Obj?.FIELD07 !== null) {
    setT02Obj({ ...T02Obj, FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07 });
    // }
  }, [T02Obj?.FIELD33, T02Obj?.FIELD07]);

  useEffect(() => {
    if (T02Obj?.FIELD33) {
      setT02Obj({ ...T02Obj, FIELD21: parseInt(T02Obj?.FIELD33) / parseInt(T02Obj?.FIELD22) })
    }
  }, [T02Obj?.FIELD33])

  //Add New Row
  const handleCreateUser = (e) => {
    // console.log("handleCreateUser==>", T02Obj);
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
    let objChange = (e ? e : T02Obj)
    dispatch(BatchLogAddRemoveFun({ PageCode: pagecCode, T02Data: T02Data, Row: objChange, OBJ: obj, T41Obj: T41Obj }));

    setT02Data([...T02Data, objChange]);
    // console.log('T02Data handleCreateUser==>', T02Data)
    table.setCreatingRow(null)
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    setBatchIssueObj()
    setRecieptIssueVal(null)
    setLocationObj(null)
  };

  // Update Row
  const handleEditSave = (e) => {
    let objChange = (e ? e : T02Obj)
    dispatch(BatchLogAddRemoveFun({ PageCode: pagecCode, T02Data: T02Data, Row: objChange, OBJ: obj, T41Obj: T41Obj }));
    let EditT02Data = [...T02Data];
    EditT02Data[tableEditingRow.index] = objChange;
    setT02Data(EditT02Data)
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    table.setEditingRow(null); //exit editing mode
    setTableEditingRow(null)
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
  };

  const handleNullT02Obj = () => {
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    setBatchIssueObj(null)
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
    setSelectedProductObj({})
  };

  const DeleteRow = (row) => {

    console.log("DeleteRow", row);
    if (window.confirm('Confirm to Delete ?')) {
      let newArray = T02Data?.filter((d, i) => i != row?.index)
      setT02Data(newArray)
    }
  }

  useEffect(() => {
    console.log('textBox', textBox)
  }, [textBox])

  const OriginalInvoiceTableFun = () => {
    // if (T41Obj.FIELD33 !== "" && T41Obj.FIELD48 !== "") {
    return setDrawerOBJ({
      ...DRA, title: <Text fw={700}>Original Invoice Detail</Text>, body: <>
        <OriginalInvoiceDetailTable
          InvTableData={InvTableData}
          OBJ={obj}
          textBox={textBox}
          setTextBoxSend={(e) => {
            setTextBox(e)
          }}
          setInvTableData={(e) => {
            console.log("setInvTableData", e);
            setInvTableData(e);
          }}
          T41ObjSend={T41Obj}
          setT41ObjSend={(e) => {
            // console.log("setT41ObjSend=>", e);
            setT41Obj(e);
          }}
          DrawerObjSend={DrawerOBJ}
          setDrawerObjSend={(e) => {
            setDrawerOBJ(e)
          }}
        />
      </>, open: true, size: "md", position: "right"
    })
    // } else {
    //   console.log("FIELD33 or FIELD48 is null");
    //   return null;
    // }
  }

  // let rowSum = T02Data.reduce((a, b) => (a = a + b?.FIELD08), 0);

  // console.log('rowSum', rowSum)

  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      if (row?.id == 'mrt-row-create') {
        return
      } else {
        table.setEditingRow(row)
        setTableEditingRow(row)
        setT02Obj(row?.original)
      }
    }
  }

  const handleCancelRow = (e) => {
    if (e.key === "Escape") {
      console.log("Editing row:", tableEditingRow);
      console.log("Current state:", rowSelection);
      console.log('e.preventDefault();', e.preventDefault())
      // e.preventDefault();
      if (tableEditingRow) {
        table.setEditingRow(null);
      } else {
        table.setCreatingRow(false);
      }
      handleNullT02Obj();
    }
  }

  const table = useMantineReactTable({
    columns,
    data: T02Data,
    enableTopToolbar: true,
    mantineTableContainerProps: {
      sx: { maxHeight: "350px", minHeight: "300px" },
    },
    enableHiding: true,
    positionActionsColumn: "last",
    enableRowActions: true,
    // getRowId: (row) => row.FIELD01,
    initialState: {
      density: "0px",
      columnVisibility: {
        "GRPNM": false, //hide firstName column by default
      },
    },
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: true,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    editDisplayMode: "row",
    createDisplayMode: "row",
    enableHiding: false,
    positionToolbarAlertBanner: false,
    onCreatingRowSave: () => {
      if (
        // T02Obj?.FIELD41 == "" || 
        T02Obj?.FIELD03 == "") {
        return GlobalClass.Notify(
          "warning",
          "Validation error",
          "Please fill out all the required fields"
        );
      } else {
        handleCreateUser();
      }
    },
    onEditingRowSave: ({ values, table, row }) => {
      if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
        GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
      } else {
        handleEditSave()
      }
    },
    onCreatingRowCancel: handleNullT02Obj,
    onEditingRowCancel: handleNullT02Obj,
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      onKeyDown: (e) => handleCancelRow(e),
      onClick: () => {
        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    state: {
      density: "0px",
      rowSelection
    },
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              console.log("row.original =>", row?.original);
              table.setEditingRow(row);
              setTableEditingRow(row)
              setT02Obj(row.original);
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => DeleteRow(row)}>
            <IconTrash size={'20px'} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onFocus={() => {
          if (spoilerControlRef?.current?.innerText == "Hide") {
            spoilerControlRef.current.click()
          }
          Promise.resolve(table.setCreatingRow(true)).then(() => setT02Obj({ ...T02Obj, FIELD05: OS11?.S11F21 }))
        }}
        onClick={() => {
          table.setCreatingRow(true);
        }}
        size="xs"
      >
        Add New
      </Button>
    ),
  });

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key == "Escape") {
        console.log('useEffect run')
        Promise.resolve().then(() => handleNullT02Obj())
          .then(() => {
            setTimeout(() => {
              if (tableEditingRow == null) {
                table.setCreatingRow(false)
              } else {
                table.setEditingRow(null)
              }
            }, 500)
          })
      }
    })
  }, [tableEditingRow])

  useEffect(() => {

    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef]);

  // For Batch Issue
  // useEffect(() => {
  //   if (T02Obj?.FIELD41 !== null) {
  //     setT02Obj({ ...T02Obj, FIELD11: T02Obj?.FIELD41 });
  //   }
  // }, [T02Obj?.FIELD41]);

  return (
    <>
      {
        SaveLoading == true ?
          GlobalClass.RenderLoader("dots")
          :
          <>
            {
              AccountisLoading["T_M32G"] || AccountisLoading["T_M32C"] || isLoading
                ? GlobalClass.RenderLoader("dots")
                : CreditDebitData &&
                <div ref={formRef}>
                  <Modal
                    opened={DrawerOBJ?.open}
                    // onClose={close}
                    withCloseButton={true}
                    fullScreen={false}
                    closeButtonProps={{
                      onClick: () => {
                        setTextBox(false)
                        if (typeof DrawerOBJ?.onClickCloseButton == "function") {
                          DrawerOBJ?.onClickCloseButton();
                        } else {
                          setDrawerOBJ(DRA);
                        }
                      },
                    }}
                    title={DrawerOBJ.title}
                    size={DrawerOBJ.size}
                    // centered={.Centered}
                    closeOnClickOutside={true}
                    position={DrawerOBJ?.position ?? 'bottom'}
                    onClose={typeof DrawerOBJ?.onclose == "function" ? DrawerOBJ?.onclose : () => { setDrawerOBJ(DRA); }}
                  >
                    {DrawerOBJ.body}
                  </Modal>
                  <Paper shadow="md" radius="md" p="xs" withBorder>
                    <Grid gutter={4}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                    >
                      <Grid.Col
                        span={12}
                        style={{ padding: 0, display: "flex" }}
                      >
                        <Grid.Col span={4}>
                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Party A/c.</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                width={"500px"}
                                value={{ value: T41Obj?.FIELD04, label: T41Obj?.FIELD04NM }}
                                TmData={M01P}
                                p1={"P_M01"}
                                setEdit={(e) => {
                                  setT41Obj({ ...T41Obj, FIELD04: e?.FIELD01, FIELD04NM: e.FIELD02 });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>

                          <Grid.Col span={6}>
                            <Button size="xs" variant="light">
                              History
                            </Button>
                          </Grid.Col>

                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Adjust In</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLComboBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                value={T41Obj?.FIELD75}
                                data={COP75 || []}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                  setT41Obj({ ...T41Obj, FIELD75: e });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>

                          {
                            visibleField.revisedVou == "Y" && (
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text size={12}>Revised Vou.</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  <PLComboBox
                                    value={T41Obj?.FIELD49}
                                    data={COP1 || []}
                                    dispexpr={"DisplayMember"}
                                    valexpr={"ValueMember"}
                                    setEdit={(e) => {
                                      setT41Obj({ ...T41Obj, FIELD49: e });
                                    }}
                                  />
                                </Grid.Col>
                              </Grid.Col>
                            )}

                          {
                            T41Obj?.FIELD49 == "N" &&
                            (
                              <Grid.Col
                                span={12}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Grid.Col span={3}>
                                  <Text size={12}>Bill No</Text>
                                </Grid.Col>
                                <Grid.Col span={9}>
                                  {T41Obj?.FIELD48 == '' && textBox ?
                                    <PLTextBox
                                      value={seperate1(T41Obj?.FIELD55)}
                                      onClick={(e) => {
                                        setTextBox(false)
                                        console.log('onclick', e)
                                      }}
                                    /> :
                                    <PLDataGrid
                                      dispexpr={"FIELD12"}
                                      pid={"P_T41"}
                                      autoFocus={
                                        obj.p0 == "E" && InvTableData.length > 0 ? true :
                                          obj.p0 == "A" && InvTableData.length > 0 ? true :
                                            false
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Tab" && T41Obj?.FIELD48 === '') {
                                          console.log('test')
                                          setTextBox(true)
                                          return OriginalInvoiceTableFun()
                                        }
                                      }}
                                      isMulti={true}
                                      width={"500px"}
                                      value={seperate1(T41Obj?.FIELD55)}
                                      TmData={P_T41}
                                      setEdit={(e) => {
                                        console.log('Bill No e=>>', e)
                                        let val = [];
                                        let tempVal;
                                        Promise.resolve().then(() => {
                                          if (e?.length > 0) {
                                            e.map((v, i) => {
                                              val.push(v.original)
                                            })
                                            console.log('Bill val', val)
                                            return val
                                          }
                                        }).then(() => {
                                          if (val && val.length > 0) {
                                            tempVal = val.map((s, i) => {
                                              return `${s.FIELD01}~C~${s.FIELD12}~C~${s.FIELD02}`
                                            }).join('~R~')
                                            console.log('tempVal', tempVal)
                                            setT41Obj({
                                              ...T41Obj,
                                              FIELD55: tempVal,
                                              FIELD48: val[0]?.FIELD01,
                                              FIELD33: val[0]?.FIELD02,
                                              FIELD40: val[0]?.FIELD12
                                            });
                                          } else {
                                            setT41Obj({
                                              ...T41Obj,
                                              FIELD48: '',
                                              FIELD55: '',
                                              FIELD33: '',
                                              FIELD40: ''
                                            })
                                          }
                                        }).then(() => setInvTableData([]))
                                      }}
                                    />
                                  }
                                </Grid.Col>
                              </Grid.Col>
                            )
                          }
                        </Grid.Col>

                        <Grid.Col span={4}>
                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Inv Type</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              {
                                M45I ?
                                  <PLDataGrid
                                    width={'500px'}
                                    value={T41Obj?.FIELD13}
                                    TmData={M45I}
                                    data={M45IDT}
                                    setEdit={(e) => {
                                      console.log("Inv Type======>", e)
                                      Promise.resolve(setT41Obj({ ...T41Obj, FIELD45: e?.FIELD20, FIELD13: e?.FIELD01 }))
                                        .then(() => dispatch(GetInvTypeDropDown(e)))
                                        .then(() => dispatch(GetExpenseData({ action: "A", code: pagecCode, invCode: e.FIELD01 })))
                                    }}
                                  />
                                  :
                                  < PLTextBox disabled={true} placeholder={'Select'} />
                              }
                            </Grid.Col>
                          </Grid.Col>

                          {
                            <Grid.Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid.Col span={3}>
                                <Text size={12}>Sales A/c.</Text>
                              </Grid.Col>
                              <Grid.Col span={9}>
                                <PLDataGrid
                                  disabled={
                                    visibleField.salesAccount === true
                                      ? true
                                      : false
                                  }
                                  width={"500px"}
                                  TmData={M01SP}
                                  value={{ value: T41Obj?.FIELD05, label: T41Obj?.FIELD05NM }}
                                  setEdit={(e) => {
                                    setT41Obj({
                                      ...T41Obj,
                                      FIELD05: e.FIELD01,
                                      FIELD05NM: e.FIELD02
                                    });
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                          }

                          {visibleField.deliveryAt == "Y" && (
                            <Grid.Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid.Col span={3}>
                                <Text size={12}>Delivery At</Text>
                              </Grid.Col>
                              <Grid.Col span={9}>
                                <PLDataGrid
                                  width={"500px"}
                                  value={T41Obj?.FIELD39}
                                  TmData={M01D}
                                  data={M01DDT?.jData}
                                  setEdit={(e) => {
                                    setT41Obj({
                                      ...T41Obj,
                                      FIELD39: e.FIELD01,
                                    });
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                          )}

                          {visibleField.applicableField == "Y" && (
                            <Grid.Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid.Col span={3}>
                                <Text size={12}>Applicable % ?</Text>
                              </Grid.Col>
                              <Grid.Col span={9}>
                                <PLComboBox
                                  // FIELD53
                                  value={T41Obj?.FIELD53}
                                  data={COP1 || []}
                                  dispexpr={"DisplayMember"}
                                  valexpr={"ValueMember"}
                                  setEdit={(e) => {
                                    setT41Obj({ ...T41Obj, FIELD53: e });
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                          )}
                        </Grid.Col>

                        <Grid.Col span={4}>
                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Vou Date</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                              <PLDateBox
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                dispformat="DD/MM/YYYY"
                                defaultval={T41Obj?.FIELD02}
                                setEdit={(e) => {
                                  let year = e.toLocaleString("en-IN", {
                                    year: "numeric",
                                  });
                                  let month = e.toLocaleString("en-IN", {
                                    month: "2-digit",
                                  });
                                  let day = e.toLocaleString("en-IN", {
                                    day: "2-digit",
                                  });

                                  let Date = year + month + day;
                                  if (Date?.length == 8) {
                                    setT41Obj({ ...T41Obj, FIELD02: Date });
                                  }
                                  // setSelectedDay(e);
                                }}
                              />
                            </Grid.Col>
                            <Grid.Col span={1}>
                              <Text size={12}>
                                {selectedDay.toString().slice(0, 3)}
                              </Text>
                            </Grid.Col>
                          </Grid.Col>

                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Voucher No.</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              {/* {
                              obj?.p0 == "E" ?
                                <Grid.Col span={12}>
                                  <PLTextBox
                                    value={T41Obj?.FIELD12}
                                    setEdit={(e) => {
                                      console.log("setEdit=>", e);
                                      setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                    }}
                                  />
                                </Grid.Col> :
                                <Grid.Col span={12}>
                                  {
                                    T92 ? <>
                                      {
                                        BillSeriesHideShow?.DataGridInput == true && <>
                                          {
                                            !T41Obj?.T92F00 ?
                                              <PLDataGrid
                                                width={"400px"}
                                                position={"unset"}
                                                valexpr={"FIELD00"}
                                                value={BillSeriesHideShow?.BillNoVal}
                                                TmData={T92}
                                                setEdit={(e) => {
                                                  console.log("Bill No.=>", e)
                                                  Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                    if (e) {
                                                      var letr = e.FIELD02
                                                      console.log("num=>", num);
                                                      var num = parseInt(e.FIELD03) + 1
                                                      setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                    }
                                                  })

                                                }}
                                              /> : <PLTextBox
                                                value={T41Obj?.FIELD12}
                                                setEdit={(e) => {
                                                  console.log("setEdit=>", e);
                                                  setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                                }}
                                              />
                                          }
                                        </>
                                      }
                                      {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                        value={BillSeriesHideShow?.BillNoVal}
                                        setEdit={(e) => {
                                          console.log("setEdit=>", e);
                                          setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                          setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                        }}
                                      />
                                      }
                                    </> :
                                      < PLTextBox
                                        disabled={true}
                                        placeholder={'Select'}
                                      />
                                  }
                                </Grid.Col>
                            } */}
                              <Grid.Col span={12}>
                                <PLTextBox
                                  value={T41Obj?.FIELD12}
                                  setEdit={(e) => {
                                    console.log("setEdit=>", e);
                                    setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                          </Grid.Col>

                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Doc No.</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDataGrid
                                width={"500px"}
                                value={T41Obj?.FIELD10}
                                setEdit={(e) => {
                                  setT41Obj({ ...T41Obj, FIELD10: e.FIELD01 });
                                }}
                                disabled={
                                  OSV?._DOCNODATE === "Y" ? false : true
                                }
                              />
                            </Grid.Col>
                          </Grid.Col>

                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Doc Date</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDateBox
                                dispformat="DD/MM/YYYY"
                                disabled={
                                  OSV?._DOCNODATE === "Y" ? false : true
                                }
                                setEdit={(e) => {
                                  let year = e.toLocaleString("en-IN", {
                                    year: "numeric",
                                  });
                                  let month = e.toLocaleString("en-IN", {
                                    month: "2-digit",
                                  });
                                  let day = e.toLocaleString("en-IN", {
                                    day: "2-digit",
                                  });

                                  let Date = year + month + day;
                                  if (Date?.length == 8) {
                                    setT41Obj({ ...T41Obj, FIELD11: Date });
                                  }
                                }}
                              // defaultval={T41Obj?.FIELD11}
                              />
                            </Grid.Col>
                          </Grid.Col>

                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={3}>
                              <Text size={12}>Reason</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLComboBox
                                // FIELD52
                                onKeyDown={(e) => {
                                  GlobalClass.formNextInput(e, formRef);
                                }}
                                value={T41Obj?.FIELD52}
                                data={COPV52 || []}
                                dispexpr={"DisplayMember"}
                                valexpr={"ValueMember"}
                                setEdit={(e) => {
                                  setT41Obj({ ...T41Obj, FIELD52: e });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>
                    </Grid>

                    {/* Header User Field */}
                    {/* <Grid.Col span={12}> */}
                    {UFT41H?.length > 0 && userFieldHideShow == "Y" ? (
                      <>
                        <Divider
                          my="xs"
                          label="User Fields"
                          labelPosition="center"
                          labelProps={{ component: "strong" }}
                        />
                        <Spoiler
                          maxHeight={80}
                          showLabel="Show more"
                          hideLabel="Hide"
                          controlRef={spoilerControlRef}
                        >
                          <Grid gutter={4} mb={5}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                          >
                            {UFT41HRenderField("Header")}
                          </Grid>
                        </Spoiler>
                      </>
                    ) : (
                      ""
                    )}
                    {/* </Grid.Col> */}

                    <Grid gutter={4}>
                      <Grid.Col span={12} style={{ padding: 0 }}>
                        <MantineReactTable table={table} />
                      </Grid.Col>
                      <Grid.Col
                        my={5}
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Group>
                          <Button variant="light" compact
                            onClick={() => {
                              if (table?.getSelectedRowModel()?.flatRows?.length > 0) {
                                setT02Data([...T02Data, table.getSelectedRowModel()?.flatRows[0]?.original])
                              }
                            }}
                          >
                            Copy
                          </Button>
                          <Button variant="light" compact>
                            Serial No.
                          </Button>
                        </Group>
                      </Grid.Col>
                    </Grid>

                    {/* Footer User Field */}
                    <Grid gutter={4}>
                      <Grid.Col
                        span={12}
                        style={{ display: "flex", padding: 0 }}
                      >
                        <Grid.Col span={6} onKeyDown={(e) => {
                          GlobalClass.formNextInput(e, formRef);
                        }}>
                          <Grid.Col span={12}>
                            {userFieldHideShow == "Y" && UFT41HRenderField("Footer")}
                          </Grid.Col>

                          <Grid.Col span={12}>
                            <Grid.Col span={12}>
                              <Text size={12}>Narration</Text>
                            </Grid.Col>
                            <Grid.Col span={8}>
                              <PLTextarea
                                // variant='filled'
                                minRows={2}
                                value={T41Obj?.FIELD15}
                                onChange={(e) => {
                                  // console.log(e)
                                  setT41Obj({
                                    ...T41Obj,
                                    FIELD15: e.target.value,
                                  });
                                }}
                                onKeyDown={(e) => {
                                  // console.log("Narration=>", e);
                                  if (e.ctrlKey && e.key === "F1") {
                                    //console.log("Narration call");

                                    setDrawerOBJ({
                                      ...DRA, title: <Text fw={700}>Naration</Text>, body: <>
                                        <PLNarration
                                          NarrationArray={NarrationData}
                                          Flag={SelectedNarrationData?.length > 0 ? false : true}
                                          selectedNarrationArray={
                                            SelectedNarrationData
                                          }
                                          // NarrationVal={T41Obj}
                                          setNarrationArray={(e) => {
                                            // setT41Obj(e)
                                            console.log("setNarrationArray", e);
                                            setNarrationData(e.NarrationData);
                                            setSelectedNarrationData(
                                              e.selectedNarration
                                            );
                                          }}
                                          DrawerOBJ={DrawerOBJ}
                                          setDrawerOBJ={(e) => setDrawerOBJ(e)}
                                        />
                                      </>, open: true, size: "50%", position: "right"
                                    })
                                  }
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={6}>
                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Grid.Col span={4}> */}
                            <Text
                              style={{ fontSize: 12, fontWeight: "bold", display: 'flex' }}
                            >
                              Item Amount ₹ : {rowSum ? Math.abs(rowSum) + " DB" : 0}
                            </Text>
                            {/* </Grid.Col> */}
                          </Grid.Col>

                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Grid.Col span={6}> */}
                            <Grid.Col span={12} style={{ border: "1px solid gray", height: "200px", overflow: "hidden", overflowY: "scroll" }}>

                              {/* Footer Expense */}
                              {
                                ExpenseData?.dtExp &&

                                <ExpenseEntryForm
                                  ProductObj={selectedProductObj}
                                  isExpense={false}
                                  // expenseArr={ExpenseData?.dtExp}
                                  expenseArr={ExpenseArrayT41?.length > 0 ? ExpenseArrayT41 : ExpenseData?.dtExp}
                                  expenseArrDef={ExpenseData?.dtExpDef}
                                  // M01PData={M01PDT}
                                  M01PData={ExpenseData?.M01SPDT?.jData}
                                  // M01Format={M01P}
                                  M01Format={ExpenseData?.M01SP}
                                  valueSaveObj={T41Obj}
                                  // TableExpenseValue={tableExpenseVal}
                                  TableExpenseValue={T41Obj?.FIELD07}
                                  TableExpenseArr={T02Data}
                                  // SendselectedTaxAcc={selectedTaxAcc}
                                  onChange={(e) => {
                                    setT41Obj(e)
                                    // setFooterExpenseObj(e)
                                    // setExpenseRowSum(e.FIELD07)
                                    console.log("TableExpenseEntry=>", e);
                                  }}
                                  DrawerOBJ={DrawerOBJ}
                                  setDrawerOBJ={(e) => setDrawerOBJ(e)}
                                />
                              }

                            </Grid.Col>
                            {/* </Grid.Col> */}
                          </Grid.Col>

                          <Grid.Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Grid.Col span={4} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                              <Text
                                style={{ fontSize: 12, fontWeight: "bold", textWrap: 'nowrap', padding: 0 }}
                              >
                                Bill Amount ₹ : {T41Obj?.FIELD06 == 0 ? T41Obj?.FIELD07 : T41Obj?.FIELD06} DB
                              </Text>
                            </Grid.Col>
                          </Grid.Col>
                          <Grid.Col span={12}>
                            <Group
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                style={{ height: "30px", width: "100px" }}
                                size="sm"
                                id="okbutton"
                                onClick={() => handleSubmit()}
                              // disabled={T02Data.length < 2 ? true : false}
                              >OK</Button>
                              <Button style={{ height: "30px", width: "100px" }} size="sm">Print</Button>
                            </Group>
                          </Grid.Col>
                        </Grid.Col>
                      </Grid.Col>
                    </Grid>
                  </Paper>
                  {
                    lEditVou == false && GlobalClass.Alert('info', props.index)
                  }
                </div>
            }
          </>
      }
    </>
  );
};
export default CreditDebitNoteForm;
