import { useEffect, useMemo, useState } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { createColumnHelper } from '@tanstack/react-table';
import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import PLDateBox from '../../PlusComponent/PLDateBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLTextBox from '../../PlusComponent/PLTextBox';
import gensetting from '../../utils/gensetting';
import GlobalClass from '../../utils/GlobalClass';
import Store from '../../utils/store';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';
import { GetAccountList } from '../../utils/slices/AccountListSlice';
import { ProductListAPi } from '../../utils/slices/ProductList';
import { ModalDelete } from '../../utils/slices/ModalSlice';

const ItemWiseList = (props) => {

  const { OBJ, M21, btnFocus, flag,setFlagFunction } = props;

  const columnHelper = createColumnHelper();
  const ProductListData = useSelector(state => state?.ProductList?.ProductListData);
  const SaveLoading = useSelector(store => store?.DataSaveLoadingSlice?.SaveLoading);

  const [columns, setColumns] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [data, setData] = useState();
  const [final, setFinal] = useState([]);
  const [M45M46, setM45M46] = useState();
  const [rowIndex, setRowIndex] = useState();
  const [M46, setM46] = useState([])

  const [rowSelection, setRowSelection] = useState({});
  const [selectedRowObj, setSelectedRowObj] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    var final = [];
    ProductListData?.M45?.map(item => {
      ProductListData?.M46?.map(element => {
        if (item?.FIELD54 === element?.FIELD12 && item?.FIELD21 === element?.FIELD10) {
          final.push({ ...item, M46_FIELD01: element.FIELD01, M46_FIELD02: element.FIELD02, M46_FIELD03: element.FIELD03, M46_FIELD04: element.FIELD04, M46_FIELD05: element.FIELD05, M46_FIELD10: element.FIELD10, M46_FIELD11: element.FIELD11, M46_FIELD12: element.FIELD12, M46_FIELD13: element.FIELD13, M46_FLDBRC: element.FLDBRC, M46_FLDUNQ: element.FLDUNQ, M46_M46FDS: element.M46FDS, M46_M46UID: element.M46UID })
        }
      })
    })
    // console.log("final", final);
    setData({ ...ProductListData, M45_M46: final });
    setFinal(final)
    // setM45M46(final[0])
  }, [ProductListData]);

  const seperate1 = (e) => {
    var paraArr = e.split('~C~');
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr

  }

  const renderValue = (cellValue, row, column) => {

    let editor = null;

    let filteredData = data?.M46GRD?.filter(item => item?.S53F00 === "C");

    filteredData?.forEach(item => {
      if (item.S53F03 === column?.columnDef?.header) {
        switch (item?.S53F14) {
          case "TEXTBOX":
            editor = <PLTextBox
              value={M45M46?.M46_M46FDS}
              disabled
            // setEdit={(e) => {
            //   setM45M46({ ...M45M46, M46_M46FDS: e.target.value })
            // }}
            />;
            break;
          case "NUMBOX":
            editor = <PLNumberBox
              value={M45M46?.M46_FIELD04}
              decimalSeparator={true}
              precision={2}
              setEdit={(e) => {
                // console.log(e, "e");
                setM45M46({ ...M45M46, M46_FIELD04: e })
                setM46({ ...M46, FIELD04: e })
              }}
            />;
            break;
          case "COMBOBOX":
            editor = (
              <PLComboBox
                copno={seperate1(item.S53F15)[1]}
                value={M45M46?.M46_FIELD03}
                dispexpr='DisplayMember'
                valexpr='ValueMember'
                setEdit={(e) => {
                  setM45M46({ ...M45M46, M46_FIELD03: e.value })
                  setM46({ ...M46, FIELD03: e.value })
                }}
              />
            );
            break;
          default:
            break;
        }
      }
    });

    return editor;
  };

  useEffect(() => {
    if (flag) {
      // console.log("flag",flag);
      setRowSelection(selectedRowObj)
      setFlagFunction()
    }
  }, [flag])

  document.addEventListener("keydown", function (event) {
    //  alert("hello") 
    // console.log("event", event);
    // console.log("rowSelection", rowSelection);

    const value = Object.values(rowSelection).join(',')
    // const value1 = value && value
    // console.log("value1", value);

    if (value && event.key == "Tab") {
      btnFocus()
      setSelectedRowObj(rowSelection)
      setRowSelection({})
    }

  });

  useEffect(() => {
    // console.log("data", data);

    if (data?.M46GRD) {
      var cols = [];
      data?.M46GRD?.map(item => {
        cols.push(columnHelper.accessor(`M46_${item.S53F04}`, {
          header: `${item.S53F03}`,
          Cell: ({ row, cell }) => {
            // console.log("cell", cell);
            return cell.getValue()
          },
          Edit: ({ cell, column, table, row }) => {
            // setSelectedRowObj(row?.original);
            return renderValue(cell.getValue(), row, column);
          }
        })
        );
      });
      cols.push(columnHelper.accessor('M45F21NM', {
        header: "",
      })
      );
      setColumns(cols);
      setGrouping(["M45F21NM"]);
    }
  }, [data?.M46GRD, M45M46])

  // useEffect(() => {
  //   console.log("M45M46", M45M46);
  //   console.log("m46", M46);
  // }, [M45M46])

  const handleCreateUser = async ({ values, exitCreatingMode }) => {

    dispatch(DataSaveLoading(true))
    table.setEditingRow(null);
    GlobalClass.Notify("info", "Editing", "Please wait while we process your data");

    let M46ary = [];
    M46ary.push(M46)

    let ary = { M21: M21, M46: M46ary }

    var data = {
      "CorpID": gensetting.getcorpid(),
      "cUser": gensetting.getcuser(),
      "cPass": gensetting.getcpass(),
      "CmpNo": gensetting.getcmpno(),
      "cAction": OBJ.p0,
      "cCode": "",
      // "cOPara": "",
      "cSData": JSON.stringify(ary)
    };

    let param =
      JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": "A",
        "cCode": "",
        // "cOPara": "",
      });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(GlobalClass.ApiUrl + GlobalClass.PostM21 + "?pa=" + param, requestOptions)
      .then(response => response.json())
      .then((data) => {
        if (data.status === 'SUCCESS') {

          const result = final?.map((item, index) => {
            if (index == rowIndex) {
              return M45M46
            }
            else {
              return item
            }
          })
          setFinal(result)

          Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)))

          GlobalClass.Notify("success", "Edited", "Edited successfully");
          // dispatch(ProductListAPi(OBJ))

          let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
          dispatch(GetAccountList({
            id: "00930001",
            name: "Product List",
            p0: "MT",
            p1: "T_M21",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["00930001"]
          }))

        }
        else if (data.status === 'FAIL') {
          // Promise.resolve(dispatch(DataSaveLoading(false)))
          GlobalClass.Notify("error", data?.status, data?.message);
        }
      })
      .catch((e) => {
        // dispatch(DataSaveLoading(false));
        GlobalClass.Notify("error", "Error", `${e?.message}`);
      });



  }

  const table = useMantineReactTable({
    columns,
    data: final || [],
    enableColumnFilters: false,
    enableSorting: false,
    enableTopToolbar: false,
    enableColumnDragging: false,
    enableColumnActions: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableFilters: false,
    enableHiding: false,
    enableRowNumbers: false,
    enablePagination: false,
    enableGrouping: true,
    enableEditing: true,
    editDisplayMode: "row",
    initialState: {
      columnVisibility: { M46_M45UID: false },
      grouping,
      density: "0px",
      expanded: true,
    },
    state: {
      rowSelection,
      grouping,
      density: "0px",
    },
    // onEditingRowCancel: () => { setValidationErrors({}); funEnable(true); setT41(T41Data); dispatch(setToggleButton(false)); flag = false; setWeekDay(WDAY); setRowSelection({}) },
    onEditingRowSave: handleCreateUser,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => {
            // console.log("row", row?.original);

            if (M21?.FIELD02 && M21?.FIELD03) {
              table.setEditingRow(row);
              setRowIndex(row?.id)
              setM45M46(row?.original)
              let response = data?.M46?.find(item => item.FIELD12 === row?.original?.FIELD54)
              response && setM46(response)
            }
            else {
              GlobalClass.Notify("warning", "Blank Product Name and Tax Class is not allowed")
            }


            // funEnable(false);
            // dispatch(setToggleButton(true));
            // flag = true;

            // let rowId = row.original.FIELD01;
            // if (rowId) {
            //   dispatch(CashBankEditApi({ obj, rowId }));
            // }
            // else {
            //   setT41(row.original);
            // }
          }}
          >
            <IconEdit size={'20px'} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    mantineTableBodyCellProps: ({ cell, row }) => ({
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
  });

  return (
    <>
      {
        SaveLoading == true ? GlobalClass.RenderLoader("dots") :
          <MantineReactTable table={table} />
      }
    </>
  )
};

export default ItemWiseList;

