import {
  Box,
  Button,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  ScrollArea,
  Text,
  Textarea,
  createStyles,
} from "@mantine/core";
import React, { useState } from "react";
import PLTextBox from "../../../PlusComponent/PLTextBox";
import PLComboBox from "../../../PlusComponent/PLComboBox";
import PrintingOption from "./InnerBtnForms/PrintingOption";
import GraphFormat from "./InnerBtnForms/GraphFormat";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import CustomCol from "./InnerBtnForms/CustomCol";
import ReportHeader from "./InnerBtnForms/ReportHeader";
import ReportSummary from "./InnerBtnForms/ReportSummary";
const useStyles = createStyles((theme) => ({
    button: {
      "&:disabled, &[data-disabled]": {
        // Add your custom styles for disabled buttons here
        borderColor: "0.0625rem solid transparent",
        backgroundColor: "#228be6",
        color: "white",
        cursor: "pointer",
        pointerEvents: "auto",
      },
    },
  }));
export default function NewBtnReportFormatForm(props) {
    const { classes } = useStyles();
    let dra = {
      title: "",
      size: "md",
      body: "",
      open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);
    const {DrawerOBJ: draw , setDrawerOBJ : setClose} = props
    function callGraph(){
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Graph</Text>,
        body: (
          <GraphFormat
            cAction="A"
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => {
              setDrawerObj(e);
            }}
          />
        ),
        open: true,
        size: "lg",
        position: "right",
      });
        // Store.dispatch(
        //     ModalFunction({
        //       MTitle: <Text fw={700}>Graph</Text>,
        //       MAction: true,
        //       MSize: "50%",
        //       MBody: (i) => <GraphFormat index={i} cAction="A" />,
        //       MClose: true,
        //       closeOnClickOutside: true,
        //       onclose: () => {},
        //     })
        //   );
    }
    function callPrinting(){
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Advance Printing Option</Text>,
        body: (
          <PrintingOption
            cAction="A"
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => {
              setDrawerObj(e);
            }}
          />
        ),
        open: true,
        size: "lg",
        position: "right",
      });
        // Store.dispatch(
        //     ModalFunction({
        //       MTitle: <Text fw={700}>Advance Printing Option</Text>,
        //       MAction: true,
        //       MSize: "50%",
        //       MBody: (i) => <PrintingOption index={i} cAction="A" />,
        //       MClose: true,
        //       closeOnClickOutside: true,
        //       onclose: () => {},
        //     })
        //   );
    }
    function callCustomCol(){
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Custom Column Header</Text>,
        body: (
          <CustomCol
            cAction="A"
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => {
              setDrawerObj(e);
            }}
          />
        ),
        open: true,
        size: "lg",
        position: "right",
      });
        // Store.dispatch(
        //     ModalFunction({
        //       MTitle: <Text fw={700}>Custom Column Header</Text>,
        //       MAction: true,
        //       MSize: "50%",
        //       MBody: (i) => <CustomCol index={i} cAction="A" />,
        //       MClose: true,
        //       closeOnClickOutside: true,
        //       onclose: () => {},
        //     })
        //   );
    }
    function callReportHeader(){
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Report Header Detail</Text>,
        body: (
          <ReportHeader
            cAction="A"
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => {
              setDrawerObj(e);
            }}
          />
        ),
        open: true,
        size: "lg",
        position: "right",
      });
        // Store.dispatch(
        //     ModalFunction({
        //       MTitle: <Text fw={700}>Report Header Detail</Text>,
        //       MAction: true,
        //       MSize: "50%",
        //       MBody: (i) => <ReportHeader index={i} cAction="A" />,
        //       MClose: true,
        //       closeOnClickOutside: true,
        //       onclose: () => {},
        //     })
        //   );
    }
    function callSummary(){
      setDrawerObj({
        ...dra,
        title: <Text fw={700}>Report Summary Detail</Text>,
        body: (
          <ReportSummary
            cAction="A"
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => {
              setDrawerObj(e);
            }}
          />
        ),
        open: true,
        size: "lg",
        position: "right",
      });
        // Store.dispatch(
        //     ModalFunction({
        //       MTitle: <Text fw={700}>Report Summary Detail</Text>,
        //       MAction: true,
        //       MSize: "50%",
        //       MBody: (i) => <ReportSummary index={i} cAction="A" />,
        //       MClose: true,
        //       closeOnClickOutside: true,
        //       onclose: () => {},
        //     })
        //   );
    }
  return (
    <>
    <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        closeOnClickOutside={true}
        position={DrawerObj?.position ?? "right"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
      >
        {DrawerObj.body}
      </Modal>
      <Box style={{ padding: "30px" }}>
        <Grid gutter={4}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Description</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <PLTextBox
              //   value={group?.[h?.S13F02]}
              //   setEdit={(e) => {
              //     //console.log(e)
              //     setGroup({ ...group, [h?.S13F02]: e.target.value });
              //   }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Report Header</Text>
            </Grid.Col>
            <Grid.Col span={9}>
              <Textarea/>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Zooming</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Filter</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Grand Total</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Columns</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Line</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Vertical Line</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Product Detail</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Page Total</Text>
            </Grid.Col>
            <Grid.Col span={7}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Box>
      <Grid mt={20}>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Group spacing={"xs"} position="center" mt={5} mb={5}>
            <Button
              size="xs"
              onClick={() => callCustomCol()}
              // disabled={activeBtn ? false : true}
            >
              Custom Column
            </Button>
            <Button
              size="xs"
              onClick={() => callReportHeader()}
              // disabled={activeBtn ? true : false}
              // className={classes.button}
            >
              Report Header
            </Button>
            <Button
              size="xs"
              onClick={() => callSummary()}
              // disabled={activeBtn ? true : false}
              // className={classes.button}
            >
              Report Summary
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
      <Divider size="xs" />
      <Grid>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Button
                size="xs"
                mr={4}
              onClick={() => callPrinting()}
              // disabled={activeBtn ? false : true}
            >
              Printing Options
            </Button>
            <Button
                size="xs"

              onClick={() => callGraph()}
              // disabled={activeBtn ? true : false}
              // className={classes.button}
            >
              Graph
            </Button>
          </Grid.Col>
          <Grid.Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "4px",
            }}
          >
            <Button
              size="xs"
              // onClick={() => EditVoucherNum()}
              disabled={true}
              className={classes.button}
            >
              &lt; Back
            </Button>
            <Button
              size="xs"
              // onClick={() => EditVoucherNum()}
              disabled={true}
              className={classes.button}
            >
              Next &gt;
            </Button>
            <Button
              size="xs"
              // onClick={() => EditVoucherNum()}
              disabled={true}
              className={classes.button}
            >
              Finish
            </Button>
            <Button
              size="xs"
              onClick={() => setClose({...draw, open :false})}
            //   disabled={activeBtn ? true : false}
              className={classes.button}
            >
              Cancel
            </Button>
          </Grid.Col>
        </Grid.Col>
      </Grid>
    </>
  );
}
