import {
  Alert,
  Box,
  Button,
  Card,
  Drawer,
  Grid,
  Group,
  LoadingOverlay,
  Modal,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { useDispatch, useSelector } from "react-redux";
// import { GetEntList, GetM41UFList, PostM41UFEnt } from '../utils/slices/CompanySetupSlice'
import PLDateBox from "../../PlusComponent/PLDateBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import ButtonPanel from "../../PlusComponent/ButtonPanel";
import gensetting from "../../utils/gensetting";
import axios from "axios";
import GlobalClass from "../../utils/GlobalClass";
import { closeModal } from "@mantine/modals";
import { IconCircleLetterX, IconThumbUpFilled } from "@tabler/icons-react";
import Store from "../../utils/store";
import { useDisclosure } from "@mantine/hooks";
import { IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import {
  GetEntList,
  GetM41UFList,
  PostM41UFEnt,
} from "../../utils/slices/CompanySetupSlice";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import UFExtraDetails from "./UFExtraDetails";

const AddUserField = (props) => {
  // console.log('props modalRef=>',props.modalRef);
  const { modal, obj } = props;

  console.log("props AddUserField obj=>", obj);
  const dispatch = useDispatch();

  const [visible, { open, close }] = useDisclosure(false);

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseBtn: ""
  }

  const [DrawerObj, setDrawerObj] = useState(dra);

  // const GetEntListData = useSelector(state => state.CompanySetup.GetEntListData);
  const { isLoading, GetEntListData, EntisLoading } = useSelector(
    (state) => state.CompanySetup
  );
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const [btnDisabled, setBtnDisabled] = useState(true);
  const [placementType, setPlacementType] = useState("");
  const [docType, setDocType] = useState("");
  const [formatType, setFormatType] = useState("");
  const [voucherType, setVoucherType] = useState([]);
  const [valueDisabled, setValueDisabled] = useState(false);

  // const [inputDisabled, setInputDisabled] = useState(['Character', 'Numeric', 'Memo', 'Date', 'Image', 'Time', 'Date Time', 'Attechment', 'Hyperlink']);
  const [inputType, setInputType] = useState("");
  const [S11, setS11] = useState([]);
  const [inputDisabled, setInputDisabled] = useState({
    Character: "false",
    Numeric: "false",
    Memo: "false",
    Date: "false",
    Image: "false",
    Time: "false",
    Date_Time: "false",
    Attechment: "false",
    Hyperlink: "false",
  });

  const [M41Autono, setM41Autono] = useState("")
  const [userData, saveUserData] = useState({
    "FLDUNQ": 0,
    "FLDAED": "",
    "FIELD01": "U",
    "FIELD02": "",
    "FIELD03": "",
    "FIELD04": "",
    "FIELD05": "0",
    "FIELD06": "0",
    "FIELD07": "H",
    "FIELD08": "",
    "FIELD09": "",
    "FIELD10": "0",
    "FIELD11": "N",
    "FIELD12": "NONE",
    "FIELD13": "",
    "FIELD14": "E",
    "FIELD15": "",
    "FIELD16": "",
    "FIELD17": "E",
    "FIELD18": "",
    "FIELD19": "",
    "FIELD20": "",
    "FIELD21": "",
    "FIELD22": 0,
    "FIELD23": 0,
    "FIELD24": "",
    "FIELD25": 0,
    "FIELD26": 0,
    "FIELD27": 0,
    "FIELD31": "",
    "FIELD32": "",
    "FIELD33": "",
    "FIELD39": "",
    "FIELD40": "",
    "FIELD41": "",
    "FIELD42": "",
    "FIELD54": "",
    "FIELD43": "C",
    "FIELD35": "",
    "FIELD55": "",
    "FIELD50": "",
    "FIELD51": "",
    "FIELD52": "",
    "FIELD53": "",
    "FIELD91": false,
    "FIELD99": "",
    "FLDBRC": "",
    "M41UID": "",
    "M41F05NM": "",
    "M41F43NM": "",
    "M41F32NM": "",
    "M41F07NM": "",
    "M41F17NM": "",
    "M41F11NM": "",
    "M41F12NM": "",
    "M41AUTONO": "",
    "M41RNEWYR": "",
    "M41EDTALW": "",
    "M41PADCHR": "",
    "M41PRELEN": 0,
    "M41SUELEN": 0,
    "M41DCTYPE": "",
    "M41EXIT13": ""
  });
  const DocTypeFun = (e) => {
    setDocType(e);
    console.log("docType.label fun", e.label);
    switch (e.label) {
      case "Character":
        setInputType("Character");
        break;
      case "Numeric":
        setInputType("Numeric");
        break;
      case "Memo":
        setInputType("Memo");
        break;
      case "Date":
        Promise.resolve(setInputType("Date")).then(() => saveUserData({ ...userData, FIELD05: 10 }));
        break;
      case "Image":
        setInputType("Image");
        break;
      case "Time":
        setInputType("Time");
        break;
      case "Date_Time":
        setInputType("Date_Time");
        break;
      case "Attechment":
        setInputType("Attechment");
        break;
      case "Hyperlink":
        setInputType("Hyperlink");
        break;
      default:
        setInputType("Character");
        break;
    }
  };

  useEffect(() => {
    dispatch(
      GetEntList({ action: obj?.p0, code: obj?.p0 == "E" ? obj?.M41UID : "" })
    );
  }, [obj]);

  useEffect(() => {
    // if (obj?.p0 == 'E' && !isLoading) {
    //     console.log("EE");
    if (GetEntListData?.S11) {
      var data = Object.keys(GetEntListData?.S11).map((e) => ({
        label: GetEntListData?.S11[e],
        value: e,
      }));
      setS11(data);
    }
    if (GetEntListData?.M41 && !EntisLoading) {
      saveUserData(GetEntListData?.M41);
      console.log("GetEntListData", GetEntListData);
      setVoucherType([GetEntListData.M41.FIELD32]);
    }

    // }
    console.log("set UserData==>", userData);
  }, [GetEntListData?.M41]);

  useEffect(() => {
    console.log("voucherType", voucherType, voucherType.length);
  }, [voucherType])

  useEffect(() => {
    if (obj?.p0 == "E" && GetEntListData?.M41?.FIELD04) {
      switch (GetEntListData?.M41?.FIELD04) {
        case "C":
          setDocType({ label: "Character", value: "C" });
          setInputType("Character");
          break;
        case "N":
          setDocType({ label: "Numeric", value: "N" });
          setInputType("Numeric");
          break;
        case "M":
          setDocType({ label: "Memo", value: "M" });
          setInputType("Memo");
          break;
        case "D":
          setDocType({ label: "Date", value: "D" });
          setInputType("Date");
          break;
        case "I":
          setDocType({ label: "Image", value: "I" });
          setInputType("Image");
          break;
        case "T":
          setDocType({ label: "Time", value: "T" });
          setInputType("Time");
          break;
        case "E":
          setDocType({ label: "Date_Time", value: "E" });
          setInputType("Date_Time");
          break;
        case "A":
          setDocType({ label: "Attechment", value: "A" });
          setInputType("Attechment");
          break;
        case "H":
          setDocType({ label: "Hyperlink", value: "H" });
          setInputType("Hyperlink");
          break;
        default:
          setDocType({ label: "Character", value: "C" });
          setInputType("Character");
          break;
      }
    }
  }, [obj?.p0, GetEntListData?.M41?.FIELD04]);

  useEffect(() => {
    // if(voucherType != []){

    // }
    if (userData.FIELD01 != "") {
      if (userData.FIELD03 != "") {
        if (userData.FIELD04 != "") {
          setBtnDisabled(false);
        } else {
          setBtnDisabled(true);
        }
      } else {
        setBtnDisabled(true);
      }
    } else {
      setBtnDisabled(true);
    }
  }, [
    userData.FIELD01,
    userData.FIELD03,
    userData.FIELD04,
    userData.FIELD16,
    btnDisabled,
  ]);

  useEffect(() => {
    saveUserData({
      ...userData,
      M41AUTONO: M41Autono
    })

  }, [M41Autono, userData?.M41AUTONO])

  const ExtraDetailPopupFun = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Extra Details</Text>,
      body: <>
        <UFExtraDetails
          valueSaveObj={userData}
          onChangeFun={(e) => {
            console.log("saveUserData =>", e);
            saveUserData(e)
          }}
          DrawerOBJ={DrawerObj}
          setDrawerOBJ={(e) => {
            console.log("setDrawerOBJ=>", e);
            setDrawerObj(e)
          }}
        />
      </>,
      open: true,
      size: "md",
      position: "right"
    })
  }

  const submitUserData = () => {
    dispatch(DataSaveLoading(true));

    console.log(" obj?.p0==>", obj?.p0);

    // Simple POST request with a JSON body using fetch

    // props.modalClose();

    console.log("submit obj=>", obj);

    setBtnDisabled(true);
    voucherType.map((e) => {
      var Jdata = { ...userData };
      GlobalClass.Notify(
        "info",
        obj.p0 == "A" ? "Adding" : "Editing",
        "Please wait while we process your data"
      );
      if (obj?.p0 == "A") {
        Jdata.FIELD32 = e;
        Jdata.FIELD01 = "U" + Jdata.FIELD01;
      } else {
        Jdata.FIELD01 = Jdata.FIELD01;
        Jdata.M41UID = obj?.M41UID;
      }
      // userData.FIELD05 = parseFloat(userData.FIELD05.toFixed(2))

      console.log("Jdata===>", Jdata);
      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0,
        cCode: obj.p0 == "E" ? obj?.M41UID : "",
        cSData: JSON.stringify(Jdata),
      };
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0,
        cCode: obj.p0 == "E" ? obj?.M41UID : "",
        cOPara: "",
        // "StrtDt": "2021-04-01",
        // "EndDt":  "2022-03-31"
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostM41UFEnt + "?pa=" + param,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("api data=>", data);
          console.log("api data status=>", data?.status);

          if (data.status == "SUCCESS") {
            Promise.resolve(dispatch(GetM41UFList()))
              .then(() => dispatch(ModalDelete(props.index)))
              .then(() =>
                GlobalClass.Notify(
                  "success",
                  obj.p0 == "A" ? "Added" : "Edited",
                  obj.p0 == "A" ? "Added successfully" : "Edited successfully"
                )
              )
              .then(() => dispatch(DataSaveLoading(false)));
          } else if (data.status == "FAIL") {
            Promise.resolve(
              GlobalClass.Notify("error", data?.status, data?.message)
            ).then(() => dispatch(DataSaveLoading(false)));
          }
        })
        .catch(() =>
          Promise.resolve(
            GlobalClass.Notify("error", "Error", `${e.message}`)).then(() => dispatch(DataSaveLoading(false)))
        );
    });
  };

  // var isEdit=obj.p0=="E";

  return (
    <>
      {SaveLoading == true ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          {EntisLoading == true ? (
            GlobalClass.RenderLoader("dots")
          ) : (
            <>

              <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={true}
                fullScreen={false}//
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                  onClick: (e) => {
                    setDrawerObj(dra);
                  },
                }}
                title={DrawerObj?.title}
                size={DrawerObj?.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? 'bottom'}
                onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
              // scrollAreaComponent={ScrollArea.Autosize}
              >
                {DrawerObj.body}
              </Modal>

              <Box pos="relative">
                {/* <LoadingOverlay visible={visible || EntisLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} /> */}

                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Voucher Type:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        {!EntisLoading && S11.length > 0 && (
                          <PLComboBox
                            value={voucherType}
                            data={S11}
                            dispexpr={"label"}
                            valexpr="value"
                            multiple={true}
                            setEdit={(e) => {
                              setVoucherType(e);
                              console.log("multi", e);
                            }}
                          />
                        )}

                        {/* <PLDataGrid /> */}
                      </Grid.Col>
                    </Grid.Col>
                    <Grid.Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Placement:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLComboBox
                          // cmbid="18"
                          // name="18"
                          value={userData?.FIELD07}
                          copno={18}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          setEdit={(e) => {
                            setPlacementType(e);
                            saveUserData({ ...userData, FIELD07: e.value });
                            console.log("setPlacementType log=>", e);
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Field Id:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          nmaxlength={8}
                          disabled={obj.p0 == "E" ? true : false}
                          value={userData.FIELD01}
                          setEdit={(e) => {
                            console.log("PLTextBox FIELD01==>", e?.target?.value?.toUpperCase());
                            saveUserData({
                              ...userData,
                              FIELD01: e?.target?.value?.toUpperCase()
                            });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Sequence:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLNumberBox
                          value={userData?.FIELD10}
                          setEdit={(e) => {
                            saveUserData({ ...userData, FIELD10: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                <Card.Section style={{ marginBottom: 10 }}>
                  <Text weight={500}>Field Information</Text>
                  <hr />
                </Card.Section>
                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Field Name:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLTextBox
                          nmaxlength={15}
                          value={userData?.FIELD03}
                          setEdit={(e) => {
                            saveUserData({
                              ...userData,
                              FIELD03: e.target.value,
                            });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Type:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLComboBox
                          // cmbid="19"
                          // name="19"
                          value={userData?.FIELD04}
                          copno={19}
                          dispexpr="DisplayMember"
                          valexpr="ValueMember"
                          setEdit={(e) => {
                            console.log("Type=>", e);
                            DocTypeFun(e);
                            saveUserData({ ...userData, FIELD04: e.value });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>

                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Length:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLNumberBox
                          value={userData?.FIELD05}
                          disabled={
                            docType?.label == "Memo"
                              ? true
                              : docType.label == "Date"
                                ? true
                                : docType.label == "Image"
                                  ? true
                                  : docType.label == "Attechment"
                                    ? true
                                    : docType.label == "Hyperlink"
                                      ? true
                                      : false
                          }
                          setEdit={(e) => {
                            saveUserData({ ...userData, FIELD05: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>

                    <Grid.Col
                      span={6}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Decimal:</Text>
                      </Grid.Col>
                      <Grid.Col span={9}>
                        <PLNumberBox
                          value={userData?.FIELD06}
                          disabled={
                            docType.label == "Character"
                              ? true
                              : docType.label == "Memo"
                                ? true
                                : docType.label == "Date"
                                  ? true
                                  : docType.label == "Image"
                                    ? true
                                    : docType.label == "Attechment"
                                      ? true
                                      : docType.label == "Hyperlink"
                                        ? true
                                        : false
                          }
                          setEdit={(e) => {
                            saveUserData({ ...userData, FIELD06: e });
                          }}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                <Card.Section style={{ marginBottom: 10 }}>
                  <Text weight={500}>Other</Text>
                  <hr />
                </Card.Section>

                <Grid gutter={4}>
                  <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                    <Grid.Col
                      span={6}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <Grid gutter={4}>
                        <Grid.Col span={12} style={{ display: "flex" }}>
                          <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Master File:</Text>
                          </Grid.Col>
                          <Grid.Col span={9}>
                            <PLComboBox
                              value={userData?.FIELD12}
                              disabled={
                                docType.label == "Numeric"
                                  ? true
                                  : docType.label == "Date"
                                    ? true
                                    : docType.label == "Image"
                                      ? true
                                      : docType.label == "Attechment"
                                        ? true
                                        : docType.label == "Hyperlink"
                                          ? true
                                          : false
                              }
                              data={GetEntListData?.MSTTBL}
                              dispexpr={"S14F04"}
                              valexpr={"S14F01"}
                              setEdit={(e) => {
                                // console.log('Master File=>',e);
                                saveUserData({ ...userData, FIELD12: e });
                              }}
                            />
                          </Grid.Col>
                        </Grid.Col>

                        {/* character */}

                        {inputType == "Character" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Default Value:
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                value={userData?.FIELD18}
                                setEdit={(e) => {
                                  saveUserData({
                                    ...userData,
                                    FIELD18: e.target.value,
                                  });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {/* numeric */}
                        {inputType == "Numeric" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Default Value:
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLNumberBox
                                value={userData?.FIELD18}
                                setEdit={(e) => {
                                  saveUserData({ ...userData, FIELD18: e });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {/* Memo */}
                        {inputType == "Memo" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Exit On Enter:
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLComboBox
                                cmbid="1"
                                name="1"
                                value={docType.label}
                                copno={1}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                setEdit={(e) => {
                                  console.log("Exit On Enter", e);
                                  // DocTypeFun(e)
                                  // saveUserData({...userData,FIELD18:e});
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {/* Date */}
                        {inputType == "Date" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Default Value:
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDateBox
                                // value={userData?.FIELD18}
                                setEdit={(e) => {
                                  // DocTypeFun(e)
                                  saveUserData({ ...userData, FIELD18: e });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {/* image */}
                        {inputType == "Image" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>Image Path:</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                value={userData?.FIELD18}
                                setEdit={(e) => {
                                  // DocTypeFun(e)
                                  saveUserData({
                                    ...userData,
                                    FIELD18: e.target.value,
                                  });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {/* Attechment => not any input */}
                        {/* Hyprlink  => not any input */}
                        {/* Time */}
                        {inputType == "Time" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>
                                Default Value:
                              </Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDateBox
                                // value={userData?.FIELD18}
                                setEdit={(e) => {
                                  // DocTypeFun(e)
                                  saveUserData({ ...userData, FIELD18: e });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {/* Date Time */}
                        {inputType == "Date_Time" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>Date Time:</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLDateBox
                                // value={userData?.FIELD18}
                                setEdit={(e) => {
                                  // DocTypeFun(e)
                                  saveUserData({ ...userData, FIELD18: e });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}
                      </Grid>
                    </Grid.Col>

                    <Grid.Col span={6}>
                      <Grid gutter={4}>
                        {inputType == "Attechment" ? null : (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>Format:</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLTextBox
                                value={userData?.FIELD16}
                                disabled={
                                  docType.label == "Image"
                                    ? true
                                    : docType.label == "Hyperlink"
                                      ? true
                                      : false
                                }
                                setEdit={(e) => {
                                  // DocTypeFun(e)
                                  saveUserData({
                                    ...userData,
                                    FIELD16: e.target.value,
                                  });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        )}

                        {/* character */}
                        {inputType == "Character" ? (
                          <>
                            <Grid.Col span={12} style={{ display: "flex" }}>
                              <Grid.Col span={3}>
                                <Text style={{ fontSize: 12 }}>
                                  Auto Number:
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={9}>
                                <PLComboBox
                                  cmbid="1"
                                  name="1"
                                  value={userData?.M41AUTONO}
                                  copno={1}
                                  dispexpr="DisplayMember"
                                  valexpr="ValueMember"
                                  setEdit={(e) => {
                                    // DocTypeFun(e)
                                    // saveUserData({
                                    //   ...userData,
                                    //   M41AUTONO: e.value,
                                    // });

                                    Promise.resolve(setM41Autono(e.value)).then(() => {
                                      if (e?.value == "Y") {
                                        ExtraDetailPopupFun()
                                      }
                                    })


                                    console.log("Auto Number", e);
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={12} style={{ display: "flex" }}>
                              <Grid.Col span={3}>
                                <Text style={{ fontSize: 12 }}>
                                  Proper Case:
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={9}>
                                <PLComboBox
                                  cmbid="1"
                                  name="1"
                                  // value={docType.label}
                                  copno={1}
                                  dispexpr="DisplayMember"
                                  valexpr="ValueMember"
                                  setEdit={(e) => {
                                    // DocTypeFun(e)
                                    console.log("Proper Case", e.label);
                                  }}
                                />
                              </Grid.Col>
                            </Grid.Col>
                          </>
                        ) : null}

                        {/* numeric=> no any input */}
                        {/* Memo => no any input */}
                        {/* Date=> no any input */}
                        {/* image=> no any */}

                        {/* Attechment => no any input but format dropdown  */}

                        {inputType == "Attechment" ? (
                          <Grid.Col span={12} style={{ display: "flex" }}>
                            <Grid.Col span={3}>
                              <Text style={{ fontSize: 12 }}>Format:</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                              <PLComboBox
                                cmbid="21"
                                name="21"
                                value={userData?.FIELD16}
                                copno={21}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                setEdit={(e) => {
                                  setFormatType(e);
                                  saveUserData({
                                    ...userData,
                                    FIELD16: e.value,
                                  });
                                }}
                              />
                            </Grid.Col>
                          </Grid.Col>
                        ) : null}

                        {/* Hyprlink  => not any input */}
                        {/* Time */}
                        {/* Date Time */}
                      </Grid>
                    </Grid.Col>
                  </Grid.Col>
                </Grid>

                <Grid gutter={4}>
                  <Grid.Col
                    span={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Group
                      spacing={"xs"}
                      position="center"
                      display={"flex"}
                      style={{ marginTop: 10, width: "100%" }}
                    >
                      <Button
                        //  disabled={visible}
                        onClick={() => {
                          dispatch(ModalDelete(props.index));
                        }}
                        size="sm"
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled={btnDisabled}
                        size="sm"
                        onClick={() => {
                          if (voucherType.length > 0 && voucherType[0] != "") {

                            submitUserData()
                          }
                          else {
                            GlobalClass.Notify("warning", "Warning", "You Must Select Atlest One Voucher Type")
                          }
                        }
                        }
                      >
                        Ok
                      </Button>
                    </Group>
                  </Grid.Col>
                </Grid>
              </Box>
              {/* </Card> */}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AddUserField;
