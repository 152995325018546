import React, { useEffect, useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { Button, Grid, Group } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLComboBox from '../../PlusComponent/PLComboBox';
import Store from '../../utils/store';
import { useSelector } from 'react-redux';
import { UFEquationFunction } from './EquationsFunctions';
// import { UFEquationFunction } from './EquationsFunctions';

const SITableUserField = (props) => {
    // PageObj={obj}
    // T41Obj={T41Obj}
    const { T02UF, UFT11, T02ObjSend, setT02Objsend, DrawerOBJ, setDrawerOBJ,PageObj, T41Obj} = props
    console.log(T02UF, "SITableUserField", T02ObjSend)
    const [UserFieldObj, SetUserFieldObj] = useState(null)

    // const TransactionSalesPLdata = useSelector(
    //     (state) => state?.TSalesSlices?.TransactionSalesPLdata
    //   );
    console.log(PageObj, "PageObj--")
    console.log(UserFieldObj, "t?.S13F02-")
    const TransactionSalesPLdata = useSelector((state) => state?.TSalesSlices?.TransactionSalesPLdata);
    const MstinfoData = useSelector((state) => state?.TSalesSlices?.MstinfoData)


    useEffect(() => {
        if (T02ObjSend) {
            //UGRO: T02ObjSend?.FIELD07,
            SetUserFieldObj({ ...T02ObjSend, UPE: 100.000000, USALE: MstinfoData?.USALES_E })
        }
    }, [T02ObjSend])

    // useEffect(()=>{
    //     if(MstinfoData){
    //         // USALES_E
    //         SetUserFieldObj({ ...T02ObjSend, USALE:MstinfoData?.USALES_E})
    //         console.log("MstinfoData =>",MstinfoData);
    //     }
    // },[MstinfoData])

    const seperate = (e) => {
        var paraArr = e?.split("~C~");
        console.log("paraArr=>", paraArr);
        return paraArr;
    };

    const UserFieldRender = () => {
        let InputField;
        return (
            T02UF?.map((t) => {
                // console.log("t?.S13F14", t?.S13F14);
                // console.log([t?.S13F02], "UserFieldObj?.[t?.S13F02]")

                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    onFocus={(e) => {
                                        // Promise.resolve().then(() => { return UFEquationFunction(t, T02ObjSend, T41Obj, T02UF, PageObj,TransactionSalesPLdata?.nRate) }).then((res) => {
                                        //     console.log("Equtation Response Rate =>", res)
                                        //     SetUserFieldObj(res)
                                        // })
                                        if (t?.S13F07 == "Gross Rate") {
                                            if(PageObj?.id != "02340213"){
                                                SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: TransactionSalesPLdata?.nRate })
                                            }
                                        }
                                    }}
                                    nmaxlength={t?.S13F04}
                                    value={PageObj?.page == "04020399" ?UserFieldObj?.FIELD07 : UserFieldObj?.[t?.S13F02] }
                                    disabled={t?.S13F02 == "UPE" || PageObj?.page == "04020399"}
                                    setEdit={(e) => {
                                       
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e })
                                    }}

                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    nmaxlength={t?.S13F04}
                                    disabled={t?.S13F02 == "USALE"}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        console.log("UserFieldDataGrid value =>", [{
                                            "FIELD": t?.S13F02,
                                            "SelectedObj": e,
                                            "p1": t?.S13F09
                                        }]);
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                    p1={seperate(t?.S13F09)[1]}

                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }
    const BatchUserFieldRender = () => {
        let InputField;
        return (
            UFT11?.map((t) => {
                console.log("t?.S13F14", t?.S13F14);
                console.log([t?.S13F07], "UserFieldObj?.[t?.S13F02]")

                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    nmaxlength={t?.S13F04}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e })
                                    }}

                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    nmaxlength={t?.S13F04}
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        console.log(e?.FIELD01, "e?.FIELD01")
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                    p1={seperate(t?.S13F09)[1]}

                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                    value={UserFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        SetUserFieldObj({ ...UserFieldObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }

    const SaveTableUserField = () => {
        console.log("UserFieldObj", UserFieldObj);
        // console.log("T02ObjSend",T02ObjSend);
        Promise.resolve(setT02Objsend(UserFieldObj)).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
        // props.context.closeModal(props.id)
    }

    console.log("T02UF=>", T02UF);
    return (<>
        <Grid gutter={4}>
            {
                T02UF == undefined ? BatchUserFieldRender() : UserFieldRender()
            }
            <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                SaveTableUserField()
                            }
                        }}
                        onClick={() => SaveTableUserField()}>Save</Button>
                </Group>
            </Grid.Col>
        </Grid>

    </>)
}

export default SITableUserField