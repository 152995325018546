import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetPrefixSuffix } from '../../utils/slices/CompanySetUpPopUpSlice'
import { Button, Grid, Paper, Text } from '@mantine/core'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice'
import GlobalClass from '../../utils/GlobalClass'
import gensetting from '../../utils/gensetting'
import { ModalDelete } from '../../utils/slices/ModalSlice'

const CommonPrefixSuffix = (props) => {
  const { obj, data, setDrawerObjSend, DrawerObjSend } = props
  // console.log('props==>>',props)
  const isLoading = useSelector((state) => state.CompanySetupPopUp?.isLoading)
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const PrefixSuffixData = useSelector((state) => state.CompanySetupPopUp?.PrefixSuffixData)
  const dispatch = useDispatch()
  const [prefixSuffix, setPrefixSuffix] = useState({
    "FIELD01": "",
    "FIELD02": "",
    "FIELD00": "",
    "FIELD03": 0,
    "FIELD04": "",
    "FIELD11": "",
    "FIELD12": "",
    "FIELD13": "",
    "FIELD14": "",
    "FIELD15": "",
    "FIELD16": 0.0,
    "FIELD17": "",
    "FIELD18": "",
    "FIELD20": "",
    "FLDUNQ": 0.0,
    "FLDAED": "",
    "FLDBRC": ""
  })

  useEffect(() => {
    dispatch(GetPrefixSuffix())
  }, [])

  const handleSave = () => {
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify("info", "Saving", "Please wait while we process your data")
    let jData = prefixSuffix
    console.log('jData===>', jData)
    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "",
      cCode: "CCCC",
      cSData: JSON.stringify(jData),
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "",
      cCode: "CCCC",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostT92PrefixSuffix + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => setDrawerObjSend({ ...DrawerObjSend, open: false }))
            .then(() =>
              GlobalClass.Notify(
                "success",
                "Added",
                "Added Successfully"
              )
            );
        } else {
          Promise.response(dispatch(DataSaveLoading(false))).then(() =>
            GlobalClass.Notify(
              "error",
              data?.status,
              data?.message
            )
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify(
            "error",
            "Error",
            `${e?.message}`
          )).then(() => dispatch(DataSaveLoading(false)));
      });
  }

  return (
    <>
      {SaveLoading == true ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          {
            isLoading == true ? GlobalClass.RenderLoader("dots") :
              PrefixSuffixData && (
                <Paper shadow="md" radius="md" p="xs" withBorder>
                  <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid.Col span={2}>
                        <Text size={12}>Prefix</Text>
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLTextBox
                          setEdit={(e) => {
                            setPrefixSuffix({ ...prefixSuffix, FIELD14: e.target.value })
                          }}
                          value={prefixSuffix?.FIELD14}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid>

                  <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid.Col span={2}>
                        <Text size={12}>Suffix</Text>
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLTextBox
                          setEdit={(e) => {
                            setPrefixSuffix({ ...prefixSuffix, FIELD15: e.target.value })
                          }}
                          value={prefixSuffix?.FIELD15}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid>

                  <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Grid.Col span={2}>
                        <Text size={12}>Number</Text>
                      </Grid.Col>
                      <Grid.Col span={10}>
                        <PLNumberBox
                          setEdit={(e) => {
                            setPrefixSuffix({ ...prefixSuffix, FIELD16: e })
                          }}
                          value={prefixSuffix?.FIELD16}
                        />
                      </Grid.Col>
                    </Grid.Col>
                  </Grid>

                  <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Button onClick={() => handleSave()}>OK</Button>
                    </Grid.Col>
                  </Grid>
                </Paper>
              )}
        </>
      )}
    </>
  )
}

export default CommonPrefixSuffix