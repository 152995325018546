import { Button, Grid, Group, Modal, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { useDispatch, useSelector } from 'react-redux'
import { ModalDelete } from '../../utils/slices/ModalSlice'
import { modals } from '@mantine/modals'
import { GetCasePartyRequired } from '../../utils/TransactionSlices/TSalesSlices'
// import { closeContextModal } from '@mantine/modals'

const SICasePartyDetail = (props) => {

    console.log("CasePartyDetail props", props);

    const { SaveFIELD32, SendFIELD32, DrawerOBJ, setDrawerOBJ } = props

    const [CasePartyObj, setCasePartyObj] = useState({
        'TxtName': '',
        'TxtTinNo': '',
        'TxtCstNo': '',
        'TxtPanNo': '',
        'TxtAdharNo': '',
        'TxtCity': '',
        'TxtAddress': '',
        'TxtAddress2': '',
        'TxtAddress3': '',
        'TxtPinCode': '',
        'TxtMobile': '',
        'TxtGstIn': '',
        'TxtRegType': '',
        'TxtState': ''
    })

    const CasePartyRequired = useSelector((state) => state?.TSalesSlices?.CasePartyRequired)

    useEffect(() => {
        if (SendFIELD32 != "") {
            const stringArray = SendFIELD32?.split("#")
            // console.log("log=>", JSON.stringify(stringArray.slice(1)));
            let array =[];

            array = stringArray.slice(1)
            // console.log("array=>", array);
            let newObj = {};
            // array?.map((a) => {
                Object.keys(CasePartyObj).map((k,i) => {
                    newObj = { ...newObj, [k]: array[i] }
                })
            // })

            // console.log("newObj",newObj);
            setCasePartyObj(newObj)

            // console.log("updatedObj==>",test,updatedObj);
        }
    }, [SendFIELD32])

    // useEffect(()=>{
    //     console.log("CasePartyObj.TxtName useEffect");
    //     dispatch(GetCasePartyRequired({ ...CasePartyRequired, CasePartyFormName: CasePartyObj.TxtName }))
    // },[])

    const dispatch = useDispatch();

    const SubmitCasePartyForm = () => {
        // console.log("Submit Click CasePartyObj=>", CasePartyObj);
        // let CashPartyObjData = CasePartyObj.TxtName.trim() + `#` + CasePartyObj.TxtTinNo.trim() + `#` + CasePartyObj.TxtCstNo.trim() + `#` + CasePartyObj.TxtCity.trim() + `#` + CasePartyObj.TxtAddress.trim() + `#` + CasePartyObj.TxtAddress2.trim() + `#` + CasePartyObj.TxtAddress3.trim() + `#` + CasePartyObj.TxtPinCode.trim() + `#` + CasePartyObj.TxtMobile.trim() + `#` + CasePartyObj.TxtGstIn.trim() + `#` + CasePartyObj.TxtRegType.trim() + `#` + CasePartyObj.TxtState.trim() + `#` + CasePartyObj.TxtPanNo.trim() + `#` + CasePartyObj.TxtAdharNo.trim()
        dispatch(GetCasePartyRequired({ ...CasePartyRequired, CasePartyFormName: CasePartyObj.TxtName }))
        let CashPartyObjData = [];
        Object.keys(CasePartyObj).map((c) => {
            CashPartyObjData.push(`#${CasePartyObj[c].trim()}`)
        })
        // console.log("CashPartyObjData", CashPartyObjData.join(""))

        Promise.resolve(SaveFIELD32(CashPartyObjData.join(""))).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))

        // console.log("Submit Click CasePartyObjData With Trim=>", CashPartyObjData);
    }
    return (<>
        <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Name</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtName}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtName: e.target.value })
                            // dispatch(GetCasePartyRequired({ ...CasePartyRequired, CasePartyFormName: e.target.value }))
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>TIN No.</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtTinNo}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtTinNo: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>C.S.T. No.</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtCstNo}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtCstNo: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>PAN No.</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtPanNo}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtPanNo: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Aadhar No.</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtAdharNo}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtAdharNo: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>City</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtCity}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtCity: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Address</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtAddress}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtAddress: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Address-2</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtAddress2}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtAddress2: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Address-3</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtAddress3}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtAddress3: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Pincode</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtPinCode}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtPinCode: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Mobile</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtMobile}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtMobile: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>GSTIN No.</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                    <PLTextBox
                        value={CasePartyObj.TxtGstIn}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtGstIn: e.target.value })
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={2}>
                    <Button size='xs' variant='light' compact>
                        Check GSTIN
                    </Button>
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Reg. Type</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLComboBox
                        copno={9}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        value={CasePartyObj.TxtRegType}
                        setEdit={(e) => {
                            console.log("e=>", e);
                            setCasePartyObj({ ...CasePartyObj, TxtRegType: e.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", padding: 0 }}>
                <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>State</Text>
                </Grid.Col>
                <Grid.Col span={10}>
                    <PLTextBox
                        value={CasePartyObj.TxtState}
                        setEdit={(e) => {
                            setCasePartyObj({ ...CasePartyObj, TxtState: e.target.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button size='sm' onClick={() => SubmitCasePartyForm()}>
                        Ok
                    </Button>
                    {/* <Button size='sm' onClick={() => props?.context.closeModal(props?.id)}>
                        close
                    </Button> */}
                </Group>
            </Grid.Col>
        </Grid>
    </>)
}

export default SICasePartyDetail