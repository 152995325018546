import React from 'react'
import Store from '../../utils/store'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import NarrationForm from './NarrationForm'
import { Text } from '@mantine/core'
import { GetNarrationData } from '../../utils/TransactionSlices/NarrationSlice'
import GlobalClass from '../../utils/GlobalClass'

const Narration = () => {
  return (
    <div>Narration</div>
  )
}

export default Narration

export const M35AED = (data, Skey) => {
  const OBJ = JSON.parse(data);

  console.log("M35AED Narration 1=>", OBJ);

  if (OBJ?.p0 == "A") {
    console.log("M35AED Narration 2=>", OBJ);
    Store.dispatch(
      ModalFunction({
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        position: 'right',
        MBody: (i) => <NarrationForm OBJ={{...OBJ,id:""}} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ?.p0 == "E" && OBJ?.id) {
    Store.dispatch(
      ModalFunction({
        onclose: (e) => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        position: 'right',
        MBody: (i) => <NarrationForm OBJ={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ?.p0 == "D" && OBJ?.id) {
    if (window.confirm('Confirm to Delete ?')) {
      Promise.resolve(GlobalClass.Notify("info", "Info: " + "Delete Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(GetNarrationData(OBJ)))
    }
  }


}