import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";


export const GetM01List = createAsyncThunk(
    "M01FormSlice/GetM01List",
    async (data) => {
        // console.log("M01FormSlice/GetM01List=>", data);
        if (data.OBJ === 'D' || data.OBJ === 'F') {
            GlobalClass.Notify(
                "info",
                data.OBJ === 'D' ? "Deleting" : "Auditing",
                "Please wait while we process your data"
              );
             }
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": data?.OBJ ?? 'A',
                    "cCode": data?.Skey ?? '',
                    "cOPara":""

                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM01 + "?pa=" + param);
            // console.log("GetM01List response=>>", response.data.data);
            if ((data.OBJ === 'D' || data.OBJ === 'F')&& response.data.status === "SUCCESS") {
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj

                Store.dispatch(GetAccountList({
                    'id': "00510001",
                    'name': "Account List",
                    'p0': "MT",
                    'p1': "T_M01",
                    'p2': "",
                    'p3': "",
                    'type': "A",
                    'pagination':PaginationObj["00510001"]

                }))
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
    
            }else if((data.OBJ === 'D'|| data.OBJ === 'F') && response.data.status !== "SUCCESS"){
                GlobalClass.Notify(
                    "error",
                    response?.data?.status,
                    response?.data?.message
                  );
            }
            return response.data.data;
        } catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
          }
    }
);

// M31 - Account Category
export const GetAccountCategory = createAsyncThunk(
    "M01FormSlice/GetAccountCategory",
    async (data) => {
        // console.log("M01FormSlice/GetAccountCategory", data);
        if (data.OBJ === 'D' || data?.OBJ == "F") {
            GlobalClass.Notify(
                "info",
                data.OBJ === 'D' ? "Deleting" : "Auditing",
                "Please wait while we process your data"
              );
               }
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": data?.OBJ ?? 'A',
                    "cCode": data?.Skey ?? '',
                    "cOPara":""
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM31CR + "?pa=" + param);
            // console.log("GetM01List response=>>", response.data.data);
            if ((data.OBJ === 'D' || data.OBJ === 'F') && response.data.status === "SUCCESS") {
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj

                Promise.resolve(Store.dispatch(GetAccountList({
                    'id': "01360123",
                    'name': "A/c. Category List",
                    'p0': "MT",
                    'p1': "T_M31R",
                    'p2': "",
                    'p3': "",
                    'type': "A",
                    'pagination':PaginationObj["01360123"]
                }))).then(()=>GlobalClass.Notify("success", "Deleted", "Deleted successfully")
                )    
            }else {
                if(data.OBJ === 'D'){
                    GlobalClass.Notify(
                        "error",
                        response?.data?.status,
                        response?.data?.message
                      );
                }
            }
            return response.data.data;
        } catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
            return { e };
          }
    }
);


const M01FormSlice = createSlice({
    name: 'M01FormSlice',
    initialState: {
        M01FormData: [],
        AccountCategoryData: [],
        accountName : "",
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
    },
    reducers: {
        getAccountName: (state, action) =>{
            state.accountName = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetM01List.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetM01List.fulfilled, (state, action) => {
                // console.log("GetM01List fulfilled==>", action.payload);
                state.M01FormData = action.payload;
                state.isLoading = false;
                state.hasError = false;

            })
            .addCase(GetM01List.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
                state.ErrorMsg = action.payload?.error
            })
            .addCase(GetAccountCategory.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetAccountCategory.fulfilled, (state, action) => {
                // console.log("GetAccountCategory fulfilled==>", action.payload);
                state.AccountCategoryData = action.payload;
                state.isLoading = false;
            })
            .addCase(GetAccountCategory.rejected, (state, action) => {
                state.hasError = true
                state.ErrorMsg = action.payload?.error
                state.isLoading = false;
            })


    }
})


export const {getAccountName} = M01FormSlice?.actions
export default M01FormSlice.reducer