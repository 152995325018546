import { Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { AccountCategoryForm } from "./AccountCategoryForm"
import Store from "../../utils/store"
import { GetAccountCategory } from "../../utils/slices/M01FormSlice"
import { ModalFunction } from "../../utils/slices/ModalSlice"
import GlobalClass from "../../utils/GlobalClass"

export async function MSAED(data, Skey) {
  const OBJ = JSON.parse(data)
  // console.log('obj MSAED AccountCategoryList=>', OBJ)
  if (OBJ.p0 === 'A') {
    Store.dispatch(
      ModalFunction({
        onclose: () => {
          // Store.dispatch(ModalFunction({
          //   MTitle: "",
          //   MAction: false,
          //   MSize: "",
          //   MBody: () => "",
          //   MClose: false,
          // }));
        },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        MBody: (i) => (
          <AccountCategoryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        // Style: {
        //   backgroundColor: "red",
        // },
      })
    )
  } else if (OBJ.p0 === 'E' && !OBJ.id == "") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "md",
        MBody: (i) => (
          <AccountCategoryForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        // Style: {
        //   backgroundColor: "red",
        // },
      })
    )
  } else if (OBJ.p0 === 'D') {

    if (window.confirm("Are you sure to delete selected data?")) {
    Store.dispatch(GetAccountCategory({ OBJ: OBJ.p0, Skey: OBJ.id }))
      
    }
  }
}