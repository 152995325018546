
import { Accordion, ActionIcon, Box, Button, Collapse, ColorInput, Grid, Group, NavLink, Text, rem, useMantineColorScheme } from '@mantine/core'
import React, { forwardRef, useEffect, useState } from 'react'
import PLNumberBox from '../PlusComponent/PLNumberBox'
import { IconFingerprint, IconMoonStars, IconSettings, IconSun } from '@tabler/icons-react'
import PLComboBox from '../PlusComponent/PLComboBox'
import { useDisclosure, useLocalStorage } from '@mantine/hooks'

const SessionSetting = () => {

    const [sessionSetting, setSessionSetting] = useLocalStorage({
        key: 'session_setting',
        defaultValue: {
            Primary_Color: 'blue',
            Font_Family: 'Arial',
            Font_Size: 12,
            Font_XS: 10,
            Font_SM: 12,
            Font_MD: 14,
            Font_LG: 16,
            Font_XL: 20

        },
        getInitialValueInEffect: true,
    })

    const [opened, { toggle }] = useDisclosure(false)
    // const [sessionSettingObj, setSessionSettingObj] = useState({
    //     Primary_Color: 'blue',
    //     Font_Family: 'Arial',
    //     Font_Size: 12
    //   })

    const [primaryColorData, setPrimaryColorData] = useState([
        { value: "blue", label: "Blue" },
        { value: "cyan", label: "Cyan" },
        { value: "dark", label: "Dark" },
        { value: "grape", label: "Grape" },
        { value: "gray", label: "Gray" },
        { value: "green", label: "Green" },
        { value: "indigo", label: "Indigo" },
        { value: "lime", label: "Lime" },
        { value: "orange", label: "Orange" },
        { value: "pink", label: "Pink" },
        { value: "red", label: "Red" },
        { value: "teal", label: "Teal" },
        { value: "violet", label: "Violet" },
        { value: "yellow", label: "Yellow" }
    ])

    const [fontFamily, setFontFamily] = useState([])
    const [FontSize, setFontSize] = useState([])

    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    const fontCheck = new Set([
        // Windows 10
        'Arial', 'Arial Black', 'Bahnschrift', 'Calibri', 'Cambria', 'Cambria Math', 'Candara', 'Comic Sans MS', 'Consolas', 'Constantia', 'Corbel', 'Courier New', 'Ebrima', 'Franklin Gothic Medium', 'Gabriola', 'Gadugi', 'Georgia', 'HoloLens MDL2 Assets', 'Impact', 'Ink Free', 'Javanese Text', 'Leelawadee UI', 'Lucida Console', 'Lucida Sans Unicode', 'Malgun Gothic', 'Marlett', 'Microsoft Himalaya', 'Microsoft JhengHei', 'Microsoft New Tai Lue', 'Microsoft PhagsPa', 'Microsoft Sans Serif', 'Microsoft Tai Le', 'Microsoft YaHei', 'Microsoft Yi Baiti', 'MingLiU-ExtB', 'Mongolian Baiti', 'MS Gothic', 'MV Boli', 'Myanmar Text', 'Nirmala UI', 'Palatino Linotype', 'Segoe MDL2 Assets', 'Segoe Print', 'Segoe Script', 'Segoe UI', 'Segoe UI Historic', 'Segoe UI Emoji', 'Segoe UI Symbol', 'SimSun', 'Sitka', 'Sylfaen', 'Symbol', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana', 'Webdings', 'Wingdings', 'Yu Gothic',
        // macOS
        'American Typewriter', 'Andale Mono', 'Arial', 'Arial Black', 'Arial Narrow', 'Arial Rounded MT Bold', 'Arial Unicode MS', 'Avenir', 'Avenir Next', 'Avenir Next Condensed', 'Baskerville', 'Big Caslon', 'Bodoni 72', 'Bodoni 72 Oldstyle', 'Bodoni 72 Smallcaps', 'Bradley Hand', 'Brush Script MT', 'Chalkboard', 'Chalkboard SE', 'Chalkduster', 'Charter', 'Cochin', 'Comic Sans MS', 'Copperplate', 'Courier', 'Courier New', 'Didot', 'DIN Alternate', 'DIN Condensed', 'Futura', 'Geneva', 'Georgia', 'Gill Sans', 'Helvetica', 'Helvetica Neue', 'Herculanum', 'Hoefler Text', 'Impact', 'Lucida Grande', 'Luminari', 'Marker Felt', 'Menlo', 'Microsoft Sans Serif', 'Monaco', 'Noteworthy', 'Optima', 'Palatino', 'Papyrus', 'Phosphate', 'Rockwell', 'Savoye LET', 'SignPainter', 'Skia', 'Snell Roundhand', 'Tahoma', 'Times', 'Times New Roman', 'Trattatello', 'Trebuchet MS', 'Verdana', 'Zapfino',
    ].sort());

    useEffect(() => {
        FontFunction().then((e) => {
            let newArr = [];
            e.map((a, i) => {
                newArr.push({ value: a, label: a })
            })
            // console.log("newArr", newArr);
            setFontFamily(newArr)
        })
    }, [])

    // console.log("fontFamily=>", fontFamily);

    const FontFunction = async () => {

        await document.fonts.ready;

        const fontAvailable = new Set();

        for (const font of fontCheck.values()) {
            if (document.fonts.check(`12px "${font}"`)) {
                fontAvailable.add(font);
            }
        }
        // console.log('Available Fonts:', [...fontAvailable.values()]);
        return [...fontAvailable.values()]
    }

    useEffect(() => {
        FontSizeFunction().then((f) => setFontSize(f))
    }, [])

    let fontSize = [];
    const FontSizeFunction = async () => {
        for (let i = 8; i <= 50; i++) {
            fontSize.push({ value: i, label: i })
        }
        // console.log('fontsize==>', fontSize);
        return fontSize
    }

    // useEffect(() => {
    //     if (sessionSettingObj) {
    //         setSessionSetting(sessionSettingObj)
    //     }
    // }, [sessionSettingObj])

    // console.log("sessionSetting=>", sessionSetting);
    const SelectItem = forwardRef(
        ({ value,label, ...others }, ref) => (
          <div ref={ref} {...others}>
            <Text ff={value}>{label}</Text>
          </div>
        )
      );
    return (<>
        <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: "12px" }}>Color Scheme</Text>
                </Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={8}>
                    <ActionIcon
                        onClick={() => toggleColorScheme()}
                        variant="outline"
                        color={dark ? 'yellow' : 'blue'}
                        title="Toggle color scheme"
                    >
                        {dark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
                    </ActionIcon>
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: "12px" }}>Primary Color</Text>
                </Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={8}>
                    {/* <ColorInput
                        format='hex'
                        value={sessionSettingObj?.Primary_Color}
                        onChange={(e) => {
                            console.log('Primary Color =>', e)
                            setSessionSettingObj({ ...sessionSettingObj, Primary_Color: e })
                        }}
                    /> */}
                    <PLComboBox
                        value={sessionSetting?.Primary_Color}
                        data={primaryColorData}
                        dispexpr={"label"}
                        valexpr={"value"}
                        setEdit={(e) => {
                            setSessionSetting({ ...sessionSetting, Primary_Color: e })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: "12px" }}>Font Family</Text>
                </Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={8}>
                    <PLComboBox
                        value={sessionSetting?.Font_Family}
                        data={fontFamily}
                        itemComponent={SelectItem}
                        dispexpr={"label"}
                        valexpr={"value"}
                        setEdit={(e) => {
                            console.log("Font Family=>", e);
                            setSessionSetting({ ...sessionSetting, Font_Family: e })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={3}>
                    <Text style={{ fontSize: "12px" }}>Font Size</Text>
                </Grid.Col>
                <Grid.Col span={1}>:</Grid.Col>
                <Grid.Col span={8}>
                    <PLComboBox
                        value={sessionSetting?.Font_Size}
                        data={FontSize}
                        dispexpr={"label"}
                        valexpr={"value"}
                        setEdit={(e) => {
                            console.log("Font Size=>", e);
                            setSessionSetting({ ...sessionSetting, Font_Size: parseInt(e) })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12}>
                <Box maw={400} mx="auto">
                    <Group style={{ display: "flex", alignItems: "center", justifyContent: "end" }} mb={5}>
                        <Text onClick={toggle} style={{ textDecoration: "underline", color: sessionSetting?.Primary_Color, cursor: "pointer", fontSize: "12px" }}>{opened?"Hide":"Show"} Advance Font Setting</Text>
                        {/* <Button onClick={toggle}>Toggle content</Button> */}
                    </Group>

                    <Collapse in={opened}>
                        <Grid gutter={4}>
                            <Grid.Col span={12} style={{ display: "flex" }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: "12px" }}>XS</Text>
                                </Grid.Col>
                                <Grid.Col span={1}>:</Grid.Col>
                                <Grid.Col span={8}>
                                    <PLComboBox
                                        value={sessionSetting?.Font_XS}
                                        data={FontSize}
                                        dispexpr={"label"}
                                        valexpr={"value"}
                                        setEdit={(e) => {
                                            setSessionSetting({ ...sessionSetting, Font_XS: parseInt(e) })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>

                            <Grid.Col span={12} style={{ display: "flex" }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: "12px" }}>SM</Text>
                                </Grid.Col>
                                <Grid.Col span={1}>:</Grid.Col>
                                <Grid.Col span={8}>
                                    <PLComboBox
                                        value={sessionSetting?.Font_SM}
                                        data={FontSize}
                                        dispexpr={"label"}
                                        valexpr={"value"}
                                        setEdit={(e) => {
                                            setSessionSetting({ ...sessionSetting, Font_SM: parseInt(e) })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>

                            <Grid.Col span={12} style={{ display: "flex" }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: "12px" }}>MD</Text>
                                </Grid.Col>
                                <Grid.Col span={1}>:</Grid.Col>
                                <Grid.Col span={8}>
                                    <PLComboBox
                                        value={sessionSetting?.Font_MD}
                                        data={FontSize}
                                        dispexpr={"label"}
                                        valexpr={"value"}
                                        setEdit={(e) => {
                                            setSessionSetting({ ...sessionSetting, Font_MD: parseInt(e) })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>

                            <Grid.Col span={12} style={{ display: "flex" }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: "12px" }}>LG</Text>
                                </Grid.Col>
                                <Grid.Col span={1}>:</Grid.Col>
                                <Grid.Col span={8}>
                                    <PLComboBox
                                        value={sessionSetting?.Font_LG}
                                        data={FontSize}
                                        dispexpr={"label"}
                                        valexpr={"value"}
                                        setEdit={(e) => {
                                            setSessionSetting({ ...sessionSetting, Font_LG: parseInt(e) })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>

                            <Grid.Col span={12} style={{ display: "flex" }}>
                                <Grid.Col span={3}>
                                    <Text style={{ fontSize: "12px" }}>XL</Text>
                                </Grid.Col>
                                <Grid.Col span={1}>:</Grid.Col>
                                <Grid.Col span={8}>
                                    <PLComboBox
                                        value={sessionSetting?.Font_XL}
                                        data={FontSize}
                                        dispexpr={"label"}
                                        valexpr={"value"}
                                        setEdit={(e) => {
                                            setSessionSetting({ ...sessionSetting, Font_XL: parseInt(e) })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        </Grid>
                    </Collapse>
                </Box>
            </Grid.Col>

            {/* <Grid.Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Button size='xs' onClick={() => SaveSettingFun()}>Save</Button>
            </Grid.Col> */}

        </Grid>
    </>)
}

export default SessionSetting