import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { GetAccountList } from "./AccountListSlice";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { DataSaveLoading } from "./DataSaveLoadingSlice";

export const GetItemwiseExpenseList = createAsyncThunk(
  "ItemwiseExpenseList/GetItemwiseExpenseList",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: "",
        cOPara: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM46Exp + "?pa=" + param
      );
      // console.log("response", response);
      return response?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
export const DeleteItemwiseExpenseList = createAsyncThunk(
  "ItemwiseExpenseList/DeleteItemwiseExpenseList",
  async (cOPara) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
     try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "D",
        cCode: "",
        cOPara: cOPara,
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM46Ent + "?pa=" + param
      );
      // console.log("response  ", response);
      if (response.data.status === "SUCCESS") {
        // dispatch(DataSaveLoading(true))
        Promise.resolve(Store.dispatch(GetItemwiseExpenseList())).then(()=>GlobalClass.Notify("success", "Deleted", "Deleted successfully")).then(()=>Store.dispatch(DataSaveLoading(false)))
      } else {
        Promise.resolve(GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        )).then(()=>Store.dispatch(DataSaveLoading(false)))
      }
      return response.data;
    } catch (e) {
      Promise.resolve(GlobalClass.Notify("error", "Error", `${e?.message}`)).then(()=>Store.dispatch(DataSaveLoading(false)))
      return { e };
    }
  }
);

const ItemwiseExpenseListSlice = createSlice({
  name: "ItemwiseExpenseList",
  initialState: {
    ItemwiseExpenseListData: [],
    RowObjGet: {},
    getIndex: "",
    getDropdownValue: "",
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    PageData: "",
    valueOfDropDown: "",
    tableTwoSeletedRow: {},
  },
  reducers: {
    getRowObj: (state, action) => {
      // console.log("action.payload row", action.payload);
      state.RowObjGet = action.payload;
    },
    getSelectedIndexOfRightSideTable: (state, action) => {
      // console.log("action.payload", action.payload);
      state.getIndex = action.payload;
    },
    getSelectedDropdownValue: (state, action) => {
      // console.log("action.payload", action.payload);
      state.getDropdownValue = action.payload;
    },
    getTableTwoSelectedRow: (state, action) => {
      // console.log("action.payloadYasvi", action.payload);
      state.tableTwoSeletedRow = action.payload;
    },
    // getPageData: (state, action) => {
    //   console.log("getPageData action.payload=>",action.payload);
    //   state.PageData = action.payload
    // },
    // getValueOfDropDown : (state,action) => {
    //   console.log("getPageData action.payload=>",action.payload);
    //   state.valueOfDropDown = action.payload
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetItemwiseExpenseList.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.ItemwiseExpenseListData = [];
      })
      .addCase(GetItemwiseExpenseList.fulfilled, (state, action) => {
        // console.log("action.payload", action.payload?.error);
        if(!action.payload?.error){
        state.ItemwiseExpenseListData = action.payload;
        // console.log("ItemwiseExpenseListData", state.ItemwiseExpenseListData);
        state.isLoading = false;
        state.hasError = false;
        }else{
        // console.log("ItemwiseExpenseListData", state.ItemwiseExpenseListData);
        state.isLoading = false;
        state.hasError = true;
        state.ErrorMsg=action.payload?.error;
        }
      })
      .addCase(GetItemwiseExpenseList.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
        state.ErrorMsg=action.payload?.error;
      })
      .addCase(DeleteItemwiseExpenseList.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(DeleteItemwiseExpenseList.fulfilled, (state, action) => {
        state.successMsg = action.payload.status;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(DeleteItemwiseExpenseList.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export const {
  getRowObj,
  getSelectedIndexOfRightSideTable,
  getSelectedDropdownValue,
  getPageData,
  getValueOfDropDown,
  getTableTwoSelectedRow,
} = ItemwiseExpenseListSlice.actions;

export default ItemwiseExpenseListSlice.reducer;
