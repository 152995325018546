import { Button, Grid, Group } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Store from "../../../utils/store";
import { GetTMFormat } from "../../../utils/slices/AccountListSlice";
import { createColumnHelper } from "@tanstack/react-table";
import GlobalClass from "../../../utils/GlobalClass";

export default function PendingOrder(props) {
  const { setDrawerOBJ, DrawerOBJ, data ,setEmpty} = props;
  const [rowSelection, setRowSelection] = useState({});
  const [columns, setColumns] = useState([]);
  const [t02Jobj, setT02Jobj] = useState([]);
  const {
    JWOrderData,
    hasErrorOder : hasError,
    isLoadingOrder: isLoading,
    errorMsgOrder: error,
  } = useSelector((state) => state?.GetPendingIssue);
  const { TMFormatData: tmList } = useSelector((state) => state.AccountList);

  const dtOrd = JWOrderData?.dtOrd ?? [];
  const OrdCol = JWOrderData?.OrdCol ?? "";
  const columnHelper = createColumnHelper();
  console.log(JWOrderData, "JWOrderData");

  useEffect(() => {
    if (OrdCol) {
      console.log("tmList", tmList);
      Store.dispatch(
        GetTMFormat({
          id: OrdCol.toString().split("~C~")[0],
          name: "",
          type: "A",
          p0: OrdCol.toString().split("~C~")[0],
          p1: OrdCol.toString().split("~C~")[0],
          p2: OrdCol.toString().split("~C~")[1],
          p3: "",
        })
      );
    }
  }, [OrdCol, JWOrderData]);
  const P_JORECPO = tmList[OrdCol.toString().split("~C~")[0]];
  useEffect(() => {
    if (
      P_JORECPO !== undefined &&
      OrdCol !== undefined &&
      P_JORECPO instanceof Object
    ) {
      var cols = [];
      // tmList[M45.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?
      P_JORECPO?.oDCFrmt?.aDCol?.map((v, i) => {
        cols.push(
          columnHelper.accessor(v?.DispExpr, {
            header: v?.ColCap,
            enableHiding: true,
            enableColumnFilter: v?.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: "auto",
            id: v?.ColCap,

            Cell: ({ cell }) => {
              var val = cell.getValue();
              return val;
            },
          })
        );
      });
      setColumns(cols);
    }
  }, [OrdCol, P_JORECPO, JWOrderData]);
  function SavePendingOrderData() {
    let newArray = [];
    
     
    Promise.resolve(
      // setT02Jobj(newArray);
      dtOrd?.map((n, i) => {
      if (i in rowSelection) {
        newArray.push(n);
      } 
      props?.setData(newArray) })
    
    ).then(() =>
      typeof setDrawerOBJ == "function"
        ? setDrawerOBJ({ ...DrawerOBJ, open: false })
        : ""
    );
  }
  useEffect(() => {
    console.log(error, "error2");
    if (hasError) {
      GlobalClass.Notify("error", "Error: " + error.code, error.message);
    }
  }, [hasError, error]);
  // useEffect(() => {
  //   if ((dtOrd?.length == 0 && !isLoading) ) {
  //     console.log(dtOrd, "T02JDT--", typeof setDrawerOBJ )
  //     if (typeof setDrawerOBJ == "function" ) {
  //       console.log(dtOrd, "T02JDT--");
  //       // setEmpty(true)
  //       setDrawerOBJ({ ...DrawerOBJ, open: false });
  //     }
  //   }
  // }, [dtOrd, isLoading])
  const table = useMantineReactTable({
    data: dtOrd ?? [],
    columns: columns,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getRowId: (row) => row?.id,
    onRowSelectionChange: setRowSelection,
    state: { rowSelection },
  });
  return (
    <>
      {P_JORECPO == undefined || isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Grid gutter={4}>
          <Grid.Col span={12}>
            <MantineReactTable table={table} />
          </Grid.Col>
          <Grid.Col span={12}>
            <Group spacing={"xs"} position="center">
              <Button
                size="xs"
                variant="filled"
                compact
                onClick={() => SavePendingOrderData()}
              >
                OK
              </Button>
            </Group>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
}
