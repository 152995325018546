import { ActionIcon, Button, Flex, Grid, Group, Text, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { modals } from '@mantine/modals';

const SIBillToBillTable = (props) => {

    const {T05Data,T05Format,setT05Data} = props.innerProps.modalBody.props.children.props



    console.log("BillToBillTable props",props);
    // const { T05Format, T05Data } = props;
    const [columns, setColumns] = useState([])
    const [T05, setT05] = useState([])
    const [T05RowObj, setT05RowObj] = useState({
        "FIELD01": null,
        "FIELD02": 0.0,
        "FIELD03": null,
        "FIELD04": null,
        "FIELD05": "",
        "FIELD10": 0,
        "FIELD11": null,
        "FIELD12": 0,
        "FIELD15": null,
        "FIELD16": null,
        "FIELD21": null,
        "FIELD22": null,
        "FIELD23": null,
        "FIELD31": null,
        "FIELD32": null,
        "FIELD33": null,
        "FIELD35": null,
        "FIELD40": null,
        "USALM": null,
        "USALE": null,
        "FLDUNQ": null,
        "FLDAED": null,
        "FLDBRC": null,
        "EGKEYID": 1,
        "T05PAR": null,
        "T05CRDB": null,
        "PRTCOD": null,
        "USERFLD": null,
        "PRTICLR": null,
        "LSELECT": null,
        "LAPPEN": 1,
        "SAMOUNT": 0,
        "CURRNM": null
    })

    const columnHelper = createColumnHelper();

    useEffect(() => {
        if (T05Data) {
            setT05(T05Data)
        }
    }, [T05Data])

    useEffect(() => {
        if (T05Format) {
            let col = [];
            T05Format.map((d) => {
                col.push(
                    columnHelper.accessor(d.S53F04, {
                        header: d.S53F03,
                        Edit: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderInputBox(d, val)
                        },
                        Cell: ({ cell, row }) => {
                            let val = cell.getValue();
                            return renderRowValue(d, val)
                        }
                    })
                )
                // console.log("Row=>",d.S53F03,":",d.S53F04);
            })
            setColumns(col)
        }

    }, [T05Format, T05RowObj])

    const seprate = (e) => {
        var paraArr = e.split('~C~').join('~R~').split('~R~')
        // console.log('paraArr==>', paraArr);
        return paraArr
    }

    const renderInputBox = (d) => {
        // let InputBox = ""
        switch (d?.S53F03) {
            case "Reference Type": //FIELD04 
                return <PLComboBox
                    value={T05RowObj?.FIELD04}
                    copno={seprate(d?.S53F15)[1]}
                    dispexpr={"DisplayMember"}
                    valexpr={"ValueMember"}
                    setEdit={(e) => {
                        console.log("Reference Type combo=>", e);
                        setT05RowObj({ ...T05RowObj, FIELD04: e.value })
                    }}
                />
                break;
            case "Particular": //T05PAR 
                return <PLTextBox
                    value={T05RowObj?.T05PAR}
                    setEdit={(e) => {
                        console.log("Particular", e.target.value);
                        setT05RowObj({ ...T05RowObj, T05PAR: e.target.value })
                    }}
                />
                break;
            case "Credit Days": //FIELD12 
                return <PLNumberBox
                    value={T05RowObj?.FIELD12}
                    setEdit={(e) => {
                        setT05RowObj({ ...T05RowObj, FIELD12: e })
                    }}
                />
                break;
            case "Adjusted Amount": //FIELD10 
                return <PLNumberBox
                    value={T05RowObj?.FIELD10}
                    setEdit={(e) => {
                        setT05RowObj({ ...T05RowObj, FIELD10: e })
                    }}
                />
                break;
            case "CR/DB": //T05CRDB 
                return <PLComboBox
                    value={T05RowObj?.T05CRDB}
                    copno={seprate(d?.S53F15)[1]}
                    dispexpr={"DisplayMember"}
                    valexpr={"ValueMember"}
                    setEdit={(e) => {
                        setT05RowObj({ ...T05RowObj, T05CRDB: e.value })
                    }}
                />
                break;
            case "Voucher Amount": //SAMOUNT 
                return <PLNumberBox
                    value={T05RowObj?.SAMOUNT}
                    setEdit={(e) => {
                        setT05RowObj({ ...T05RowObj, SAMOUNT: e })
                    }}
                />
                break;
            default:
                return null
                break;
        }
    }

    const renderRowValue = (d, v) => {
        let value;
        switch (d?.S53F03) {
            case "Reference Type":
                value = v == "N" ? 'New Reference' :
                    v == 'P' ? 'Advance Payment' :
                        v == 'O' ? 'On Account' :
                            v == 'A' ? 'Against Ref.' :
                                v == 'M' ? 'New Against Adv. Pay' : v
                break;
            case "CR/DB":
                value = v == 'C' ? 'Credit' :
                    v == 'D' ? 'Debit' : v
                break;
            default:
                value = v
                break;
        }
        return value
    }

    // add new row
    const handleCreateUser = ({ exitCreatingMode }) => {

        console.log("handleCreateUser =>", T05RowObj);

        setT05([...T05, T05RowObj])

        exitCreatingMode();

        setT05RowObj({
            "FIELD01": null,
            "FIELD02": 0.0,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": "",
            "FIELD10": 0,
            "FIELD11": null,
            "FIELD12": 0,
            "FIELD15": null,
            "FIELD16": null,
            "FIELD21": null,
            "FIELD22": null,
            "FIELD23": null,
            "FIELD31": null,
            "FIELD32": null,
            "FIELD33": null,
            "FIELD35": null,
            "FIELD40": null,
            "USALM": null,
            "USALE": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1,
            "T05PAR": null,
            "T05CRDB": null,
            "PRTCOD": null,
            "USERFLD": null,
            "PRTICLR": null,
            "LSELECT": null,
            "LAPPEN": 1,
            "SAMOUNT": 0,
            "CURRNM": null
        })
    }

    // update row
    const handleSaveUser = ({ values, table, row }) => {
        console.log("handleSaveUser =>", T05RowObj);

        let newArray = [...T05]

        newArray[row.index] = T05RowObj

        setT05(newArray)

        setT05RowObj({
            "FIELD01": null,
            "FIELD02": 0.0,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": "",
            "FIELD10": 0,
            "FIELD11": null,
            "FIELD12": 0,
            "FIELD15": null,
            "FIELD16": null,
            "FIELD21": null,
            "FIELD22": null,
            "FIELD23": null,
            "FIELD31": null,
            "FIELD32": null,
            "FIELD33": null,
            "FIELD35": null,
            "FIELD40": null,
            "USALM": null,
            "USALE": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1,
            "T05PAR": null,
            "T05CRDB": null,
            "PRTCOD": null,
            "USERFLD": null,
            "PRTICLR": null,
            "LSELECT": null,
            "LAPPEN": 1,
            "SAMOUNT": 0,
            "CURRNM": null
        })

        table.setEditingRow(null);
    }

    const openDeleteConfirmModal = (row) => {
        console.log("openDeleteConfirmModal", row);

        let newArray = T05.filter((d, i) => i != row.index)

        setT05(newArray)

    }

    const handleNullT41Obj = () => {
        setT05RowObj({
            "FIELD01": null,
            "FIELD02": 0.0,
            "FIELD03": null,
            "FIELD04": null,
            "FIELD05": "",
            "FIELD10": 0,
            "FIELD11": null,
            "FIELD12": 0,
            "FIELD15": null,
            "FIELD16": null,
            "FIELD21": null,
            "FIELD22": null,
            "FIELD23": null,
            "FIELD31": null,
            "FIELD32": null,
            "FIELD33": null,
            "FIELD35": null,
            "FIELD40": null,
            "USALM": null,
            "USALE": null,
            "FLDUNQ": null,
            "FLDAED": null,
            "FLDBRC": null,
            "EGKEYID": 1,
            "T05PAR": null,
            "T05CRDB": null,
            "PRTCOD": null,
            "USERFLD": null,
            "PRTICLR": null,
            "LSELECT": null,
            "LAPPEN": 1,
            "SAMOUNT": 0,
            "CURRNM": null
        })
    }

    console.log("T05RowObj==>", T05RowObj);

    const table = useMantineReactTable({
        data: T05,
        columns,
        enableTopToolbar: true,
        enableFilters: false,
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableSorting: false,
        enableColumnActions: false,
        mantineTableContainerProps: { sx: { maxHeight: "200px", minHeight: "200px" } },
        initialState: {
            density: "0px",
            // columnVisibility: {
            //     EGKEYID: false,
            //     LSELECT: false, FIELD21: false, FIELD22: false, FIELD35: false, USERFLD: false, T05MCF01: false, T05MCF02: false, T05MCF04: false
            // }
        },
        defaultColumn: { minSize: 60, maxSize: 120, size: 150 },
        enableEditing: true,
        editDisplayMode: 'row',
        createDisplayMode: 'row',
        onCreatingRowSave: handleCreateUser,
        onEditingRowSave: handleSaveUser,
        onCreatingRowCancel: handleNullT41Obj,
        onEditingRowCancel: handleNullT41Obj,
        renderRowActions: ({ row, table }) => (
            <Flex gap="xs">
                <Tooltip label="Edit">
                    <ActionIcon onClick={() => {
                        table.setEditingRow(row)
                        console.log('table.setEditingRow=>', row);
                        setT05RowObj(row.original);
                    }}>
                        <IconEdit size={'20px'} />
                    </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                    <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
                        <IconTrash size={'20px'} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                onClick={() => {
                    table.setCreatingRow(true);
                }}
                size='xs'
            >
                Add New
            </Button>
        ),
    })

    useEffect(() => {
        if (T05) {
            setT05Data(T05)
        }
    }, [T05])

    // const BillToBillSubmit = () => {
    //    setT05Data(T05)
    //     props.context.closeModal(props.id)

    // }

    let rowSum = T05.reduce((a, b) => a = a + b?.FIELD10, 0)

    return (<>

        <Grid gutter={4}>
            <Grid.Col span={12}>
                <MantineReactTable table={table} />
            </Grid.Col>
            {/* <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button size='xs' variant='filled' onClick={() => BillToBillSubmit()}>
                        Ok
                    </Button>
                </Group>
            </Grid.Col> */}
            <Grid.Col span={12} style={{ display: "flex" }}>
                <Grid.Col span={4}>
                    <Text style={{ fontSize: 15, fontWeight: "bold" }}>Total Amount : {rowSum}</Text>
                    <Text style={{ fontSize: 15, fontWeight: "bold" }}>Pending:</Text>
                </Grid.Col>
                <Grid.Col span={8}>
                    <Group spacing={'xs'} style={{ display: "flex", flexDirection: "row" }}>
                        <Button size='xs' variant='filled' >
                            Edit
                        </Button>
                        <Button size='xs' variant='filled'>
                            Bill No. Search
                        </Button>
                    </Group>
                </Grid.Col>
            </Grid.Col>
        </Grid>

    </>)
}

export default SIBillToBillTable