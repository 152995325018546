import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";

export const GetStockRate = createAsyncThunk(
  "StockRate/GetStockRate",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "",
        cCode: "",
        cOPara: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetStockRate + "?pa=" + param
      );
      // console.log(response, "jkhjjh");
      return response.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

const StockRateSlice = createSlice({
  name: "StockRate",
  initialState: {
    StockRateData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetStockRate.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(GetStockRate.fulfilled, (state, action) => {
        // console.log("GetStockRate=>", action.payload?.error);
        if (!action.payload?.error) {
          state.StockRateData = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.StockRateData = action.payload;
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetStockRate.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
        state.ErrorMsg = action.payload?.error;
      });
  },
});
export default StockRateSlice.reducer;
