import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetGroupFilterParty } from '../../utils/slices/VoucherSetupPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const GroupFilterInParty = (props) => {
    const { value, setEditSend } = props
    const isLoading = useSelector((state) => state.VoucherSetupPopUp?.isLoading)
    const GroupFilterInPartyData = useSelector((state) => state.VoucherSetupPopUp?.GroupFilterPartyData)
    const P_M11 = useSelector((state) => state.VoucherSetupPopUp?.GroupFilterPartyData?.P_M11)
    const P_M11DT = useSelector((state) => state.VoucherSetupPopUp?.GroupFilterPartyData?.P_M11DT)

    // console.log('GroupFilterInPartyData', GroupFilterInPartyData)
    const dispatch = useDispatch()
    const [multiSelectedVal, setMultiSelectedVal] = useState([])

    useEffect(() => {
        dispatch(GetGroupFilterParty())
    }, [])

    useEffect(() => {
        if (multiSelectedVal && multiSelectedVal.length > 0) {
            let Arr = [];
            Arr = multiSelectedVal.map((m) => {
                // console.log('========>',m.FIELD01)
                return m.FIELD01
            })
            // console.log('arr==>',Arr.toString());
            return setEditSend(Arr.toString())
        }
    }, [multiSelectedVal])

    return (
        <div style={{ width: '100%' }}>
            {
                isLoading ? GlobalClass.RenderLoader('dots') :
                    GroupFilterInPartyData &&
                    <PLDataGrid
                        dropdownPosition={true}
                        // width={"500px"}
                        value={value}
                        isMulti={true}
                        TmData={P_M11}
                        data={P_M11DT?.jData}
                        setEdit={(e) => {
                            console.log('selected val', e)
                            let val = [];
                            if (e?.length > 0) {
                                e?.map((m) => {
                                    val.push(m.original)
                                })
                                // console.log('val=>', val);
                                return setMultiSelectedVal(val)
                            }
                        }}
                    />
            }
        </div>
    )
}

export default GroupFilterInParty