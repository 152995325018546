import React, { forwardRef, useEffect, useState } from "react";
import { DateInput } from "@mantine/dates";
import Store from "../utils/store";
import { useSelector } from "react-redux";

const PLDateBox = forwardRef((props, ref) => { //Nairin Salot
  // console.log("date=>>", props);

  const [date, setDate] = useState();

  const GetYearAndDate = useSelector((state) => state?.UserList?.YearAndDateSend);

  useEffect(() => {
    let defaultDate = props?.defaultval ? new Date(
      Date.parse(
        props?.defaultval?.slice(0, 4) +
        "-" +
        props?.defaultval?.slice(4, 6) +
        "-" +
        props?.defaultval?.slice(6, 8)
      )
    ) : props.value ? props.value : new Date()

    setDate(defaultDate)

  }, [props?.defaultval, props?.value])//Vara Pooja


  //   setEdit = {(e) => { console.log("edit", e); setEdit({ ...editObj, [v.F02F01]: e }); }
  // }
  // name = { v.F02F01 }
  // defaultval = { v[c.S53F04]}
  // showClearButton = { false}
  // showDropDownButton = { false}
  // useMaskBehavior = { true}
  // disptype = "date"
  // dispformat = "dd/MM/yyyy"
  // onvalchange = { onValChanged }
  // setDate = {(e)=> setDateBox(e)}
  // onInitialized = {(e) => {
  //   e.component.registerKeyHandler("enter", function (event) {
  //     console.log("custom_logic enter", event);
  //   });
  // }}
  // const date= new Date(props.defaultval).getTime();
  return (
    props?.isVal == false ? //Yasvi Patel ( conditionally render DateInput because in M01 in "effective date", we are not passing value in dateInput if user doesn't enter date by himself )
      <DateInput
        ref={ref} //Nairin Salot
        autoFocus={props?.autoFocus}
        style={{ zIndex: props?.zIndex ? props?.zIndex : 999 }}
        // size={props?.size ? props.size : "sm"}
        size="xs"
        // clearable={props?.clearable ?? false}
        label={props.label}
        height={5}
        id={props.name}
        valueFormat={props.dispformat}
        locale="en-IN"
        type={props.disptype}
        onKeyDown={(e) => props?.onKeyDown && typeof props?.onKeyDown == "function" ? props?.onKeyDown(e) : null} //Nairin Salot
        display={props.disptype}
        // onBlur={()=>props.setDate("")}
        onChange={(e) => {
          // console.log("date edit", e.getDate() + "/" + e.getMonth());
          props.setEdit(e);
        }}
        // label="Date input"
        placeholder={props?.placeholder ?? "Date input"}
        // maw={400}
        // mx="auto"
        minDate={props?.minDate ?? GetYearAndDate?.strtDate}
        maxDate={props?.maxDate ?? GetYearAndDate?.endDate}
        disabled={props.disabled}
      />
      :
      <DateInput
      ref={ref} //Nairin Salot
        autoFocus={props?.autoFocus}
        style={{ zIndex: props?.zIndex ? props?.zIndex : 999 }}
        // size={props?.size ? props.size : "sm"}
        size="xs"
        onKeyDown={(e) => props?.onKeyDown && typeof props?.onKeyDown == "function" ? props?.onKeyDown(e) : null} //Nairin Salot
        // clearable={props?.clearable ?? false}
        label={props.label}
        height={5}
        id={props.name}
        valueFormat={props.dispformat}
        value={date} // value to defaultValue Change
        // value={props.value ? new Date(props.value).getTime() : new Date()}
        locale="en-IN"
        type={props.disptype}
        onBlur={props?.onBlur}
        display={props.disptype}
        // onBlur={()=>props.setDate("")}
        onChange={(e) => {
          // console.log("onChange PLDate =>",e);
          // console.log("date edit", e.getDate() + "/" + e.getMonth());

          let y = e.getFullYear();
          let m = e.getMonth();
          let d = e.getDay();

          console.log("onChange PLDate =>", [{
            "e": e,
            "year": y,
            "month": m,
            "day": d
          }]);

          // if(y.length == 4 && m.length == 2 && d.length == 2){
            props.setEdit(e);
          // }

        }}
        // label="Date input"
        placeholder={props?.placeholder ?? "Date input"}
        // maw={400}
        // mx="auto"
        minDate={props?.minDate ?? GetYearAndDate?.strtDate}
        maxDate={props?.maxDate ?? GetYearAndDate?.endDate}
        disabled={props.disabled}
      />
  );
}
 )
export default PLDateBox;