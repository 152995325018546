import { ActionIcon, Badge, List, Loader, Text, Timeline } from '@mantine/core'
import React, { useState, useEffect } from 'react';
import { Button, Card, Container, TextInput, Textarea, Grid, Col, Stack, Group, Paper, Divider } from '@mantine/core';
import { IconEdit, IconPlus, IconTrash, IconX } from '@tabler/icons-react';
import axios from 'axios';
import { useDisclosure } from '@mantine/hooks';
import GlobalClass from '../utils/GlobalClass';
const apiUrl = 'https://lms.cyberhale.com/api/changelog';

const ChangelogManager = () => {
    const [entries, setEntries] = useState([]);
    const [newEntry, setNewEntry] = useState({
      title: '',
      date: '',
      pages: [],
    });
    const [editingEntry, setEditingEntry] = useState(null);
    const [pageCounter, setPageCounter] = useState(0);
    const [loading, setLoading] = useState(false);
  
    useEffect(() => {
      loadChangelog();
    }, []);
  
    const loadChangelog = async () => {
      setLoading(true);
      try {
        const response = await axios.get(apiUrl);
        setEntries(response.data);
      } catch (error) {
        console.error('Error loading changelog:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleInputChange = (e) => {
      setNewEntry({ ...newEntry, [e.target.name]: e.target.value });
    };
  
    const handlePageChange = (e, index) => {
      const updatedPages = [...newEntry.pages];
      updatedPages[index] = { ...updatedPages[index], [e.target.name]: e.target.value };
      setNewEntry({ ...newEntry, pages: updatedPages });
    };
  
    const handleAddPage = () => {
      setNewEntry({
        ...newEntry,
        pages: [...newEntry.pages, { name: '', items: [] }],
      });
      setPageCounter(pageCounter + 1);
    };
  
    const handleAddItem = (pageIndex) => {
      const updatedPages = [...newEntry.pages];
      updatedPages[pageIndex] = {
        ...updatedPages[pageIndex],
        items: [...updatedPages[pageIndex].items, ''],
      };
      setNewEntry({ ...newEntry, pages: updatedPages });
    };
  
    const handleItemChange = (e, pageIndex, itemIndex) => {
      const updatedPages = [...newEntry.pages];
      updatedPages[pageIndex].items[itemIndex] = e.target.value;
      setNewEntry({ ...newEntry, pages: updatedPages });
    };
  
    const handleDeletePage = (index) => {
      const updatedPages = newEntry.pages.filter((_, i) => i !== index);
      setNewEntry({ ...newEntry, pages: updatedPages });
    };
  
    const handleDeleteItem = (pageIndex, itemIndex) => {
      const updatedPages = [...newEntry.pages];
      updatedPages[pageIndex].items = updatedPages[pageIndex].items.filter((_, i) => i !== itemIndex);
      setNewEntry({ ...newEntry, pages: updatedPages });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {
        if (newEntry.title && newEntry.date) {
          if (editingEntry) {
            await axios.put(`${apiUrl}/${editingEntry.title}`, newEntry);
          } else {
            await axios.post(apiUrl, newEntry);
          }
          setNewEntry({ title: '', date: '', pages: [] });
          setEditingEntry(null);
          setPageCounter(0);
          loadChangelog();
          setform.close()
        } else {
            GlobalClass.Notify('warning',editingEntry?"Updating":"Adding","Please fill in all required fields");
        }
      } catch (error) {
        console.error('Error saving changelog:', error);
        GlobalClass.Notify('error',"Error saving changelog",error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleEditEntry = (entry) => {
      setNewEntry(entry);
      setEditingEntry(entry);
      setform.open();
    };
  
    const handleDeleteEntry = async (version) => {
      setLoading(true);
      try {
        await axios.delete(`${apiUrl}/${version}`);
        loadChangelog();
        setform.close()
      } catch (error) {
        console.error('Error deleting changelog entry:', error);
      } finally {
        setLoading(false);
      }
    };
    const [form,setform]=useDisclosure(false);
    return (
      <Container>
        {!(process.env.NODE_ENV === "production") && <Button fullWidth compact variant='default' onClick={setform.toggle}>{!form?"Open":"Close"} Form</Button>}
        {form && <Card shadow="xs" padding="xs" radius="md">
          <h2>{editingEntry ? 'Edit Changelog' : 'Add New Changelog'}</h2>
          <form onSubmit={handleSubmit}>
            <Stack spacing="md">
              <TextInput
                name="title"
                value={newEntry.title}
                onChange={handleInputChange}
                label="Version"
                placeholder="Enter version (e.g., V 1.31)"
                required
              />
              <TextInput
                name="date"
                type="date"
                value={newEntry.date}
                onChange={handleInputChange}
                label="Date"
                required
              />
              <Button onClick={handleAddPage} leftIcon={<IconPlus />} variant="outline" color="blue">
                Add Page
              </Button>
              {newEntry.pages.map((page, pageIndex) => (
                <Card key={pageIndex} shadow="xs" padding="lg" radius="md" withBorder>
                  <Stack spacing="md">
                    <Group position="apart">
                      <TextInput
                        name="name"
                        value={page.name}
                        onChange={(e) => handlePageChange(e, pageIndex)}
                        label={`Page ${pageIndex + 1} Name`}
                        placeholder="Enter page name"
                        required
                      />
                      <ActionIcon color="red" onClick={() => handleDeletePage(pageIndex)}>
                        <IconTrash size={18} />
                      </ActionIcon>
                    </Group>
                    <Button onClick={() => handleAddItem(pageIndex)} leftIcon={<IconPlus />} variant="outline" color="blue">
                      Add Item
                    </Button>
                    {page.items.map((item, itemIndex) => (
                      <Group key={itemIndex} spacing="sm">
                        <TextInput
                          value={item}
                          onChange={(e) => handleItemChange(e, pageIndex, itemIndex)}
                          placeholder="Enter item"
                          required
                        />
                        <ActionIcon color="red" onClick={() => handleDeleteItem(pageIndex, itemIndex)}>
                          <IconX size={18} />
                        </ActionIcon>
                      </Group>
                    ))}
                  </Stack>
                </Card>
              ))}
              <Button type="submit" color="blue" loading={loading}>
                {editingEntry ? 'Update Changelog' : 'Save Changelog'}
              </Button>
              {editingEntry?<Button color="gray" onClick={()=>{setNewEntry({
      title: '',
      date: '',
      pages: [],
    }); setEditingEntry(false)} } loading={loading}>Cancel Update</Button>:""}
            </Stack>
          </form>
        </Card>}
  
        {form && <Divider my="lg" />}
          {loading ? (
            <Loader />
          ) : (
            <Timeline active={1} bulletSize={14} lineWidth={2}>
            {entries.map((e)=>(<Timeline.Item title={e.title+" ("+e.date+")"}>
                {!(process.env.NODE_ENV === "production") && <Group>
                      <ActionIcon color="blue" onClick={() => handleEditEntry(e)}>
                        <IconEdit size={18} />
                      </ActionIcon>
                      <ActionIcon color="red" onClick={() => handleDeleteEntry(e.title)}>
                        <IconTrash size={18} />
                      </ActionIcon>
                    </Group>}
                {e.pages.map((p)=>(<><Badge color='blue'>{p.name}</Badge><List>{p.items.map((l)=>(<List.Item><Text size="sm">{l}</Text></List.Item>))}</List></>))}
    
            </Timeline.Item>))}
          
        </Timeline>
            
          )}
      </Container>
    );
  };
  
export default ChangelogManager
