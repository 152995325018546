import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const ProcessMasterData = createAsyncThunk(
  "ProcessMaster/ProcessMasterData",
  async (obj) => {
    // console.log("ProcessMaster ==>", obj);
   
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": obj.action ? obj.action : "A",
        'cCode': obj.code ? obj.code : "",
        "cOPara": "",
      });
      const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM71Ent + "?pa=" + param);
      // console.log("ProcessMaster/ProcessMasterData", response?.data?.data)
      if (response.data.status === "SUCCESS" && obj.action === 'D') {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj

        Promise.resolve(Store.dispatch(GetAccountList({
          'id': '01980001',
          'name': 'Process Master',
          'p0': 'MT',
          'p1': 'T_M71',
          'p2': "",
          'p3': "",
          'type': "A",
          'pagination': PaginationObj['01980001']
        })
        )).then(() => GlobalClass.Notify("success", "Deleted", "Deleted successfully"))
      } else {
        if (obj.action === 'D') {
          GlobalClass.Notify(
            "error",
            response?.data?.status,
            response?.data?.message
          );}
      }
      return response?.data?.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);

const ProcessMasterSlice = createSlice({
  name: "ProcessMaster",
  initialState: {
    ProcessMData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ProcessMasterData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(ProcessMasterData.fulfilled, (state, action) => {
        // console.log('ProcessMasterData action.payload==>',action.payload);
        state.ProcessMData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(ProcessMasterData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});
export default ProcessMasterSlice.reducer;
