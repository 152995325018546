import { createSlice } from "@reduxjs/toolkit";


const stockJournalSlice = createSlice({
  name: "StockJournal",
  initialState: {
    processName: "",
  },
  reducers: {
    setProcessName: (state, action) => {
      // console.log(action.payload, "action.payload processName");
      state.processName = action.payload;
    },
  },

});

export const { setProcessName } = stockJournalSlice.actions;

export default stockJournalSlice.reducer;
