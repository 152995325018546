import React from "react";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Text } from "@mantine/core";
import TransactionSalesForm from "../../../Transaction/SaleEntry/TransactionSalesForm";
import Store from "../../../utils/store";
import OpeningStockAddForm from "../../../Transaction/StockEntry/OpeningStockForm";
import TransactionForm from "../../../FunctionsCall/Transaction/TransactionForm";
import { GetInvTypeDropDown } from "../../../utils/TransactionSlices/TSalesSlices";
import JournalForm from "../../../Transaction/JournalEntry/Journal/JournalForm";
import BillFormChallanModal from "../../../Transaction/JobWorkOut/JobWorkOutBill/BillFormChallanModal";
import CreditDebitNoteForm from "../../../Transaction/StockEntry/CreditDebitNote/CreditDebitNoteForm";

export function TM_ZINOUT(props) {
  const OBJ = JSON.parse(props);
  console.log(OBJ?.DataGridRow?.T41PVT, "AccLedger", OBJ);
  const Obj = { ...OBJ, id: OBJ?.id.trim(), p0: "E" };
  const T41PVT = OBJ?.DataGridRow?.T41PVT;

  if ((T41PVT === "SS" || T41PVT === "SR" || T41PVT === "PP") && OBJ?.id) {
    let newObj = {
      ...Obj,
      page:
        T41PVT === "SS"
          ? "02210213"
          : T41PVT === "SR"
          ? "02250213"
          : "02530249",
    };

    Store.dispatch(
      ModalFunction({
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "80%",
        // position:'right',
        MBody: (i) => <TransactionSalesForm obj={newObj} index={i} />,
        MClose: true,
        onclose: () => {},
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
      })
    );
  } else if ((T41PVT === "BR" || T41PVT === "BP") && OBJ?.id.trim()) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: (e) => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position: "right",
        MBody: (i) => <TransactionForm OBJ={Obj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if ((T41PVT === "CR" || T41PVT === "CP") && OBJ?.id.trim()) {
    let newObj = { ...Obj, page: T41PVT === "CR" ? "04830462" : "04800462" };
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: (e) => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position: "right",
        MBody: (i) => <TransactionForm OBJ={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (
    (T41PVT === "JR" ||
      T41PVT === "ND" ||
      T41PVT === "NC" ||
      T41PVT === "BC") &&
    OBJ?.id.trim()
  ) {
    console.log(OBJ , "OBJ-")
    let newObj = {
      ...Obj,
      page:
        T41PVT === "JR"
          ? "05130489"
          : T41PVT === "ND"
          ? "05100489"
          : T41PVT === "BC"
          ? "05040489"
          : "05070489",
    };
    console.log(newObj, "newObj-");
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {},
        onclose: (e) => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position: "right",
        MBody: (i) => <JournalForm obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (T41PVT === "OA" && OBJ?.id.trim()) {
    // Store.dispatch(
    //     ModalFunction({
    //       onclose: () => {},
    //       MTitle: <Text fw={500}>{OBJ?.text}</Text>,
    //       MAction: true,
    //       MSize: "xl",
    //       position:"right",
    //       MBody: (i) => <OpeningStockAddForm obj={Obj} index={i} />,
    //       MClose: true,
    //       Overlay: {
    //         backgroundOpacity: 0.8,
    //         blur: 5,
    //       },
    //     })
    //   );
  } else if ((T41PVT === "JZ" || T41PVT === "JJ") && OBJ?.id.trim()) {
    let newObj = { ...Obj, page: T41PVT === "JZ" ? "04320417" : "04110399" };
    console.log(T41PVT === "JJ", "T41PVT ===");
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: () => {},
        MTitle: <Text fw={500}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position: "right",
        MBody: (i) => <BillFormChallanModal obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if ((T41PVT === "HC" || T41PVT === "HD") && OBJ?.id.trim()) {
    let newObj = { ...Obj, page: T41PVT === "HC" ? "03510342" : "03540342" };

    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null));
        },
        onclose: () => {},
        MTitle: <Text fw={500}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        position: "right",
        MBody: (i) => <CreditDebitNoteForm obj={newObj} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  }
}
// export
