import React from "react";
import { Box, Button, Center, Divider, Grid, Text } from "@mantine/core";
import PLComboBox from "../../../../PlusComponent/PLComboBox";
import PLDataGrid from "../../../../PlusComponent/PLDataGrid";
import PLNumberBox from "../../../../PlusComponent/PLNumberBox";

export default function GraphFormat() {
  return (
    <Box>
      <Grid gutter={4}>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>X-Axis Value</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLDataGrid
            // value={group?.[h?.S13F02]}
            // setEdit={(e) => {
            //   //console.log(e)
            //   setGroup({ ...group, [h?.S13F02]: e.target.value });
            // }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>Y-Axis Value-1</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLDataGrid
            // value={group?.[h?.S13F02]}
            // setEdit={(e) => {
            //   //console.log(e)
            //   setGroup({ ...group, [h?.S13F02]: e.target.value });
            // }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>Graph Type</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLComboBox
            // value={group?.[h?.S13F02]}
            // setEdit={(e) => {
            //   //console.log(e)
            //   setGroup({ ...group, [h?.S13F02]: e.target.value });
            // }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid.Col span={4}>
            <Text style={{ fontSize: 12 }}>Position</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLComboBox
            // value={group?.[h?.S13F02]}
            // setEdit={(e) => {
            //   //console.log(e)
            //   setGroup({ ...group, [h?.S13F02]: e.target.value });
            // }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Width%</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <PLNumberBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Height%</Text>
            </Grid.Col>
            <Grid.Col span={6}>
              <PLNumberBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button mr={4} size="xs" compact>OK</Button>
          <Button size="xs" compact>Remove</Button>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
