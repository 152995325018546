import { ActionIcon, Alert, Button, Checkbox, Drawer, Flex, Grid, Modal, Text, Tooltip } from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CashBankAddApi, CashBankApi, CashBankEditApi, getData, getFlagStatus, getSaveBtnLoader, setInvoiceValue, setSuccess, setToggleButton } from '../../utils/TransactionSlices/CashBankSlice';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLDateBox from '../../PlusComponent/PLDateBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import { IconEdit } from '@tabler/icons-react';
import GlobalClass from '../../utils/GlobalClass';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import gensetting from '../../utils/gensetting';
import PLBillToBill from '../../PlusComponent/PLBillToBill';
import { GetExpenseData } from '../../utils/TransactionSlices/TSalesSlices';

let data;
let flag = false;

export const CashBankTable = (props) => {

    // console.log("props", props);

    const { obj, funEnable, rowSelection, setRowSelection, T41List, setT41List, T41, setT41, setNarrationValue, setBalanceObj, rowId, setRowID,row,setRow} = props;

    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const WDAY = weekdays[new Date().getDay()];
    const [weekday, setWeekDay] = useState(WDAY)

    const dispatch = useDispatch()
    const columnHelper = createColumnHelper();
    const [validationErrors, setValidationErrors] = useState({});
    const [columns, setColumns] = useState();
    const [visible, setVisible] = useState(false);
    const [dataObj, setdataObj] = useState();
    const [T05, setT05] = useState([]);
    const [T05ENT, setT05ENT] = useState([]);
    const [isBillToBill, setIsBillToBill] = useState(false);


    const CashBankAddData = useSelector(store => store?.CashBank?.CashBankAddData);
    const CashBankEditData = useSelector(store => store?.CashBank?.CashBankEditData);
    const T41Data = useSelector(store => store?.CashBank?.Data);
    const success = useSelector(store => store?.CashBank?.success);
    const toggleButton = useSelector(store => store?.CashBank?.toggleButton);
    const CashBankData = useSelector(store => store?.CashBank?.CashBankData);


    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);

    useEffect(() => {
        // console.log("useEffect cashbank add api call");
        if (success) {
            dispatch(CashBankAddApi({ obj }))
        }
    }, [success])

    useEffect(() => {
        const date = T41?.FIELD02;
        const date1 = date?.slice(0, 4) +
            "-" +
            date?.slice(4, 6) +
            "-" +
            date?.slice(6, 8)
        const newDate = new Date(`"${date1}"`)
        const day = newDate.getDay();
        setWeekDay(weekdays[day])
        if (!T41?.MIDDLECRDB) {
            setT41({ ...T41, MIDDLECRDB: "C" })
        }
    }, [T41])

    useEffect(() => {
        console.log("CashBankAddData", CashBankAddData);

        if (CashBankAddData?.T41) {
            data = obj.id === "04500447" ? { ...CashBankAddData?.T41, FIELD05: "ACASHACT" } : { ...CashBankAddData?.T41, FIELD05: "AHP5S1OG" }
            setT41(data);
            dispatch(getData(data));
        }
        setT05(CashBankAddData?.T05)
        setT05ENT(CashBankAddData?.T05ENT)
    }, [CashBankAddData])

    useEffect(() => {
        console.log("CashBankEditData", CashBankEditData);

        const newData = CashBankEditData?.T41 ? CashBankEditData?.T41 : data;
        setT41(newData)
        setdataObj(newData)
    }, [CashBankEditData])

    // useEffect(() => {
    //     console.log("invoiceValue", invoiceValue);
    //     if (invoiceValue) {
    //         // dispatch(GetExpenseData({ action: "A", code: obj?.id === "04500447" ? "CRCR" : "JRJR", invCode: invoiceValue }))
    //     } // Expense Calculation
    // }, [invoiceValue])

    const refreshBtn = () => {
        dispatch(CashBankApi(obj));
        setRowSelection({});
        setRowID("");
        dispatch(setInvoiceValue({}));
        dispatch(setSuccess(true))
    }

    const seperate = (e) => {
        var paraArr = e.split("~C~");
        paraArr[1] = paraArr[1] + "";
        var paraArrSep = paraArr[1].split("~R~");
        console.log("paraArrSep", paraArrSep[0]);
        return paraArrSep;
    };

    const seperate1 = (e) => {
        var paraArr = e.split('~C~');
        if (paraArr.length == 1) {
            paraArr[1] = paraArr[0];
        }
        return paraArr
    }

    const NextIndexFun = (index) => {
        let nextInd;
        nextInd = index + 1;
        const nextInput = document.getElementById(`T41_${nextInd}`);
        // console.log("nextInput=>", nextInput)

        if (nextInput) {
            nextInput.focus();
        }
    };

    const BilltoBillBtn = () => {
        isBillToBill && setDrawerObj({
            ...dra, title: <Text fw={700}>Bill to Bill</Text>, body: <>
                <PLBillToBill
                    T41ObjSend={T41}
                    T05Format={T05ENT}
                    T05UserField={rowId ? CashBankEditData?.UFT05H : CashBankAddData?.UFT05H}
                    T05Data={T05}
                    setT05Data={(e) => setT05(e)}
                />
            </>, open: true, size: "50%", position: "right"
        })
    }

    const renderValue = (cellValue, row, column, item, index) => {
        var editor;
        props?.T41GRD?.map((item) => {
            if (item.S53F03 == column?.columnDef?.header) {
                switch (item?.S53F14) {
                    case "PLPOPUP":
                        if (item.S53F02 == "QUICK010") {
                            editor = (
                                <PLDataGrid
                                    id={`T41_${index}`}
                                    position="bottom"
                                    width="500px"
                                    data={CashBankAddData?.M45IDT}
                                    TmData={CashBankAddData?.M45I}
                                    value={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        console.log("e", e);
                                        Promise.resolve(setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: e.FIELD01,
                                            // FIELD13NM : e.FIELD02
                                        })))
                                            .then(() => {
                                                NextIndexFun(index);
                                            })

                                    }}
                                />
                            );
                        }
                        else {
                            editor = (
                                <PLDataGrid
                                    id={`T41_${index}`}
                                    position="bottom"
                                    width="500px"
                                    p1={seperate(item.S53F15)[0]}
                                    value={item.S53F02 == "QUICK006" ? { value: T41?.[item.S53F04], label: T41?.FIELD04NM } : T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        if (item.S53F02 == "QUICK006") {
                                            Promise.resolve(setT41((prevT41) => ({
                                                ...prevT41,
                                                [item.S53F04]: e.FIELD01,
                                                FIELD04NM: e.FIELD02
                                            }))).then(() => {
                                                NextIndexFun(index);
                                            })

                                            if (e.FIELD08 == "2") {
                                                setIsBillToBill(true)
                                            }
                                        }
                                        else {
                                            Promise.resolve(setT41((prevT41) => ({
                                                ...prevT41,
                                                [item.S53F04]: e.FIELD01,
                                                // FIELD13NM : e.FIELD02
                                            }))).then(() => {
                                                NextIndexFun(index);
                                            })
                                        }

                                    }}
                                />
                            );
                        }

                        break;
                    case "DATEPICKER":
                        editor = (
                            <PLDateBox
                                id={`T41_${index}`}
                                dispformat="DD/MM/YYYY"
                                defaultval={T41?.[item.S53F04]}
                                setEdit={(e) => {
                                    let dayName = e.getDay();
                                    setWeekDay(weekdays[dayName])
                                    let year = e.toLocaleString('en-IN', { year: 'numeric' })
                                    let month = e.toLocaleString('en-IN', { month: '2-digit' })
                                    let day = e.toLocaleString('en-IN', { day: '2-digit' })
                                    let startDate = year + month + day;
                                    Promise.resolve(setT41((prevT41) => ({
                                        ...prevT41,
                                        [item.S53F04]: startDate,
                                    }))).then(() => {
                                        NextIndexFun(index)
                                    })
                                }}
                            />
                        );
                        break;
                    case "NUMBOX":
                        editor = (
                            <PLNumberBox
                                id={`T41_${index}`}
                                decimalSeparator={item.S53F03 == "Amount" ? true : false}
                                precision={item.S53F03 == "Amount" ? 2 : ""}
                                value={T41?.[item.S53F04]}
                                setEdit={(e) => {
                                    setT41((prevT41) => ({
                                        ...prevT41,
                                        [item.S53F04]: e,
                                    }))
                                }}
                                onKeyDown={(e) => {
                                    if (e.key == "Tab") {
                                        if (item.S53F03 == "Amount") {
                                            NextIndexFun(index - 1);
                                        }
                                        else {
                                            NextIndexFun(index)
                                        }
                                    }
                                }}
                            />
                        );
                        break;
                    case "TEXTBOX":
                        if (item.S53F03 == "Day") {
                            editor = (<PLTextBox value={weekday} disabled />)
                        }
                        else {
                            editor = (
                                <PLTextBox
                                    id={`T41_${index}`}
                                    value={T41?.[item.S53F04]}
                                    setEdit={(e) => {
                                        setT41((prevT41) => ({
                                            ...prevT41,
                                            [item.S53F04]: e.target.value,
                                        }))
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key == "Tab") {
                                            // console.log("item", item);
                                            if (item.S53F02 == "QUICK009") {
                                                BilltoBillBtn()
                                            }
                                            else if (item.S53F02 == "QUICK008") {
                                                NextIndexFun(index - 1);
                                            }
                                            else {
                                                NextIndexFun(index);
                                            }
                                        }
                                        else if (e.key == "Enter") {
                                            if (item.S53F02 == "QUICK009") {
                                                BilltoBillBtn()
                                            }
                                        }
                                    }}
                                />
                            );

                        }

                        break;
                    case "COMBOBOX":
                        editor = (
                            <PLComboBox
                                cmbid={`T41_${index}`}
                                copno={seperate1(item.S53F15)[1]}
                                value={T41?.[item.S53F04]}
                                dispexpr='DisplayMember'
                                valexpr='ValueMember'
                                setEdit={(e) => {
                                    Promise.resolve(setT41((prevT41) => ({
                                        ...prevT41,
                                        [item.S53F04]: e.value,
                                    }))).then(() => {
                                        NextIndexFun(index);
                                    })
                                }}
                            />
                        );
                        break;
                    case "CHECKBOX":
                        editor = (
                            <Checkbox
                                checked={T41?.[item.S53F04] == "A" ? true : false}
                                onChange={(event) => {
                                    setT41((prevT41) => ({
                                        ...prevT41,
                                        [item.S53F04]: event.target.checked == true ? "A" : "N",
                                    }))
                                }}

                            />
                        );
                        break;
                    default:
                        break;
                }
            }
        });

        return editor;
    };

    useEffect(() => {
        var cols = []

        props?.T41GRD?.filter(item => item.S53F00 == "C").map((item, index) => {
            // props?.T41GRD?.map((item, index) => {
            cols.push(columnHelper.accessor(item?.S53F04, {
                header: item?.S53F03,
                uid: item?.S53F03,
                size: item?.S53F03 == "Audit" ? 40 : 120,
                Cell: ({ cols, cell }) => {
                    var val = cell.getValue();
                    if (item.S53F03.trim() == "Date") {
                        return val?.slice(6, 8) +
                            "-" +
                            val?.slice(4, 6) +
                            "-" +
                            val?.slice(0, 4)
                    }
                    // else if (item.S53F02 == "QUICK010") {
                    //     return cell?.row?.original?.FIELD13NM
                    // }// INV Type
                    // else if (item.S53F02 == "QUICK006") {
                    //     console.log("VAL",val);
                    //     // console.log("cell?.row?.original?.FIELD04NM",cell);
                    //     // return M01F02 
                    // }//Account Name
                    else {
                        return val
                    }
                },
                Edit: ({ cell, column, table, row }) =>
                    renderValue(cell.getValue(), row, column, item, index),
            }))
        })
        console.log("cols",cols);
        setColumns(cols)
    }, [props?.T41GRD, T41, weekday])

    //CREATE action
    const handleCreateUser = async ({ values, exitCreatingMode }) => {

        if (props?.obj?.id === "04500447") { // Cash/Bank
            if (T41.FIELD04) {

                GlobalClass.Notify("info", "Adding", "Please wait while we process your data");
                dispatch(getSaveBtnLoader(true))

                try {
                    let T41Obj = { T41: T41 };
                    let cCode = T41?.T41PVT == "BR" ? "BRBR" : T41?.T41PVT == "BP" ? "BPBP" : T41?.T41PVT == "CR" ? "CRCR" : T41?.T41PVT == "CP" ? "CPCP" : T41?.T41PVT == "NC" ? "NCNC" : T41?.T41PVT == "ND" ? "NDND" : T41?.T41PVT == "JR" ? "JRJR" : "";

                    var data2 = {
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cAction": "A",
                        "cCode": cCode,
                        "cOPara": obj.id === "04500447" ? "C" : "J",
                        "cSData": JSON.stringify(T41Obj)
                    };

                    let param =
                        JSON.stringify({
                            "CorpID": gensetting.getcorpid(),
                            "cUser": gensetting.getcuser(),
                            "cPass": gensetting.getcpass(),
                            "CmpNo": gensetting.getcmpno(),
                            "cAction": "A",
                            "cCode": cCode,
                            "cOPara": obj.id === "04500447" ? "C" : "J",
                        });

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(data2),
                    };

                    const response = await fetch(GlobalClass.ApiUrl + GlobalClass.QuickPostVouEnt + "?pa=" + param, requestOptions);
                    const responseData = await response.json();

                    // console.log("data=>>>>>>>>>>>", responseData);

                    if (responseData.status === 'SUCCESS') {
                        setT41List([...T41List, T41])
                        GlobalClass.Notify(
                            "success",
                            "Added",
                            "Added successfully"
                        );
                        dispatch(getFlagStatus(true))

                        Promise.resolve(dispatch(CashBankApi(obj)))
                            .then()
                            .catch((error) => {
                                GlobalClass.Notify("error", "Error", error?.message);
                            })
                    }
                    else if (responseData.status === 'FAIL') {
                        setT41List(T41List)
                        GlobalClass.Notify("error", responseData?.status, responseData?.message);
                        dispatch(getSaveBtnLoader(false))

                    }
                }
                catch (error) {
                    setT41List(T41List)
                    GlobalClass.Notify("error", "Error ", error.message);
                    dispatch(getSaveBtnLoader(false))

                }
                finally {
                    // if (!saveBtnLoader) {
                    funEnable(true)
                    exitCreatingMode();
                    setValidationErrors({});
                    setT41(T41Data)
                    setWeekDay(WDAY)
                    dispatch(setToggleButton(false))
                    flag = false
                    setRowSelection({})
                    setRowID("");
                    // }
                };
            }
            else {
                GlobalClass.Notify("warning", "", "Please Select Account Code")
            }
        }
        else if (props?.obj?.id === "04560447") {

            if (T41.FIELD04 && T41?.FIELD07) {

                GlobalClass.Notify("info", "Adding", "Please wait while we process your data");
                dispatch(getSaveBtnLoader(true))

                try {

                    // let T41Data;
                    // if (T41?.MIDDLECRDB) {
                    //     T41Data = T41
                    // }
                    // else {
                    //     T41Data = { ...T41, MIDDLECRDB: "C" }
                    // }
                    // let T41Obj = { T41: T41Data };

                    // console.log("T41Data", T41Data);

                    let T41Obj = { T41: T41 };

                    let cCode = T41?.T41PVT == "BR" ? "BRBR" : T41?.T41PVT == "BP" ? "BPBP" : T41?.T41PVT == "CR" ? "CRCR" : T41?.T41PVT == "CP" ? "CPCP" : T41?.T41PVT == "NC" ? "NCNC" : T41?.T41PVT == "ND" ? "NDND" : T41?.T41PVT == "JR" ? "JRJR" : "";

                    var data2 = {
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cAction": "A",
                        "cCode": cCode,
                        "cOPara": obj.id === "04500447" ? "C" : "J",
                        "cSData": JSON.stringify(T41Obj)
                    };

                    let param =
                        JSON.stringify({
                            "CorpID": gensetting.getcorpid(),
                            "cUser": gensetting.getcuser(),
                            "cPass": gensetting.getcpass(),
                            "CmpNo": gensetting.getcmpno(),
                            "cAction": "A",
                            "cCode": cCode,
                            "cOPara": obj.id === "04500447" ? "C" : "J",
                        });

                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(data2),
                    };

                    const response = await fetch(GlobalClass.ApiUrl + GlobalClass.QuickPostVouEnt + "?pa=" + param, requestOptions);
                    const responseData = await response.json();

                    // console.log("data=>>>>>>>>>>>", responseData);

                    if (responseData.status === 'SUCCESS') {
                        setT41List([...T41List, T41])
                        GlobalClass.Notify(
                            "success",
                            "Added",
                            "Added successfully"
                        );
                        dispatch(getFlagStatus(true))

                        Promise.resolve(dispatch(CashBankApi(obj)))
                            .then()
                            .catch((error) => {
                                GlobalClass.Notify("error", "Error", error?.message);
                            })
                    }
                    else if (responseData.status === 'FAIL') {
                        setT41List(T41List)
                        GlobalClass.Notify("error", responseData?.status, responseData?.message);
                        dispatch(getSaveBtnLoader(false))

                    }
                }
                catch (error) {
                    setT41List(T41List)
                    GlobalClass.Notify("error", "Error ", error.message);
                    dispatch(getSaveBtnLoader(false))

                }
                finally {
                    // if (!saveBtnLoader) {
                    funEnable(true)
                    exitCreatingMode();
                    setValidationErrors({});
                    setT41(T41Data)
                    setWeekDay(WDAY)
                    dispatch(setToggleButton(false))
                    flag = false
                    setRowSelection({})
                    setRowID("")
                    // }
                };
            }
            else {
                GlobalClass.Notify("warning", "", "Please Enter Account and Amount")
            }
        }
        setIsBillToBill(false)
    }

    //UPDATE action
    const handleSaveUser = async ({ values, table, row }) => {


        GlobalClass.Notify("info","Editing","Please wait while we process your data");
        if (JSON.stringify(T41) === JSON.stringify(dataObj)) {
            GlobalClass.Notify("success", "Edited", "Edited successfully");
            funEnable(true)
            table.setEditingRow(null);
            setValidationErrors({})
            dispatch(setToggleButton(false))
            flag = false;
            setT41(T41Data)
            setWeekDay(WDAY)
            setRowSelection({})
            setRowID("")
        }
        else {
            try {
                let T41Obj = { T41: T41 };
                const rowId = T41?.FIELD01;

                var data1 = {
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "E",
                    "cCode": rowId,
                    "cOPara": obj.id === "04500447" ? "C" : "J",
                    "cSData": JSON.stringify(T41Obj)
                };

                let param =
                    JSON.stringify({
                        "CorpID": gensetting.getcorpid(),
                        "cUser": gensetting.getcuser(),
                        "cPass": gensetting.getcpass(),
                        "CmpNo": gensetting.getcmpno(),
                        "cAction": "E",
                        "cCode": rowId,
                        "cOPara": obj.id === "04500447" ? "C" : "J",
                    });

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data1),
                };

                const response = await fetch(GlobalClass.ApiUrl + GlobalClass.QuickPostVouEnt + "?pa=" + param, requestOptions);
                const responseData = await response.json();

                // console.log("data=>>>>>>>>>>>", responseData);

                if (responseData.status === 'SUCCESS') {
                    GlobalClass.Notify(
                        "success",
                        "Edited",
                        "Edited successfully"
                    );
                    var tempT41List = [...T41List];
                    tempT41List[row.index] = T41;
                    setT41List(tempT41List)
                }
                else if (responseData.status === 'FAIL') {
                    GlobalClass.Notify("error", responseData?.status, responseData?.message);
                    setT41List(T41List)
                }
            }
            catch (e) {
                setT41List(T41List)
                GlobalClass.Notify("error", "Error", `${e.message}`);
            }
            finally {
                funEnable(true)
                table.setEditingRow(null);
                setValidationErrors({})
                dispatch(setToggleButton(false))
                flag = false
                setT41(T41Data)
                setWeekDay(WDAY)
                setRowSelection({})
                setRowID("")
            };
        }
        setIsBillToBill(false)
    };

    const table = useMantineReactTable({
        columns: columns || [],
        data: T41List || [],
        enableFilters: false,
        enableSorting: false,
        positionToolbarAlertBanner: "none",
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableColumnActions: false,
        enableColumnDragging: false,
        enableTopToolbar: true,
        enableHiding: false,
        enableRowNumbers: false,
        enableColumnOrdering: false,
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        // enablePagination:false, 
        initialState: {
            density: "0px",
            columnVisibility: {
                CVOUNAME: obj.id === "04500447" ? false : false,
                MIDDLECRDB: obj.id === "04500447" ? false : true,
                FIELD14: obj.id === "04500447" ? true : true,
                CDAYNAME: obj.id === "04500447" ? true : false,
                FIELD10: obj.id === "04500447" ? true : false,
            }
        },
        state: {
            rowSelection,
            // density: "xs"
        },
        enableEditing: true,
        editDisplayMode: 'row',
        createDisplayMode: 'row',
        // getCoreRowModel: getCoreRowModel(),

        mantineTableContainerProps: { sx: { height: '300px' } },
        enableSelectAll: false,
        enableMultiRowSelection: false,
        enableTableFooter: false,
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: (e) => {

                console.log("row", row, rowId);
                console.log("rowSelection", rowSelection);

                row?.original && dispatch(setInvoiceValue(row?.original))// Inv Type

                setNarrationValue(row?.original?.FIELD15) 

                setBalanceObj({ balance: row?.original?.M01F99, CRDB: row?.original?.M01F99 > 0 ? "CR" : "DB" })

                setRowID(row?.original?.FIELD01)

                // setRow(row?.original)

                if (flag === false) {
                    setRowSelection((prev) => ({
                        [row.id]: !prev[row.id],
                    }));
                }
            },
            selected: flag === false && rowSelection[row.id],
            sx: {
                cursor: 'pointer',
                color: 'black',
            },
            height: 30

        }),

        onCreatingRowCancel: () => { setValidationErrors({}); funEnable(true); setT41(T41Data); dispatch(setToggleButton(false)); flag = false; setWeekDay(WDAY); setRowSelection({}); setIsBillToBill(false); setRowID("") },
        onCreatingRowSave: handleCreateUser,
        onEditingRowCancel: () => { setValidationErrors({}); funEnable(true); setT41(T41Data); dispatch(setToggleButton(false)); flag = false; setWeekDay(WDAY); setRowSelection({}); setIsBillToBill(false); setRowID("") },
        onEditingRowSave: handleSaveUser,
        renderRowActions: ({ row, table }) => (
            !toggleButton &&
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon onClick={() => {
                        table.setEditingRow(row);
                        funEnable(false);
                        dispatch(setToggleButton(true));
                        // flag = true;

                        let rowId = row.original.FIELD01;
                        if (rowId) {
                            dispatch(CashBankEditApi({ obj, rowId }));
                        }
                        else {
                            setT41(row.original);
                        }
                    }}>
                        <IconEdit size={'20px'} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),

        renderTopToolbarCustomActions: ({ table }) => (
            <Grid>
                <Grid.Col span={10} >
                    <Button
                        disabled={toggleButton}
                        onClick={() => {
                            table.setCreatingRow(true);
                            setVisible(true);
                            funEnable(false);
                            dispatch(setToggleButton(true));
                            // flag = true;
                        }}
                        size='xs'
                    >
                        Add New
                    </Button>
                </Grid.Col>

                <Grid.Col span={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant='light'
                        size='xs'
                        onClick={refreshBtn}
                    >
                        Refresh
                    </Button>
                </Grid.Col>
            </Grid>


        ),
    });

    // console.log("cashbankadddata", CashBankAddData);
    // console.log("success", success);
    // console.log("saveBtnLoader", saveBtnLoader);

    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => { }}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: () => {
                        setDrawerObj(dra);
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            // scrollAreaComponent={ScrollArea.Autosize}
            >
                {DrawerObj.body}
            </Modal>
            <MantineReactTable table={table} />
        </>

    )

}



