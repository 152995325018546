import { Button, Grid, Text } from '@mantine/core'
import React from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'

const BackUpForm = (props) => {
    const { obj } = props
    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Grid.Col span={3}>
                        <Text size={12}>Company Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLTextBox 
                        disabled
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>

                <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Grid.Col span={3}>
                        <Text size={12}>Backup</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLComboBox 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <PLTextBox
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
            <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Button>OK</Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default BackUpForm