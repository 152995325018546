import { Button, FileButton, FileInput, Grid, Group, Text } from '@mantine/core'
import React, { useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PLComboBox from '../../PlusComponent/PLComboBox'

const OtherImportForm = (props) => {
    const { obj } = props
    const [file, setFile] = useState(null)

    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Profile Name</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>From</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLDateBox
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                        
                            }}
                        />
                    </Grid.Col>

                    <Grid.Col span={3}>
                        <Text size={12} style={{ display: 'flex', justifyContent: 'center' }}>To</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLDateBox
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                        
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Vouchers</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLComboBox 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Master File</Text>
                    </Grid.Col>
                    <Grid.Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid.Col span={11}>
                            <FileInput />
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Group>
                                <FileButton onChange={setFile}>
                                    {(props) => <Button variant='light' size='xs' {...props}>...</Button>}
                                </FileButton>
                            </Group>
                        </Grid.Col>
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Transaction</Text>
                    </Grid.Col>
                    <Grid.Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid.Col span={11}>
                            <FileInput />
                        </Grid.Col>
                        <Grid.Col span={1}>
                            <Group>
                                <FileButton onChange={setFile}>
                                    {(props) => <Button variant='light' size='xs' {...props}>...</Button>}
                                </FileButton>
                            </Group>
                        </Grid.Col>
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={4}>
                        <Text size={12}>Convert Sales Bill To Cash Memo ?</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <PLComboBox 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button>OK</Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default OtherImportForm