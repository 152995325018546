import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import React from "react";
import M01Forms from "../M01Form/M01Forms";
import { ProcessMaster } from "./ProcessMaster";
import { ProcessMasterData } from "../../utils/slices/ProcessMasterSlice";
import Store from "../../utils/store";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";

export const M71 = () => {
  return <div>ProcessMaster</div>;
};

export async function M71AED(data, Skey) {
  const OBJ = JSON.parse(data);
  console.log("M71AED==>", OBJ);
  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        MBody: (i) => <ProcessMaster obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 === "E" && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "xl",
        MBody: (i) => <ProcessMaster obj={OBJ} index={i} />,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
      })
    );
  } else if (OBJ.p0 === "D") {
    if (window.confirm("Confirm to Delete ?")) {
      
        Store.dispatch(ProcessMasterData({ action: OBJ.p0, code: OBJ.id }))
    }
  }
}
