import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
import { GetAccountList } from "./AccountListSlice";
import Store from "../store";
// import { notifications } from "@mantine/notifications";

export const GetUserMasterEntry = createAsyncThunk(
  "UserMasterEntry/GetUserMasterEntry",
  async (obj) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0 ? obj.p0 : "A",
        cCode: obj?.p0 == "A"  ? "" : obj?.id,
        cOPara: obj?.BtnPnlObj?.p1?.split("_")[1],
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetUserMasterEntry + "?pa=" + param
      );
      return response.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

export const GetUserMasterEntryDG = createAsyncThunk(
  "UserMasterEntry/GetUserMasterEntryDG",
  async (obj) => {
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0 ? obj.p0 : "A",
        cCode: obj?.p0 == "A"  ? "" : obj?.id,
        cOPara: obj?.BtnPnlObj?.p1?.split("_")[1],
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetUserMasterEntryDG + "?pa=" + param
      );
      return response.data?.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
export const DeleteUserMasterEntry = createAsyncThunk(
  "UserMasterEntry/DeleteUserMasterEntry",
  async (obj) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0 ? obj.p0 : "A",
        cCode: obj?.p0 == "A"  ? "" : obj?.id,
        cOPara: obj?.BtnPnlObj?.p1?.split("_")[1],
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetUserMasterEntry + "?pa=" + param
      );
      // console.log("response  ", response);
      if (response.data.status === "SUCCESS") {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;

        Store.dispatch(
          GetAccountList({
            id: obj?.BtnPnlObj?.id,
            name: obj?.BtnPnlObj?.name,
            p0: obj?.BtnPnlObj?.p0,
            p1: obj?.BtnPnlObj?.p1,
            p2: obj?.BtnPnlObj?.p2,
            p3: obj?.BtnPnlObj?.p3,
            type: obj?.BtnPnlObj?.type,
            pagination: PaginationObj[obj?.BtnPnlObj?.id],
          })
        );
        GlobalClass.Notify("success", "Deleted", "Deleted successfully");
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);

const UserMasterEntrySlice = createSlice({
  name: "UserMasterEntry",
  initialState: {
    UserMasterEntryData: [],
    UserMasterEntryDataDG : [],
    isLoading: false,
    hasError: false,
    isLoading1: false,
    hasError1: false,
    ErrorMsg: {},
    ErrorMsg1 : {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetUserMasterEntry.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.UserMasterEntryData = [];
      })
      .addCase(GetUserMasterEntry.fulfilled, (state, action) => {
        state.UserMasterEntryData = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetUserMasterEntry.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(GetUserMasterEntryDG.pending, (state, action) => {
        state.isLoading1 = true;
        state.hasError1 = false;
        state.UserMasterEntryDataDG = [];
      })
      .addCase(GetUserMasterEntryDG.fulfilled, (state, action) => {
        state.UserMasterEntryDataDG = action.payload;
        state.isLoading1 = false;
        state.hasError1 = false;
      })
      .addCase(GetUserMasterEntryDG.rejected, (state, action) => {
        state.hasError1 = true;
        state.isLoading1 = false;
      })
      .addCase(DeleteUserMasterEntry.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(DeleteUserMasterEntry.fulfilled, (state, action) => {
        state.successMsg = action.payload.status;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(DeleteUserMasterEntry.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});

export default UserMasterEntrySlice.reducer;
