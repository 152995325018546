import { Button, Grid, Group, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox';
import { useDispatch, useSelector } from 'react-redux';
import { GetAccountCategory } from '../../utils/slices/M01FormSlice';
import GlobalClass from '../../utils/GlobalClass';
import { notifications } from '@mantine/notifications';
import gensetting from '../../utils/gensetting';
import { modals } from '@mantine/modals';
import { GetAccountList } from '../../utils/slices/AccountListSlice';
import { ModalDelete } from '../../utils/slices/ModalSlice';
import Store from '../../utils/store';
import { DataSaveLoading } from '../../utils/slices/DataSaveLoadingSlice';



export const AccountCategoryForm = (props) => {
  // console.log('AccountCategoryForm props==>', props);

  const { obj } = props;

  const [accountCategoryObj, setAccountCategoryObj] = useState({
    "FIELD01": null,
    "FIELD02": null,
    "FIELD02_01": null,
    "FIELD02_02": null,
    "FIELD02_03": null,
    "FIELD03": null,
    "FIELD04": null,
    "FIELD05": null,
    "FIELD10": null,
    "FLDUNQ": null,
    "FLDAED": null,
    "FLDBRC": null
  })

  const AccountCategoryData = useSelector((state) => state?.M01FormSlice?.AccountCategoryData)
  const { isLoading } = useSelector((state) => state?.M01FormSlice)

  const SaveLoading = useSelector(state => state?.DataSaveLoadingSlice?.SaveLoading)

  const dispatch = useDispatch();

  useEffect(() => {
    if (obj.p0 == 'A') {
      dispatch(GetAccountCategory({ OBJ: obj.p0, Skey: "" }))

    } else {
      dispatch(GetAccountCategory({ OBJ: obj.p0, Skey: obj.id }))
    }
  }, [])

  useEffect(() => {
    if (AccountCategoryData) {
      setAccountCategoryObj(AccountCategoryData)
    }
  }, [AccountCategoryData])



  // console.log('AccountCategoryData==>', AccountCategoryData);

  const submitAccountCategory = () => {
    // console.log('accountCategoryObj submit==>', accountCategoryObj);
    dispatch(DataSaveLoading(true))
    GlobalClass.Notify(
      "info",
      obj.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    let Jdata = accountCategoryObj;

    var data = {
      "CorpID": gensetting.getcorpid(),
      "cUser": gensetting.getcuser(),
      "cPass": gensetting.getcpass(),
      "CmpNo": gensetting.getcmpno(),
      "cAction": "A",
      "cCode": "",
      "cOPara": "",
      "cSData": JSON.stringify(Jdata)
    };

    let param =
      JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": obj.p0,
        "cCode": "",
        "cOPara": "",
      });

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(GlobalClass.ApiUrl + GlobalClass.PostM31Ent + "?pa=" + param, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log('api data=>', data);
        // console.log('api data status=>', data?.status);

        if (data.status == 'SUCCESS') {
          let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
          Promise.resolve(
            GlobalClass.Notify(
              "success",
              obj.p0 == "A" ? "Added" : "Edited",
              obj.p0 == "A" ? "Added successfully" : "Edited successfully"
            )
            ).then(() => dispatch(ModalDelete(props.index))).then(() => dispatch(DataSaveLoading(false))).then(() => dispatch(GetAccountList({
            'id': "01360123",
            'name': "A/c. Category List",
            'p0': "MT",
            'p1': "T_M31R",
            'p2': "",
            'p3': "",
            'type': "A",
            'pagination': PaginationObj["01360123"]
          })))
        } else if (data.status == 'FAIL') {
          Promise.resolve(
            GlobalClass.Notify("error", data?.status, data?.message)
             ).then(() => dispatch(DataSaveLoading(false)))
        }
      })
      .catch((e)=>{
        GlobalClass.Notify("error", "Error", `${e.message}`);
            dispatch(DataSaveLoading(false));
      })
  }

  return (<>
    {
      SaveLoading == true || isLoading == true ? GlobalClass.RenderLoader('dots') :
        <>
          <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>Category:</Text>{/* category */}
              </Grid.Col>
              <Grid.Col span={10}>
                <PLTextBox
                nmaxlength={25}
                  value={accountCategoryObj?.FIELD02}
                  setEdit={(e) => {
                    setAccountCategoryObj({ ...accountCategoryObj, FIELD02: e.target.value })
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          </Grid>
          <Group spacing={'xs'} position='center' mt={5} mb={5}>
            <Button
              size='sm'
              onClick={() => submitAccountCategory()}
            // disabled={submitBtnDisable}
            >
              Ok
            </Button>
          </Group>
        </>
    }
  </>)
}
