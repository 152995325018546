import { Divider, Grid, Paper, Text, Textarea } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLDateBox from '../../PlusComponent/PLDateBox'
import { useDispatch, useSelector } from 'react-redux'
import { TransactionApi } from '../../utils/TransactionSlices/Transaction'
import GlobalClass from '../../utils/GlobalClass'

const SIDetailPage = (props) => {

    const { obj } = props;

    const [T41Obj, setT41Obj] = useState({
        "FIELD01": "",
        "FIELD02": "",
        "FIELD03": 0.0,
        "FIELD04": "",
        "FIELD05": "",
        "FIELD06": "0.00",
        "FIELD07": "0.00",
        "FIELD08": 0.0,
        "FIELD09": 0.00,
        "FIELD10": "",
        "FIELD11": "",
        "FIELD12": "",
        "FIELD13": "",
        "FIELD14": "",
        "FIELD15": "",
        "FIELD16": "D",
        "FIELD17": "",
        "FIELD18": 0.0000,
        "FIELD21": "",
        "FIELD22": "",
        "FIELD23": "",
        "FIELD30": "",
        "FIELD31": "",
        "FIELD32": "",
        "FIELD33": "        ",
        "FIELD70": "",
        "FIELD71": "",
        "FIELD75": "0",
        "FIELD99": "",
        "FIELD72": "",
        "FIELD20": 0.0,
        "FIELD80": "",
        "FIELD51": 0.00,
        "FIELD34": "",
        "FIELD35": "",
        "FIELD36": "",
        "FIELD37": "",
        "FIELD38": "",
        "FLDJIO": "",
        "FIELD39": "",
        "FIELD40": "",
        "FIELD41": "",
        "FIELD42": "",
        "FIELD43": "",
        "FIELD44": "",
        "FIELD45": "",
        "FIELD46": "",
        "FIELD47": "",
        "FIELD48": "",
        "FIELD49": "",
        "FIELD50": "",
        "FIELD52": "",
        "FIELD53": "",
        "T41PVT": "",
        "FIELD54": "",
        "T41VNO": "",
        "CMID": 0.0,
        "ADDID": 0.0,
        "PRESCPATH": "",
        "OSTATUS": "",
        "DTYPE": "",
        "DELURL": "",
        "DELMBNO": "",
        "FIELD55": "",
        "FIELD56": "",
        "T41POS": "",
        "UBILTO": "",
        "UGSTR": "",
        "USALM": "",
        "UDISP": "",
        "UPAYT": "",
        "UPR": "",
        "UNAME": "",
        "UOP": "",
        "UTRA": "",
        "UODP": "",
        "UREMA": "",
        "UEMAIL": "",
        "URA": "",
        "FLDUNQ": 0,
        "FLDAED": "",
        "FLDBRC": "",
        "PA000001": "",
        "PA000002": "",
        "PD000001": 0.0000,
        "PD000002": 0.0000,
        "UUASDF05": "",
        "UUBAER": "",
        "UUUBAER": "",
        "EA00000001": "",
        "EA00000002": "",
        "EA00000003": "",
        "EA00000004": "",
        "EA00000005": "",
        "EA00000006": "",
        "EA00000007": "",
        "EA00000008": "",
        "EA00000009": "",
        "EA00000010": "",
        "EA00000011": "",
        "EA00000012": "",
        "EA00000013": "",
        "EA00000014": "",
        "EA00000015": "",
        "ED00000001": 0.0000,
        "ED00000002": 0.0000,
        "ED00000003": 0.0000,
        "ED00000004": 0.0000,
        "ED00000005": 0.0000,
        "ED00000006": 0.0000,
        "ED00000007": 0.0000,
        "ED00000008": 0.0000,
        "ED00000009": 0.0000,
        "ED00000010": 0.0000,
        "ED00000011": 0.0000,
        "ED00000012": 0.0000,
        "ED00000013": 0.0000,
        "ED00000014": 0.0000,
        "ED00000015": 0.0000,
        "EP00000001": 0.0000,
        "EP00000002": 0.0000,
        "EP00000003": 0.0000,
        "EP00000004": 0.0000,
        "EP00000005": 0.0000,
        "EP00000006": 0.0000,
        "EP00000007": 0.0000,
        "EP00000008": 0.0000,
        "EP00000009": 0.0000,
        "EP00000010": 0.0000,
        "EP00000011": 0.0000,
        "EP00000012": 0.0000,
        "EP00000013": 0.0000,
        "EP00000014": 0.0000,
        "EP00000015": 0.0000,
        "UUASWER": "",
        "UUUASWER": "",
        "UCRE": "",
        "UCRE2": "0",
        "UDCNO": "",
        "UDE": "",
        "UDEL": "",
        "UDT": "",
        "ULEN": "",
        "ULRD": "",
        "UOD": "",
        "UODD": "",
        "USAL": 0.0,
        "UTR": "",
        "UUUASD09": "",
        "UUJA": "",
        "UUUJBOS": "",
        "UUUJOB": "",
        "UUSALM": "",
        "UUUOS": "",
        "EA00000016": "",
        "ED00000016": 0.0000,
        "EP00000016": 0.0000,
        "UREST6": "",
        "UUPRODC": "",
        "UUPRDID": "",
        "DSDATE": "2023-04-01T00:00:00+03:00",
        "DEDATE": "2024-03-31T00:00:00+02:00",
        "CACTION": "VD",
        "lInvType": true,
        "lMTaxEnt": true,
        "T92F02": "",
        "CFRMTITLE": "",
        "BTOBEFF": "",
        "M01BAL": "",
        "M01GRP": "",
        "M01OPPGRP": "",
        "M01CT": "",
        "F07ACC": "",
        "LBLF08AMT": "",
        "LBLF07AMT": "",
        "LBLF08SYMB": null,
        "LBLF07SYMB": null,
        "T41F04NM": "",
        "T02F06": 0.0,
        "T02F21": 0.0
    })

    const dispatch = useDispatch();

    const TransactionData = useSelector((state) => state.Transaction?.TransactionData)
    const { isLoading, hasError, ErrorMsg } = useSelector((state) => state.Transaction)


    useEffect(() => {
        if (obj) {
            dispatch(TransactionApi(obj))
        }
    }, [obj])

    useEffect(() => {
        if (TransactionData) {
            setT41Obj(TransactionData)
        }
    }, [TransactionData])

    console.log("TransactionData==>", TransactionData);

    return (<>
        {
            isLoading == true ? GlobalClass.RenderLoader('dots') :
                <Paper shadow="md" radius="md" p="xs" withBorder>
                    <Grid gutter={4}>
                        <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                            <Grid.Col span={2}>
                                <Text style={{ fontSize: 12 }}>Account</Text>
                            </Grid.Col>
                            <Grid.Col span={10}>
                                <PLTextBox
                                    value={T41Obj.FIELD04NM}
                                />
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={12} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                            <Grid.Col span={4} style={{ display: 'flex', padding: 0 }}>
                                <Grid.Col span={6}>
                                    <Text style={{ fontSize: 12 }}>Number</Text>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <PLTextBox
                                        value={T41Obj.FIELD12}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={4} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                <Grid.Col span={6}>
                                    <Text style={{ fontSize: 12 }}>Date</Text>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <PLDateBox
                                        defaultval={T41Obj.FIELD02}
                                        dispformat={'DD/MM/YYYY'}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={4} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                <Grid.Col span={6}>
                                    <Text style={{ fontSize: 12 }}>Amount</Text>
                                </Grid.Col>
                                <Grid.Col span={6}>
                                    <PLNumberBox
                                        value={T41Obj.FIELD06}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                            <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                <Grid.Col span={4}>
                                    <Text style={{ fontSize: 12 }}>Qty-1</Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLNumberBox
                                        value={T41Obj.FIELD33}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={6} style={{ display: 'flex', padding: 0, alignItems: 'center' }}>
                                <Grid.Col span={4}>
                                    <Text style={{ fontSize: 12 }}>Qty-2</Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLNumberBox
                                        value={T41Obj.FIELD21}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                            <Grid.Col span={6} >

                                <Grid.Col span={12}>
                                    <Divider my="xs" label="Document Information" labelPosition='center' labelProps={{ component: 'strong' }} />
                                </Grid.Col>

                                <Grid.Col span={12} style={{ padding: 0, borderStyle: "solid", borderWidth: "1px", borderColor: "white silver silver silver", height: "100px" }}>
                                    <Grid.Col span={12} style={{ display: "flex", padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>Number</Text>
                                        </Grid.Col>
                                        <Grid.Col span={8}>
                                            <PLNumberBox />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={12} style={{ display: "flex", padding: 0, alignItems: 'center' }}>
                                        <Grid.Col span={4}>
                                            <Text style={{ fontSize: 12 }}>Date</Text>
                                        </Grid.Col>
                                        <Grid.Col span={8}>
                                            <PLDateBox />
                                        </Grid.Col>
                                    </Grid.Col>

                                </Grid.Col>

                            </Grid.Col>
                            <Grid.Col span={6}>

                                <Grid.Col span={12}>
                                    <Divider my="xs" label="Narration" labelPosition='center' labelProps={{ component: 'strong' }} />
                                </Grid.Col>
                                <Grid.Col span={12} style={{ padding: 0, borderStyle: "solid", borderWidth: "1px", borderColor: "white silver silver silver", height: "100px" }}>
                                    <Grid.Col span={12}>
                                        <Grid.Col span={12}>
                                            <Text style={{ fontSize: 12 }}>Narration</Text>
                                        </Grid.Col>
                                        <Grid.Col span={12}>
                                            <Textarea
                                                value={T41Obj.FIELD15}
                                                minRows={2}
                                                onChange={(e) => {
                                                    console.log(e)

                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>
                            </Grid.Col>
                        </Grid.Col>

                    </Grid>
                </Paper>
        }

    </>)
}



export default SIDetailPage