import { Container, Grid, Text, Card, Group, Title, Space, Button, Badge, Divider, Box, HoverCard, Transition, Overlay, Image } from '@mantine/core';
import { IconInfoCircle, IconCalendar, IconHelpCircle, IconMail, IconPhoneCall, IconMessageCircle2 } from '@tabler/icons-react';
import { useState } from 'react';
import App from '../App.json';

function AboutUsInsideApp() {
  // State for animations
  const [visible, setVisible] = useState(true);

  // Sample data (can be dynamic)
  const companyInfo = {
    name: "Aakash Infoway Pvt. Ltd.",
    founded: "2004",
    location: "Rajkot, Gujarat",
    mission: "To provide the best business management software that empowers businesses globally."
  };

  const versionInfo = App.version;
  const registeredDate = "15-01-2024";
  const subscription = {
    type: "Pro Plan",
    renewalDate: "15-01-2025",
    status: "Active",
  };

  const contactDetails = {
    email: "info@eplus.biz",
    phone: "+91 6353117739",
  };

  return (
    <Container>
        <Box sx={{ position: 'relative', height: 200 }}>
        <Image
          src="https://images.unsplash.com/photo-1579548122080-c35fd6820ecb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="About Us Banner"
          radius="md"
          fit="cover"
          height={200}
          sx={{ filter: 'brightness(0.9)'}}
        />
        <Overlay color="black" opacity={0.3} zIndex={1} />
        <Group
          position="center"
          direction="column"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
          }}
        >
          <Title order={1} align="center" color="white">
            Welcome to ePlus
          </Title>
          <Text size="lg" align="center" color="gray.2">
            Simplifying business management with innovative tools
          </Text>
        </Group>
      </Box>

      <Space h="xl" />
      {/* Header Section */}
      <Transition mounted={visible} transition="fade" duration={600} timingFunction="ease">
        {(styles) => (
          <Card withBorder shadow="md" radius="md" p="xl" style={styles}>
            <Group position="apart">
              <Title order={2}>About Us</Title>
              {/* <Badge color="blue" size="lg">
                Version {versionInfo}
              </Badge> */}
            </Group>
            <Space h="md" />
            <Text size="md" color="dimmed">
            ePlus is a cloud-based accounting solution offering real-time access, secure storage, and powerful features to simplify financial management for businesses anywhere, anytime.
            </Text>
          </Card>
        )}
      </Transition>

      <Space h="xl" />

     

      {/* Version & Registered Info */}
      <Grid>
        <Grid.Col md={6}>
          <Card withBorder shadow="sm" radius="md">
            <Group position="apart">
              <Title order={4}>Version Information</Title>
              <IconInfoCircle size={24} color="blue" />
            </Group>
            <Space h="sm" />
            <Text>Current Version: <Badge color="blue" variant='outline'>{versionInfo}</Badge></Text>
            <Text>Last Update: <Badge color="blue" variant='outline'>{App.lastUpdate}</Badge></Text>
          </Card>
        </Grid.Col>

        <Grid.Col md={6}>
          <Card withBorder shadow="sm" radius="md">
            <Group position="apart">
              <Title order={4}>Registered Date</Title>
              <IconCalendar size={24} color="green" />
            </Group>
            <Space h="sm" />
            <Text>Account Registered: <Badge color="blue" variant='outline'>{registeredDate}</Badge></Text>
            <Text>Subscription Start: <Badge color="blue" variant='outline'>{registeredDate}</Badge></Text>
          </Card>
        </Grid.Col>
      </Grid>

      <Space h="xl" />

      {/* Subscription Info */}
      <Card withBorder shadow="md" radius="md">
        <Group position="apart">
          <Title order={4}>Subscription Info</Title>
        </Group>
        <Space h="sm" />
        <Text>Plan: <Badge color="blue" variant='outline'>{subscription.type}</Badge></Text>
        <Text>Renewal Date: <Badge color="blue" variant='outline'>{subscription.renewalDate}</Badge></Text>
        <Text>Status: <Badge color="green" variant='outline'>{subscription.status}</Badge></Text>
      </Card>

      <Space h="xl" />
 {/* Company Information */}
 <Card withBorder shadow="sm" radius="md">
        <Group position="apart">
          <Title order={4}>Company Information</Title>
        </Group>
        <Space h="sm" />
        <Text>Company Name: <strong>{companyInfo.name}</strong></Text>
        <Text>Founded: <strong>{companyInfo.founded}</strong></Text>
        <Text>Location: <strong>{companyInfo.location}</strong></Text>
        <Text>Mission: <strong>{companyInfo.mission}</strong></Text>
      </Card>

      <Space h="xl" />
      {/* Contact & Help Info */}
      <Card withBorder shadow="sm" radius="md" p="xl">
        <Group position="center" direction="column">
          <IconHelpCircle size={48} color="red" />
          <Title order={3}>Need Help?</Title>
          <Space h="md" />
          <Text size="md" align="center" color="dimmed">
            Our support team is here to assist you. For any issues or inquiries, please get in touch with us.
          </Text>
          <Space h="md" />

          {/* HoverCard for email */}
          <HoverCard width={280} shadow="md">
            <HoverCard.Target>
              <Button variant="outline" leftIcon={<IconMail />} size="md">
                Contact via Email
              </Button>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">
                Email us at: <strong>{contactDetails.email}</strong>
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>

          <Space h="md" />

          {/* HoverCard for phone */}
          <HoverCard width={280} shadow="md">
            <HoverCard.Target>
              <Button variant="outline" leftIcon={<IconPhoneCall />} size="md">
                Contact via Phone
              </Button>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Text size="sm">
                Call us at: <strong>{contactDetails.phone}</strong>
              </Text>
            </HoverCard.Dropdown>
          </HoverCard>

          <Space h="md" />

          {/* Live Chat Button */}
          <Button
            variant="filled"
            color="teal"
            leftIcon={<IconMessageCircle2 />}
            size="md"
            onClick={() => alert('Starting live chat...')}
          >
            Live Chat Support
          </Button>
        </Group>
      </Card>
    </Container>
  );
}

export default AboutUsInsideApp;
