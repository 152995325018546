import { Box, Button, Center, Divider, Grid, Text } from "@mantine/core";
import PLComboBox from "../../../../PlusComponent/PLComboBox";

export default function PrintingOption() {
  return (
    <>
      <Box>
        <Grid gutter={3}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Company Name On</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Report Header On</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Footer On</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Print Report Title</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Print Date Range</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Print Page On</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Page Total</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
          >
            <Grid.Col span={4}>
              <Text></Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text>Windows</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <Text>Dos</Text>
            </Grid.Col>
          </Grid.Col>
        </Grid>
        <Divider size="xs" />
        <Grid gutter={3} my={3}>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Page Total</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Left Margin</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col span={4}>
              <Text style={{ fontSize: 12 }}>Top Margin</Text>
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <PLComboBox
              // value={group?.[h?.S13F02]}
              // setEdit={(e) => {
              //   //console.log(e)
              //   setGroup({ ...group, [h?.S13F02]: e.target.value });
              // }}
              />
            </Grid.Col>
          </Grid.Col>
            <Grid.Col span={12}>
          <Center><Button size="xs" compact>OK</Button></Center> </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
