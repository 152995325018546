import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Grid, Group, Skeleton } from "@mantine/core";
import {
  GetSaveUserMasterTableData,
  GetUserMasterData,
  GetUserMasterFlag,
  GetUserMasterTableData,
  SaveUserMasterOBJ,
} from "../../utils/slices/UserMasterSlice";
import ButtonPanel from "../../PlusComponent/ButtonPanel";
import GlobalClass from "../../utils/GlobalClass";
import { GetTMFormat } from "../../utils/slices/AccountListSlice";

const UserMasterTable = (props) => {
  const { obj } = props;

  // console.log('UserMaster==>', obj);

  const [columns, setColumns] = useState([]);

  const [rowSelection, setRowSelection] = useState({});

  const [newTableData, setNewTableData] = useState([]);

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state?.UserMasterSlice);

  const UserMasterData = useSelector(
    (state) => state?.UserMasterSlice?.UserMasterData
  );

  const P_M41UM = useSelector(
    (state) => state?.UserMasterSlice?.UserMasterData?.P_M41UM
  );
  const P_M41UMData = useSelector(
    (state) => state?.UserMasterSlice?.UserMasterData?.P_M41UMData?.jData
  );

  const P_M41UML = useSelector(
    (state) => state?.UserMasterSlice?.UserMasterData?.P_M41UML
  );
  const P_M41UMLData = useSelector(
    (state) => state?.UserMasterSlice?.UserMasterData?.P_M41UMLData?.jData
  );

  // const UserMasterOBJ = useSelector((state) => state?.UserMasterSlice?.UserMasterOBJ)
  const UserMasterFlag = useSelector(
    (state) => state?.UserMasterSlice?.UserMasterFlag
  );
  const SaveLoading = useSelector(
    (state) => state?.UserMasterSlice?.SaveLoading
  );
  const UserMasterTableData = useSelector(
    (state) => state?.UserMasterSlice?.UserMasterTableData
  );

  const {TMFormatData: tmList ,isLoading : tmListIsLoading } = useSelector((state) => state.AccountList);

  // const UserMasterTableData = useSelector((state) => state?.UserMasterSlice?.UserMasterTableData)

  useEffect(() => {
    dispatch(GetUserMasterData());
  }, []);

  const columnHelper = createColumnHelper();

  useEffect(() => {
    if (P_M41UM) {
      console.log("tmList", tmList);
      dispatch(GetTMFormat({
        "id": P_M41UM.toString().split("~C~")[0],
        "name": "",
        "type": "A",
        "p0": P_M41UM.toString().split("~C~")[0],
        "p1": P_M41UM.toString().split("~C~")[0],
        "p2": P_M41UM.toString().split("~C~")[1],
        "p3": ""
      }))
    }
  }, [P_M41UM])

  useEffect(() => {
    if (tmList) {
      let col = [];
      tmList[P_M41UM?.toString().split("~C~")[0]]?.oDCFrmt?.aDCol.map((d) => {
        col.push(
          columnHelper.accessor(d.DispExpr, {
            header: d.ColCap,
            enableHiding: true,
            enableColumnFilter: d.lSearchCol,
            enableSorting: d.lColOrder,
            size: d.ColWidth,
            minSize: 0,
            maxSize: d.ColWidth,
          })
        );
      });
      tmList[P_M41UM?.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?.map((d) => {
        d?.aChildCol?.map((c) => {
          col.push(
            columnHelper.accessor(c.DispExpr, {
              header: c.ColCap,
              enableHiding: true,
              enableColumnFilter: c.lSearchCol,
              enableSorting: c.lColOrder,
              size: c.ColWidth,
              minSize: 0,
              maxSize: c.ColWidth,
            })
          );
        });
      });
      setColumns(col);
    }
  }, [tmList]);

  useEffect(() => {
    if (P_M41UMData && UserMasterFlag?.Flag1 == true) {
      // let NewData = [...P_M41UMData,]
      // setNewTableData(P_M41UMData)
      console.log("UserMaster Flag True.");
      Promise.resolve(
        dispatch(
          GetUserMasterTableData({ M41UM: P_M41UMData, M41UF: P_M41UMLData })
        )
      ).then(() => dispatch(GetUserMasterFlag({ Flag1: false })));
    }
  }, [P_M41UMData, P_M41UMLData]);

  const simulateEnterKeyPress = () => {
    // Get the row element using the rowIndex
    const rowElement = document.querySelector(`table`);
    if (rowElement) {
      // Create a new KeyboardEvent with the "Enter" key
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        bubbles: true,
        cancelable: true,
      });

      // Dispatch the event on the row element
      rowElement.dispatchEvent(event);
    }
  };

  const table = useMantineReactTable({
    data: UserMasterTableData?.M41UM,
    columns,
    mantineTableContainerProps: { sx: { maxHeight: "350px" } },
    enableTopToolbar: false,
    enableColumnActions: false,
    // enableSorting:false,
    initialState: {
      density: "xs",
    },
    state: {
      rowSelection,
      // density: "0px"
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      pl: 5,
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
        dispatch(SaveUserMasterOBJ(row.original));
      },

      onDoubleClick: () => {
        Promise.resolve(
          setRowSelection((prev) => ({
            [row.id]: true,
          }))
        ).then(() => {
          simulateEnterKeyPress();
        });
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
  });

  console.log("P_M41UMData==>", P_M41UMData);
  console.log("tmList =>",tmList);
  return (
    <>
      {SaveLoading == true ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          {isLoading == true ? (
            GlobalClass.RenderLoader("dots")
          ) : (
            <Grid gutter={4}>
              <Grid.Col span={12}>
                {P_M41UM && P_M41UMData?.length > 0 && (
                  <MantineReactTable table={table} />
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <Group spacing={"xs"} position="center" display={"flex"}>
                  <ButtonPanel
                    data={tmList[P_M41UM?.toString().split("~C~")[0]]?.oS60?.DTHKey}
                    Skey={table.getSelectedRowModel().flatRows[0]}
                    from={obj?.id}
                    FormCode={"UMF"}
                    ModalIndex={props.index}
                  />
                </Group>
              </Grid.Col>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default UserMasterTable;
