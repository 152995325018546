import React from 'react'
import JobProcessListForm from './JobProcessListForm'
import { Text } from '@mantine/core'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import Store from '../../utils/store'
import { GetJobProcessList } from '../../utils/slices/JobProcessListSlice'

const JobProcessList = () => {
  return (
    <div>JobProcessList</div>
  )
}

export default JobProcessList

export const MSAED=(data)=>{
    const OBJ = JSON.parse(data)
    console.log('JobProcessList obj MSAED', OBJ)
    if (OBJ.p0 === "A") {
        Store.dispatch(
          ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "md",
            MBody: (i) => <JobProcessListForm obj={OBJ} index={i} />,
            MClose: true,
            onclose: () => { },
          })
        );
      }else if (OBJ.p0 === "E" && OBJ.id) {
        Store.dispatch(
          ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "md",
            MBody: (i) => <JobProcessListForm obj={OBJ} index={i} />,
            MClose: true,
            onclose: () => { },
          })
        );
      }else if (OBJ.p0 === 'D' && OBJ.id) {
        console.log("Delete Object==>", OBJ);
        // dispatch(TransactionApi(obj))
        if (window.confirm('Confirm to Delete ?')) {
           Store.dispatch(GetJobProcessList(OBJ))
        }
    }
}