import React, { useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { Box, Button, Drawer, Grid, Group, Text } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";

export default function FieldValueList() {
  let columns = [
    {
      accessor: "Party Name", // Use 'accessor' instead of 'accessorKey'
      header: "Party Name",
      Cell: ({ row }) => {
        // console.log(row.original.PrName, "getVal");
        return row?.original?.PrName;
      },
    },
    // {
    //   accessor: "Bill", // Convert index to string
    //   header: "Generate Bill",
    //   Cell: ({ row }) => {
    //     console.log(row.original, "row.orginal");
    //     return (
    //       <Button onClick={() => generateBill(row?.original)}>
    //         Generate Bill
    //       </Button>
    //     );
    //   },

    //   maxSize: 100,
    // },
  ];
  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: [],
    columns: columns,
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    initialState: {
      density: "xs",
      expanded: true,
    },
    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {},
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      height: 50,
    }),
  });
  return (
    <>
      <Grid>
        <Grid.Col span={12}>
          <MantineReactTable table={table} />
        </Grid.Col>
      </Grid>
    </>
  );
}
