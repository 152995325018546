import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../utils/store";
import { GetStockRate } from "../../utils/slices/StockRateSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { useMantineReactTable } from "mantine-react-table";
import { MantineReactTable } from "mantine-react-table";
import { Skeleton } from "@mantine/core";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import gensetting from "../../utils/gensetting";
import { notifications } from "@mantine/notifications";
import GlobalClass from "../../utils/GlobalClass";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import useWindowDimensions from "../../utils/UseWindowDimensions";

const StockRatePage = (props) => {
  // console.log("StockRatePage=>", props);
  const dispatch = useDispatch();
  let column = [];
  const stockRateData = useSelector(
    (state) => state.StockRate?.StockRateData?.data?.STKLIST
  );
  // console.log("stockRateData", stockRateData);
  const isLoading = useSelector((state) => state.StockRate?.isLoading);
  const hasError = useSelector((state) => state.StockRate?.hasError);
  const ErrorMsg = useSelector((state) => state.StockRate?.ErrorMsg);
  // console.log("isLoading", isLoading);
  const [data, setData] = useState();
  const [updatedData, setUpdatedData] = useState("");
  const columnHelper = createColumnHelper();
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const { height } = useWindowDimensions();
  useEffect(() => {
    dispatch(GetStockRate());
  }, []);
  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  const rowSaveHandler = async ({ table, row, values }) => {
    setData(updatedData);
    let newData = updatedData[row.index];
    // console.log("newData", newData);
    // console.log("updateddata", updatedData);
    GlobalClass.Notify(
      "info",
      "Editing",
      "Please wait while we process your data"
    );
    var data1 = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "",
      cCode: "",
      cSData: JSON.stringify(newData),
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "",
      cCode: "",
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data1),
    };

    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostStockRate + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("api data", data);
        // console.log("api data status", data?.status);
        if (data.status === "SUCCESS") {
          GlobalClass.Notify("success", "Edited", "Edited successfully");
          Store.dispatch(GetStockRate());
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
        }
      })
      .catch((e) => {
        GlobalClass.Notify(
          "error",
          "Error",
          `${e?.message}`
        );
      });

    table.setEditingRow(null);
  };

  const renderValue = (cellValue, row, column) => {
    var editor;
    // console.log("Row", row);
    editor = (
      <PLNumberBox
        value={row?.original?.FIELD78}
        setEdit={(e) => {
          // console.log("e", e);
          // console.log("stockRateData", stockRateData);
          var temp = { ...stockRateData };
          // console.log("temp", temp);
          temp[row.index] = { ...temp[row.index], FIELD78: e };
          // console.log("updateddata", temp);
          setUpdatedData(temp);
          // console.log("updateddata", updatedData);
        }}
      />
    );

    return editor;
  };

  column.push(
    columnHelper.accessor("FIELD02", {
      header: "Product Name",
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
      size: "130",
    })
  );
  column.push(
    columnHelper.accessor("FIELD98", {
      header: "Stock Qty",
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
      size: "130",
    })
  );
  column.push(
    columnHelper.accessor("FIELD78", {
      header: "Stock Rate",
      enableColumnFilter: false,
      enableSorting: false,
      size: "130",
      Edit: ({ cell, column, table, row }) =>
        renderValue(cell.getValue(), row, column),
      Cell: ({ cell, renderedCellValue, row }) => cell.getValue(),
    })
  );
  column.push(
    columnHelper.accessor("M21STKAMT", {
      header: "Amount",
      enableColumnFilter: false,
      enableSorting: false,
      enableEditing: false,
      size: "130",
    })
  );
  var final;
  const [updatedColumn, setUpdatedColumn] = useState(column);
  useEffect(() => {
    // console.log(column, "column");
    setData(stockRateData);
    setUpdatedData(stockRateData);
    setUpdatedColumn(column);
  }, [stockRateData]);

  const table = useMantineReactTable({
    columns: updatedColumn ? updatedColumn : [],
    data: data ? data : [],
    enableFilters: false,
    enableColumnActions: false,
    // enableFullScreenToggle: false,
    // enableDensityToggle: false,
    enableTopToolbar: false,
    editDisplayMode: "row",
    onEditingRowSave: rowSaveHandler,
    enableStickyHeader: true,
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },

    manualPagination: false,
    enablePagination: true,
    initialState: {
      density: "0px",
    },
    enableEditing: true,
  });
  return (
    <>
      {isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <MantineReactTable table={table} striped={true} />
      )}
    </>
  );
};

export default StockRatePage;
