import { Button, Center, Grid, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLNumberBox from '../../PlusComponent/PLNumberBox'

const JBUserField = (props) => {
    console.log('userField props===>>>', props)

    const { T02ObjSend, setT02Objsend, dtUFT11, setDrawerObjSend, DrawerObjSend } = props
    // console.log('T02ObjSend=>>',T02ObjSend)
    const [userFieldObj, setUserFieldObj] = useState(null)
    // console.log('userFieldObj=>',userFieldObj)

    useEffect(() => {
        if (T02ObjSend) {
            setUserFieldObj(T02ObjSend)
        }
    }, [T02ObjSend])

    const seperate = (e) => {
        var paraArr = e?.split("~C~");
        console.log("paraArr=>", paraArr);
        return paraArr;
    };

    const UserFieldRender = () => {
        let InputField;
        return (
            dtUFT11?.map((t) => {
                console.log("t?.S13F14", t?.S13F14);
                console.log([t?.S13F02], "UserFieldObj?.[t?.S13F02]")

                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    value={userFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e })
                                    }}

                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    value={userFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                    value={userFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        console.log(e?.FIELD01, "e?.FIELD01")
                                        setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                    p1={seperate(t?.S13F09)[1]}

                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                    value={userFieldObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }

    const SaveUserField = () => {
        console.log("userFieldObj", userFieldObj);
        Promise.resolve(setT02Objsend(userFieldObj)).then(() => setDrawerObjSend({ ...DrawerObjSend, open: false }))
    }

    return (
        <>
            <Grid gutter={4}>
                {
                    UserFieldRender()
                }
                <Center mx="auto">
                    <Button
                        onKeyUp={(e) => {
                            if (e.key == "Enter") {
                                SaveUserField()
                            }
                        }}
                        onClick={() => SaveUserField()}
                    >
                        OK
                    </Button>
                </Center>
            </Grid>
        </>
    )
}

export default JBUserField