import { Button, FileButton, FileInput, Grid, Group, Text } from '@mantine/core'
import React, { useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDateBox from '../../PlusComponent/PLDateBox'
import PLRadioButton from '../../PlusComponent/PLRadioButton'
import PLTextBox from '../../PlusComponent/PLTextBox'

const DataExportForm = (props) => {

    const [RadioVal, setRadioVal] = useState('')
    const [file, setFile] = useState(null)

    const RadioData = [
        {
            value: "internal",
            label: "Internal"
        },
        {
            value: "other",
            label: "Other"
        },
        {
            value: "email",
            label: "eMail"
        },
    ]

    const { obj } = props
    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ padding: 0, display: "flex" }}>

                    <Grid.Col span={6}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={3}>
                                <Text size={12}>Export Type</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <PLComboBox
                                setEdit={(e) => {
                        
                                }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={3}>
                                <Text size={12}>From Date</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <PLDateBox
                                    dispformat="DD/MM/YYYY"
                                    setEdit={(e) => {
                        
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                    </Grid.Col>

                    <Grid.Col span={6}>
                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={3}>
                                <Text size={12}>Export For</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <PLComboBox
                                setEdit={(e) => {
                        
                                }}
                                />
                            </Grid.Col>
                        </Grid.Col>

                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Grid.Col span={3}>
                                <Text size={12}>To Date</Text>
                            </Grid.Col>
                            <Grid.Col span={9}>
                                <PLDateBox
                                    dispformat="DD/MM/YYYY"
                                    setEdit={(e) => {
                        
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12}>
                    <Text size={12}>Export To</Text>
                </Grid.Col>

                <Grid.Col span={12} style={{ display: 'flex' }}>
                    <Grid.Col span={3}>
                        <PLRadioButton
                            value={RadioVal}
                            data={RadioData}
                            onChangeVal={(e) => {
                                console.log("Radio Val=>", e);
                                setRadioVal(e)
                            }}
                        />
                    </Grid.Col>

                    <Grid.Col span={9}>
                        <Grid.Col span={12}>
                            <PLTextBox 
                            setEdit={(e) => {
                        
                            }}
                            />
                        </Grid.Col>
                        <Grid.Col span={12} style={{ display: 'flex' }}>
                            <Grid.Col span={11}>
                                <FileInput />
                            </Grid.Col>
                            <Grid.Col span={1}>
                                <Group>
                                    <FileButton onChange={setFile}>
                                        {(props) => <Button variant='light' size='xs' {...props}>...</Button>}
                                    </FileButton>
                                </Group>
                            </Grid.Col>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <PLTextBox 
                            setEdit={(e) => {
                        
                            }}
                            />
                        </Grid.Col>
                    </Grid.Col>

                </Grid.Col>

            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Group>
                        <Button>OK</Button>
                        <Button>Cancel</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default DataExportForm