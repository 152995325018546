import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";

export const GetOpeningStockGen = createAsyncThunk(
  "OpeningStockGen/GetOpeningStockGen",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cRepId: "TXNVOULISTHO",
        cFmtID: "TFORM0000001",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GenData + "?pa=" + param
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

const OpeningStockSliceGen = createSlice({
  name: "OpeningStockGen",
  initialState: {
    OpeningStockDataGen: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetOpeningStockGen.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.OpeningStockDataGen = [];
      })
      .addCase(GetOpeningStockGen.fulfilled, (state, action) => {
        // console.log("GetStockRate=>", action.payload?.error);
        if (!action.payload?.error) {
          state.OpeningStockDataGen = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.OpeningStockDataGen = action.payload;
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetOpeningStockGen.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
        state.ErrorMsg = action.payload?.error;
      });
  },
});
export default OpeningStockSliceGen.reducer;
