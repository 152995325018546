import { Button, Grid, Group } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import React, { useEffect, useState } from 'react'
import { GetTMFormat } from '../../utils/slices/AccountListSlice';
import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import GlobalClass from '../../utils/GlobalClass';
import useWindowDimensions from '../../utils/UseWindowDimensions';

const SIPendingChallanTable = (props) => {
    console.log("SIPendingChallanTable props=>", props);
    const { ChallanData, ChallanFormat, DrawerOBJ, setDrawerOBJ, selectedPendingChallan, selectedChallanArray } = props;

    const [columns, setColumns] = useState([])
    const [TblData, setTblData] = useState([])
    const [rowSelection, setRowSelection] = useState({})

    const { height, width } = useWindowDimensions();

    const { TMFormatData: tmList, isLoading } = useSelector((state) => state.AccountList)

    const dispatch = useDispatch();
    const columnHelper = createColumnHelper();

    useEffect(() => {
        if (ChallanFormat) {
            dispatch(GetTMFormat({
                "id": ChallanFormat.toString().split("~C~")[0],
                "name": "",
                "type": "A",
                "p0": ChallanFormat.toString().split("~C~")[0],
                "p1": ChallanFormat.toString().split("~C~")[0],
                "p2": ChallanFormat.toString().split("~C~")[1],
                "p3": ""
            }))
        }
    }, [ChallanFormat])

    useEffect(() => {
        if (ChallanData) {
            setTblData(ChallanData)
        }
    }, [ChallanData])

    useEffect(() => {
        if (tmList) {
            console.log("tmList[ChallanFormat?.toString().split()[0]]==>", tmList[ChallanFormat?.toString().split("~C~")[0]]);
            let col = [];
            tmList[ChallanFormat?.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?.map((d, i) => {
                col.push(
                    columnHelper.accessor(d.DispExpr, {
                        header: d.ColCap,
                        // enableHiding: true,
                        size: d.ColWidth,
                        minSize: 0,
                        maxSize: d.ColWidth,
                        Cell: ({ cell, renderedCellValue }) => {
                            //  console.log('cell====>',cell),
                            return rendercolcell(i == 0 ? renderedCellValue : cell.getValue(), d)
                        },
                    })
                )
            })
            setColumns(col)
        }
    }, [tmList])

    useEffect(() => {
        if (selectedChallanArray) {
            let newObj = {}
            console.log("selectedChallanArray=>", selectedChallanArray);
            ChallanData?.map((n1, i) => {
                selectedChallanArray?.map((n2, j) => {
                    if (n1 == n2) {
                        newObj = { ...newObj, [i]: true }
                        // setRowSelection({ ...rowSelection, [i]: true })
                    }
                })
            })
            return setRowSelection(newObj)
        }
    }, [selectedChallanArray, ChallanData])

    const rendercolcell = (data, v) => {
        // console.log("EPLUS",v);
        // console.log('data===>>',data)
        let dtxt = data;
        var datatype =
            v.DataType === "N"
                ? "number"
                : v.ColClick !== "" || v.DispExpr === "M01F68"
                    ? "custom"
                    : "string";
        if (datatype === "custom") {
            if (dtxt === "A") {
                dtxt = "✓";
            } else {
                dtxt = "";
            }
        }

        if (v.DispFormat.includes("A")) {
            dtxt = Math.abs(dtxt);
        }
        if (v?.ColCap == "Date") {
            // dtxt = dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8) ;
            //  dtxt = "2023/12/12"
            if (data != null && data != "" && data != undefined) {
                dtxt = dtxt.toString()  //Vara Pooja
                dtxt =
                    dtxt?.slice(0, 4) + "/" + dtxt?.slice(4, 6) + "/" + dtxt?.slice(6, 8);
            } else {
                dtxt = "";
            }
            // return dtxt
        }
        if (v.DispFormat.includes("Z")) {
            if (dtxt === ".00") {
                dtxt = "";
            }
        }

        if (v.DispFormat.includes("X")) {
            if (dtxt !== "") {
                if (parseFloat(dtxt) > 0) {
                    dtxt += " CR";
                } else {
                    dtxt += " DB";
                }
            }
        }

        return (
            <p
                style={{
                    fontSize: v.FontSize ? v.FontSize : 12,
                    fontFamily: v.FontName ? v.FontName : "Verdana",
                    color: v.ForeColor,
                    backgroundColor: v.BackColor,
                }}
            >
                {dtxt}
            </p>
        );
    };

    console.log("columns=>", columns)

    const table = useMantineReactTable({
        data: TblData,
        columns,
        positionToolbarAlertBanner: "bottom",
        onRowSelectionChange: setRowSelection,
        state: {
            rowSelection
        },
        initialState: {
            density: "xs"
        },
        enableRowSelection: true,
        enableDensityToggle: false,
        enableSorting: false,
        enableFilters: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableTopToolbar: false,
        mantineTableContainerProps: {
            sx: { height:height * 0.7 },
          },
    })

    const SubmitPendingChallan = () => {
        console.log("submit");

        let newArray = []

        ChallanData?.map((n, i) => {
            if (i in rowSelection) {
                newArray.push(n)
            }
        })

        Promise.resolve(selectedPendingChallan({ challanData: ChallanData, selectedChallan: newArray })).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
    }

    return (<>
        {
            isLoading ? GlobalClass.RenderLoader('dots') :
                <Grid gutter={4}>
                    <Grid.Col span={12}>
                        <MantineReactTable table={table} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Group spacing={"xs"} position="center">
                            <Button onClick={() => SubmitPendingChallan()}>Ok</Button>
                        </Group>
                    </Grid.Col>
                </Grid>
        }
    </>)
}

export default SIPendingChallanTable