import React, { useEffect } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'

const BaseCurrency = (props) => {
    const { obj, setEditSend, value } = props
    const BaseCurrData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const P_M03 = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M03)
    const P_M03DT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M03DT)
    const dispatch = useDispatch()

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    return (
        <div style={{ width: "100%" }}>
            {
                BaseCurrData &&
                <PLDataGrid
                    dropdownPosition={true}
                    // width={"500px"}
                    TmData={P_M03}
                    data={P_M03DT?.jData}
                    setEdit={(e) => {
                        setEditSend(e.FIELD01)
                    }}
                    value={value}
                />
            }
        </div>
    )
}

export default BaseCurrency