import { Button, Drawer, Grid, Modal, Paper, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice';
import { PLFilter } from './PLFilter';
import { getFilterStatus, getFiltersName } from '../../utils/FilterSlice/FilterSlice';
import { GetAccountList } from '../../utils/slices/AccountListSlice';
import { GetAccountListForPrint } from '../../utils/PrintSlice/PrintSlice';
import GlobalClass from '../../utils/GlobalClass';


function PLModal(props) {

    console.log("props", props);
    const dispatch = useDispatch();
    const FiltersName = useSelector(store => store?.FilterSlice?.FiltersName);
    const AccountListData = useSelector(store => store?.AccountList?.AccountListData);
    const isLoading = useSelector(store => store?.PrintList?.isLoading)

    const columnHelper = createColumnHelper();
    const [search, setSearch] = useState();
    const [columns, setColumns] = useState([]);
    const [rowSelection, setRowSelection] = useState({});
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState();
    const [isRowSelected, setIsRowSelected] = useState("");
    const [filterName, setFilterName] = useState("");
    const [isFilterSave, setIsFilterSave] = useState(false);

    const [accData, setAccData] = useState();

    const [accountData, setAccountData] = useState([]);
    const [groupData, setGroupData] = useState([]);
    const [cityData, setCityData] = useState([]);

    var group = "";
    // const [group, setGroup] = useState("");

    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    };
    const [DrawerObj, setDrawerObj] = useState(dra);

    var obj = {
        id: "P_M01",
        name: "",
        type: "A",
        p0: "",
        p1: "P_M01",
        p2: "",
        p3: "",
    };
    var obj1 = {
        id: "P_M11",
        name: "",
        type: "A",
        p0: "",
        p1: "P_M11",
        p2: "",
        p3: "",
    };
    var obj2 = {
        id: "P_M31T",
        name: "",
        type: "A",
        p0: "",
        p1: "P_M31T",
        p2: "",
        p3: "",
    };

    useEffect(() => {
        dispatch(GetAccountList(obj))
        dispatch(GetAccountList({ ...obj1 }))
        dispatch(GetAccountList({ ...obj2 }))
    }, [])

    useEffect(() => {
        if (AccountListData) {
            console.log("AccountListData", AccountListData);
            // setAccData(AccountListData[props?.OBJ?.BtnPnlObj?.id]);

            setAccountData(AccountListData?.P_M01)
            setGroupData(AccountListData?.P_M11)
            setCityData(AccountListData?.P_M31T)

            let cols = []
            cols.push(
                columnHelper.accessor("FIELD01", {
                    header: "Select Filters",
                    id: "FIELD01"
                })
            );
            setColumns(cols)
        }

    }, [AccountListData])

    useEffect(() => {
        // console.log("FiltersName", FiltersName);
        const data = Object.entries(FiltersName).map(item => ({ "FIELD01": item[0] }))
        // console.log("data", data);
        setData(data)
    }, [FiltersName])

    useEffect(() => {
        let isRowSelected = Object.values(rowSelection).join('')
        setIsRowSelected(isRowSelected)
    }, [rowSelection])

    useEffect(() => {
        if (isFilterSave) {
            Promise.resolve(dispatch(getFiltersName(filterName)))
                .then(() => {
                    setDrawerObj({ ...DrawerObj, open: false });
                })
        }
    }, [isFilterSave])

    // useEffect(() => {
    //     console.log("useEffect accData", accData);
    // }, [accData])

    const editBtn = () => {
        if (isRowSelected === "true") {
            console.log("FiltersName", FiltersName)
            console.log("selectedRow", selectedRow);
            const ary = Object.entries(FiltersName).reduce((acc, item, index) => {
                if (item[0] === selectedRow?.FIELD01) {
                    acc = item[1]
                }
                return acc;
            }, {});
            ary && dispatch(
                ModalFunction({
                    onclose: (e) => { },
                    MTitle: <Text fw={700}>{""}</Text>,
                    MAction: true,
                    MSize: "sm",
                    position: 'bottom',
                    MBody: (i) => <PLFilter OBJ={props?.OBJ} index={i} ary={ary} />,
                    MClose: true,
                    Overlay: {
                        backgroundOpacity: 0.8,
                        blur: 5,
                    },
                })
            );
            console.log(ary);
            // console.log("ary",ary);
            // ary?.reduce((acc, item, index) => 
            // alert("ehloo")
            // console.log("acc",acc,item)
            // if (item === selectedRow?.FIELD01) {
            //     console.log(item);
            //     dispatch(
            //         ModalFunction({
            //             onclose: (e) => { },
            //             MTitle: <Text fw={700}>{""}</Text>,
            //             MAction: true,
            //             MSize: "sm",
            //             position: 'bottom',
            //             MBody: (i) => <PLFilter OBJ={props?.OBJ} index={i} />,
            //             MClose: true,
            //             Overlay: {
            //                 backgroundOpacity: 0.8,
            //                 blur: 5,
            //             },
            //         })
            //     );
            // }
            // )
            // .filter((item) => {
            //     if (item === selectedRow?.FIELD01) {
            //         console.log(item);
            //         dispatch(
            //             ModalFunction({
            //                 onclose: (e) => { },
            //                 MTitle: <Text fw={700}>{""}</Text>,
            //                 MAction: true,
            //                 MSize: "sm",
            //                 position: 'bottom',
            //                 MBody: (i) => <PLFilter OBJ={props?.OBJ} index={i} />,
            //                 MClose: true,
            //                 Overlay: {
            //                     backgroundOpacity: 0.8,
            //                     blur: 5,
            //                 },
            //             })
            //         );
            //     }
            // })
        }
    }

    const newBtn = () => {
        dispatch(
            ModalFunction({
                onclose: (e) => { },
                MTitle: <Text fw={700}>{""}</Text>,
                MAction: true,
                MSize: "sm",
                position: 'bottom',
                MBody: (i) => <PLFilter OBJ={props?.OBJ} index={i} />,
                MClose: true,
                Overlay: {
                    backgroundOpacity: 0.8,
                    blur: 5,
                },
            })
        );
    }

    const deleteBtn = () => {
        if (isRowSelected === "true") {
            const confirm = window.confirm("Are you sure ?");
            if (confirm) {
                Object.entries(FiltersName).filter((item) => {
                    if (item[0] === selectedRow?.FIELD01) {
                        console.log(item);
                        dispatch(getFiltersName(item[0]))
                        // dispatch(
                        //     ModalFunction({
                        //         onclose: (e) => { },
                        //         MTitle: <Text fw={700}>{""}</Text>,
                        //         MAction: true,
                        //         MSize: "sm",
                        //         position: 'bottom',
                        //         MBody: (i) => <PLFilter OBJ={props?.OBJ} index={i} />,
                        //         MClose: true,
                        //         Overlay: {
                        //             backgroundOpacity: 0.8,
                        //             blur: 5,
                        //         },
                        //     })
                        // );
                    }
                })

            }
        }
    }

    const copyBtn = () => {
        if (isRowSelected === "true") {

            let queryState1 = FiltersName[selectedRow?.FIELD01];

            setDrawerObj({
                ...dra,
                title: <Text fw={700}>Save Filter</Text>,
                body: (
                    <>
                        <Grid>
                            <Grid.Col span={12} style={{ display: 'flex' }}>
                                <Grid.Col span={4}>
                                    <Text fw={700}>Filter Name</Text>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <PLTextBox
                                        setEdit={(e) => {
                                            setFilterName({ [e.target.value]: queryState1 })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={() => setIsFilterSave(true)} >Ok</Button>
                            </Grid.Col>
                        </Grid>
                    </>
                ),
                open: true,
                size: "md",
                position: "right",
            });
        }
    }


    const runBtn = () => {
        if (isRowSelected === "true") {
            let selectedFilter = FiltersName?.[selectedRow?.FIELD01];
            selectedFilter = Object.keys(selectedFilter).filter(objKey => objKey !== 'undefined')
                .reduce((newObj, key) => {
                    newObj[key] = selectedFilter[key];
                    return newObj;
                }, {}
                );

            console.log("selectedFilter", selectedFilter)
            let filteredObj = {};
            Object.keys(selectedFilter).forEach(key => {
                if (selectedFilter[key].hasOwnProperty('value')) {
                    filteredObj[key] = selectedFilter[key];
                }
            });


            console.log("filteredObj",filteredObj);


            // function constructQuery(rules) {
            //     console.log("rules", rules);
            //     console.log("group", group);

            //     let query = '';
            //     let isFirstRule = true;
            //     let condition = "AND"
            //     let qry = "";

            //     for (const key in rules) {
            //         console.log("query", query);
            //         if (key.startsWith('group0')) {
            //             query += ' ' + constructGroupQuery(rules[key])
            //             console.log("qry", qry);
            //             console.log("query", query);

            //             if (qry != query) {
            //                 qry = query
            //             }
            //             if (isFirstRule) {
            //                 let flag = true;
            //                 for (const key in rules) {
            //                     if (key.startsWith('group0_condition')) {
            //                         query = query + ' ' + rules[key].toUpperCase()
            //                         isFirstRule = false
            //                         flag = false;
            //                         condition = rules[key].toUpperCase()
            //                     }
            //                 }
            //                 if (flag) {
            //                     if (isQuery) {
            //                         query = query + " AND "
            //                         condition = "AND"
            //                     }
            //                 }
            //             }
            //         }
            //         else {
            //             console.log("group.localeCompare(key.split", group.localeCompare(key.split("_")[0]));

            //             if (group.localeCompare(key.split("_")[0]) !== 0) {
            //                 console.log("typeof ", typeof key.split("_")[0]);
            //                 query += condition
            //                 group = key.split("_")[0]

            //                 query += ' (' + constructGroupQuery(rules[key]);
            //                 if (qry != query) {
            //                     qry = query
            //                 }

            //                 let flag = true;
            //                 for (const key in rules) {
            //                     if (key.startsWith(rules[key + '_condition'])) {
            //                         query = query + ' ' + rules[key].toUpperCase()
            //                         flag = false;
            //                     }
            //                 }
            //                 if (flag) {
            //                     if (qry != query) {
            //                         query = query + " AND"
            //                     }
            //                 }
            //             }
            //             else {
            //                 query += ' ' + constructGroupQuery(rules[key])
            //                 query += ')';
            //             }
            //         }

            //     }
            //     return query;

            // }


            let isQuery = false;

            function constructQuery(rules) {
                console.log("rules", rules);
                console.log("group", group);

                let query = '';
                let isFirstRule = true;
                let condition = "AND"
                let qry = "";
                let globalConditin = "AND"


                for (const key in rules) {
                    if (key.startsWith('group0')) {

                        if (!isFirstRule) {
                            if (isQuery) {
                                // alert("isFirstRule")
                                if (!query.trim().endsWith('AND') && !query.trim().endsWith('OR')) {
                                    query += ' ' + condition; // Add condition between groups
                                }
                            }
                        }

                        query += ' ' + constructGroupQuery(rules[key]);

                        console.log("qry", qry);
                        console.log("query", query);

                        if (qry != query) {
                            qry = query
                        }

                        if (isFirstRule) {
                            // alert("OR")
                            let flag = true;
                            for (const key in rules) {
                                if (key.startsWith('group0_condition')) {
                                    // query = query + ' ' + rules[key].toUpperCase()
                                    isFirstRule = false
                                    flag = false;
                                    condition = rules[key].toUpperCase()
                                    globalConditin = rules[key].toUpperCase()
                                }
                            }
                            if (flag) {
                                if (isQuery) {
                                    // query = query + " AND "

                                    // alert("AND")
                                    condition = "AND"
                                }
                            }
                            isFirstRule = false;
                        }
                    }

                    else {

                        console.log("group", group);
                        console.log("key", key);
                        console.log("group.localeCompare(key.split", group.localeCompare(key.split("_")[0]));

                        if (group.localeCompare(key.split("_")[0]) !== 0) {

                            if (!query.trim().endsWith('AND') && !query.trim().endsWith('OR')) {
                                query += condition // Add condition between groups
                            }

                            group = key.split("_")[0]

                            query = query.trim() + ' (' + constructGroupQuery(rules[key]);

                            if (qry != query) {
                                qry = query
                            }

                            console.log("key", key);

                            let flag = true;
                            for (const key in rules) {
                                const part = key.split("_")[0];

                                function getPreviousGroup(key) {
                                    const match = key.match(/^group(\d+)$/);
                                    console.log("match", match);

                                    if (match) {
                                        const currentNumber = parseInt(match[1]);
                                        if (currentNumber > 0) {
                                            const previousNumber = currentNumber - 1;
                                            const previousKey = `group${previousNumber}`;
                                            return previousKey;
                                        } else {
                                            return null; // Assuming group0 doesn't exist
                                        }
                                    } else {
                                        return null; // Handle cases where key format doesn't match
                                    }
                                }

                                const previousKey = getPreviousGroup(part);
                                console.log("previousKey", previousKey);

                                if (key.endsWith(previousKey + '_condition')) {

                                    alert("hey")
                                    query = query + ' ' + previousKey.toUpperCase()
                                    flag = false;
                                }
                            }
                            if (flag) {
                                // alert("hello")
                                // console.log("qry",qry,query);
                                // if (qry != query) {
                                query = query + " AND"
                                // }
                            }
                        }
                        else {
                            query += ' ' + constructGroupQuery(rules[key])
                            query += ')';
                        }
                    }
                }
                return query;

            }

            function constructGroupQuery(group) {

                console.log("groupgroup", group);
                let query = '';
                let accountName = []
                let accountGroup = []
                let city = []

                if (group.field === "Account Name") {
                    // group = { ...group, field: "M02.FIELD02" }
                    if (group.value) {
                        let values = group.value.split(',');
                        console.log("accountData", accountData);
                        accountData?.filter((item) => {
                            values.map((ele) => {
                                ele === item.FIELD01 && item.FIELD02 && accountName.push(`%${item.FIELD02}%`)
                            })
                        })
                        accountName && accountName.join(',')
                        console.log("accountName", accountName);


                    }
                }
                else if (group.field === "Account Group") {
                    // group = { ...group, field: "M11.GAPGRPNAME" }
                    if (group.value) {
                        let values = group.value.split(',');
                        groupData?.filter((item) => {
                            values.map((ele) => {
                                ele === item.FIELD01 && item.FIELD02 && accountGroup.push(`%${item.FIELD02}%`)
                            })
                        })
                        // console.log("accountGroup", accountGroup);
                        accountGroup && accountGroup.join(',')
                        console.log("accountGroup", accountGroup);

                    }
                }
                else if (group.field === "City") {
                    // group = { ...group, field: "M01.FIELD17" }
                    if (group.value) {
                        let values = group.value.split(',');
                        cityData?.filter((item) => {
                            values.map((ele) => {
                                if (ele === item.FIELD01 && item.FIELD02) {
                                    city.push(`%${item.FIELD02}%`)
                                }
                            })
                        })
                        console.log("city", city);
                        city && city.join(',')
                        console.log("city", city);

                    }
                }

                if (group.field && group.operator && group.value) {
                    switch (group.field) {
                        case "Account Name":
                            if (group.operator === 'Contains' && accountName.length > 0) {
                                for (let i = 0; i <= accountName.length - 1; i++) {
                                    query += `M01.FIELD02 LIKE '${accountName[i]}'`;
                                    if (i === accountName.length - 1) {

                                    }
                                    else {
                                        query += " OR "
                                    }
                                }
                            } else if (group.operator === 'Not Contains' && accountName.length > 0) {
                                for (let i = 0; i <= accountName.length - 1; i++) {
                                    query += `M01.FIELD02 NOT LIKE '${accountName[i]}'`;
                                    if (i === accountName.length - 1) {
                                    }
                                    else {
                                        query += " AND "
                                    }
                                }

                            }
                            break;
                        case "Account Group":
                            if (group.operator === 'Contains' && accountGroup.length > 0) {
                                for (let i = 0; i <= accountGroup.length - 1; i++) {
                                    query += `M11.GAPGRPNAME LIKE '${accountGroup[i]}'`;
                                    if (i === accountGroup.length - 1) {

                                    }
                                    else {
                                        query += " OR "
                                    }
                                }
                            } else if (group.operator === 'Not Contains' && accountGroup.length > 0) {
                                for (let i = 0; i <= accountGroup.length - 1; i++) {
                                    query += `M11.GAPGRPNAME NOT LIKE '${accountGroup[i]}'`;
                                    if (i === accountGroup.length - 1) {
                                    }
                                    else {
                                        query += " AND "
                                    }
                                }

                            }
                            break;
                        case "City":
                            if (group.operator === 'Contains' && city.length >= 1) {
                                for (let i = 0; i <= city.length - 1; i++) {
                                    query += `M01.FIELD17 LIKE '${city[i]}'`;
                                    if (i === city.length - 1) {

                                    }
                                    else {
                                        query += " OR "
                                    }
                                }
                            } else if (group.operator === 'Not Contains' && city.length >= 1) {
                                for (let i = 0; i <= city.length - 1; i++) {
                                    query += `M01.FIELD17 NOT LIKE '${city[i]}'`;
                                    if (i === city.length - 1) {
                                    }
                                    else {
                                        query += " AND "
                                    }
                                }

                            }
                            break;
                        default:
                            break;
                    }

                }
                if (query.trim() !== "") {
                    // alert("isquery")
                    isQuery = true
                }
                return query;
            }

            let query = constructQuery(selectedFilter);
            console.log("query", query);

            let cWhere = "";

            if (query.trim() !== "") {
                if (query.trim().endsWith('AND') || query.trim().endsWith('OR') || query.trim().endsWith('AND (') || query.trim().endsWith('OR (')) {
                    let andOrLength = 0;

                    const queryLength = query.length;

                    if (query.trim().endsWith('AND')) {
                        andOrLength = 4
                    }
                    else if (query.trim().endsWith('OR')) {
                        andOrLength = 3
                    }
                    else if (query.trim().endsWith('AND (') || query.trim().endsWith('OR (')) {
                        andOrLength = 5;
                    }

                    // console.log("queryLength", queryLength, andOrLength);
                    // console.log("qry", qry, andOrLength)
                    let qry = query.substring(0, queryLength - andOrLength);
                    query = qry;
                    console.log("query", query)
                }
                if (query.includes('(')) {
                    // Check if the query ends with an incomplete group bracket
                    if (!query.trim().endsWith(')')) {
                        query = query + ')';
                    } else {
                        query = query;
                    }
                }
            }


            console.log("query", query)
            dispatch(ModalDelete(props.index))
            dispatch(GetAccountList({ ...props?.OBJ?.BtnPnlObj, pagination: { pageIndex: 0, pageSize: 25, }, cWhere: query }))
            dispatch(getFilterStatus(true))

            // function constructQuery(rules) {
            //     let query = '';
            //     let groupConditions = {}; // To store group-specific conditions

            //     // First pass to collect group-specific conditions
            //     for (const key in rules) {
            //         const rule = rules[key];
            //         const groupName = key.split('_')[0]; // Extract group name

            //         if (rule.condition) {
            //             groupConditions[groupName] = rule.condition.toUpperCase();
            //         }
            //     }

            //     // Second pass to construct the query
            //     let isFirstGroup = true;

            //     for (const key in rules) {
            //         const rule = rules[key];
            //         const { field, operator, value } = rule;
            //         let condition = '';

            //         if (value) {
            //             const values = value.split(',');
            //             const valueConditions = values.map(val => {
            //                 switch (operator) {
            //                     case 'Contains':
            //                         return `${field} LIKE '%${val}%'`;
            //                     case 'Not Contains':
            //                         return `${field} NOT LIKE '%${val}%'`;
            //                     // Add more cases for other operators if needed
            //                     default:
            //                         return ''; // Handle other operators as needed
            //                 }
            //             });
            //             condition = valueConditions.join(' OR ');
            //         }

            //         // Determine the condition to use for joining rules in this group
            //         const groupName = key.split('_')[0];
            //         const groupCondition = groupConditions[groupName] || 'AND';

            //         if (!isFirstGroup) {
            //             query += ` ${groupCondition} `;
            //         }

            //         if (groupConditions[groupName]) {
            //             query += '(';
            //         }

            //         query += condition;

            //         if (groupConditions[groupName]) {
            //             query += ')';
            //         }

            //         isFirstGroup = false;
            //     }

            //     return query;
            // }


            // // if (key.startsWith('group')) {
            // //     // Start a new group
            // //     if (!isFirstGroup) {
            // //         console.log("groupConditions",groupConditions[key]);
            // //         query += ` ${groupConditions[key]} (`; // Use groupConditions dynamically
            // //     } else {
            // //         
            // //         isFirstGroup = false;
            // //     }

            // //     query += constructGroupQuery(rules[key]);
            // //     query += ')';
            // //     if (rules[key].condition) {
            // //          console.log("rules[key].condition",rules[key].condition);
            // //         groupConditions[key] = rules[key].condition;
            // //     }
            // // } else {
            // //     // Individual rule
            // //     query += constructRuleQuery(rules[key]);
            // // }
            // function constructRuleQuery(rule) {
            //     let query = '';

            //     if (rule.operator === 'Contains') {
            //         query += `${rule.field} LIKE '%${rule.value}%'`;
            //     } else if (rule.operator === 'Not Contains') {
            //         query += `${rule.field} NOT LIKE '%${rule.value}%'`;
            //     }

            //     return query;
            // }

            // // function constructQuery(rules) {
            // //     console.log("rules", rules);
            // //     let query = '';
            // //     let isFirstGroup = true;
            // //     let groupConditions = {};

            // //     for (const key in rules) {
            // //         if (key.startsWith('group')) {
            // //             // Start a new group
            // //             if (!isFirstGroup) {
            // //                 query += ` ${groupConditions[key]} (`; // Use groupConditions dynamically
            // //             } else {
            // //                 query += '(';
            // //                 isFirstGroup = false;
            // //             }

            // //             query += constructGroupQuery(rules[key]);
            // //             query += ')';
            // //             if (rules[key].condition) {
            // //                 groupConditions[key] = rules[key].condition;
            // //             }
            // //         } else {
            // //             // Individual rule
            // //             query += constructRuleQuery(rules[key]);
            // //         }
            // //     }

            // //     return query;


            // // }

            // // function constructRuleQuery(rule) {
            // //     let query = '';

            // //     if (rule.operator === 'Contains') {
            // //         query += `${rule.field} LIKE '%${rule.value}%'`;
            // //     } else if (rule.operator === 'Not Contains') {
            // //         query += `${rule.field} NOT LIKE '%${rule.value}%'`;
            // //     }

            // //     return query;
            // // }

            // // function constructGroupQuery(group) {
            // //     let query = '';


            // //     console.log("group", group);

            // //     if (group.field && group.operator && group.value) {
            // //         if (group.operator === 'Contains') {
            // //             query += `${group.field} LIKE '%${group.value}%'`;
            // //         } else if (group.operator === 'Not Contains') {
            // //             query += `${group.field} NOT LIKE '%${group.value}%'`;
            // //         }
            // //     }
            // //     console.log("query", query);


            // //     return query;
            // // }

            // // const rulesObject = {
            // //     "ABC": {
            // //         "group0_rule0": {
            // //             "field": "Account Group",
            // //             "operator": "Not Contains",
            // //             "value": "APMDSR84,APVWNWYM"
            // //         },
            // //         "group0_rule1": {
            // //             "field": "City",
            // //             "operator": "Contains",
            // //             "value": "AZHFDCHJ"
            // //         },
            // //         "group0": {
            // //             "operator": "Contains",
            // //             "condition": "or"
            // //         },
            // //         "group1_rule2": {
            // //             "field": "Account Name",
            // //             "operator": "Contains",
            // //             "value": "APMDSR84"
            // //         },
            // //         "group1_rule3": {
            // //             "field": "City",
            // //             "operator": "Not Contains",
            // //             "value": "APHA7ARY"
            // //         }
            // //     }
            // // };

            // // const query1 = constructQuery(rulesObject.ABC);
            // // console.log(query1);

            // //  function constructQuery(rules) {
            // //     console.log("rules",rules);
            // //     let query = '';
            // //     let isFirstGroup = true;

            // //     for (const key in rules) {
            // //         if (key.startsWith('group')) {
            // //             // Start a new group
            // //             if (!isFirstGroup) {
            // //                 query += ' AND (';
            // //             } else {
            // //                 query += '(';
            // //                 isFirstGroup = false;
            // //             }

            // //             query += constructGroupQuery(rules[key]);
            // //             query += ')';
            // //             console.log("constructQuery",query);
            // //         } else {
            // //             // Individual rule
            // //             query += constructRuleQuery(rules[key]);
            // //         }
            // //     }

            // //     return query;
            // // }

            // // function constructRuleQuery(rule) {


            // //     let query = '';

            // //     console.log("ruleQuery",rule);
            // //     if (rule.operator === 'Contains') {
            // //         query += `${rule.field} LIKE '%${rule.value}%'`;
            // //     } else if (rule.operator === 'Not Contains') {
            // //         query += `${rule.field} NOT LIKE '%${rule.value}%'`;
            // //     }

            // //     return query;
            // // }

            // // function constructGroupQuery(group) {
            // //     let query = '';

            // //     // Check if the group object has any rules
            // //     console.log("group",group);

            // //     if (group.field && group.operator && group.value) {
            // //         if (group.operator === 'Contains') {
            // //             query += `${group.field} LIKE '%${group.value}%'`;
            // //         } else if (group.operator === 'Not Contains') {
            // //             query += `${group.field} NOT LIKE '%${group.value}%'`;
            // //         }
            // //     }
            // // console.log("query",query);
            // //     return query;
            // // }

            // // const rulesObject = {
            // //     "ABC": {

            // //         "group0_rule0": {
            // //             "field": "Account Group",
            // //             "operator": "Not Contains",
            // //             "value": "APMDSR84,APVWNWYM"
            // //         },
            // //         "group0_rule1": {
            // //             "field": "City",
            // //             "operator": "Contains",
            // //             "value": "AZHFDCHJ"
            // //         },
            // //         "group0": {
            // //             "operator": "Contains",
            // //             "condition": "or"
            // //         },
            // //         "group1_rule2": {
            // //             "field": "Account Name",
            // //             "operator": "Contains",
            // //             "value": "APMDSR84"
            // //         },
            // //         "group1_rule3": {
            // //             "field": "City",
            // //             "operator": "Not Contains",
            // //             "value": "APHA7ARY"
            // //         }
            // //     }
            // // };

            // // const query1 = constructQuery(rulesObject.ABC);
            // // console.log(query1);


            // // const rulesObject = {
            // //     "ABC": {
            // //         "group0_rule0": {
            // //             "field": "Account Group",
            // //             "operator": "Not Contains",
            // //             "value": "APMDSR84,APVWNWYM"
            // //         },
            // //         "group0_rule1": {
            // //             "field": "City",
            // //             "operator": "Contains",
            // //             "value": "AZHFDCHJ"
            // //         },
            // //         "group0_condition": "or",

            // //         "group1_rule2": {
            // //             "field": "Account Name",
            // //             "operator": "Contains",
            // //             "value": "APMDSR84"
            // //         },
            // //         "group1_rule3": {
            // //             "field": "City",
            // //             "operator": "Not Contains",
            // //             "value": "APHA7ARY"
            // //         }
            // //     }
            // // };

            // // const query1 = constructQuery(rulesObject.ABC);
            // // console.log(query1);

            // const query2 = `M01.FIELD17 LIKE '%AHMEDABAD.%' OR (M01.FIELD02 LIKE '%11a%' AND M01.FIELD17 NOT LIKE '%ab%')`
            // const query = `M01.FIELD17 LIKE '%AHMEDABAD%'`
        }
    }

    const table = useMantineReactTable({
        data,
        columns,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableTopToolbar: false,
        enablePagination: true,
        enableColumnActions: false,
        enableSorting: false,
        enableGlobalFilter: true,
        enableGlobalFilterModes: true,
        enableColumnFilters: false,
        enableColumnDragging: false,
        initialState: {
            globalFilter: search,
        },
        state: {
            globalFilter: search,
            rowSelection
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            onClick: () => {
                console.log(row, "row");
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
                setSelectedRow(row?.original)
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
    });

    return (
        <>
            <Modal
                opened={DrawerObj?.open}
                // onClose={close}
                withCloseButton={() => { }}
                fullScreen={false} //
                // overlayProps={{ opacity: 0.5, blur: 4 }}
                closeButtonProps={{
                    onClick: () => {
                        setDrawerObj(dra);
                    },
                }}
                title={DrawerObj.title}
                size={DrawerObj.size}
                // centered={.Centered}
                closeOnClickOutside={true}
                // overlayProps={m.Overlay}
                position={DrawerObj?.position ?? "bottom"}
                onClose={
                    typeof DrawerObj?.onclose == "function"
                        ? DrawerObj?.onclose
                        : () => {
                            setDrawerObj(dra);
                        }
                }
            // scrollAreaComponent={ScrollArea.Autosize}
            >
                {DrawerObj.body}
            </Modal>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    <div>
                        <Paper shadow="md" radius="md" p="xs" withBorder>
                            <Grid style={{ marginTop: '1px', padding: '0px' }}>
                                <Grid.Col span={12}>
                                    <PLTextBox value={search}
                                        placeholder="Search Here"
                                        setEdit={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <MantineReactTable table={table} />
                                </Grid.Col>
                            </Grid>
                            <Grid>
                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                    {/* <Grid.Col span={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Text>Format</Text>
                            <PLComboBox value={format}
                                data={AddressBookData?.CBOT2}
                                dispexpr="DisplayMember"
                                valexpr="ValueMember"
                                setEdit={(e) => {
                                    setFormat(e)
                                }}
                            />
                        </Grid.Col> */}
                                    {/* <Grid.Col span={8} style={{ display: 'flex', justifyContent: 'space-around' }}> */}
                                    <Button mx={5} onClick={newBtn}>New</Button>
                                    <Button mx={5} onClick={editBtn}>Edit</Button>
                                    <Button mx={5} onClick={deleteBtn}>Delete</Button>
                                    <Button mx={5} onClick={copyBtn}>Copy</Button>
                                    <Button mx={5} onClick={runBtn}>Run</Button>
                                    {/* </Grid.Col> */}
                                </Grid.Col>
                            </Grid>
                        </Paper>
                    </div>
            }

        </>

    )
}

export default PLModal
