import { Container, Group, ActionIcon, rem, Image, useMantineColorScheme, createStyles, Text } from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram, IconVersions } from '@tabler/icons-react';
import App from "../App.json"

export function FooterSocial() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  
  return (
    <div className={'footerf'} >
      {/* <Container fluid className={'innerf'}> */}
      {/* <Group style={{ filter: colorScheme == "dark" ? "grayscale(100%) invert(1);" : "" }}>
              <Image
                src='logo1.svg'
                height={35}
                sx={{ filter: colorScheme == "dark" ? "grayscale(100%) invert(1);" : "" }}
              />
            </Group>
        <Group className={'linksf'}> */}
        <Text size={"sm"} variant='text' color={'gray'}>{"App Version: "+App.version}</Text>
        {/* </Group> */}
      {/* </Container> */}
    </div>
  );
}