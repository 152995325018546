import { createSlice } from '@reduxjs/toolkit';

export const TabList = createSlice({
  name: 'menu',
  initialState: {
    value: [],
    selected: "Dashboard",
    selectedMenu: "",
  },
  reducers: {

    TabAdd: (state, action) => {
      // console.log("TabADD action=>",state.value);

      // if(!state.value.some((value,index)=>value.id===action.payload.id)){
      //   state.value.push(action.payload);
      // }
      // if(!state.value[action.payload.id]){
      //  console.log("state",state,"action",action.payload);
      state.value = { ...state.value, [action.payload.id]: action.payload }
      // console.log("action.payload.id",state.value);
      // state.value[action.payload.id] = action.payload;
      // }
      state.selected = action.payload.id;
    },
    TabRemove: (state, action) => {
      console.log("remove", action.payload);
      // const filtered = state.value.filter((item) => item.id !== action.payload);
      // if(filtered.length>0){
      //   
      //   state.selected = filtered.at(-1).name
      // }
      //   state.value = filtered
      if (state.value[action.payload]) {
        delete state.value[action.payload]
      }
      if (Object.keys(state.value).length > 0) {
        // console.log("last=>", Object.keys(state.value).at(-1));
        state.selected = Object.keys(state.value).at(-1);
      }
      else {
        state.selected = "Dashboard"
      }
    },
    UpdateSelected: (state, action) => {
      if (state.value[action.payload]) {
        state.selected = action.payload
        // console.log("UpdateSelected=>",action.payload);
      }

    },
    ClearTabs: (state, action) => {

      state.selected = "Dashboard";
      state.value = [];

    },
    UpdateSelectedMenu: (state, action) => {
      // console.log("UpdateSelectedMenu=>",action.payload);
      state.selectedMenu = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { TabAdd, TabRemove, UpdateSelected, UpdateSelectedMenu, ClearTabs } = TabList.actions

export default TabList.reducer