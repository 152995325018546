import { useEffect, useState } from 'react';
import { MantineProvider, ColorSchemeProvider, ColorScheme, rem } from '@mantine/core';
import { useColorScheme, useLocalStorage } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,

} from "react-router-dom";
import ErrorPage from './errorPages/ErrorPage';
import { Provider } from 'react-redux';
// import store from './utils/store'
import Store from '../src/utils/store';
import { ModalsProvider, ContextModalProps } from '@mantine/modals';
import JBBatchWiseDetail from './Transaction/JobWorkOpening/JBBatchWiseDetail'
import JBUserField from './Transaction/JobWorkOpening/JBUserField'
import CBUserField from './FunctionsCall/Transaction/CBUserField';
import SIBatchWiseDetail from './Transaction/SaleEntry/SIBatchWiseDetail';
import SIBillToBillTable from './Transaction/SaleEntry/SIBillToBillTable';
import SICasePartyDetail from './Transaction/SaleEntry/SICasePartyDetail';
import SITableUserField from './Transaction/SaleEntry/SITableUserField';
import SIExpenseEntry from './Transaction/SaleEntry/SIExpenseEntry';
import SITablePriceList from './Transaction/SaleEntry/SITablePriceList';
import OSTableUserField from './Transaction/StockEntry/OSTableUserField';
import PLBillToBill from './PlusComponent/PLBillToBill';
import PendingBillTable from './Transaction/AutoBill/AutoBillFormSE';
import PRUserField from './Transaction/Production/PRUserField';
import DataImportForm from './Transaction/Production/DataImportForm';
import CDExpenseEntry from './Transaction/StockEntry/CreditDebitNote/CDExpenseEntry';
// import SJTableUserField from './Transaction/StockEntry/SJTableUserField'
import Login from './Auth';
import M01Forms from './FunctionsCall/M01Form/M01Forms';
import CapitalAccountTable from './FunctionsCall/M01Form/CapitalAccountTable';
import OriginalInvoiceDetailTable from './Transaction/StockEntry/CreditDebitNote/OriginalInvoiceDetailTable';
import CDUserField from './Transaction/StockEntry/CreditDebitNote/CDUserField';
import ReportViewer from './components/ReportViewer';
import { AuthProtected } from './Auth/AuthProtected';
import { registerLicense } from '@syncfusion/ej2-base';
import FontModal from './SetupComponent/CompanySetup/WaterMarkList/FontModal';
import MyReportDesigner from './components/ReportDesigner';
import ReportViewerTest from './components/ReportViewerTest';
declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}
const modals = {
  SIBillToBillTable: SIBillToBillTable,
  SICasePartyDetail: SICasePartyDetail,
  SITableUserField: SITableUserField,
  SITablePriceList: SITablePriceList,
  SIExpenseEntry: SIExpenseEntry,
  SIBatchWiseDetail: SIBatchWiseDetail,
  CBUserField: CBUserField,
  PLBillToBill: PLBillToBill,
  JBBatchWiseDetail: JBBatchWiseDetail,
  JBUserField: JBUserField,
  OSTableUserField: OSTableUserField,
  PendingBillTable: PendingBillTable,
  PRUserField: PRUserField,
  DataImportForm: DataImportForm,
  M01Forms: M01Forms,
  CapitalAccountTable: CapitalAccountTable,
  CDExpenseEntry: CDExpenseEntry,
  OriginalInvoiceDetailTable: OriginalInvoiceDetailTable,
  CDUserField: CDUserField,
  FontModal: FontModal,
  // SJTableUserField: SJTableUserField
}
function Main() {
  const preferredColorScheme = useColorScheme();
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: preferredColorScheme,
    getInitialValueInEffect: true,
  });

  const [sessionSetting, setSessionSetting] = useLocalStorage({
    key: 'session_setting',
    defaultValue: {
      Primary_Color: 'blue',
      Font_Family: 'Arial',
      Font_Size: 12,
      Font_XS: 10,
      Font_SM: 12,
      Font_MD: 14,
      Font_LG: 16,
      Font_XL: 20
    },
    getInitialValueInEffect: true,
  })

  // useEffect(()=>{
  //   setSessionSetting("test")
  // },[])

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AuthProtected><App /></AuthProtected>,
      errorElement: <ErrorPage />
    },
    {
      path: "/:tabValue",
      element: <AuthProtected><App /></AuthProtected>,
      errorElement: <ErrorPage />
    },
    {
      path: "/Auth/Login",
      element: <Login />,
      errorElement: <ErrorPage />
    },
    { path: "/designer", element: <MyReportDesigner />, errorElement: <ErrorPage /> },
    { path: "/viewer", element: <ReportViewerTest />, errorElement: <ErrorPage /> }
  ]);

  // console.log("sessionSetting =>", sessionSetting);

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <Provider store={Store}>
        <MantineProvider
          // sessionSetting?.Font_Family
          theme={{
            colorScheme,
            primaryColor: sessionSetting?.Primary_Color,
            fontFamily: sessionSetting?.Font_Family,
            breakpoints: {
              xs: '30em',
              sm: '48em',
              md: '64em',
              lg: '74em',
              xl: '90em',
            },
            // fontSizes:{}
          }}
          withGlobalStyles
          withNormalizeCSS
        >
          <Notifications />
          <ModalsProvider modals={modals}>
            <RouterProvider router={router} />
          </ModalsProvider>
        </MantineProvider>
      </Provider>
    </ColorSchemeProvider>
  );
}
export default Main