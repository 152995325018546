import { modals } from "@mantine/modals";
import { Text } from "@mantine/core";
import Store from "../../utils/store";
import { TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import TransactionForm from "./TransactionForm";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";
import { GetInvTypeDropDown } from "../../utils/TransactionSlices/TSalesSlices";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";

export function VouEntAct(data) {
    const OBJ = JSON.parse(data);
    console.log("OBJ", OBJ);

    if (OBJ.p0 === "A") {
        Store.dispatch(
            ModalFunction({
                onClickCloseButton: () => {
                    // Deval (For Company Setup)
                    Store.dispatch(GetInvTypeDropDown(null))
                  },
                onclose: (e) => {},
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "xl",
                position:'right',
                MBody: (i) => <TransactionForm OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                  backgroundOpacity: 0.8,
                  blur: 5,
                },
                // onclose: () => { },
                // MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                // MAction: true,
                // MSize: "xl",
                // MBody: (i) => (
                //     <TransactionForm OBJ={OBJ} index={i} />
                // ),
                // MClose: true,
                // Overlay: {
                //     backgroundOpacity: 0.8,
                //     blur: 5,
                // }
            })
        )

        
        //====================================================
        // modals.open({
        //     title: <Text style={{ fontWeight: 700 }}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children:
        //         <>
        //             <TransactionForm OBJ={OBJ} />
        //         </>
        // })
    }

    else if (OBJ.id && OBJ.p0 === "E") {
        Store.dispatch(
            ModalFunction({
                onClickCloseButton: () => {
                    // Deval (For Company Setup)
                    Store.dispatch(GetInvTypeDropDown(null))
                  },
                onclose: (e) => {},
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "xl",
                position:'right',
                MBody: (i) => <TransactionForm OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                  backgroundOpacity: 0.8,
                  blur: 5,
                },
            })
        )
        //====================================================
        // modals.open({
        //     title: <Text style={{ fontWeight: 700 }}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children:
        //         <>
        //             <TransactionForm OBJ={OBJ} />
        //         </>
        // })
    }
    else if (OBJ.id && OBJ.p0 === "D") {
        const confirm = window.confirm("Are you sure ?");
        if (confirm) {
            Store.dispatch(TransactionDeleteApi(OBJ))
        }
    }
    else if(OBJ.id && OBJ.p0 ==="P"){
        // eval('TM_PRINTVF('+OBJ+')')
        TM_PRINTVF(data);
    }


}