import { Radio } from "@mantine/core";
import React, { useEffect, useState } from "react";


export default function PLRadioButton(props) {
    // console.log("PLRadioButton=>",props);
    // const [value, setValue] = useState()


    return (
        <>
            <Radio.Group
                value={props.value}
                onChange={(e) => props.onChangeVal(e)}
                name="favoriteFramework"
                //   label="Select your favorite framework/library"
                //   description="This is anonymous"
                withAsterisk
            >
                {
                 props?.data?.map((d) => {
                      return (
                        <>
                        <Radio mt={10} value={d.value} label={d.label} />
                        </>
                      )
                    })
                }
            </Radio.Group>
        </>
    )

}