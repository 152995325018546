import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ColorInput, Drawer, FileButton, FileInput, Grid, Image, Modal, Paper, Radio, Text } from '@mantine/core'
import { GetWaterMarkList } from '../../../utils/slices/CompanySetUpPopUpSlice'
import GlobalClass from '../../../utils/GlobalClass'
import gensetting from '../../../utils/gensetting'
import { DataSaveLoading } from '../../../utils/slices/DataSaveLoadingSlice'
import { ModalDelete } from '../../../utils/slices/ModalSlice'
import PLTextBox from '../../../PlusComponent/PLTextBox'
import PLNumberBox from '../../../PlusComponent/PLNumberBox'
import PLComboBox from '../../../PlusComponent/PLComboBox'
import FontModal from './FontModal'
import { IconPhotoOff } from '@tabler/icons-react'


const WaterMarkList = (props) => {
    const { obj } = props
    // console.log('obj==>>', obj)
    const isLoading = useSelector((state) => state.CompanySetupPopUp?.isLoading)
    const SaveLoading = useSelector(
        (state) => state?.DataSaveLoadingSlice?.SaveLoading
    );
    const WaterMarkData = useSelector((state) => state.CompanySetupPopUp?.WaterMarkListData)
    const oWMData = useSelector((state) => state.CompanySetupPopUp?.WaterMarkListData?.oWM)
    const COPF44 = useSelector((state) => state.CompanySetupPopUp?.WaterMarkListData?.COPF44)
    const COPTMH = useSelector((state) => state.CompanySetupPopUp?.WaterMarkListData?.COPTMH)
    const COPF45 = useSelector((state) => state.CompanySetupPopUp?.WaterMarkListData?.COPF45)

    // console.log('WaterMarkData=>>', WaterMarkData)
    const dispatch = useDispatch()

    const [checked, setChecked] = useState(false)
    const [pictureChecked, setPictureChecked] = useState(false)
    const [file, setFile] = useState(null)
    const [base64URL, setBase64URL] = useState("");
    const [waterMark, setWaterMark] = useState({
        "WMF01": "",
        "WMF02": "",
        "WMF03": "",
        "WMF04": "",
        "WMF11": "",
        "WMF12": "",
        "WMF13": 0,
        "WMF14": 0,
        "WMF15": "",
        "WMF16": "",
        "WMF17": "",
        "WMF21": "",
        "WMF22": "",
        "WMF24": 0,
        "WMF25": "",
        "WMF26": "",
        "WMF27": "",
        "FLDUNQ": 0,
        "FLDAED": "A",
        "FLDBRC": ""
    })
    const [colorVal, setColorVal] = useState(null)
    const [color, setColor] = useState("")
    let dra = {
        title: "",
        size: "md",
        body: "",
        open: false,
    }
    const [DrawerObj, setDrawerObj] = useState(dra);

    // console.log("base64URL",base64URL)

    useEffect(() => {
        if (obj.p0 === "E") {
            // console.log("obj==>", obj)
            dispatch(GetWaterMarkList({ action: "E", code: obj?.WMF01 }))
        } else {
            dispatch(GetWaterMarkList())
        }
    }, [obj])

    useEffect(() => {
        if (oWMData) {
            setWaterMark(oWMData)
        }
    }, [oWMData])

    useEffect(() => {
        if (obj.p0 == "E" && waterMark.WMF02 !== "") {
            setChecked(true)
            setPictureChecked(false)
        } else if (obj.p0 == "E" && waterMark?.WMF25 !== "") {
            setPictureChecked(true)
            setChecked(false)
        }
    }, [obj, waterMark.WMF02, waterMark?.WMF25])

    // console.log('checked=>>', checked)
    // console.log('pictureChecked=>>', pictureChecked)

    useEffect(() => {
        if (pictureChecked == true) {
            setChecked(false)
        } else if (obj.p0 == "A") {
            setChecked(true)
        }
    }, [pictureChecked, obj])

    useEffect(() => {
        if (checked == true) {
            setPictureChecked(false)
        } else if (obj.p0 == "A" && pictureChecked == true) {
            setChecked(false)
        }
    }, [checked, obj])

    // for Edit Case (Check Box)
    useEffect(() => {
        if (obj.p0 == "E" && (waterMark?.WMF24 !== 0 || waterMark?.WMF25 !== "" || waterMark?.WMF26 !== "" || waterMark?.WMF27 !== "")) {
            setChecked(false)
            setPictureChecked(true)
        } else if (obj.p0 == "E" && (waterMark?.WMF02 !== "" || waterMark?.WMF11 !== "" || waterMark.WMF12 !== "" || waterMark.WMF13 !== 0 || waterMark?.WMF14 !== "" || waterMark?.WMF15 !== "" || waterMark?.WMF16 !== "" || waterMark?.WMF17 !== "")) {
            setChecked(true)
            setPictureChecked(false)
        }
    }, [obj, waterMark])

    //     useEffect(() => {
    // if((waterMark?.WMF24 < 10 || waterMark?.WMF24 > 500) && pictureChecked == true) {
    // GlobalClass?.Notify("warning", "Enter Value Between 10 To 500")
    // }
    //     },[waterMark?.WMF24, pictureChecked])

    const handleSaveData = () => {
        if (waterMark?.WMF02 == "") {
            GlobalClass.Notify("warning", "Empty Value Not Allowed")
        } else if ((waterMark?.WMF14 < 0 || waterMark?.WMF14 > 100) && checked == true) {
            GlobalClass.Notify('warning', "Enter Value Between 0 to 100")
        } else if ((waterMark?.WMF24 < 10 || waterMark?.WMF24 > 500) && pictureChecked == true) {
            GlobalClass.Notify("warning", "Enter Value Between 10 To 500")
        } else if (waterMark?.WMF11 == "" && checked == true) {
            GlobalClass.Notify("warning", "Empty Value Not Allowed")
        } else {
            console.log('waterMark=>>', waterMark)
            dispatch(DataSaveLoading(true));
            GlobalClass.Notify("info", obj.p0 == "E" ? "Editing" : "Adding", "Please wait while we process your data")
            let jData = { ...waterMark }
            var data = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: obj.p0,
                cCode: obj.p0 === "E" ? obj.id : "",
                cSData: JSON.stringify(jData),
            };
            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: obj.p0,
                cCode: obj.p0 === "E" ? obj.id : "",
            });
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data),
            };
            fetch(
                GlobalClass.ApiUrl + GlobalClass.PostWMKEnt + "?pa=" + param,
                requestOptions
            )
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "SUCCESS") {
                        Promise.resolve(dispatch(DataSaveLoading(false)))
                            .then(() => dispatch(ModalDelete(props.index)))
                            .then(() =>
                                GlobalClass.Notify(
                                    "success",
                                    obj.p0 == "E" ? "Edited" : "Added",
                                    obj.p0 == "E" ? "Edited Successfully" : "Added Successfully"
                                )
                            );
                    } else {
                        Promise.response(dispatch(DataSaveLoading(false))).then(() =>
                            GlobalClass.Notify(
                                "error",
                                data?.status,
                                data?.message
                            )
                        );
                    }
                })
                .catch((e) => {
                    Promise.resolve(
                        GlobalClass.Notify(
                            "error",
                            "Error",
                            `${e?.message}`
                        )).then(() => dispatch(DataSaveLoading(false)));
                });
        }
    }

    const OpenFontModalFun = () => {
        return setDrawerObj({
            ...dra, title: <Text fw={700}>Report Font</Text>, body: <>
                <FontModal
                    waterMarkObjSend={waterMark}
                    setWaterMarkObjSend={(e) => {
                        console.log('e==>', e)
                        setWaterMark(e)
                    }}
                    DrawerObjSend={DrawerObj}
                    setDrawerObjSend={(e) => {
                        setDrawerObj(e)
                    }}
                />
            </>, open: true, size: "md", position: "right"
        })
    }

    // convert Hex to Decimal value
    useEffect(() => {
        if (colorVal !== null) {

            let a = colorVal
            let d = a.replace("#", "0x")
            console.log('d===>', d)

            let c = d;
            var components = {
                r: (c & 0xff0000) >> 16,
                g: (c & 0x00ff00) >> 8,
                b: (c & 0x0000ff)
            };
            Promise.resolve(c = (components.r << 16) + (components.g << 8) + (components.b)).then(() => setWaterMark({ ...waterMark, WMF13: c }))
            // c = (components.r << 16) + (components.g << 8) + (components.b);
            console.log('c======>', c)
        }
    }, [colorVal])

    // convert Decimal to Hex value
    useEffect(() => {
        let hexString;
        if (waterMark.WMF13 !== 0) {
            hexString = "#" + (waterMark.WMF13).toString(16);
            // console.log('hexString', hexString)
        }
        return setColor(hexString)
    }, [waterMark.WMF13])

    useEffect(() => {
        console.log('waterMark', waterMark)
    }, [waterMark])

    const getBase64 = (e) => {
        return new Promise(resolve => {
            let baseURL = "";
            let reader = new FileReader();

            reader.readAsDataURL(e);

            reader.onload = () => {
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    return (
        <>
            {SaveLoading == true ? (
                GlobalClass.RenderLoader("dots")
            ) : (
                <>
                    {
                        isLoading == true ? GlobalClass.RenderLoader("dots") :
                            WaterMarkData &&
                            <>
                                <Modal
                                    opened={DrawerObj?.open}
                                    // onClose={close}
                                    withCloseButton={() => {

                                    }}
                                    fullScreen={false}
                                    closeButtonProps={{
                                        onClick: () => {
                                            setDrawerObj(dra);
                                        },
                                    }}
                                    title={DrawerObj.title}
                                    size={DrawerObj.size}
                                    // centered={.Centered}
                                    closeOnClickOutside={true}
                                    position={DrawerObj?.position ?? 'bottom'}
                                    onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                                >
                                    {DrawerObj.body}
                                </Modal>
                                <Grid gutter={4}>
                                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Grid.Col span={2}>
                                            <Text size={12}>Profile Name</Text>
                                        </Grid.Col>
                                        <Grid.Col span={10}>
                                            <PLTextBox
                                                setText={(e) => {
                                                    if (waterMark?.WMF02 == "" || e.target?.value?.length == 0) {
                                                        GlobalClass.Notify("warning", "Empty Value Not Allowed")
                                                    }
                                                }}
                                                value={waterMark?.WMF02}
                                                setEdit={(e) => {
                                                    setWaterMark({ ...waterMark, WMF02: e.target.value })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid>

                                <Grid gutter={4}>
                                    <Grid.Col span={12} my={10}>
                                        <Radio
                                            checked={checked}
                                            onChange={(e) => {
                                                // console.log('eeee=====>',e)
                                                setChecked(e.target.checked)
                                            }}
                                            label={<Text fw="bold" size={12}>Text Water Mark</Text>}
                                        />
                                    </Grid.Col>
                                </Grid>

                                <Paper shadow='sm' radius="md" p="xs" style={{ border: '1px solid grey' }}>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Text</Text>
                                            </Grid.Col>
                                            <Grid.Col span={10}>
                                                <PLTextBox
                                                    disabled={pictureChecked == true ? true : false}
                                                    setText={(e) => {
                                                        if ((waterMark?.WMF11 == "" || e.target.value.length == 0) && checked == true) {
                                                            GlobalClass.Notify("warning", "Empty Value Not Allowed")
                                                        }
                                                    }}
                                                    value={waterMark?.WMF11}
                                                    setEdit={(e) => {
                                                        setWaterMark({ ...waterMark, WMF11: e.target.value })
                                                    }}
                                                />
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Font</Text>
                                            </Grid.Col>
                                            <Grid.Col span={9}>
                                                <PLTextBox
                                                    disabled={true}
                                                    value={waterMark.WMF12}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={1}>
                                                <Button variant='light' size='xs' fullWidth
                                                    onClick={() => OpenFontModalFun()}
                                                    disabled={pictureChecked == true ? true : false}
                                                >...</Button>
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Forecolor</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <ColorInput
                                                    disabled={pictureChecked == true ? true : false}
                                                    format='hex'
                                                    // WMF13
                                                    value={color}
                                                    onChange={(e) => {
                                                        console.log('e======>', e)
                                                        setColorVal(e)
                                                        // setWaterMark({ ...waterMark, WMF13: e })
                                                    }}
                                                // value={waterMark.WMF13}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Opacity</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLNumberBox
                                                    disabled={pictureChecked == true ? true : false}
                                                    setNumber={(e) => {
                                                        // console.log('e====>', e)
                                                        if (waterMark?.WMF14 < 0 || waterMark?.WMF14 > 100) {
                                                            GlobalClass.Notify("warning", "Enter Value Between 0 To 100")
                                                        }
                                                    }}
                                                    value={waterMark?.WMF14}
                                                    setEdit={(e) => {
                                                        setWaterMark({ ...waterMark, WMF14: e })
                                                    }}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Layout</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLComboBox
                                                    disabled={pictureChecked == true ? true : false}
                                                    data={COPF44}
                                                    dispexpr={"DisplayMember"}
                                                    valexpr={"ValueMember"}
                                                    value={waterMark?.WMF15}
                                                    setEdit={(e) => {
                                                        setWaterMark({ ...waterMark, WMF15: e })
                                                    }}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Horizontal</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLComboBox
                                                    disabled={pictureChecked == true ? true : false}
                                                    data={COPTMH}
                                                    dispexpr={"DisplayMember"}
                                                    valexpr={"ValueMember"}
                                                    value={waterMark?.WMF16 == "D" ? "DDD" : waterMark?.WMF16 == "0" ? "000" : waterMark?.WMF16 == "1" ? "111" : waterMark?.WMF16 == "2" ? "222" : waterMark?.WMF16}
                                                    setEdit={(e) => {
                                                        // console.log('e==>', e)
                                                        setWaterMark({ ...waterMark, WMF16: e === "DDD" ? "D" : e === "000" ? "0" : e === "111" ? "1" : e === "222" ? "2" : '' })
                                                    }}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid>

                                    <Grid gutter={4}>
                                        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Grid.Col span={2}>
                                                <Text size={12}>Verical</Text>
                                            </Grid.Col>
                                            <Grid.Col span={4}>
                                                <PLComboBox
                                                    disabled={pictureChecked == true ? true : false}
                                                    data={COPF45}
                                                    dispexpr={"DisplayMember"}
                                                    valexpr={"ValueMember"}
                                                    value={waterMark?.WMF17}
                                                    setEdit={(e) => {
                                                        setWaterMark({ ...waterMark, WMF17: e })
                                                    }}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={6}>
                                            </Grid.Col>
                                        </Grid.Col>
                                    </Grid>

                                </Paper>

                                <Grid gutter={4}>
                                    <Grid.Col span={12} my={10}>
                                        <Radio
                                            checked={pictureChecked}
                                            onChange={(e) => {
                                                setPictureChecked(e.target.checked)
                                            }}
                                            label={<Text fw="bold" size={12}>Picture Water Mark</Text>}
                                        />
                                    </Grid.Col>
                                </Grid>

                                <Grid gutter={4}>
                                    <Grid.Col span={12}>
                                        <Paper shadow='sm' radius="md" p="xs" style={{ border: '1px solid grey' }}>

                                            <Grid gutter={4}>
                                                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Grid.Col span={2}>
                                                        <Text size={12}>Picture</Text>
                                                    </Grid.Col>
                                                    {/* <Grid.Col span={9}>
                                                      
                                                    </Grid.Col> */}
                                                    <Grid.Col span={10}>
                                                        <FileInput
                                                            onChange={(e) => {
                                                                console.log('e====>', e)
                                                                setFile(e)
                                                                getBase64(e)
                                                                    .then(result => {
                                                                        let RemoveStr = "data:image/jpeg;base64,"
                                                                        setBase64URL(result.substring(RemoveStr.length));
                                                                        setWaterMark({ ...waterMark, WMF22: result.substring(RemoveStr.length) })
                                                                    })
                                                                    .catch(err => {
                                                                        console.log("err=>", err)
                                                                    });
                                                            }}
                                                            accept="image/png,image/jpeg"
                                                            disabled={checked == true ? true : false}
                                                            value={file}
                                                        >
                                                        </FileInput>
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid>

                                            <Grid gutter={4}>
                                                <Grid.Col span={6} >
                                                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Grid.Col span={4}>
                                                            <Text size={12}>Scale</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={8}>
                                                            <PLNumberBox
                                                                disabled={checked == true ? true : false}
                                                                value={waterMark?.WMF24}
                                                                setNumber={(e) => {
                                                                    if (waterMark?.WMF24 < 10 || waterMark?.WMF24 > 500) {
                                                                        GlobalClass.Notify("warning", "Enter Value Between 10 To 500")
                                                                    }
                                                                }}
                                                                setEdit={(e) => {
                                                                    setWaterMark({ ...waterMark, WMF24: e })
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Grid.Col span={4}>
                                                            <Text size={12}>Layout</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={8}>
                                                            <PLComboBox
                                                                disabled={checked == true ? true : false}
                                                                data={COPF44}
                                                                dispexpr={"DisplayMember"}
                                                                valexpr={"ValueMember"}
                                                                value={waterMark?.WMF25}
                                                                setEdit={(e) => {
                                                                    setWaterMark({ ...waterMark, WMF25: e })
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Grid.Col span={4}>
                                                            <Text size={12}>Horizontal</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={8}>
                                                            <PLComboBox
                                                                disabled={checked == true ? true : false}
                                                                data={COPTMH}
                                                                dispexpr={"DisplayMember"}
                                                                valexpr={"ValueMember"}
                                                                value={waterMark?.WMF26 == "D" ? "DDD" : waterMark?.WMF26 == "0" ? "000" : waterMark?.WMF26 == "1" ? "111" : waterMark?.WMF26 == "2" ? "222" : waterMark?.WMF26}
                                                                setEdit={(e) => {
                                                                    // console.log('horizontal val',e)
                                                                    setWaterMark({ ...waterMark, WMF26: e == "DDD" ? "D" : e == "000" ? "0" : e == "111" ? "1" : e == "222" ? "2" : "" })
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                    <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Grid.Col span={4}>
                                                            <Text size={12}>Verical</Text>
                                                        </Grid.Col>
                                                        <Grid.Col span={8}>
                                                            <PLComboBox
                                                                disabled={checked == true ? true : false}
                                                                data={COPF45}
                                                                dispexpr={"DisplayMember"}
                                                                valexpr={"ValueMember"}
                                                                value={waterMark?.WMF27}
                                                                setEdit={(e) => {
                                                                    setWaterMark({ ...waterMark, WMF27: e })
                                                                }}
                                                            />
                                                        </Grid.Col>
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6}>
                                                    {
                                                        waterMark.WMF22 === "" ?
                                                            <><IconPhotoOff size={125} color='gray' /></> : <>
                                                                <Image
                                                                    radius="md"
                                                                    height={120}
                                                                    fit="contain"
                                                                    src={`data:image/jpeg;base64,` + waterMark.WMF22}
                                                                /></>
                                                    }
                                                </Grid.Col>
                                            </Grid>

                                        </Paper>
                                    </Grid.Col>
                                </Grid>

                                <Grid gutter={4}>
                                    <Grid.Col span={12} mt={5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button onClick={() => handleSaveData()}>OK</Button>
                                    </Grid.Col>
                                </Grid>
                            </>
                    }
                </>
            )}
        </>
    )
}

export default WaterMarkList