import { Button, Center, Grid } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLComboBox from '../../PlusComponent/PLComboBox'

const PRUserFieldEntry = (props) => {
  const { T02ObjSend, setT02Objsend, T02UF, DrawerObjSend, setDrawerObjSend } = props
  const [userFieldObj, setUserFieldObj] = useState(null)

  useEffect(() => {
    if (T02ObjSend) {
        setUserFieldObj(T02ObjSend)
    }
}, [T02ObjSend])

const seperate = (e) => {
  var paraArr = e?.split("~C~");
  if(paraArr.length == 1){
    paraArr[1]=paraArr[0];
  }
  // console.log("paraArr=>", paraArr);
  return paraArr;
};

const UserFieldRender = () => {
  let InputField;
  return (
      T02UF?.map((t) => {
          // console.log("t?.S13F14", t?.S13F14);
          // console.log([t?.S13F02] , "UserFieldObj?.[t?.S13F02]")

          switch (t?.S13F14) {
              case "NUMBOX":
                  InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                      <Grid.Col span={8}>
                          <PLNumberBox
                              value={userFieldObj?.[t?.S13F02]}
                              setEdit={(e) => {
                                  setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e })
                              }}

                          />
                      </Grid.Col>
                  </Grid.Col>

                  break;
              case "TEXTBOX":
                  InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                      <Grid.Col span={8}>
                          <PLTextBox
                              value={userFieldObj?.[t?.S13F02]}
                              setEdit={(e) => {
                                  setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e.target.value })
                              }}
                          />
                      </Grid.Col>
                  </Grid.Col>
                  break;
              case "PLPOPUP":
                  InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                      <Grid.Col span={8}>
                          <PLDataGrid
                              value={userFieldObj?.[t?.S13F02]}
                              setEdit={(e) => {
                                  // console.log(e?.FIELD01 , "e?.FIELD01")
                                  setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e?.FIELD01 })
                              }}
                              p1={seperate(t?.S13F09)[1]}

                          />
                      </Grid.Col>
                  </Grid.Col>
                  break;
              case "COMBOBOX":
                  InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                      <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                      <Grid.Col span={8}>
                          <PLComboBox
                              value={userFieldObj?.[t?.S13F02]}
                              setEdit={(e) => {
                                  setUserFieldObj({ ...userFieldObj, [t?.S13F02]: e?.value })
                              }}
                          />
                      </Grid.Col>
                  </Grid.Col>
                  break;
              default:
                  InputField = null
                  break;
          }
          return InputField
      })
  )
}

const SaveUserField = () => {
  // console.log("userFieldObj", userFieldObj);
  Promise.resolve(setT02Objsend(userFieldObj)).then(() => setDrawerObjSend({...DrawerObjSend, open:false}))
}
  return (
    <>
   <Grid gutter={4}>
        {
            UserFieldRender()
        }
        <Center mx="auto">
            <Button
             onKeyUp={(e) => {
                if (e.key == "Enter") {
                    SaveUserField()
                }
            }}
                onClick={() => SaveUserField()}
            >
                OK
            </Button>
        </Center>
    </Grid>
  </>
  )
}

export default PRUserFieldEntry