import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import GlobalClass from '../../utils/GlobalClass'

const LanZoom = (props) => {
    const { obj, setEditSend, value } = props
    console.log('props',props)
    console.log('value', value)
    const LanZoomingData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const { isLoading } = useSelector((state) => state.CompanySetupPopUp)
    const P_M81 = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M81)
    const P_M81DT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_M81DT)
    const dispatch = useDispatch()

    const [multiSelectVal, setMultiSelectVal] = useState([])

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    useEffect(() => {
        if (multiSelectVal && multiSelectVal.length > 0) {
            let Arr = [];
            Arr = multiSelectVal.map((m) => {
                return m.FIELD01
            })
            return setEditSend(Arr.toString())
        }
    }, [multiSelectVal])

    return (
        <div style={{ width: "100%" }}>
            {
                isLoading ? GlobalClass.RenderLoader("dots") :
                    LanZoomingData &&
                    <PLDataGrid
                    dropdownPosition={true}
                        // width={"500px"}
                        TmData={P_M81}
                        data={P_M81DT?.jData}
                        isMulti={true}
                        setEdit={(e) => {
                            console.log('e====>', e)
                            let val = [];
                            if (e.length > 0) {
                                e.map((m) => {
                                    val.push(m.original)
                                })
                                // console.log('val=>', val);
                                return setMultiSelectVal(val)
                            }
                            // console.log('e==>', e)
                            // setEditSend(e.FIELD01)
                        }}
                        value={value}
                    />
            }
        </div>
    )
}

export default LanZoom