import { createSlice } from '@reduxjs/toolkit';

const FilterSlice = createSlice({
    name: "FilterSlice",
    initialState: {
        FiltersName: [],
        isFilterApplied : false
    },
    reducers: {
        getFiltersName: (state, action) => {
            console.log("action", action.payload)

            if (typeof action.payload === 'object' && !Array.isArray(action.payload) && action.payload !== null) {
                const key = Object.keys(action.payload)[0];
                const value = action.payload[key];

                console.log("key", key, "value", value);
                state.FiltersName = { ...state.FiltersName, [key]: value }
            }
            else {
                delete state?.FiltersName[action.payload]
                console.log("else");
                console.log("FiltersName",state.FiltersName);

            }
        },
        getFilterStatus : (state,action) => {
            state.isFilterApplied = action.payload
        }
    },


});
export const { getFiltersName,getFilterStatus} = FilterSlice.actions;
export default FilterSlice.reducer;
