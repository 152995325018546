import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import LocalizedStrings from 'react-localization';


export const GetLanguageData = createAsyncThunk(
    "Language/GetLanguageData",
    async (data) => {
        // console.log("LanguageData");
        // console.log("obj",obj);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cWhere": ""
                });
            const response1 = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetGeneral + "?pa=" + param)
            var response2 = "";
            // console.log("GetLanguageData response1=>>", response1.data.data);
            if (response1?.data?.data !== null) {
                var jsonver = localStorage.getItem("ver");
                var jsondata = localStorage.getItem("lang");
                if (jsonver < response1.data.data.APPJsonVer || !jsonver || !jsondata) {
                    response2 = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetCapJson + "?pa=" + param);
                    console.log("GetLanguageData response2=>>", response2.data.data);
                    if (response2?.data?.data) {
                        localStorage.setItem("lang", JSON.stringify(response2.data.data));
                        localStorage.setItem("ver", JSON.stringify(response1.data.data.APPJsonVer));
                        response2 = response2.data.data
                    }
                    else{
                        if(response2?.data?.status == "FAIL"){
                            return { error: response2?.data?.message }
                        }
                    }

                }
                else {
                    response2 = JSON.parse(localStorage.getItem("lang"));
                }
                //else {
                //     new LocalizedStrings(JSON.parse(localStorage.getItem("en")))
                // }
            }
            else{
                if(response1?.data?.status == "FAIL"){
                    return { error: response1?.data?.message }
                }
            }
            return [response1.data.data, response2];

        } catch (error) {
            console.error(error);
            return { error:"Network Error!!" }
        }
    }
);


const LanguageSlice = createSlice({
    name: 'Language',
    initialState: {
        General: [],
        LanguageData: [],
        isLoading: true,
        hasError: false,
        ErrorMsg:""
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetLanguageData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetLanguageData.fulfilled, (state, action) => {
                // console.log("LanguageData fulfilled==>", action.payload);
                if (!action.payload.error) {
                    state.General = action.payload[0];
                    state.LanguageData = action.payload[1];
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.General = [];
                    state.LanguageData = [];
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg=action.payload?.error

                }

            })
            .addCase(GetLanguageData.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
    }
})

export default LanguageSlice.reducer