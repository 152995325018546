import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";

export const GetOpeningStockTM = createAsyncThunk(
  "OpeningStockTM/GetOpeningStockTM",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cRepId": "TXNVOULISTHO",
        'cFmtID':  "TFORM0000001",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

const OpeningStockSliceTM = createSlice({
  name: "OpeningStockTM",
  initialState: {
    OpeningStockDataTM: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetOpeningStockTM.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.OpeningStockDataTM = [];
      })
      .addCase(GetOpeningStockTM.fulfilled, (state, action) => {
        // console.log('GetStockRate=>',action.payload);
        state.OpeningStockDataTM = action.payload;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetOpeningStockTM.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});
export default OpeningStockSliceTM.reducer;
