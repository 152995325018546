// import APIInterface from "./APIInterface";

// import { useSelector } from "react-redux";
import Store from "./store";

var gensetting = (function () {
    // var corpid = 'DEVSERVER';
    // var cuser = 'prashant';
    // var cpass = 'a@123';
    // var cmpno = '6001'; //'4';

    const queryString = window.location.search;
    //const urlParams = new URLSearchParams(queryString);
    var cmp = queryString.replace('?cmpno=', '');

    //const cmp = new URLSearchParams(search).get('cmpno');
    //    if(queryString !== ''){
    //        APIInterface.MAINURL = "http://localhost:50300/api/";
    //    }
    var getcmpId = function () {
        const CmpNumber = Store.getState().UserList.CmpNumber;
        return CmpNumber ? CmpNumber : '1';    // Or pull this from cookie/localStorage
    };

    // console.log("corpid=======>",localStorage.getItem('corpid'));
    //     var corpid =  localStorage.getItem('corpid') ?? ''; 
    //     var cuser = localStorage.getItem('cuser') ?? 'SUPERVISOR';
    //     var cpass = localStorage.getItem('cpass') ?? 'a@123';
    var cmpno = getcmpId(); //'4';  

    var corpid;
    var cuser;
    var cpass;

    var getcorpid = function () {
        corpid = localStorage.getItem('corpid')
        return corpid;    // Or pull this from cookie/localStorage
    };

    var setcorpid = function (cid) {
        corpid = cid;
    };

    var getcuser = function () {
        cuser = localStorage.getItem('cuser')
        return cuser;    // Or pull this from cookie/localStorage
    };

    var setcuser = function (cu) {
        cuser = cu;
    };

    var getcpass = function () {
        cpass = localStorage.getItem('cpass')
        return cpass;    // Or pull this from cookie/localStorage
    };

    var setcpass = function (cp) {
        cpass = cp;
    };

    var getcmpno = function () {
        const CmpNumber = Store.getState().UserList.CmpNumber;
        return CmpNumber ? CmpNumber : '1';    // Or pull this from cookie/localStorage
        // return cmpno;    // Or pull this from cookie/localStorage
    };

    var setcmpno = function (no) {
        cmpno = no;
    };


    return {
        getcorpid: getcorpid,
        setcorpid: setcorpid,
        getcuser: getcuser,
        setcuser: setcuser,
        getcpass: getcpass,
        setcpass: setcpass,
        getcmpno: getcmpno,
        setcmpno: setcmpno
    }
})();

export default gensetting;
