import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const GetAreaList = createAsyncThunk(
  "AreaList/GetAreaList",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        cOPara: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM31AR + "?pa=" + param
      );
      // console.log("response", response);
      return response;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
export const DeleteAreaData = createAsyncThunk(
  "AreaList/DeleteAreaList",
  async (obj) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        cOPara: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM31AR + "?pa=" + param
      );
      // console.log("response  ", response);
      if (response.data.status === "SUCCESS") {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;

        Store.dispatch(
          GetAccountList({
            id: "01350123",
            name: "Area List",
            p0: "MT",
            p1: "T_M31A",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["01350123"],
          })
        );
        GlobalClass.Notify("success", "Deleted", "Deleted successfully");
   
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);
      return { e };
    }
  }
);
const AreaListSlice = createSlice({
  name: "AreaList",
  initialState: {
    AreaListData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAreaList.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.AreaListData = [];
      })
      .addCase(GetAreaList.fulfilled, (state, action) => {
        state.AreaListData = action.payload;
        // console.log("action.payload", action.payload);
        // console.log("state.AreaListData", state.AreaListData);
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetAreaList.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(DeleteAreaData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(DeleteAreaData.fulfilled, (state, action) => {
        state.successMsg = action.payload.status;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(DeleteAreaData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});
export default AreaListSlice.reducer;
