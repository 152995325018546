import React from 'react'
import Store from '../../../utils/store';
import CreditDebitNoteForm from './CreditDebitNoteForm';
import { ModalFunction } from '../../../utils/slices/ModalSlice';
import { Text } from '@mantine/core';
import GlobalClass from '../../../utils/GlobalClass';
import { TransactionDeleteApi } from '../../../utils/TransactionSlices/Transaction';
import { GetInvTypeDropDown } from '../../../utils/TransactionSlices/TSalesSlices';

export function VouEntAct(itemOBJ) {
  const OBJ = JSON.parse(itemOBJ);
  // console.log('Credit/Debit Note Object===>', OBJ);
  if (OBJ.p0 === "A") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null))
        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position:"right",
        MBody: (i) => (
          <CreditDebitNoteForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  } else if (OBJ.p0 === "E" && OBJ.id) {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          // Deval (For Company Setup)
          Store.dispatch(GetInvTypeDropDown(null))
        },
        onclose: () => { },
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "98%",
        position:"right",
        MBody: (i) => (
          <CreditDebitNoteForm obj={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
  }
   else if (OBJ.p0 === "D" && OBJ.id) {
    if (window.confirm('Confirm to Delete ?')) {
     
       Store.dispatch(TransactionDeleteApi(OBJ))
    }
  } else {
    return null
  }
}