import React, { useEffect, useState, createContext } from 'react';
import { createStyles, Navbar, UnstyledButton, Tooltip, Title, rem, Divider, NavLink, ScrollArea, Text, Image, Drawer, MediaQuery, Modal } from '@mantine/core';
import {
  IconDeviceDesktopAnalytics,
  IconSettings,
  IconBooks,
  IconFileInvoice,
  IconReport,
  IconLogout,
  IconTool,
  IconFingerprint
} from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';

import { ClearTabs, TabAdd, UpdateSelectedMenu } from '../utils/slices/TabList';
import BindFunWithPara from '../MyComponent/BindFunWithPara';
import { modals } from '@mantine/modals';
import DataGrid from '../components/DataGrid';
import MyComponent from '../MyComponent/MyComponent';
import YearAndDate from '../MyComponent/YearAndDate';
import Localize from '../utils/rc';
import { ModalFunction } from '../utils/slices/ModalSlice';
import { GetCmpSetUpObj, GetReportIdArray, GetTransactionReportIdArray } from '../utils/slices/DataSaveLoadingSlice';
import Store from '../utils/store';
import useWindowDimensions from '../utils/UseWindowDimensions'
import App from "../App.json"
import { FooterSocial } from './footer';

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
  },

  aside: {
    flex: `0 0 ${rem(60)}`,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRight: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]
      }`,
  },

  main: {
    flex: 1,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },
  menuList: {
    // height: rem(500),
    // border: '1px solid red',
    overflow: 'hidden',
    overflowY: 'scroll'
  },

  mainLink: {
    // width: rem(40),
    height: rem(40),
    borderRadius: theme.radius.md,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
  },

  mainLinkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
  },

  title: {
    boxSizing: 'border-box',
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xl,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    padding: theme.spacing.md,
    paddingTop: rem(18),
    height: rem(60),
    borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
  },

  logo: {
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: rem(60),
    paddingTop: theme.spacing.md,
    borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
    marginBottom: theme.spacing.xl,
  },

  link: {
    boxSizing: 'border-box',
    display: 'block',
    textDecoration: 'none',
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    padding: `0 ${theme.spacing.md}`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: rem(26),
    lineHeight: rem(26),

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    },
  },

  linkActive: {
    '&, &:hover': {
      borderLeftColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
        .background,
      backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor })
        .background,
      color: theme.white,
    },
  },
}));

export const NavbarName = createContext('');

export default function Sidebar(props: any) {
  interface menuArray {
    name?: string,
    icon?: React.ElementType,
    id?: string,
    type?: string,
    capid?: string,
    items?: any
  }
  const { classes, cx } = useStyles();
  const selected = useSelector((state: any) => state.TabList.selected);
  const Menuarray = useSelector((state: any) => state.UserList.MenuListData);
  const { height } = useWindowDimensions();
  const [active, setActive] = useState(selected);
  const [activeLink, setActiveLink] = useState('Settings');
  const [MainMenu, setMainMenu] = useState<menuArray[]>([]);
  const [SubMenuarray, setSubMenuarray] = useState<menuArray[]>([]);

  let dra = {
    title: null,
    size: "80%",
    body: null,
    open: false,
    onClickCloseBtn: () => { },
    position: null,
    onclose: () => { }
  }

  const [DrawerObj, setDrawerObj] = useState(dra);

  const dispatch = useDispatch();

  const mainLinks = () => MainMenu.map((link) => {
    var CustomIcon: React.ElementType = link.icon;
    if (link.name.includes("Separator")) {
      return (<Divider key={link.id} my="sm" variant="dashed" />)
    }
    return (<Tooltip
      label={link.name}
      position="right"
      withArrow
      transitionProps={{ duration: 200 }}
      key={link.name}
    >
      <UnstyledButton
        onClick={() => {
          setActive(link.name);
          setSubMenuarray(link.items)

          if (link.id == "00030000") {
            SidebarObjSave(link.id, link.items)

            // let AarrayList = [];
            // if (link.items) {
            //   let array1 = [];
            //   let array2 = [];
            //   let array3 = [];
            //   link.items.map((l1) => {
            //     array1.push(l1.id)
            //     if (l1.items) {
            //       l1.items.map((l2) => {
            //         array2.push(l2.id)
            //         if (l2.items) {
            //           l2.items.map((l3) => {
            //             array3.push(l3.id)
            //           })
            //         }
            //       })
            //     }
            //   })
            //   // console.log("array1=>",array1);
            //   // console.log("array2=>",array2);
            //   // console.log("array3=>",array3);
            //   AarrayList = [...array1, ...array2, ...array3]
            //   console.log("Report AarrayList=>",AarrayList);

            // }
            // Store.dispatch(GetReportIdArray(AarrayList))
            // Store.dispatch(GetTransactionReportIdArray(AarrayList))
          } else if (link.id == "00020000") {

            SidebarObjSave(link.id, link.items)

            // let AarrayList = [];
            // if (link.items) {
            //   let array1 = [];
            //   let array2 = [];
            //   let array3 = [];
            //   link.items.map((l1) => {
            //     array1.push(l1.id)
            //     if (l1.items) {
            //       l1.items.map((l2) => {
            //         array2.push(l2.id)
            //         if (l2.items) {
            //           l2.items.map((l3) => {
            //             array3.push(l3.id)
            //           })
            //         }
            //       })
            //     }
            //   })
            //   // console.log("array1=>",array1);
            //   // console.log("array2=>",array2);
            //   // console.log("array3=>",array3);
            //   AarrayList = [...array1, ...array2, ...array3]
            //   console.log("Transaction AarrayList=>",AarrayList)
            // }
            // Store.dispatch(GetTransactionReportIdArray(AarrayList))
          }
        }}
        className={cx(classes.mainLink, { [classes.mainLinkActive]: link.name === active })}
      >
        <CustomIcon size="1.5rem" stroke={2} />
      </UnstyledButton>
    </Tooltip>)
  });
  let ReportArray = [];
  let ReportAarrayList = [];
  let TransactionArray = [];
  let TransactionArrayList = [];
  const SidebarObjSave = (id, items) => {
    switch (id) {
      case "00030000":
        if (items) {
          items.map((l1) => {
            ReportArray.push(l1.id)
            if (l1.items) {
              return SidebarObjSave(id, l1.items)
            }
          })
          // console.log("ReportArray=>", ReportArray);
          ReportAarrayList = [...ReportArray]
          // console.log("AarrayList=>", ReportAarrayList);
        }
        Store.dispatch(GetReportIdArray(ReportAarrayList))
        Store.dispatch(GetTransactionReportIdArray(ReportAarrayList))
        break;
      case "00020000":
        if (items) {
          items.map((l1) => {
            TransactionArray.push(l1.id)
            if (l1.items) {
              return SidebarObjSave(id, l1.items)
            }
          })
          // console.log("TransactionArray=>", TransactionArray)
          TransactionArrayList = [...TransactionArray]
          // console.log("TransactionArrayList=>", TransactionArrayList)
        }
        Store.dispatch(GetTransactionReportIdArray(TransactionArrayList))
        break;

      default:
        break;
    }

  }

  useEffect(() => {
    if (Menuarray) {
      setMainMenu(Menuarray);
    }
  }, [Menuarray])
  useEffect(() => {
    if (MainMenu && MainMenu?.length > 0 && SubMenuarray?.length == 0) {
      setActive(MainMenu[0].name);
      setSubMenuarray(MainMenu[0].items)
    }
  }, [MainMenu])
  useEffect(() => {
    if (selected !== activeLink) {
      setActiveLink(selected)
    }
  }, [selected, activeLink])
  const [setupDrawer,setDrawer] = useState({
    opened:false,
    body:null,
    title:null,
  })
  const [vouDrawer,setVDrawer] = useState({
    opened:false,
    body:null,
    title:null,
  })
  const renderLinks = (data) => {
    return data.map((item, i) => {
      if (item.name.includes("Separator")) {
        return <Divider key={item.id} my="sm" variant="dashed" />
      }
      else {
        return <NavLink
          key={item.id}
          label={item.name}
          active={activeLink === item.id}
          childrenOffset={20}
          onClick={(event) => {
            if (item.items) {
              return
            }
            event.preventDefault();
            setActiveLink(item.id);
            if (item.capid == "_MNU0114" || item.capid == "_MNU0115") { // Company Setup
              Promise.resolve(dispatch(ClearTabs(''))).then(() => {
                dispatch(GetCmpSetUpObj(null))
                var obj = {
                  id: item.capid,
                  name: item.name,
                  type: item.type,
                  p0:item
                }
                Store.dispatch(TabAdd(obj)); 
                // setDrawerObj({
                //   ...DrawerObj,
                //   title: <Text fw={700}>{link.name}</Text>,
                //   body: <MyComponent obj={link} />,
                //   onClickCloseBtn: () => {
                //     setDrawerObj(dra)
                //     Store.dispatch(GetCmpSetUpObj(null))
                    
                //   },
                //   onclose: () => {
                //     setDrawerObj(dra)
                //     Store.dispatch(GetCmpSetUpObj(null))
                    
                //   },
                //   open: true,
                //   position: "right"
                // })
              })
            } 
            // else if (link.capid == "_MNU0115") { //Voucher Setup
            //   console.log("call Voucher setup");
            //   Promise.resolve(dispatch(ClearTabs(''))).then(() => {
  
            //     setDrawerObj({
            //       ...DrawerObj,
            //       title: <Text fw={700}>{link.name}</Text>,
            //       body: <MyComponent obj={link} />,
            //       onClickCloseBtn: () => {
            //         setDrawerObj(dra)
            //         Store.dispatch(GetCmpSetUpObj(null))
                    
            //       },
            //       onclose: () => {
            //         setDrawerObj(dra)
            //         Store.dispatch(GetCmpSetUpObj(null))
                    
            //       },
            //       open: true,
            //       position: "right"
            //     })
            //   })
            // }
             else {
              BindFunWithPara(item);
            }
            props.drawerClose()
          }}

        >
          {item.items && renderLinks(item.items)}
        </NavLink>
      }
    })
  }
  return (<>

    {DrawerObj?.open && <Modal
      opened={DrawerObj?.open}
      // onClose={close}
      withCloseButton={true}
      // overlayProps={{ opacity: 0.5, blur: 4 }}
      closeButtonProps={{
        onClick: (e) => {
            setDrawerObj(dra);
            Store.dispatch(GetCmpSetUpObj(null))
        },
      }}
      title={DrawerObj?.title}
      size={DrawerObj?.size}
      // centered={.Centered}
      closeOnClickOutside={true}
      // overlayProps={m.Overlay}
      // position={DrawerObj?.position}
      onClose={() => { setDrawerObj(dra);Store.dispatch(GetCmpSetUpObj(null)); }}
    // scrollAreaComponent={ScrollArea.Autosize}
    >
      {DrawerObj?.body}
    </Modal>}
    {/* <MediaQuery largerThan="sm" styles={{ display: 'none' }}> */}
    {props.opened && <Navbar p={0} width={{ sm: 200, lg: 250 }}>
      <Navbar.Section grow className={classes.wrapper}>
        <div className={classes.aside}>
          <div className={classes.logo}>
            {/* <MantineLogo type="mark" size={30} /> */}
            {/* <Image
              src={'favicon01.png'}
              height={30}
              /> */}
          </div>
          {Menuarray?.length > 0 && mainLinks()}
        </div>
        <div className={classes.main}>
          <Title order={4} className={classes.title}>
            {active}
          </Title>
          <div className={classes.menuList} style={{ height: height * 0.80 }}>
            {SubMenuarray?.length > 0 && renderLinks(SubMenuarray)}
          </div>
          <FooterSocial />
        </div>
      </Navbar.Section>
    </Navbar>}
    {/* </MediaQuery> */}
  </>);
}