import React from 'react'
import Store from '../../utils/store'
import ProductionForm from './ProductionForm'
import { ModalFunction } from '../../utils/slices/ModalSlice'
import { Text } from '@mantine/core'
import GlobalClass from '../../utils/GlobalClass'
import { TransactionDeleteApi } from '../../utils/TransactionSlices/Transaction'
import ProductionQuickEntryForm from './ProductionQuickEntryForm'

export function VouEntAct (data) {
    const OBJ = JSON.parse(data)
    // console.log('production OBJ=>>',OBJ)
    if(OBJ.p0 == "A"){
        Store.dispatch(
            ModalFunction({
              onclose: () => { },
              MTitle: <Text fw={700}>{OBJ?.text}</Text>,
              MAction: true,
              MSize: "80%",
              position:'right',
              MBody: (i) => (
                <ProductionForm obj={OBJ} index={i} />
              ),
              MClose: true,
              Overlay: {
                backgroundOpacity: 0.8,
                blur: 5,
              }
            })
          )
    } else if(OBJ.p0 == "Q"){
      Store.dispatch(
        ModalFunction({
          onclose: () => { },
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "80%",
          position:'right',
          MBody: (i) => (
            <ProductionQuickEntryForm obj={OBJ} index={i} />
          ),
          MClose: true,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          }
        })
      )
    } else if (OBJ.p0 == 'E' && OBJ.id){
      Store.dispatch(
        ModalFunction({
          onclose: () => { },
          MTitle: <Text fw={700}>{OBJ?.text}</Text>,
          MAction: true,
          MSize: "80%",
          position:'right',
          MBody: (i) => (
            <ProductionForm obj={OBJ} index={i} />
          ),
          MClose: true,
          Overlay: {
            backgroundOpacity: 0.8,
            blur: 5,
          }
        })
      )
    } else if (OBJ.p0 == 'D' && OBJ.id) {
      if (window.confirm('Confirm to Delete ?')) {
         Store.dispatch(TransactionDeleteApi(OBJ))
      }
    } else {
      return null
    }
}