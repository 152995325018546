import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";
import Store from "../store";
import { GetAccountList } from "./AccountListSlice";

export const GetCityList = createAsyncThunk(
  "CityList/GetCityList",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        cOPara: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM31CT + "?pa=" + param
      );
      // console.log("response", response);
      return response;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);
export const DeleteCityData = createAsyncThunk(
  "CityList/DeleteCityList",
  async (obj) => {
    GlobalClass.Notify(
      "info",
      "Deleting",
      "Please wait while we process your data"
    );
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.cAction ? obj.cAction : "A",
        cCode: obj?.cCode ? obj.cCode : "",
        cOPara: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM31CT + "?pa=" + param
      );
      // console.log("response  ", response);
      if (response.data.status === "SUCCESS") {
        let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;

        Store.dispatch(
          GetAccountList({
            id: "01340123",
            name: "City List",
            p0: "MT",
            p1: "T_M31T",
            p2: "",
            p3: "",
            type: "A",
            pagination: PaginationObj["01340123"],
          })
        );
        GlobalClass.Notify("success", "Deleted", "Deleted successfully");
      } else {
        GlobalClass.Notify(
          "error",
          response?.data?.status,
          response?.data?.message
        );
      }
      return response.data;
    } catch (e) {
      GlobalClass.Notify("error", "Error", `${e?.message}`);

      return { e };
    }
  }
);
const CityListSlice = createSlice({
  name: "CityList",
  initialState: {
    CityListData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetCityList.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.CityListData = [];
      })
      .addCase(GetCityList.fulfilled, (state, action) => {
        state.CityListData = action.payload;
        // console.log("action.payload", action.payload);
        // console.log("state.CityListData", state.CityListData);
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(GetCityList.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      })
      .addCase(DeleteCityData.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(DeleteCityData.fulfilled, (state, action) => {
        state.successMsg = action.payload.status;
        state.isLoading = false;
        state.hasError = false;
      })
      .addCase(DeleteCityData.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
      });
  },
});
export default CityListSlice.reducer;
