import React, { useRef } from "react";
import {
  Grid,
  Button,
  Flex,
  Skeleton,
  Center,
  Tooltip,
  ActionIcon,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  VDeleteSalesData,
  VGetSalesData,
} from "../../utils/slices/VSalesSlice";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_TableOptions,
} from "mantine-react-table";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { VGetSalesEntryData } from "../../utils/slices/VSalesEntrySlice";
import Store from "../../utils/store";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { notifications } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons-react";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import useWindowDimensions from "../../utils/UseWindowDimensions";

const SalesSetupNew = (props) => {
  const formRef = useRef();
  const [selectedDropdownValue, setSelectedDropdownValue] = useState([]);
  const [ccode, setccode] = useState("");
  const [data, setData] = useState([]);
  const [updatedColumn, setUpdatedColumn] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [editClicked, setEditClicked] = useState();
  const [ent, setEnt] = useState("");
  const [editObject, setEditObject] = useState();
  const VSalesData = useSelector((state) => state.VSalesData?.VSalesData?.data);
  const isLoading = useSelector((state) => state.VSalesData?.isLoading);
  const hasError = useSelector((state) => state.VSalesData?.hasError);
  const ErrorMsg = useSelector((state) => state.VSalesData?.ErrorMsg);
  const isLoading1 = useSelector((state) => state.VSalesData?.isLoading1);
  const hasError1 = useSelector((state) => state.VSalesData?.hasError1);
  const ErrorMsg1 = useSelector((state) => state.VSalesData?.ErrorMsg1);
  const isLoadingEnt = useSelector((state) => state.VSalesEntryData?.isLoading);
  const hasErrorEnt = useSelector((state) => state.VSalesEntryData?.hasError);
  const ErrorMsgEnt = useSelector((state) => state.VSalesEntryData?.ErrorMsg);
  const VSalesEntryData = useSelector(
    (state) => state.VSalesEntryData?.VSalesEnrtyData?.data?.data
  );
  console.log("VSalesEntryData", VSalesEntryData);
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const myComboBox = document.getElementById("#myComboBox");
  const { height } = useWindowDimensions();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(VGetSalesData(props.obj));
  }, []);

  useEffect(() => {

    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e)=>GlobalClass.formNextInput(e,formRef));
      }
    };
  }, [formRef]);

  useEffect(() => {
    Promise.resolve().then(() => {
      if (VSalesEntryData?.M52) {
        setEditObject({
          ...VSalesEntryData?.M52,
          FIELD02:
            props.obj.id === "00780072" || props.obj.id === "00630057"
              ? "S"
              : props.obj.id === "00870072"
              ? "Z"
              : props.obj.id === "00840072"
              ? "J"
              : "P",
          FIELD03: ent?.FIELD03,
          FIELD04: selectedDropdownValue,
          FIELD05: "G000030G",
        });
      }
    });
  }, [VSalesEntryData]);


  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);


  useEffect(() => {
    if (hasError1 && !isLoading1) {
      GlobalClass.Notify(
        "error",
        "Error: " + ErrorMsg1.code,
        ErrorMsg1.message
      );
    }
  }, [hasError1]);

  useEffect(() => {
    if (hasErrorEnt && !isLoadingEnt) {
      GlobalClass.Notify(
        "error",
        "Error: " + ErrorMsgEnt.code,
        ErrorMsgEnt.message
      );
    }
  }, [hasErrorEnt]);

  useEffect(() => {
    if (ent != "") {
      // console.log("selectedDropDownValue", selectedDropdownValue);
      // console.log("ent", ent);
      let FIELD02ent;
      Promise.resolve(
        (FIELD02ent = props.obj.id === "00780072" || props.obj.id === "00630057"
            ? "S"
            : props.obj.id === "00870072"
            ? "Z"
            : props.obj.id === "00840072"
            ? "J"
            : "P")
      )
        .then(() => {
          let temp = FIELD02ent.concat(
            ent?.FIELD03,
            selectedDropdownValue,
            "G000030G"
          );
          return temp;
        })
        .then((temp) => {
          console.log("inside setccode",temp);
          setccode(temp);
        })
    
        
    }
  }, [ent]);

useEffect(()=>{  
  console.log("inside useeffect outside");
  console.log("editClicked",editClicked)
  if(editClicked==true){
    console.log("inside useeffect");
     setEditClicked(false);
     Store.dispatch(
    VGetSalesEntryData({ ccode: ccode, obj: props.obj, ent: ent})
  );
}},[ccode])

useEffect(()=>{
  if(table?.getSelectedRowModel()?.flatRows.length > 0)
  {data?.map((t, index) => {
    if (index in rowSelection) {
      //  onkeydown=((e)=>{
      document.addEventListener("keydown", (e) => {
        
        if (e.key == "Enter") {
          Promise.resolve().then(()=>{
            table.setCreatingRow(null);
          }).then(() => {
            Promise.resolve(setEditClicked(true)).then(()=>{
           
            table.setEditingRow(table?.getSelectedRowModel()?.flatRows[0]);
            setEnt(table?.getSelectedRowModel()?.flatRows[0]?.original);
            })
          });
        } 
        else if (e.key == "Escape") {
            table.setEditingRow(null);
            table.setCreatingRow(null);
          
        }
      });
      // })
    }
  });}
},[rowSelection])

  let arr = [];
  let column = [];
  var FIELD22;
  var FIELD07;
  var FIELD13;
  const columnHelper = createColumnHelper();
  useEffect(() => {
    if (VSalesData && selectedDropdownValue) {
      FIELD22 = VSalesData?.M01?.jData.FIELD22;
      FIELD07 = VSalesData?.M01?.jData.FIELD07;
      FIELD13 = VSalesData?.M01?.jData.FIELD13;
      VSalesData?.M51?.map((e) => {
        VSalesData?.M52?.map((d) => {
          if (e.FIELD01 === d.FIELD03) {
            arr.push({ M52F03NM: e.FIELD02, ...d });
          }
        });
      });
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]?.FIELD04 != selectedDropdownValue) {
          VSalesData?.M51?.map((e) => {
            VSalesData?.M52?.map((d) => {
              if (e.FIELD01 === arr[i].FIELD03) {
                arr[i] = {M52F03NM: e.FIELD02, ...e };
              }
            });
          });
        }
      }

      setData(arr);
    }
  }, [selectedDropdownValue]);

  useEffect(() => {
    setSelectedDropdownValue(VSalesData?.M52INV[0]?.FIELD01);
  }, [VSalesData]);

  useEffect(() => {
    VSalesData?.M52Grd?.filter((item) => item.S53F00 == "C")?.map((a, i) => {
      column.push(
        columnHelper?.accessor(
          a?.S53F03 == "Product GST Slab" ? "M52F03NM" : a?.S53F04,
          {
            header: a?.S53F03,
            size: 150,
            id: a?.S53UID,
            enableColumnFilter: false,
            enableSorting: false,
            enableGrouping: false,
            enableColumnActions: false,
            Edit: ({ cell, column, table, row }) => {
              let val = cell.getValue();
              return renderValue(a, val, row, column, i);
            },
          }
        )
      );
    });
    // console.log("column", column);
    setUpdatedColumn(column);
  }, [VSalesData?.M52Grd, data, editObject]);

  // console.log("ent", ent);

  const renderValue = (ele, val, row, column, i) => {
    var editor = null;
    // console.log("EDITobJECT", editObject);
    // console.log("[ele.S53F04]", [ele.S53F04]);
    if (column?.columnDef?.header == "Product GST Slab") {
      return val;
    }
    // if (ele.S53F03 === column?.columnDef?.header) {
    switch (ele?.S53F14) {
      case "PLPOPUP":
        editor = (
          <PLDataGrid
          onKeyDown={(e)=>{
            GlobalClass.formNextInput(e,formRef);
          }}
            position="target"
            width={"470px"}
            height={"28px"}
            value={editObject?.[ele.S53F04]}
            p1={seperate(ele.S53F15)[0]}
            setEdit={(e) => {
              // console.log("e of datagrid", ele.S53F04);
              setEditObject({ ...editObject, [ele.S53F04]: e?.FIELD01 });
            }}
          />
        );

        break;
      case "NUMBOX":
        editor = (
          <PLNumberBox
          onKeyDown={(e)=>{
            GlobalClass.formNextInput(e,formRef);
          }}
            value={editObject?.[ele.S53F04]}
            setEdit={(e) => {
              setEditObject({ ...editObject, [ele.S53F04]: e });
            }}
          />
        );
        break;

      case "COMBOBOX":
        editor = (
          <PLComboBox
          onKeyDown={(e)=>{
            GlobalClass.formNextInput(e,formRef);
          }}
            copno={seperate(ele?.S53F15)[1]}
            dispexpr="DisplayMember"
            valexpr="ValueMember"
            value={editObject?.[ele.S53F04]}
            setEdit={(e) => {
              setEditObject({ ...editObject, [ele.S53F04]: e?.value });
            }}
          />
        );

        break;
      case "TEXTBOX":
        editor = (
          <PLTextBox
          onKeyDown={(e)=>{
            GlobalClass.formNextInput(e,formRef);
          }}
            value={editObject?.[ele.S53F04]}
            setEdit={(e) => {
              setEditObject({ ...editObject, [ele.S53F04]: e?.target?.value });
            }}
          />
        );
        break;
      default:
        return null;
    }
    // }

    return editor;
  };

  const rowSaveHandler = () => {
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      "Editing",
      "Please wait while we process your data"
    );
    var newData = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction:
        // props.obj.id === "00810072" ||
        // props.obj.id === "00630057" ||
        // props.obj.id === "00660057" ||
        // props.obj.id === "00840072" ||
        // props.obj.id === "00870072"
        //   ? "E":
        "A",
      cCode: ccode,
      cOPara:
        props.obj.id === "00810072"
          ? "G~C~P"
          : props.obj.id === "00630057"
          ? "V~C~S"
          : props.obj.id === "00840072"
          ? "G~C~J"
          : props.obj.id === "00870072"
          ? "G~C~Z"
          : props.obj.id === "00780072"
          ? "G~C~S"
          : "V~C~P",
      cSData: JSON.stringify(editObject),
    };

    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: "A",
      cCode: ccode,
      cOPara:
        props.obj.id === "00810072"
          ? "G~C~P"
          : props.obj.id === "00630057"
          ? "V~C~S"
          : props.obj.id === "00840072"
          ? "G~C~J"
          : props.obj.id === "00870072"
          ? "G~C~Z"
          : props.obj.id === "00780072"
          ? "G~C~S"
          : "V~C~P",
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(newData),
    };

    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostM52Ent + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log("api data", data);
        // console.log("api data status", data?.status);
        if (data.status === "SUCCESS") {
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => Store.dispatch(VGetSalesData(props?.obj)))
            .then(() => {
              GlobalClass.Notify("success", "Edited", "Edited successfully");
            });
        } else {
          GlobalClass.Notify("error", data?.status, data?.message);
          dispatch(DataSaveLoading(false));
        }
      })
      .catch((e) => {
        GlobalClass.Notify("error", "Error", `${e?.message}`);
        dispatch(DataSaveLoading(false));
      });
    // }
    table?.setEditingRow(null);
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure to delete selected data?")) {
      Store.dispatch(VDeleteSalesData({ ccode: ccode, obj: props.obj }));
    } else {
      return null;
    }
  };
  const seperate = (e) => {
    // console.log("seperate=>", e);
    var paraArr = e.split("~C~");
    paraArr[1] = paraArr[1] + "";
    // console.log("paraArr", typeof paraArr[1]);
    var paraArrSep = paraArr[1].split("~R~");
    // console.log("paraArrSep==>", paraArrSep);
    return paraArrSep;
  };
  const comboBoxFocus = () => {
    myComboBox && myComboBox.focus();
  };

  // console.log("Data", data);
  // console.log("updatedColumn", updatedColumn);
  const initialStateJW = {
    "Product GST Slab": true,
    "Sale/Purc. A/c": true,
  };
  const initialStateGST = {
    "Sale/Purc. A/c": !(
      (FIELD22 === "V" || FIELD22 === "C") &&
      (FIELD07 === "TS" ||
        FIELD07 === "IN" ||
        FIELD07 === "TI" ||
        FIELD07 === "JP" ||
        FIELD07 === "PT" ||
        FIELD07 === "JE" ||
        FIELD07 === "JT")
    ),
    "CGST A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
    "SGST A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
    "IGST A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
    "CESS A/c.":
      !(
        (FIELD22 === "V" || FIELD22 === "C") &&
        (FIELD07 === "TS" || FIELD07 === "TP")
      ) && FIELD07 === "TX",
  };
  const initialStateVAT = {
    "Sale/Purc. A/c": !(
      FIELD22 === "3" &&
      FIELD22 === "4" &&
      (FIELD07 === "TS" ||
        FIELD07 === "IN" ||
        FIELD07 === "TI" ||
        FIELD07 === "JP" ||
        FIELD07 === "PT" ||
        FIELD07 === "JE" ||
        FIELD07 === "JT")
    ),
    "Tax A/C":
      !(
        FIELD22 === "3" ||
        (FIELD22 === "4" && !FIELD13 === "99" && FIELD22 === "V")
      ) && FIELD07 === "TX",
    "%":
      !(
        FIELD22 === "3" ||
        (FIELD22 === "4" && !FIELD13 === "99" && FIELD22 === "V")
      ) && FIELD07 === "TX",
    "Addi. Tax A/c.":
      !(
        FIELD22 === "3" ||
        (FIELD22 === "4" && !FIELD13 === "99" && FIELD22 === "V")
      ) && FIELD07 === "TX",
  };

  const table = useMantineReactTable({
    columns: updatedColumn ? updatedColumn : [],
    data: data ? data : [],
    enableFilters: false,
    enableSorting: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enablePagination: true,
    enableGlobalFilter: false,
    enableColumnFilters: false,
    enableColumnActions: false,
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    editDisplayMode: "row",
    enableEditing: true,
    createDisplayMode: "row",
    enableSelectAll: false,
    enableRowSelection: false,
    enableMultiRowSelection: false,
    onEditingRowSave: rowSaveHandler,
    enableStickyHeader: true,
    state: {
      rowSelection,
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        // console.log("Hello from selection");
        setRowSelection((prev) => ({
          [row?.id]: !prev[row?.id],
        }));
        setEnt(row?.original);
      },
      selected: rowSelection[row?.id],
      sx: {
        cursor: "pointer",
      },
    }),
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              Promise.resolve(
                setEditClicked(true)).then(()=>{
                setEnt(row?.original);
              }).then(()=>{
                  table.setEditingRow(row);
              })
              
            }}
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    initialState: {
      columnVisibility:
        props?.obj?.id == "00780072" || props?.obj?.id == "00810072"
          ? initialStateGST
          : props?.obj?.id == "00660057" || props?.obj?.id == "00630057"
          ? initialStateVAT
          : initialStateJW,
      density: "0px",
    },
  });
  return (
    <div>
      {isLoading || isLoading1 || SaveLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          <Grid gutter={4}>
            <Grid.Col span={12} mb={5} style={{ display: "flex" }}>
              <>
                <Grid.Col span={2}>
                  <Text style={{ fontSize: "14px" }}>Invoice Type</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <PLComboBox
                    cmbid="#myComboBox"
                    data={VSalesData?.M52INV}
                    value={selectedDropdownValue}
                    dispexpr="FIELD02"
                    valexpr="FIELD01"
                    setEdit={(e) => {
                      setSelectedDropdownValue(e);
                    }}
                  />
                </Grid.Col>
              </>
            </Grid.Col>
            <Grid.Col span={12} ref={formRef}>
              <MantineReactTable table={table} striped={true} />
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button onClick={comboBoxFocus}>Invoice Type</Button>
              <Button style={{ marginLeft: "10px" }} onClick={handleDelete}>
                Delete
              </Button>
            </Grid.Col>
          </Grid>
        </>
      )}
    </div>
  );
};

export default SalesSetupNew;
