const openDB = () => {
    return new Promise((resolve, reject) => {
        const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;
        const request = indexedDB.open("MyDB", 1);

        request.onerror = (event) => {
            reject("Error opening database");
        }

        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            const store = db.createObjectStore("S60", { autoIncrement: true });
            store.createIndex("S62F02", ['S62F02']);
            store.createIndex("S62VER", ['S62VER']);
            store.createIndex("S62F01_S62F02",['S62F01','S62F02'])
            const store1 = db.createObjectStore("S60C", { autoIncrement: true });
            store1.createIndex("S62F02", ['S62F02']);
            store1.createIndex("S62VER", ['S62VER']);
            store1.createIndex("S62F01_S62F02",['S62F01','S62F02'])

        }

        request.onsuccess = (event) => {
            resolve(request.result);
        }
    });
};

export const setupDB = async (object) => {
    const db = await openDB();

    // console.log("db", db);

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(["S60", "S60C"], "readwrite");
        const store = transaction.objectStore("S60");
        const store1 = transaction.objectStore("S60C");

        const S60 = object?.S60;
        const S60C = object?.S60C;

        const updateObjectStore = async (store, data) => {
            for (const key in data) {
                if(data[key]?.S62VER) // To not store the null version into indexedDB
                {
                    try {
                        await store.put(data[key], key);
                    } catch (error) {
                        await store.add(data[key], key);
                    } 
                }
            }
        };

        (async () => {
            try {
                await updateObjectStore(store, S60);
                await updateObjectStore(store1, S60C);
                transaction.oncomplete = () => {
                    resolve(db);
                };
            } catch (error) {
                transaction.onerror = (error) => {
                    reject(error);
                };
            }
        })();
    });
};

export const fetchDataFromDB = async (storeName) => {
    const db = await openDB();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, "readonly");
        const store = transaction.objectStore(storeName);
        const request = store.getAll();

        request.onerror = () => {
            reject("Failed to fetch data from IndexedDB");
        }

        request.onsuccess = () => {
            resolve(request.result);
        }
    });
};
 
export const getDatafromDB = async(storeName,obj) => {

    // console.log("storeName",storeName);

    let flag = 0;

    if(storeName == "S60C" )
    {
        flag = 1;
    } 

    const db = await openDB()

    const P1 = obj?.p1
    const P2 = obj?.p2 || "TFORM0000001";

    // console.log("p1",P1);
    // console.log("p2",P2);

    // const P1 ="P_TESTAA"
    // const P2 = "TFORM0000000";

        return new Promise((resolve,reject) => {
            const transaction = db.transaction(storeName, "readonly");
            const store = transaction.objectStore(storeName);
            
            const index = store.index("S62F01_S62F02")
            const getRequest = index.get([`${P1}`,`${P2}`]);
            
            getRequest.onsuccess = async (event) => {
                // console.log("getRequest",getRequest.result);
                // console.log("event",event.target.result);
                if(getRequest.result)
                {
                    resolve(getRequest.result);
                }
                else if(flag == 0)
                {   
                    const result = await getDatafromDB("S60C",obj)
                    // console.log("result",result);
                    resolve(result)
                }
                else
                {
                    resolve()
                }
            };
            getRequest.onerror = (event) => {  
                reject("Failed to fetch data by Index");
            }
            
         })

}

// export const setupDB = async (object) => {
//     const db = await openDB();
    
//     console.log("db",db);

//     return new Promise((resolve, reject) => {
//         const transaction = db.transaction(["S60", "S60C"], "readwrite");
//         const store = transaction.objectStore("S60");
//         const store1 = transaction.objectStore("S60C");

//         const S60 = object?.S60;
//         const S60C = object?.S60C;

//         const result =   store.getAll("S60")
//         console.log("result",result);

//         for (let key in S60) {
//             console.log(key,S60[key]);
//             store.put(S60[key]);
//         }
//         for (let key in S60C) {
//             store1.put(S60C[key]);
//         }

//         transaction.oncomplete = () => {
//             resolve(db);
//         };

//         transaction.onerror = (error) => {
//             reject(error);
//         };
//     });
// };
