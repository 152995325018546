import { modals } from '@mantine/modals';
import React from 'react'
import { ProductListForm } from './ProductListForm';
import Store from '../../utils/store';
import { ProductListDeleteAPi } from '../../utils/slices/ProductList';
import { Text } from '@mantine/core';
import { ModalFunction } from '../../utils/slices/ModalSlice';
import { notifications } from '@mantine/notifications';
import GlobalClass from '../../utils/GlobalClass';

function M21(data) {

}

export default M21;

export const M21AED = (data) => {
    const OBJ = JSON.parse(data);
    console.log('function call', OBJ);

    if (OBJ.p0 === 'A') {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => {},
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "xl",
                position:'right',
                MBody: (i) => <ProductListForm OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                  backgroundOpacity: 0.8,
                  blur: 5,
                },
            })
        )
        //====================================================
        // modals.open({
        //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children: (
        //         <>
        //             <ProductListForm OBJ={OBJ} />
        //         </>
        //     ),
        // })
    }

    else if (OBJ.id && OBJ.p0 === 'E') {
        Store.dispatch(
            ModalFunction({
                onclose: (e) => {},
                MTitle: <Text fw={700}>{OBJ?.text}</Text>,
                MAction: true,
                MSize: "xl",
                position:'right',
                MBody: (i) => <ProductListForm OBJ={OBJ} index={i} />,
                MClose: true,
                Overlay: {
                  backgroundOpacity: 0.8,
                  blur: 5,
                },
            })
        )
        //================================================================
        // modals.open({
        //     title:<Text style={{fontWeight:700}}>{OBJ?.text}</Text>,
        //     size: '100%',
        //     children: (
        //         <>
        //             <ProductListForm OBJ={OBJ} />
        //         </>
        //     ),
        // })
    }
    else if (OBJ.id && OBJ.p0 === 'D') {
        const confirm = window.confirm("Are you sure you want to delete this row?");
        if (confirm) {
            Store.dispatch(ProductListDeleteAPi(OBJ))
        }
    }

}
