import { Button, Grid, Text } from '@mantine/core'
import React from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLTextBox from '../../PlusComponent/PLTextBox'

const BatchMergeForm = (props) => {
    const { obj } = props
    return (<>
        <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid.Col span={3}>
                    <Text size={12}>Product</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLDataGrid 
                    setEdit={(e) => {
                        
                    }}
                    />
                </Grid.Col>
            </Grid.Col>
        </Grid>

        <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid.Col span={3}>
                    <Text size={12}>From Batch</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLDataGrid 
                    setEdit={(e) => {
                        
                    }}
                    /> 
                </Grid.Col>
            </Grid.Col>
        </Grid>

        <Grid gutter={4}>
            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid.Col span={3}>
                    <Text size={12}>To Batch</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                    <PLDataGrid 
                    setEdit={(e) => {
                        
                    }}
                    />
                </Grid.Col>
            </Grid.Col>
        </Grid>

        <Grid gutter={4}>
            <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <Button>OK</Button>
            </Grid.Col>
        </Grid>
    </>
    )
}

export default BatchMergeForm