import React, { useEffect, useState } from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import { Button, Grid, Group, Text } from '@mantine/core'
import { useSelector } from 'react-redux'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDataGrid from '../../PlusComponent/PLDataGrid'

const SIExpenseEntry = (props) => {

    const { dtExp_I, M01PData, M01Format, T02ObjSend, setT02Objsend } = props.innerProps.modalBody.props.children.props

    const [DtExpTableField, setDtExpTableField] = useState([])
    const [M01D, setM01D] = useState([])
    const [M01F, setM01F] = useState([])
    const [FieldObj, SetFieldObj] = useState(null)


    const InvTypeDropDown = useSelector((state) => state?.TSalesSlices?.InvTypeDropDown)

    //for oYear Obj Get
    const GetGeneral = useSelector((state) => state?.Language?.General)
    const { OS11 } = useSelector((state) => state.Transaction?.TransactionData)
    const { oYear } = useSelector((state) => state?.Language?.General)

    const TotalAmt = T02ObjSend?.FIELD08;

    useEffect(() => {
        if (T02ObjSend) {
            SetFieldObj(T02ObjSend)
        }
    }, [T02ObjSend])

    useEffect(() => {
        if (InvTypeDropDown) {
            ExpenseEntryFunction(InvTypeDropDown)
        }
        if (M01PData && M01Format) {
            setM01D(M01PData)
            setM01F(M01Format)
        }
    }, [InvTypeDropDown, M01PData, M01Format])

    // const Recalc = (d) => {
    //     return (<>
    //         <Grid.Col span={12}>
    //             <Text>{d.FIELD02}</Text>
    //         </Grid.Col>

    //     </>)
    // }

    // const isNullOrWhiteSpace = (str) => {
    //     // return str === null || str === undefined || str.trim() === '';
    //     return str === null || str === undefined
    // }

    const Recalc = (d, index, t, dr) => {
        let oExp = d;
        let lItemWise = t
        // let TotalAmt = d.FIELD08
        let GstAmt = 0;
        let FreeAmt = 0;
        let TmpVal = 0;
        let nAmt = d.FIELD08; //TotalAmt;
        let nShAmt = nAmt;
        let cVType = OS11?.S11F01;
        let EqnAmt = "";
        let sFQty = "";
        let dFQty = 0;
        let sSQty = "";
        let dSQty = 0;
        let nTemp = 0;
        let SurcVal = 0;
        let nType = 0;

        if (oYear?.OSC._GSTFREQTY == "Y") {
            // Check if FIELD08 is empty and FIELD81 is not empty
            if (lItemWise) {
                if (oExp.FIELD08 == "" && oExp.FIELD81 != "") {
                    FreeAmt = oExp.FIELD07 * oExp.FIELD81;
                }
                else if (oExp.FIELD33 != "" && oExp.FIELD81 != "") {
                    // Check if FIELD33 and FIELD81 are not empty
                    FreeAmt = oExp.FIELD08 / oExp.FIELD33 * oExp.FIELD81;
                }
            }
        };

        // let TmpVal = 0;
        //    let nT = MApp.pc.ToInt(dr["SeqNo"]); // Unknown
        // DtExpTableField.map((dr, index) => {

        // let dr = aa[i]
        let nT = dr.SeqNo;
        let lObjItemwise = dr.FIELD53 == "I" ? "I" : "";  // Expense is Itemwise
        let lFItemExp = dr.FIELD53 == "D" ? "D" : "";     // Fix-Itemwise
        let lSurcharge = dr.FIELD06 == "U" ? "U" : "";    // Surcharge
        let lCum = dr.FIELD06 == "C" ? "C" : "";          // Cummulative
        let lFQty = dr.FIELD06 == "Q" ? "Q" : "";         // First Qty
        let lSQty = dr.FIELD06 == "S" ? "S" : "";         // Second Qty
        let cType = dr.FIELD06 == "M" ? "F" : dr.FIELD06;//Calc Type
        let Perc = dr.EXPPERC //oExp['IP' + dr.FIELD54] //dr.EXPPERC   // Expense Percentage
        let Amt = dr.EXPAMT   // Expense Amount
        let cVCO = dr.FIELD03;                 // Expense Type
        let cRoundOff = dr.FIELD75;            // Round off Type 
        //lRound = MApp.pc.InList(cRoundOff, "F", "C", "Y");  // Round Off - Yes/No             
        let lRound = cRoundOff.includes("F", "C", "Y");
        let lAChange = false; //unknown

        if (dr.EXPPERDISP == "1" || dr.EXPACCDISP == "1") {
            // d.EXPPERC = d.EXPPERC == null ? 0 : d.EXPPERC; //(EXPPERC- Expense%) Unknown
            if (lItemWise && !lObjItemwise && !lFItemExp) { //1
                return null
            } else if (nT < index) { //2
                TmpVal = Amt;
                if (cVCO.includes("3", "4")) {
                    GstAmt = GstAmt + TmpVal;
                }
            } else if (!lItemWise && lObjItemwise) { //3
                TmpVal = Amt;
                if (cVCO.includes("3", "4")) {
                    GstAmt = GstAmt + TmpVal;
                }
            } else if (lSurcharge) { // 4
                TmpVal = Amt;

                if (dr.EXPPERC != null && !lAChange) {
                    let drArr = DtExpTableField.filter((a) => a.EXPSURSEQ >= 0);
                    drArr.map((dtSur, dtSuri) => {
                        let nIndex = dtSur.EXPSURSEQ;

                        SurcVal = SurcVal + DtExpTableField[nIndex].EXPAMT ? 0 : DtExpTableField[nIndex].EXPAMT
                            * (DtExpTableField[nIndex].EXPDeduct == "-" ? -1 : 1) * (DtExpTableField[index].EXPDeduct == "-" ? -1 : 1);
                    })
                    TmpVal = Math.round((SurcVal * Perc / 100), 2);
                }
                // IF lRound the set Calculated value to roundoff According to Expense defination
                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }

            } else if (EqnAmt != "" && !lAChange && cVCO.includes("E", "T") || (cVCO.includes("V", "C") && oYear.OSC._STATE == "S02")) { // 5
                //Equation Will be perform Here Pending this case right now
            } else if (dr.FIELD06 == "R") { //6
                TmpVal = nAmt - Math.round(nAmt);
                TmpVal = TmpVal * (TmpVal <= 0.50 ? -1 : 1);
            } else if (dr.EXPPERREQ != "1" || lAChange) { //7
                TmpVal = Amt;
                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }
                if (cVCO.includes("3", "4")) {
                    GstAmt = GstAmt + TmpVal;
                }
            } else if (lFQty || cType == "K") { //8
                if (cType == "K") {
                    TmpVal = dr.EXPADDICESS ? 0 : dr.EXPADDICESS * oExp.FIELD06 ? 0 : oExp.FIELD06;
                }
                else {
                    sFQty = lItemWise ? oExp.FIELD06 : "";
                    dFQty = sFQty ? 0 : sFQty;
                    TmpVal = Perc * dFQty; // Percentage Value & Quantity
                }

            } else if (lSQty) { // 9
                sSQty = lItemWise ? d.FIELD21 : "";
                dSQty = sSQty == "" ? 0 : sSQty;
                TmpVal = Perc * dSQty; // Percentage Value & Quantity
            } else if (dr.EXPPERREQ == "1" && cType != "A") { //10
                console.log("Perc", Perc);
                if (Perc == 0 && Amt != 0 && !cVCO.includes("V", "D", "3", "4")) {
                    // if ((nType > 0 || dr.FIELD53 == "D") && oVou.cVType != "SH") {
                    if ((nType > 0 || dr.FIELD53 == "D") && cVType != "SH") {

                        TmpVal = 0;
                    } else {
                        TmpVal = Amt;
                    }
                } else {
                    if (cType.includes("B", "D", "I", "J")) {
                        nTemp = nAmt - GstAmt;
                        if (oYear.OSC._GSTFREQTY)//AND NOT This.oYear.lBlwThre
                        {
                            nTemp = nTemp + FreeAmt;
                        }
                    } else {
                        nTemp = lCum ? nAmt : TotalAmt;
                    }
                    TmpVal = Math.round(nTemp * (Perc / 100), 2);
                }

                if (lRound) {
                    switch (cRoundOff) {
                        case "Y":
                            TmpVal = Math.round(TmpVal, 0);
                            break;
                        case "C":
                            TmpVal = Math.ceil(TmpVal);
                            break;
                        case "F":
                            TmpVal = Math.floor(TmpVal);
                            break;
                    }
                }


                if (cVCO.includes("3", "4")) {
                    if (oYear.OSC._GR1RTTAX && oExp.FIELD53 == "Y" &&
                        // oVou.cVType.includes("SS", "SR", "PP", "PR", "HC", "HD")) {
                        cVType.includes("SS", "SR", "PP", "PR", "HC", "HD")) {

                        TmpVal = TmpVal * 0.65;
                    }
                    GstAmt = GstAmt + TmpVal;

                }
            }
            // dr.EXPAMT = TmpVal; //Unknown
            //  oExp[dr.EXPAMTSRC] = dr.EXPAMT; // Unknown
            // Add To Total Only If Account effect Is Yes. Do Not Add In Case Of Account Effect No/Seperate

            let newarray = [...DtExpTableField];
            newarray[index] = { ...dr, EXPAMT: TmpVal }
            setDtExpTableField(newarray)
            SetFieldObj({ ...FieldObj, ['ID' + d?.FIELD54]: TmpVal })
            if (dr.FIELD61 == "Y") {
                // Add calculated value to final total
                nAmt = nAmt + (TmpVal * (dr.EXPDEDUCT == "-" ? -1 : 1));
                console.log("namt", nAmt);
                // return FieldObj['ID' + MObj?.FIELD54] = nAmt
                SetFieldObj({ ...FieldObj, FIELD08: nAmt })
                return nAmt
            }
        } else {
            dr.EXPAMT = 0;
            oExp[dr.EXPAMTSRC] = dr.EXPAMT //(EXPAMTSRC - sourcefield name)
            console.log("oExp[dr.EXPAMTSRC]", oExp[dr.EXPAMTSRC]);
            let newarray = [...DtExpTableField];
            newarray[index] = { ...dr, EXPAMT: 0 }
            console.log("newarray", newarray);
            setDtExpTableField(newarray)
        }

        // })
    }

    const ExpenseEntryFunction = (d) => {
        let newArray = [];
        switch (d) {
            case "IDS00003": // Export
                newArray = dtExp_I.filter((d) => d?.FIELD02 != 'CGST' && d?.FIELD02 != 'SGST' && d?.FIELD02 != 'IGST' && d?.FIELD02 != 'Cess')
                setDtExpTableField(newArray)
                break;
            case "IDS00004": //Export GST(0.1%)
                newArray = dtExp_I.filter((d) => d?.FIELD02 != 'IGST')
                setDtExpTableField(newArray)
                break;
            case "IDS00005": //Export IGST(0.1%)
                newArray = dtExp_I.filter((d) => d?.FIELD02 != 'CGST' && d?.FIELD02 != 'SGST' && d?.FIELD02 != 'Cess')
                setDtExpTableField(newArray)
                break;
            case "IDS00006": //Export (Without Pay)
                newArray = dtExp_I.filter((d) => d?.FIELD02 != 'CGST' && d?.FIELD02 != 'SGST' && d?.FIELD02 != 'IGST' && d?.FIELD02 != 'Cess')
                setDtExpTableField(newArray)
                break;
            case "ID000014": //VAT
                newArray = dtExp_I;
                setDtExpTableField(newArray)
                break;
            case "IDS00001": //GST
                newArray = dtExp_I.filter((d) => d?.FIELD02 != 'IGST')
                setDtExpTableField(newArray)
                break;
            case "IDS00002": //IGST
                newArray = dtExp_I.filter((d) => d?.FIELD02 != 'CGST' && d?.FIELD02 != 'SGST' && d?.FIELD02 != 'Cess')
                setDtExpTableField(newArray)
                break;
            default:
                break;
        }

    }

    const SaveExpenseDetail = () => {
        Promise.resolve(setT02Objsend(FieldObj)).then(() => props.context.closeModal(props.id))
    }
    // useEffect(()=>{
    //     console.log("T02 Object==>", FieldObj);
    // },[FieldObj])

    return (<>
        <Grid style={{ maxHeight: "300px", overflow: "hidden", overflowY: "scroll" }} gutter={4}>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                <Grid.Col span={3} ></Grid.Col>
                <Grid.Col span={3}>

                </Grid.Col>
                <Grid.Col span={1}>
                </Grid.Col>
                <Grid.Col span={2} style={{ fontSize: 12, fontWeight: 'bold' }}>
                    Item Amount:
                </Grid.Col>
                <Grid.Col span={3}>
                    {TotalAmt}
                </Grid.Col>
            </Grid.Col>
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                <Grid.Col span={3} style={{ fontSize: 12 }}>Sales A/C.</Grid.Col>
                <Grid.Col span={3}>
                    <PLDataGrid
                        width={'500px'}
                        // disabled={d?.FIELD05 == "V" ? false : true}
                        // value={d?.FIELD04}
                        TmData={M01F}
                        data={M01D}
                        setEdit={(e) => {
                            console.log(e)
                            SetFieldObj({ ...FieldObj, FIELD16: e?.FIELD01 })
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={1}>
                </Grid.Col>
                <Grid.Col span={2}>
                </Grid.Col>
                <Grid.Col span={3}>
                </Grid.Col>
            </Grid.Col>
            {
                DtExpTableField?.map((d, i) => {
                    console.log("d", d);
                    return (<Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                        <Grid.Col span={3} style={{ fontSize: 12 }}>{d?.FIELD02}</Grid.Col>
                        <Grid.Col span={3}>
                            <PLDataGrid
                                width={'500px'}
                                disabled={d?.FIELD05 == "V" ? false : true}
                                value={d?.FIELD04}
                                TmData={M01F}
                                data={M01D}
                                setEdit={(e) => {
                                    console.log(e)
                                    SetFieldObj({ ...FieldObj, ['IA' + d?.FIELD54]: e?.FIELD01 })
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={1}>
                            {
                                d?.FIELD14 === 'D' && <Text style={{ fontSize: 12 }}>(-)</Text>
                            }
                        </Grid.Col>
                        <Grid.Col span={2}>
                            {
                                d?.FIELD06 !== 'F' && <PLNumberBox
                                    value={d?.EXPPERC}
                                    setNumber={() => {
                                        Recalc(FieldObj, i, true, d)
                                    }}
                                    id={d?.FIELD54}
                                    setEdit={(e) => {
                                        console.log("d?.FIELD54", d?.FIELD54);
                                        SetFieldObj({ ...FieldObj, ['IP' + d?.FIELD54]: e })
                                        // setDtExpTableField([...DtExpTableField,{...d,EXPPERC:e}])
                                        let newarray = [...DtExpTableField];
                                        newarray[i] = { ...d, EXPPERC: e }
                                        setDtExpTableField(newarray)
                                    }}
                                />
                            }
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <PLNumberBox
                                value={d?.EXPAMT}
                                id={d?.FIELD54}
                                setEdit={(e) => {
                                    SetFieldObj({ ...FieldObj, ['ID' + d?.FIELD54]: e })
                                    let newarray = [...DtExpTableField];
                                    newarray[i] = { ...d, EXPAMT: e }
                                    setDtExpTableField(newarray)
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>)
                })
            }
            <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                <Grid.Col span={3} ></Grid.Col>
                <Grid.Col span={3}>

                </Grid.Col>
                <Grid.Col span={1}>
                </Grid.Col>
                <Grid.Col span={2} style={{ fontSize: 12, fontWeight: 'bold' }}>
                    Total Amount:
                </Grid.Col>
                <Grid.Col span={3}>
                    {FieldObj?.FIELD08}
                </Grid.Col>
            </Grid.Col>
        </Grid>
        <Grid gutter={4}>
            <Grid.Col span={12}>
                <Group spacing={"sm"} position='center'>
                    <Button size='sm' onClick={() => SaveExpenseDetail()}>
                        Ok
                    </Button>
                </Group>
            </Grid.Col>
        </Grid>
    </>)
}


export default SIExpenseEntry



