import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetNarrationData, PostNarrationData } from '../../utils/TransactionSlices/NarrationSlice';
import Store from '../../utils/store';
import { Button, Grid, Group, Text } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import GlobalClass from '../../utils/GlobalClass';
import { ModalDelete } from '../../utils/slices/ModalSlice';

const NarrationForm = (props) => {
    console.log("NarrationForm props=>", props);

    const { OBJ,index } = props;

    const dispatch = useDispatch();
    const [NarrationObj, setNarrationObj] = useState({
        "FIELD01": "",
        "FIELD02": "",
        "FIELD03": "",
        "FLDUNQ": 0,
        "FLDAED": "A",
        "FLDBRC": ""
    })

    const [StatusNotification,setStatusNotification]=useState(false)

    const NarrationData = useSelector((state) => state.NarrationSlice.NarrationData);

    const { isLoading, hasError, NarrationDataPostStatus } = useSelector((state) => state.NarrationSlice)

    useEffect(() => {
        if (OBJ) {
            dispatch(GetNarrationData(OBJ))
        }
    }, [OBJ])

    useEffect(() => {
        if (NarrationData) {
            console.log("NarrationData =>", NarrationData);
            setNarrationObj(NarrationData)
        }
    }, [NarrationData])

    const handleSubmitNarration = () => {
        console.log("NarrationObj submit=>", NarrationObj);
        Promise.resolve(GlobalClass.Notify(
            "info",
            OBJ.p0 == "A" ? "Adding" : "Editing",
            "Please wait while we process your data"
          )).then(()=>dispatch(PostNarrationData({OBJ:{...OBJ,index:index},data:NarrationObj})))
    }

    // useEffect(() => {

    //     if(StatusNotification){
    //         if (NarrationDataPostStatus?.status == "SUCCESS") {
    //             console.log("NarrationDataPostStatus SUCCESS", NarrationDataPostStatus);
    //             Promise.resolve(GlobalClass.Notify(
    //                 "success",
    //                 OBJ.p0 == "A" ? "Added" : "Edited",
    //                 OBJ.p0 == "A" ? "Added successfully" : "Edited successfully"
    //               )).then(()=>dispatch(ModalDelete(props.index)))
                
    //         }else if(NarrationDataPostStatus?.status == "FAIL"){
    //             console.log("NarrationDataPostStatus FAIL", NarrationDataPostStatus);
    //             GlobalClass.Notify("error", NarrationDataPostStatus?.status, NarrationDataPostStatus?.error)
    //         }
    //     }
    // }, [NarrationDataPostStatus,StatusNotification])

    return (<>
        {
            isLoading ? GlobalClass.RenderLoader("dots") :
                <Grid gutter={4}>
                    <Grid.Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Grid.Col span={3}>
                            <Text style={{ fontSize: 12 }}>Narration</Text>
                        </Grid.Col>
                        <Grid.Col span={9}>
                            <PLTextBox
                                value={NarrationObj?.FIELD01}
                                setEdit={(e) => {
                                    setNarrationObj({ ...NarrationObj, FIELD01: e?.target?.value,FIELD02:OBJ?.S11F01
                                    })
                                }}
                            />
                        </Grid.Col>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Group spacing={"xs"} position="center">
                            <Button
                                style={{ height: 30, width: 100 }}
                                onClick={() => handleSubmitNarration()}
                            >
                                Ok
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
        }
    </>)
}

export default NarrationForm