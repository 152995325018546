import { createSlice } from "@reduxjs/toolkit";


const DataSaveLoadingSlice = createSlice({
    name: "DataSaveLoadingSlice",
    initialState: {
        SaveLoading: false,
        PaginationObj: [],
        ReportIdArray: [],
        TransactionReportIdArray: [],
        CmpSetUpObjSave:null
    },
    reducers: {
        DataSaveLoading: (state, action) => {
            state.SaveLoading = action.payload
        },
        GetPaginationObj: (state, action) => {
            // console.log("action.payload=>", action.payload)
            state.PaginationObj = { ...state.PaginationObj, [action.payload.id]: action.payload.pagination }
            // console.log("state.PaginationObj=>", state.PaginationObj)
        },
        GetReportIdArray: (state, action) => {
            state.ReportIdArray = action.payload
        },
        GetTransactionReportIdArray: (state, action) => {
            // console.log("GetTransactionReportIdArray",action.payload);
            state.TransactionReportIdArray = [...action.payload]
        },
        GetCmpSetUpObj: (state, action) => {
            console.log("GetCmpSetUpObj action =>",action);
            state.CmpSetUpObjSave = action.payload
        }
    }

})

export const { DataSaveLoading, GetPaginationObj, GetReportIdArray,GetTransactionReportIdArray, GetCmpSetUpObj} = DataSaveLoadingSlice.actions
export default DataSaveLoadingSlice.reducer