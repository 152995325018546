import React, { useEffect, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { Box, Button, Drawer, Grid, Group, Modal, Text } from "@mantine/core";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import FormatForm from "./FormatForm";
import Store from "../../utils/store";
import {
  GetVouchNum,
  GetVouchNumData,
} from "../../utils/slices/VoucherNumSlice";
import { createColumnHelper } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import GlobalClass from "../../utils/GlobalClass";
import ButtonPanel from "../../PlusComponent/ButtonPanel";
import DataGrid from "../../components/DataGrid";

export default function FormatListTable(props) {
  const { obj, index } = props;
  const [columns, setColumns] = useState([]);
  const columnHelper = createColumnHelper();
  console.log(obj, "hhbh-");
  const p0 = obj?.p0;
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const {
    successMsg,
    VoucherNumTemp: tmFrmt,
    isLoading,
    VoucherNumdata: genData,
  } = useSelector((state) => state?.VoucherNum);
  console.log(genData, "genData", tmFrmt);
  const tabData = genData?.jData;
  useEffect(() => {
    if (!isLoading && tmFrmt && tmFrmt?.oDCFrmt && tmFrmt?.oDCFrmt?.aDCol) {
      var cols = [];
      tmFrmt?.oDCFrmt?.aDCol?.map((v, i) => {
        cols.push(
          columnHelper.accessor(v.DispExpr, {
            header: v.ColCap,
            enableHiding: true,
            enableColumnFilter: v.lSearchCol,
            enableSorting: v.lColOrder,
            size: v.ColWidth,
            minSize: 0,
            maxSize: v.ColWidth,
            Cell: ({ cell }) => {
              var val = cell.getValue();
              return val;
            },
          })
        );
      });

      setColumns(cols);
    }
  }, [tmFrmt]);
  const table = useMantineReactTable({
    // data: Vdata ? data : [],
    data: tabData ?? [],
    columns: columns,
    // enableRowSelection: true, //enable some features
    enableColumnOrdering: true,
    enableGlobalFilter: true,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enableResizing: true,
    initialState: {
      density: "0px",
      // expanded: true,
    },
    enableRowVirtualization: true,
    mantineTableContainerProps: { sx: { maxHeight: "600px" } },
    enableSelectAll: false,
    enableMultiRowSelection: false,
    enableTableFooter: false,
    state: {
      density: "0px",
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, cell }) => ({
      onDoubleClick: () => {},
      onClick: () => {
        // console.log(row?.original, "row?.original");
      },

      sx: { cursor: "pointer" },
      // height: 50,
    }),
  });
  function FormatFormFn() {
    switch (obj?.p0) {
      case "M":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>Format</Text>,
          body: (
            <FormatForm
              obj={obj}
              DrawerOBJ={DrawerObj}
              setDrawerOBJ={(e) => {
                setDrawerObj(e);
              }}
            />
          ),
          open: true,
          size: "md",
          position: "right",
        });
        break;
      case "L":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>Format</Text>,
          body: (
            <FormatForm
              obj={obj}
              DrawerOBJ={DrawerObj}
              setDrawerOBJ={(e) => {
                setDrawerObj(e);
              }}
            />
          ),
          open: true,
          size: "lg",
          position: "right",
        });
        break;
      case "W":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>Format</Text>,
          body: (
            <FormatForm
              obj={obj}
              DrawerOBJ={DrawerObj}
              setDrawerOBJ={(e) => {
                setDrawerObj(e);
              }}
            />
          ),
          open: true,
          size: "lg",
          position: "right",
        });
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      if (p0 == "S") {
        await Store.dispatch(GetVouchNumData({ RepId: "P_SMS", FmtID: true }));
        await Store.dispatch(GetVouchNum({ RepId: "P_SMS", FmtID: true }));
      } else if (p0 == "E") {
        await Store.dispatch(
          GetVouchNumData({ RepId: "P_EMAIL", FmtID: true })
        );
        await Store.dispatch(GetVouchNum({ RepId: "P_EMAIL", FmtID: true }));
      } else if (p0 == "W") {
        await Store.dispatch(GetVouchNumData({ RepId: "P_WAPP", FmtID: true }));
        await Store.dispatch(GetVouchNum({ RepId: "P_WAPP", FmtID: true }));
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        closeOnClickOutside={true}
        position={DrawerObj?.position ?? "right"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
      >
        {DrawerObj.body}
      </Modal>
      {isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Box>
          <Grid>
            {/* {!SaveLoading ? ( */}
            <>
              <Grid.Col span={12}>
                <PLTextBox placeholder="Search here..." />
              </Grid.Col>
              <Grid.Col span={12}>
                <MantineReactTable table={table} />
              </Grid.Col>
              {/* <Grid.Col span={12}>
                <Group spacing={"xs"} position="center" mt={5} mb={5}>
                  <Button
                    size="xs"
                    onClick={() => FormatFormFn()}
                    // disabled={activeBtn ? false : true}
                  >
                    Add
                  </Button>
                  <Button
                    size="xs"
                    // onClick={() => EditVoucherNum()}
                    // disabled={activeBtn ? true : false}
                    // className={classes.button}
                  >
                    Edit
                  </Button>
                  <Button
                    size="xs"
                    // onClick={() => DeleteVoucherNum()}
                    // disabled={activeBtn ? true : false}
                    // className={classes.button}
                  >
                    Delete
                  </Button>
                  <Button
                    size="xs"
                    // onClick={() => DeleteVoucherNum()}
                    // disabled={activeBtn ? true : false}
                    // className={classes.button}
                  >
                    Generate
                  </Button>
                  <Button
                    size="xs"
                    // onClick={() => DeleteVoucherNum()}
                    // disabled={activeBtn ? true : false}
                    // className={classes.button}
                  >
                    {obj?.p0 == "M" ? "SMS Setup" : "Mail Setup"}
                  </Button>
                </Group>
              </Grid.Col> */}
            </>
            {/* ) : (
        <Grid.Col span={12}>{GlobalClass.RenderLoader("dots")} </Grid.Col>
      )} */}
          </Grid>
        </Box>
      )}
      <ButtonPanel
        // tableRef={TableRef}
        data={tmFrmt?.oS60?.DTHKey}
        Skey={table.getSelectedRowModel().flatRows[0]}
        from={obj?.id}
        btnPnlObj={obj}
      />
    </>
  );
}
