import { Button, Card, Grid, Group, Skeleton, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetUserMasterEntData, GetUserMasterTableData, GetUserMasterTableDataUpdate, SaveUserMasterOBJ } from '../../utils/slices/UserMasterSlice';
import { ModalDelete, ModalFunction } from '../../utils/slices/ModalSlice';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLDateBox from '../../PlusComponent/PLDateBox';
import UserMasterListTable from './UserMasterListTable';
import GlobalClass from '../../utils/GlobalClass';

const UserMasterForms = (props) => {

    console.log("UserMasterForm props=>", props);

    const { obj } = props;

    const [formType, setFormType] = useState(obj?.FormCode)

    const [userMasterObj, setUserMasterObj] = useState({
        "FLDUNQ": 0,
        "FLDAED": "",
        "FIELD01": "",
        "FIELD02": "",
        "FIELD03": "",
        "FIELD04": "",
        "FIELD05": 0.0,
        "FIELD06": 0.0,
        "FIELD07": "",
        "FIELD08": "",
        "FIELD09": "",
        "FIELD10": 0,
        "FIELD11": "",
        "FIELD12": "",
        "FIELD13": "",
        "FIELD14": "",
        "FIELD15": "",
        "FIELD16": "",
        "FIELD17": "",
        "FIELD18": "",
        "FIELD19": "",
        "FIELD20": "",
        "FIELD21": "",
        "FIELD22": 0.0,
        "FIELD23": 0.0,
        "FIELD24": "",
        "FIELD25": 0.0,
        "FIELD26": 0.0,
        "FIELD27": 0.0,
        "FIELD31": "",
        "FIELD32": "",
        "FIELD33": "",
        "FIELD39": "",
        "FIELD40": "",
        "FIELD41": "",
        "FIELD42": "",
        "FIELD54": "",
        "FIELD43": "",
        "FIELD35": "",
        "FIELD55": "",
        "FIELD50": "",
        "FIELD51": "",
        "FIELD52": "",
        "FIELD53": "",
        "FIELD91": true,
        "FIELD99": "",
        "FLDBRC": "",
        "M41UID": ""
    })

    const [userMasterListObj, setUserMasterListObj] = useState({
        "FLDUNQ": 0,
        "FLDAED": "",
        "FIELD01": "",
        "FIELD02": "",
        "FIELD03": "",
        "FIELD04": "",
        "FIELD05": 0.0,
        "FIELD06": 0.0,
        "FIELD07": "",
        "FIELD08": "",
        "FIELD09": "",
        "FIELD10": 0,
        "FIELD11": "",
        "FIELD12": "",
        "FIELD13": "",
        "FIELD14": "",
        "FIELD15": "",
        "FIELD16": "",
        "FIELD17": "",
        "FIELD18": "",
        "FIELD19": "",
        "FIELD20": "",
        "FIELD21": "",
        "FIELD22": 0.0,
        "FIELD23": 0.0,
        "FIELD24": "",
        "FIELD25": 0.0,
        "FIELD26": 0.0,
        "FIELD27": 0.0,
        "FIELD31": "",
        "FIELD32": "",
        "FIELD33": "",
        "FIELD39": "",
        "FIELD40": "",
        "FIELD41": "",
        "FIELD42": "",
        "FIELD54": "",
        "FIELD43": "",
        "FIELD35": "",
        "FIELD55": "",
        "FIELD50": "",
        "FIELD51": "",
        "FIELD52": "",
        "FIELD53": "",
        "FIELD91": true,
        "FIELD99": "",
        "FLDBRC": "",
        "M41UID": ""
    })

    const [errorShow, setErrorShow] = useState({
        "UMField01": false,
        "UMLField13": false
    })

    const UserMasterEntData = useSelector((state) => state?.UserMasterSlice?.UserMasterEntData);
    const ErrorShowHide = useSelector((state) => state?.UserMasterSlice?.ErrorShowHide);


    const { isLoading } = useSelector((state) => state?.UserMasterSlice);

    const UserMasterTableData = useSelector((state) => state?.UserMasterSlice?.UserMasterTableData)
    const UserMasterOBJ = useSelector((state) => state?.UserMasterSlice?.UserMasterOBJ)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetUserMasterEntData())
    }, [])

    useEffect(() => {
        if (obj.p0 == "E" && obj.FormCode == "UMF") {
            console.log(obj?.DataGridRow , "obj?.DataGridRow")
            setUserMasterObj(obj?.DataGridRow)
        } else if (obj.p0 == "E" && obj.FormCode == "UMLF") {
            setUserMasterListObj(obj?.DataGridRow)
        }
    }, [props])


    // useEffect(() => {
    //     console.log("UserMasterTableData==>",UserMasterTableData);
    //     if (obj.p0 == "A") {
    //         if (obj.FormCode == "UMF") {
    //             UserMasterTableData?.M41UM.map((d) => {
    //                 if (d.FIELD01 == userMasterObj?.FIELD01) {
    //                     console.log("userMasterObj?.FIELD01");
    //                     setErrorShow({ ...errorShow, UMField01: true })
    //                 } else {
    //                     setErrorShow({ ...errorShow, UMField01: false })
    //                 }
    //             })
    //         } else if (obj.FormCode == "UMLF") {
    //             UserMasterTableData?.M41UF.map((d) => {
    //                 if (d.FIELD13 == userMasterListObj?.FIELD13) {
    //                     setErrorShow({ ...errorShow, UMLField13: true })
    //                 } else {
    //                     setErrorShow({ ...errorShow, UMLField13: false })
    //                 }
    //             })
    //         }
    //     }
    // }, [userMasterObj?.FIELD01, userMasterListObj?.FIELD13, obj])



    const HideAndShowInput = () => {
        switch (userMasterListObj?.FIELD04) {
            case "C":
                return <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                    <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Default Value:</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLTextBox
                            value={userMasterListObj?.FIELD18}
                            setEdit={(e) => {
                                setUserMasterListObj({ ...userMasterListObj, FIELD18: e.target.value })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>

                break;
            case "N":
                return <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                    <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Default Value:</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLNumberBox
                            value={userMasterListObj?.FIELD18}
                            setEdit={(e) => {
                                setUserMasterListObj({ ...userMasterListObj, FIELD18: e })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>

                break;
            case "M":
                return <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                    <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Default Value:</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLTextBox
                            value={userMasterListObj?.FIELD18}
                            setEdit={(e) => {
                                setUserMasterListObj({ ...userMasterListObj, FIELD18: e.target.value })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>

                break;
            case "D":
                return <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                    <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Default Value:</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDateBox
                            value={userMasterListObj?.FIELD18}
                            setEdit={(e) => {
                                setUserMasterListObj({ ...userMasterListObj, FIELD18: e })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>

                break;

            default:
                return <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                    <Grid.Col span={3}>
                        <Text style={{ fontSize: 12 }}>Default Value:</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLTextBox
                            value={userMasterListObj?.FIELD18}
                            setEdit={(e) => {
                                setUserMasterListObj({ ...userMasterListObj, FIELD18: e.target.value })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                break;
        }
    }

    const UserMasterFormSubmit = () => {
        console.log("submit btn click");
        console.log("User Master Obj Submit=>", userMasterObj);
        if (obj.p0 == "A" && obj.FormCode == "UMF") {
            // let newArray = [...UserMasterTableData, userMasterObj]
            let newArray = { M41UM: [...UserMasterTableData?.M41UM, userMasterObj], M41UF: [...UserMasterTableData?.M41UF] }
            console.log("newArray==>", newArray);
            Promise.resolve(dispatch(GetUserMasterTableData(newArray))).then(() => dispatch(SaveUserMasterOBJ(userMasterObj))).then(() => dispatch(ModalDelete(props.index))).then(()=>GlobalClass.Notify("success","Success","Successfully Add Data")).then(() => (
                dispatch(ModalFunction({
                    MTitle: <Text fw={700}>{userMasterObj?.FIELD03}</Text>,
                    MAction: true,
                    MSize: '70%',
                    MBody: (i) => (<UserMasterListTable obj={props.obj} index={i} />),
                    MClose: true,
                    onclose: () => { }
                }))
            ))
        } else if (obj.p0 == "E" && obj.FormCode == "UMF") {
            let newArray = [];
            UserMasterTableData?.M41UM.map((d, i) => {
                if (d?.FIELD01 == userMasterObj?.FIELD01) {
                    newArray = [...UserMasterTableData?.M41UM]
                    newArray[i] = userMasterObj
                }
            })
            Promise.resolve(dispatch(GetUserMasterTableData({ M41UM: newArray, M41UF: [...UserMasterTableData?.M41UF] }))).then(()=>GlobalClass.Notify("success","Success","Successfully Update Data")).then(() => dispatch(ModalDelete(props.index)))
        } else if (obj.p0 == "A" && obj.FormCode == "UMLF") {

            let newArray = { M41UM: [...UserMasterTableData?.M41UM], M41UF: [...UserMasterTableData?.M41UF, { ...userMasterListObj, FIELD01: UserMasterOBJ.FIELD01, FIELD10: UserMasterTableData?.M41UF.length + 1, FIELD31: UserMasterOBJ?.FIELD01 }] }
            console.log("newArray==>", newArray);
            Promise.resolve(dispatch(GetUserMasterTableData(newArray))).then(()=>GlobalClass.Notify("success","Success","Successfully Add Data")).then(() => dispatch(ModalDelete(props.index)))

        } else if (obj.p0 == "E" && obj.FormCode == "UMLF") {

            let newArray = [];
            UserMasterTableData?.M41UF.map((d, i) => {
                if (d?.FIELD13 == userMasterListObj?.FIELD13) {
                    newArray = [...UserMasterTableData?.M41UF]
                    newArray[i] = userMasterListObj
                }
            })
            console.log("newArray==>", newArray);
            Promise.resolve(dispatch(GetUserMasterTableData({ M41UM: [...UserMasterTableData?.M41UM], M41UF: newArray }))).then(()=>GlobalClass.Notify("success","Success","Successfully Update Data")).then(() => dispatch(ModalDelete(props.index)))

        }
    }

    const ErrorUMF = (e) => {
        let newArray = UserMasterTableData?.M41UM.filter((d) => d.FIELD01 == e)
        if (newArray[0]?.FIELD01 == e) {
            setErrorShow({ ...errorShow, UMField01: true })
        } else {
            setErrorShow({ ...errorShow, UMField01: false })
        }
    }

    const ErrorUMLF = (e) => {
        let newArray = UserMasterTableData?.M41UF.filter((d) => d.FIELD13 == e)
        if (newArray[0]?.FIELD13 == e) {
            setErrorShow({ ...errorShow, UMLField13: true })
        } else {
            setErrorShow({ ...errorShow, UMLField13: false })
        }
    }

    console.log("UserMasterTableData on submit==>", UserMasterTableData);
    // console.log("ErrorShowHide==>", ErrorShowHide);

    console.log("errorShow=>", errorShow);
    // console.log("errorShow1=>",errorShow1);

    return (<>
        {
            isLoading == true ? GlobalClass.RenderLoader('dots') :
                formType == "UMF" ?
                    <>
                        <Grid gutter={4}>
                            <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                <Grid.Col span={2}>
                                    <Text style={{ fontSize: 12 }}>File Code:</Text>
                                </Grid.Col>
                                <Grid.Col span={10}>
                                    <PLTextBox
                                        disabled={obj.p0 == "E" ? true : false}
                                        value={userMasterObj?.FIELD01}
                                        setText={(e) => {
                                            // console.log("File Code BLLUR===>", e.target.value);
                                            if (obj.p0 == "A") {
                                                ErrorUMF(e.target.value)
                                            }
                                        }}
                                        setEdit={(e) => {
                                            // console.log("File Code", e.target.value);
                                            setUserMasterObj({ ...userMasterObj, FIELD01: e.target.value.toUpperCase() })
                                        }}
                                    />
                                    {
                                        errorShow.UMField01 === true ?
                                            <Text style={{ fontSize: 10, color: "red" }}>File Code Already Use</Text> : null

                                    }
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                <Grid.Col span={2}>
                                    <Text style={{ fontSize: 12 }}>File Name:</Text>
                                </Grid.Col>
                                <Grid.Col span={10}>
                                    <PLTextBox
                                        value={userMasterObj?.FIELD03}
                                        setEdit={(e) => {
                                            console.log("File Name", e.target.value);
                                            setUserMasterObj({ ...userMasterObj, FIELD03: e.target.value })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                            <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                <Grid.Col span={2}>
                                    <Text style={{ fontSize: 12 }}>Comment:</Text>
                                </Grid.Col>
                                <Grid.Col span={10}>
                                    <PLTextBox
                                        value={userMasterObj?.FIELD99}
                                        setEdit={(e) => {
                                            console.log("Comment", e.target.value);
                                            setUserMasterObj({ ...userMasterObj, FIELD99: e.target.value })
                                        }}
                                    />
                                </Grid.Col>
                            </Grid.Col>
                        </Grid>
                        <Group spacing={'xs'} position='center' pt={10}>
                            <Button
                                disabled={errorShow.UMField01}
                                size='sm'
                                style={{ width: 80 }}
                                onClick={() => userMasterObj?.FIELD01 != "" && userMasterObj?.FIELD03 != "" ? UserMasterFormSubmit() : GlobalClass.Notify("warning", "Warning:Empty Field", "Empty Field Not Required")}
                            >
                                Ok
                            </Button>
                            {/* <Button size='sm' onClick={() => dispatch(ModalDelete(props.index))}>Cancel</Button> */}
                        </Group>

                    </> :
                    formType == "UMLF" ?
                        <>
                            <Grid gutter={4}>
                                <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Field Name:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLTextBox
                                                value={userMasterListObj?.FIELD03}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD03: e.target.value })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Short Name:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLTextBox
                                            nmaxlength={8}
                                                disabled={obj.p0 == "E" ? true : false}
                                                setText={(e) => {
                                                    // console.log("File Code BLLUR===>", e.target.value);
                                                    if (obj.p0 == "A") {
                                                        ErrorUMLF(e.target.value)
                                                    }
                                                }}
                                                value={userMasterListObj?.FIELD13}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD13: e.target.value.toUpperCase() })
                                                }}
                                            />
                                            {
                                                errorShow.UMLField13 == true ?
                                                    <Text style={{ fontSize: 10, color: "red" }}>Short Name Already Use</Text> : null
                                            }
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>
                                <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Sequence:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLNumberBox
                                                value={userMasterListObj?.FIELD10}

                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD10: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Type:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLComboBox
                                                data={UserMasterEntData?.COP19}
                                                dispexpr={"DisplayMember"}
                                                valexpr={"ValueMember"}
                                                value={userMasterListObj?.FIELD04}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD04: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>
                                <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Length:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLNumberBox
                                                disabled={userMasterListObj?.FIELD04 == "D" ? true :
                                                    userMasterListObj?.FIELD04 == "M" ? true :
                                                        false
                                                }
                                                value={userMasterListObj?.FIELD05}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD05: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Decimal:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLNumberBox
                                                disabled={userMasterListObj?.FIELD04 == "C" ? true :
                                                    userMasterListObj?.FIELD04 == "D" ? true :
                                                        userMasterListObj?.FIELD04 == "M" ? true :
                                                            false
                                                }
                                                value={userMasterListObj?.FIELD06}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD06: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>
                            </Grid>
                            <Card.Section style={{ marginTop: 20 }}>
                                <Text weight={500}>Input Detail</Text>
                                <hr />
                            </Card.Section>
                            <Grid gutter={4}>
                                <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Master Name:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLComboBox
                                                disabled={userMasterListObj?.FIELD04 == "N" ? true :
                                                    userMasterListObj?.FIELD04 == "D" ? true :
                                                        false
                                                }
                                                data={UserMasterEntData?.MSTTBL}
                                                dispexpr={"S14F04"}
                                                valexpr={"S14F01"}
                                                value={userMasterListObj?.FIELD12}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD12: e })

                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Row:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLNumberBox
                                                value={userMasterListObj?.FIELD22}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD22: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>
                                <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Format:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLTextBox
                                                value={userMasterListObj?.FIELD16}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD16: e.target.value })

                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={6} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                        <Grid.Col span={3}>
                                            <Text style={{ fontSize: 12 }}>Column:</Text>
                                        </Grid.Col>
                                        <Grid.Col span={9}>
                                            <PLNumberBox
                                                value={userMasterListObj?.FIELD23}
                                                setEdit={(e) => {
                                                    // console.log(e);
                                                    setUserMasterListObj({ ...userMasterListObj, FIELD23: e })
                                                }}
                                            />
                                        </Grid.Col>
                                    </Grid.Col>
                                </Grid.Col>
                                <Grid.Col span={12} style={{ display: 'flex', alignItems: 'center', padding: 0 }} >
                                    {
                                        HideAndShowInput()
                                    }
                                </Grid.Col>
                            </Grid>
                            <Group spacing={'xs'} position='center' pt={10}>
                                <Button
                                    disabled={errorShow.UMLField13}
                                    size='sm'
                                    style={{ width: 80 }}
                                    onClick={() =>userMasterListObj?.FIELD03 != "" && userMasterListObj?.FIELD13 != "" ? UserMasterFormSubmit() :GlobalClass.Notify("warning", "Warning:Empty Field", "Empty Field Not Required")}
                                >
                                    Ok
                                </Button>
                                {/* <Button size='sm' onClick={() => dispatch(ModalDelete(props.index))}>Cancel</Button> */}
                            </Group>
                        </> : null
        }
    </>)
}

export default UserMasterForms