import { Grid, Paper, Text } from "@mantine/core";
import React, { forwardRef, useEffect, useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import Store from "../../utils/store";
import { SavePartyData } from "../../utils/TransactionSlices/AutoBillSlice";
import GlobalClass from "../../utils/GlobalClass";

const PartyMaster = forwardRef ((props, ref) =>{
  const [restField, setRestField] = useState({
    groupName1: "A",
    areaName: "A",
    cityName: "A",
    prtData: "A",
  });
  const [multiField, setMultiField] = useState({
    mGroupName1: "",
    mAreaName: "",
    mCityName: "",
    mPrtData: "",
  });

  const [multiCombo, setMultiCombo] = useState({
    gN: false,
    aN: false,
    cN: false,
    pD: false,
  });
  let fieldData = props.data;

  const M01 = fieldData?.P_M01DT?.jData;
  // const M21 = fieldData?.P_M21DT;
  let M31T = fieldData?.P_M31TDT?.jData;
  let M31A = fieldData?.P_M31ADT?.jData;
  let M11 = fieldData?.P_M11DT?.jData;
  const [accData, setAccData] = useState([]);
  useEffect(() => {
    // console.log(multiCombo.cN, "inEff multiCombo");
    if (multiCombo.gN || multiCombo.aN || multiCombo.cN || multiCombo.pD) {
      let accumulatedData = [];
      M01?.map((v) => {
        if (multiCombo.gN) {
          for (let index = 0; index < multiField.mGroupName1.length; index++) {
            const element = multiField.mGroupName1[index];

            if (v?.FIELD05 == element) {
              accumulatedData.push(v);
            }
          }
        }
        //  }
        if (multiCombo.aN) {
          for (let index = 0; index < multiField.mAreaName.length; index++) {
            const element = multiField.mAreaName[index];
            if (v?.FIELD18 == element) {
              accumulatedData.push(v);
            }
          }
        }
        if (multiCombo.cN) {
          for (let index = 0; index < multiField.mCityName.length; index++) {
            const element = multiField.mCityName[index];
            if (v?.FIELD16 == element) {
              accumulatedData.push(v);
            }
          }
        }
      });
      setAccData(accumulatedData);
    }
  }, [fieldData, multiCombo]);
  useEffect(() => {
    if (multiCombo.gN || multiCombo.aN || multiCombo.cN) {
      if (Array.isArray(multiField.mPrtData)) {
        Store.dispatch(SavePartyData(multiField.mPrtData));
      }
    } else {
      Store.dispatch(SavePartyData(""));
    }
  }, [multiField.mPrtData]);
  function renderCombo({ field, data }) {
    // console.log(field, "renderCombo", data);

    return (
      <>
        {data?.length > 0 ? (
          <>
            <PLComboBox
              multiple={true}
              dispexpr="FIELD02"
              valexpr="FIELD01"
              data={data}
              setEdit={(e) => {
                // console.log(e, "restField");
                setMultiField({ ...multiField, [field]: e });
              }}
            />
          </>
        ) : (<>
          <PLComboBox
              disabled={true}
            />
          </>
        )}
      </>
    );
  }

  // console.log(accData, "accData");
  return (
    <>
      {/* <Grid> */}
      {/* <Paper shadow="md" radius="md" p="xs" withBorder>   */}
      <Grid.Col
        span={12}
        style={{ display: "flex", alignItems: "center", padding: 0 }}
      >
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={3}>
            <Text style={{ fontSize: 12 }}>Group Name</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            {/* {restField?.groupName1 == "A" ? ( */}
            <PLComboBox
            onKeyDown={(e) => {
              GlobalClass.formNextInput(e, ref);
            }}
              value={restField?.groupName1}
              dispexpr={props.dispexpr}
              valexpr={props.valexpr}
              data={fieldData?.COP25}
              setEdit={(e) => {
                // console.log(e, "restField");
                setRestField({ ...restField, groupName1: e });
                if (e == "S") {
                  setMultiCombo({ ...multiCombo, gN: true });
                } else {
                  setMultiCombo({ ...multiCombo, gN: false });
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>

        {/* <Grid.Col
          span={12}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        > */}
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={3}>
            <Text style={{ fontSize: 12 }}>Area Name</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <PLComboBox
              // dropdownOpened={multiCombo.aN ? true : false}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, ref);
              }}
              value={restField?.areaName}
              dispexpr={props.dispexpr}
              valexpr={props.valexpr}
              data={fieldData?.COP25}
              // autoOpen={multiCombo.aN ? true : false}
              setEdit={(e) => {
                // console.log(e, "restField");
                setRestField({ ...restField, areaName: e });
                if (e == "S") {
                  setMultiCombo({ ...multiCombo, aN: true });
                } else {
                  setMultiCombo({ ...multiCombo, aN: false });
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      {/* </Grid.Col> */}

      <Grid.Col
        span={12}
        style={{ display: "flex", alignItems: "center", padding: 0 }}
      >
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          {multiCombo.gN ? (
            <>
              <Grid.Col
                span={12}
                style={{ display: "flex", alignItems: "center", padding: 0 }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Select Groups</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  {renderCombo({ field: "mGroupName1", data: M11 })}
                </Grid.Col>
              </Grid.Col>
            </>
          ) : (
            ""
          )}
        </Grid.Col>
        {/* <Grid.Col
          span={12}
          style={{
            display: "flex",
            alignItems: "center",
            padding: 0,
          }}
        > */}
        {multiCombo.aN ? (
          <>
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Select Areas</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                {renderCombo({
                  field: "mAreaName",
                  data: M31A,
                })}
              </Grid.Col>
            </Grid.Col>
            {/* </Grid.Col> */}
          </>
        ) : (
          ""
        )}
      </Grid.Col>
      {/* </Grid.Col> */}
      <Grid.Col
        span={12}
        style={{ display: "flex", alignItems: "center", padding: 0 }}
      >
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={3}>
            <Text style={{ fontSize: 12 }}>City Name</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <PLComboBox
              // dropdownOpened={multiCombo.cN ? true : false}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, ref);
              }}
              value={restField?.cityName}
              dispexpr={props.dispexpr}
              valexpr={props.valexpr}
              data={fieldData?.COP25}
              onSubmit={(e) => {
                // console.log("onSubmit", e);
              }}
              setEdit={(e) => {
                // console.log(e, "restField");
                setRestField({ ...restField, cityName: e });
                // console.log(e, "setRestField");

                if (e == "S") {
                  setMultiCombo({ ...multiCombo, cN: true });
                } else {
                  setMultiCombo({ ...multiCombo, cN: false });
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>

        {/* <Grid.Col
          span={12}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        > */}
        <Grid.Col
          span={6}
          style={{ display: "flex", alignItems: "center", padding: 0 }}
        >
          <Grid.Col span={3}>
            <Text style={{ fontSize: 12 }}>Account Name</Text>
          </Grid.Col>
          <Grid.Col span={9}>
            <PLComboBox
              dropdownOpened={multiCombo.pD ? true : false}
              onKeyDown={(e) => {
                GlobalClass.formNextInput(e, ref);
              }}
              value={restField?.prtData}
              // value ={accData}
              dispexpr={props.dispexpr}
              valexpr={props.valexpr}
              data={fieldData?.COP25}
              setEdit={(e) => {
                // console.log(e, "prtData");

                setRestField({ ...restField, prtData: e });
                if (e == "S") {
                  setMultiCombo({ ...multiCombo, pD: true });
                } else {
                  setMultiCombo({ ...multiCombo, pD: false });
                }
              }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      {/* </Grid.Col> */}
      <Grid.Col
        span={12}
        style={{ display: "flex", alignItems: "center", padding: 0 }}
      >
        <Grid.Col
          span={6}
          style={{
            display: "flex",
            alignItems: "center",
            padding: 0,
          }}
        >
          {multiCombo.cN ? (
            <>
              {/* <Grid.Col
            span={12}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          > */}
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: 0,
                }}
              >
                <Grid.Col span={3}>
                  <Text style={{ fontSize: 12 }}>Select Cities</Text>
                </Grid.Col>
                <Grid.Col span={9}>
                  {renderCombo({
                    field: "mCityName",
                    data: M31T,
                  })}
                </Grid.Col>
              </Grid.Col>
              {/* </Grid.Col> */}
            </>
          ) : (
            ""
          )}
        </Grid.Col>

        {multiCombo.pD ? (
          <>
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 0,
              }}
            >
              <Grid.Col span={3}>
                <Text style={{ fontSize: 12 }}>Select Account Names</Text>
              </Grid.Col>
              <Grid.Col span={9}>
                {renderCombo({ field: "mPrtData", data: accData })}
              </Grid.Col>
            </Grid.Col>
          </>
        ) : (
          ""
        )}
      </Grid.Col>
      {/* </Grid> */}
      {/* </Paper> */}
    </>
  );
})
export default PartyMaster;