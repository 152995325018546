import { ActionIcon, Button, Flex, Group, Text, Tooltip } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useEffect, useState } from "react";
import Store from "../../utils/store";
import { ModalDelete, ModalFunction } from "../../utils/slices/ModalSlice";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLDateBox from "../../PlusComponent/PLDateBox";
import useWindowDimensions from "../../utils/UseWindowDimensions";

const CapitalAccountTable = (props) => {
  // console.log('props==>', props);

  const { height } = useWindowDimensions();
  const [validationErrors, setValidationErrors] = useState({});
  const [M48, setM48] = useState(null);
  const [columns, setColumns] = useState([]);
  let year = new Date().toLocaleString("en-IN", { year: "numeric" });
  let month = new Date().toLocaleString("en-IN", { month: "2-digit" });
  let day = new Date().toLocaleString("en-IN", { day: "2-digit" });

  let startDate1 = year + month + day;
  // console.log(startDate1,"startdate1")
  const [newObj, setNewObj] = useState({
    FIELD01: null,
    FIELD02: null,
    FIELD03: 0,
    FIELD04: null,
    FIELD10: null,
    FIELD11: null,
    FIELD12: null,
    FIELD13: null,
    FIELD14: null,
    FIELD15: null,
    FIELD16: null,
    FIELD17: startDate1,
    FLDUNQ: null,
    FLDAED: null,
    FLDBRC: null,
    EGKEYID: 1,
  });

  //CREATE action
  const handleCreateUser = async ({ values, exitCreatingMode }) => {
    // const newValidationErrors = validateUser(values);
    // if (Object.values(newValidationErrors).some((error) => error)) {
    //   setValidationErrors(newValidationErrors);
    //   return;
    // }
    // console.log('newObj==>', newObj);
    // console.log("vals", values);
    setM48([...M48, newObj]);
    // console.log("M48",M48);
    setValidationErrors({});
    setNewObj({
      FIELD01: 0,
      FIELD02: null,
      FIELD03: 0,
      FIELD04: null,
      FIELD10: null,
      FIELD11: null,
      FIELD12: null,
      FIELD13: null,
      FIELD14: null,
      FIELD15: null,
      FIELD16: null,
      FIELD17: startDate1,
      FLDUNQ: null,
      FLDAED: null,
      FLDBRC: null,
      EGKEYID: 1,
    });
    // await createUser(values);
    exitCreatingMode();
  };
  // console.log('CapitalAccountTable M48=>', M48);

  //UPDATE action
  const handleSaveUser = async ({ values, table, row }) => {
    // const newValidationErrors = validateUser(values);
    // if (Object.values(newValidationErrors).some((error) => error)) {
    //   setValidationErrors(newValidationErrors);
    //   return;
    // }
    // setValidationErrors({});
    // await updateUser(values);

    // console.log("vals",row?.original);
    let newArray = [...M48];
    newArray[row.index] = newObj;
    setM48(newArray);
    // setM48(M48[row.index]:row?.original)
    table.setEditingRow(null); //exit editing mode
  };

  //DELETE action
  const openDeleteConfirmModal = (row) => {
    if (window.confirm("Are you sure to delete selected data?")) {
      const newArr = M48.filter((object, i) => {
        return i !== row.index;
      });
      setM48(newArr);
    }
  };

  const columnHelper = createColumnHelper();

  useEffect(() => {
    // console.log("val");
    if (props?.value) {
      // console.log("helloo");
      setM48(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    // console.log("newObj",newObj);
  }, [newObj]);

  useEffect(() => {
    // console.log("grd");
    if (props.value && props.grd) {
      let cols = [];
      // setM48(props.value);
      // console.log("M48",M48)
      props.grd
        .filter((a) => a.S53F00 == "C")
        .map((d) => {
          // if (d.S53F00 !== "H") {
          // console.log("ddd",d);
          cols.push(
            columnHelper.accessor(d.S53F04, {
              header: d.S53F03,
              size: d.S53F07 == 0 ? 0 : 150,
              // props?.defaultval?.slice(0,4)+"-"+props?.defaultval?.slice(4,6)+"-"+props?.defaultval?.slice(6,8)
              Cell: ({ cell, column, table, row }) => {
                var val = cell.getValue();
                // console.log("val",cell.getValue())
                return d.S53F03 == "Date"
                  ? val
                    ? val.toString().slice(6, 8) +
                      "/" +
                      val.toString().slice(4, 6) +
                      "/" +
                      val.toString().slice(0, 4)
                    : ""
                  : val;
              },
              Edit: ({ cell, column, table, row }) => {
                var val = cell.getValue();
                if (d.S53F03 == "Capital Account") {
                  // return <PLNumberBox
                  //   value={row?.original?.EGKEYID}
                  //   setEdit={(e) => {
                  //     // console.log('Capital Account', e);
                  //     setNewObj({ ...newObj, EGKEYID: e })
                  //   }}
                  // />
                } else if (d.S53F03 == "Date") {
                  // console.log("newObj?.FIELD17",newObj?.FIELD17)
                  return (
                    <PLDateBox
                      defaultval={newObj?.FIELD17}
                      // defaultval={priceData.FIELD11}
                      dispformat="DD/MM/YYYY"
                      setEdit={(e) => {
                        // console.log('Date', e);
                        let year = e.toLocaleString("en-IN", {
                          year: "numeric",
                        });
                        let month = e.toLocaleString("en-IN", {
                          month: "2-digit",
                        });
                        let day = e.toLocaleString("en-IN", { day: "2-digit" });

                        let startDate = year + month + day;
                        // console.log("startDate", startDate)
                        if (startDate?.length == 8) {
                          setNewObj({ ...newObj, FIELD17: startDate });
                        }
                      }}
                    />
                  );
                } else if (d.S53F03 == "Percentage") {
                  return (
                    <PLNumberBox
                      max={100}
                      value={row?.original?.FIELD03 == null ?? 0}
                      setEdit={(e) => {
                        // console.log('Percentage', e);
                        setNewObj({ ...newObj, FIELD03: e });
                      }}
                    />
                  );
                }

                return val;
              },
            })
          );
          // }
        });
      setColumns(cols);
    }
  }, [props.grd, newObj]);

  const table = useMantineReactTable({
    columns,
    enableEditing: true,
    editDisplayMode: "row",
    data: M48 ? M48 : [],
    createDisplayMode: "row",
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      density: "0px",
    },
    // mantineTableContainerProps: { sx: { minHeight: "300px" } },
    mantineTableContainerProps: {
      sx: { height: height * 0.5 },
    },
    // initialState={{ columnVisibility: { address: false } }},
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => table.setEditingRow(row)}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onClick={() => {
          table.setCreatingRow(true);
        }}
      >
        Add New
      </Button>
    ),
    // enableTopToolbar: false
    enableFullScreenToggle: false,
    enableColumnActions: false,
    enableFilters: false,
    enableHiding: false,
    enableDensityToggle: false,
  });

  const submitCapitalAccount = () => {
    console.log("submitCapitalAccount ok btn click");
    Promise.resolve(props.getM48(M48))
      .then(() => props?.setDrawerOBJ({ ...props?.DrawerOBJ, open: false }))
      .then(() => {
        props?.UserFieldFun();
      });

    // Store.dispatch(ModalFunction({
    //   MTitle: '',
    //   MAction: false,
    //   MSize: '70%',
    //   MBody: () => (''),
    //   MClose: false
    // }))
    // props.UserFieldFun(props.SelectedRowObj)
  };

  return (
    <>
      <MantineReactTable table={table} />
      <Group
        spacing={"xs"}
        position="center"
        display={"flex"}
        style={{ marginTop: 5 }}
      >
        <Button
          size="sm"
          style={{ width: 100 }}
          onClick={() => submitCapitalAccount()}
          // disabled={submitBtnDisable}
        >
          Ok
        </Button>
      </Group>
    </>
  );
};

export default CapitalAccountTable;
