import React, { useState } from "react";
import { Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import Store from "../../utils/store";
import { DeleteAreaData } from "../../utils/slices/AreaListSlice";
import AreaListForm from "./AreaListForm";
import { ModalFunction } from "../../utils/slices/ModalSlice";

export function MSAED(data, Skey) {
  const OBJ = JSON.parse(data);

  if (OBJ.p0 === "A") {

    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "sm",
        position:"right",
        MBody: (i) => (
          <AreaListForm OBJ={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )

    //================================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <AreaListForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "E" && !OBJ.id == "") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={700}>{OBJ?.text}</Text>,
        MAction: true,
        MSize: "sm",
        position:"right",
        MBody: (i) => (
          <AreaListForm OBJ={OBJ} index={i} />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        }
      })
    )
    //================================================
    // modals.open({
    //   title: <Text fw={700}>{OBJ?.text}</Text>,
    //   size: "50%",
    //   children: <AreaListForm OBJ={OBJ} />,
    // });
  } else if (OBJ.p0 === "D" && !OBJ.id == "") {
    // console.log("MSAED", OBJ.id);

    if (window.confirm("Are you sure to delete selected data?")) {
      Store.dispatch(DeleteAreaData({ cCode: OBJ.id, cAction: OBJ.p0 }));
    } else {
      return null;
    }
  }
}

export default MSAED;