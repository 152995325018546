import React, { useState } from "react";
import {
  Button,
  Center,
  Checkbox,
  FileButton,
  Grid,
  Group,
  PasswordInput,
  Text,
  Textarea,
} from "@mantine/core";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLTextBox from "../../PlusComponent/PLTextBox";
export default function AdvanceBtn() {
  const [AdvanceFormObjShow, setAdvanceFormObjShow] = useState({
    StartPage: "1",
    EndPage: "1",
    PageRange: "1",
    StartingPageNo: "1",
  });
  return (
    <Grid
      gutter={4}
      style={{ display: "flex", flexDirection: "column", fontSize: "12px" }}
    >
      <Text
        style={{
          alignSelf: "center",
          backgroundColor: "#228be61a",
          color: "#228be6",
          fontSize: "14px",
        }}
      >
        Printing option
      </Text>
      <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Start Page</Text>
          </Grid.Col>

          <Grid.Col span={8}>
            <PLNumberBox
              value={AdvanceFormObjShow?.StartPage}
              setEdit={(e) => {
                setAdvanceFormObjShow({ ...AdvanceFormObjShow, StartPage: e });
              }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>End Page</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLNumberBox
              value={AdvanceFormObjShow?.EndPage}
              setEdit={(e) => {
                setAdvanceFormObjShow({ ...AdvanceFormObjShow, EndPage: e });
              }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
      >
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Page Range</Text>
          </Grid.Col>
          <Grid.Col span={8} style={{ paddingBottom: "0px" }}>
            <PLComboBox
            //   data={CBOPRN}
            //   valexpr={"ValueMember"}
            //   dispexpr={"DisplayMember"}
            //   value={AdvanceFormObjShow?.PageRange}
            //   setEdit={(e) => {
            //     setAdvanceFormObjShow({
            //       ...AdvanceFormObjShow,
            //       PageRange: e,
            //     });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Starting Page No.</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLNumberBox
            //   value={AdvanceFormObjShow?.StartingPageNo}
            //   setEdit={(e) => {
            //     setAdvanceFormObjShow({
            //       ...AdvanceFormObjShow,
            //       StartingPageNo: e,
            //     });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col span={12} style={{ display: "flex", flexDirection: "row" }}>
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Left Margin</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLNumberBox
              precision={3}
            //   value={AdvanceFormObj?.nLeftMargin}
            //   setEdit={(e) => {
            //     setAdvanceFormObj({ ...AdvanceFormObj, nLeftMargin: e });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Top Margin</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLNumberBox
              precision={3}
            //   value={AdvanceFormObj?.nTopMargin}
            //   setEdit={(e) => {
            //     setAdvanceFormObj({ ...AdvanceFormObj, nTopMargin: e });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
      >
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Paper Size</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLComboBox
            //   data={CBOPPR}
            //   valexpr={"ValueMember"}
            //   dispexpr={"DisplayMember"}
            //   value={AdvanceFormObj?.cPaperSize}
            //   setEdit={(e) => {
            //     setAdvanceFormObj({ ...AdvanceFormObj, cPaperSize: e });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
      >
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Line</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLComboBox
            //   data={CBOF21}
            //   valexpr={"ValueMember"}
            //   dispexpr={"DisplayMember"}
            //   value={AdvanceFormObj?.HLine}
            //   setEdit={(e) => {
            //     setAdvanceFormObj({ ...AdvanceFormObj, HLine: e?.value });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Vertical Line</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLComboBox
            //   data={CBOF21}
            //   valexpr={"ValueMember"}
            //   dispexpr={"DisplayMember"}
            //   value={AdvanceFormObj?.VLine}
            //   setEdit={(e) => {
            //     setAdvanceFormObj({
            //       ...AdvanceFormObj,
            //       VLine: e?.value,
            //     });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Text
        style={{
          alignSelf: "center",
          backgroundColor: "#228be61a",
          color: "#228be6",
          fontSize: "14px",
        }}
      >
        Report Title ( Advance Setup )
      </Text>
      <Grid.Col
        span={12}
        style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
      >
        <Grid.Col span={2}>
          <Text>Report Title</Text>
        </Grid.Col>
        {/* <Grid.Col span={1}></Grid.Col> */}
        <Grid.Col span={10}>
          <PLTextBox
            // value={AdvanceFormObj?.cReportTitle}
            // setEdit={(e) => {
            //   setAdvanceFormObj({
            //     ...AdvanceFormObj,
            //     cReportTitle: e.target.value,
            //   });
            // }}
          />
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{ display: "flex", flexDirection: "row", maxHeight: "47px" }}
      >
        <Grid.Col span={2}>
          <Text>Report Footer</Text>
        </Grid.Col>
        <Grid.Col span={10}>
          <PLTextBox
            // value={AdvanceFormObj?.cReportFooter}
            // setEdit={(e) => {
            //   setAdvanceFormObj({
            //     ...AdvanceFormObj,
            //     cReportFooter: e.target.value,
            //   });
            // }}
          />
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{ display: "flex", flexDirection: "row", maxHeight: "45px" }}
      >
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Company Name On</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLComboBox
            //   data={CBONMON}
            //   valexpr={"ValueMember"}
            //   dispexpr={"DisplayMember"}
            //   value={AdvanceFormObj?.nCNameOn}
            //   setEdit={(e) => {
            //     console.log(e);
            //     setAdvanceFormObj({
            //       ...AdvanceFormObj,
            //       nCNameOn: e,
            //     });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Report Header On</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLComboBox
            //   data={CBONMON}
            //   valexpr={"ValueMember"}
            //   dispexpr={"DisplayMember"}
            //   value={AdvanceFormObj?.nRHeaderOn}
            //   setEdit={(e) => {
            //     setAdvanceFormObj({
            //       ...AdvanceFormObj,
            //       nRHeaderOn: e,
            //     });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={6} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Text>Footer On</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLComboBox
            //   data={CBONMON}
            //   valexpr={"ValueMember"}
            //   dispexpr={"DisplayMember"}
            //   value={AdvanceFormObj?.nRFooterOn}
            //   setEdit={(e) => {
            //     setAdvanceFormObj({
            //       ...AdvanceFormObj,
            //       nRFooterOn: e,
            //     });
            //   }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "0px",
        }}
      >
        <Grid.Col span={11} style={{ display: "flex", flexDirection: "row" }}>
          <Grid.Col span={4}>
            <Button
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginRight: "0px",
              }}
            >
              Default
            </Button>
          </Grid.Col>

          <Grid.Col span={3}>
            <Button
              //   onClick={customPageHandler}
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginRight: "0px",
              }}
            >
              Add Custom Page
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button
              //   onClick={advanceController}
              style={{
                paddingLeft: "3px",
                paddingRight: "3px",
                marginLeft: "0px",
              }}
            >
              Save As Default
            </Button>
          </Grid.Col>
        </Grid.Col>
        {/* <Grid.Col span={2}></Grid.Col> */}
        <Grid.Col span={1}>
          <Button
            // onClick={advanceController}
            style={{
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Back
          </Button>
        </Grid.Col>
      </Grid.Col>
    </Grid>
  );
}
