import React, { useState } from "react";
import FormatForm from "../FormatForm";
import { Drawer, Text } from "@mantine/core";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
const dispatchModal = (obj) => {
  Store.dispatch(
    ModalFunction({
      MTitle: <Text fw={700}>{obj?.text}</Text>,
      MAction: true,
      MSize: "50%",
      MBody: (i) => <FormatForm obj={obj} index={i} />,
      MClose: true,
      onclose: () => {},
      onClickCloseButton: () => {
        // Store.dispatch(GetInvTypeDropDown())
      },
    })
  );
};
export function SMSAED(data) {
  const obj = JSON.parse(data);
  if (obj?.p0 == "A") {
    dispatchModal(obj);
  }
}
export function EMLAED(data) {
  // console.log(data, "data--");
  const obj = JSON.parse(data);
  if (obj?.p0 == "A") {
    dispatchModal(obj);
  }
}
export function WAPAED(data) {
  // console.log(data, "data--");
  const obj = JSON.parse(data);
  if (obj?.p0 == "A") {
    dispatchModal(obj);
  }
}
