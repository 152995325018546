import {
  Button,
  Center,
  Divider,
  Drawer,
  Grid,
  Group,
  Modal,
  Paper,
  Table,
  Text,
  Textarea,
} from "@mantine/core";
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useState } from "react";
import PLComboBox from "../../../PlusComponent/PLComboBox";

export default function QueryGenerator() {
    const [opened, setOpened] = useState(false);
    const [opened2, setOpened2] = useState(false);
 
  const fieldDropDown = (props) => {
    console.log(props, "props==");
    return (
      <PLComboBox
      // value={"a"}
      // data={["a", "b"]}
      // setEdit={(e) => {
      //   //console.log(e)
      //   setGroup({ ...group, [h?.S13F02]: e.target.value });
      // }}
      />
    );
  };
  function enterVal() {
    setOpened(true);
  }
  function enterScript() {
    setOpened2(true);
  }
  function valueForm(props) {
    let res;
    switch (props) {
      case "v":
        return (res = (
          <Grid>
            <Grid.Col span={12}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid.Col span={4}>
                  <Text style={{ fontSize: 12 }}>Select Type</Text>
                </Grid.Col>
                <Grid.Col span={5}>
                  <PLComboBox
                  // value={group?.FIELD10}
                  // setEdit={(e) => {
                  //   setGroup({ ...group, FIELD10: e?.target?.value });
                  // }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid.Col span={4}>
                  <Text style={{ fontSize: 12 }}>Enter Value</Text>
                </Grid.Col>
                <Grid.Col span={5}>
                  <Textarea
                  // value={group?.FIELD10}
                  // setEdit={(e) => {
                  //   setGroup({ ...group, FIELD10: e?.target?.value });
                  // }}
                  />
                </Grid.Col>
              </Grid.Col>
              <Center>
                <Button size="xs" compact>Ok</Button>
              </Center>
            </Grid.Col>
          </Grid>
        ));
        break;
      case "s":
        return (res = (
          <Grid>
            <Grid.Col span={12}>
              <Textarea
                mb={5}
                // value={group?.FIELD10}
                // setEdit={(e) => {
                //   setGroup({ ...group, FIELD10: e?.target?.value });
                // }}
              />

              <Center>
                <Button size="xs" compact>Exit</Button>
              </Center>
            </Grid.Col>
          </Grid>
        ));
        break;

      default:
        return (res = []);
        break;
    }
  }
  let columnData = [
    {
      field: "field",
      label: "FIELD",
      operators: [
        { key: "Contains", value: "Contains" },
        { key: "Not Contains", value: "Not Contains" },
      ],
      template: fieldDropDown,
    },
    {
      field: "function",
      label: "FUNCTION",
      operators: [
        { key: "Contains", value: "Contains" },
        { key: "Not Contains", value: "Not Contains" },
      ],
      template: fieldDropDown,
    },
    {
      field: "operator",
      label: "OPERATOR",
      operators: [
        { key: "Contains", value: "Contains" },
        { key: "Not Contains", value: "Not Contains" },
      ],
      template: fieldDropDown,
    },
    {
      field: "value",
      label: "VALUE",
      operators: [
        { key: "Contains", value: "Contains" },
        { key: "Not Contains", value: "Not Contains" },
      ],
      template: enterVal,
    },
    {
      field: "listfield",
      label: "LIST FIELD",
      operators: [
        { key: "Contains", value: "Contains" },
        { key: "Not Contains", value: "Not Contains" },
      ],
      template: fieldDropDown,
    },
    {
      field: "script",
      label: "SCRIPT",
      operators: [
        { key: "Contains", value: "Contains" },
        { key: "Not Contains", value: "Not Contains" },
      ],
      template: enterScript,
    },
  ];
  return (
    <>
    <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        // title={<Text fw={700}>Pending Bills</Text>}
        padding="md"
        size="md"
        position="right"
        // open="true"
      >
        {valueForm("v")}
      </Modal>
      <Modal
        opened={opened2}
        onClose={() => setOpened2(false)}
        title={<Text fw={700}>Expression</Text>}
        padding="md"
        size="md"
        position="right"
        // open="true"
      >
        {valueForm("s")}
      </Modal>
      <Paper shadow="md" radius="md" p="xs" withBorder>

      <Grid>
        <Grid.Col>
          <QueryBuilderComponent width="100%" columns={columnData} />
        </Grid.Col>
        <Grid.Col>
          <Center>
            <Group justify="center">
              <Button size="xs" compact>OK</Button>
              <Button size="xs" compact>Delete All</Button>
              <Button size="xs" compact>Cancel</Button>
            </Group>
          </Center>
        </Grid.Col>
      </Grid>
      </Paper>
    </>
  );
}
