import React from 'react'
import WaterMarkList from './WaterMarkList';
import { ModalFunction } from '../../../utils/slices/ModalSlice';
import Store from '../../../utils/store';
import { Text } from '@mantine/core';
import GlobalClass from '../../../utils/GlobalClass';
import { DeleteWaterMarkList } from '../../../utils/slices/CompanySetUpPopUpSlice';

export const WaterMarkListFun = () => {
  return (
    <div>WaterMarkListFun</div>
  )
}

export function WMKAED(data) {
  const OBJ = JSON.parse(data);
  console.log('WMKAED OBJ', OBJ)
  if (OBJ.p0 === "A") {
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: 'lg',
      position:"right",
      MBody: (i) => (<WaterMarkList obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { }
    }))
  } else if (OBJ.p0 === "E" && OBJ?.WMF01) {
    Store.dispatch(ModalFunction({
      MTitle: <Text fw={700}>{OBJ?.text}</Text>,
      MAction: true,
      MSize: 'lg',
      position:"right",
      MBody: (i) => (<WaterMarkList obj={OBJ} index={i} />),
      MClose: true,
      onclose: () => { }
    }))
  } else if (OBJ.p0 === "D" && OBJ?.WMF01) {
    if(window.confirm('Confirm to Delete ?')) {
      Promise.resolve(GlobalClass.Notify("info", "Info: " + "Delete Progress", "Please Wait Fetching Data")).then(() => Store.dispatch(DeleteWaterMarkList({action: OBJ?.p0, code : OBJ?.WMF01})))
    }
  } else {
    return null
  }
}

