import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";


export const GetGeneralData = createAsyncThunk(
    "MailingLetter/GetGeneralData",
    async (obj) => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cRepId": "P_LETTER",
                    "cFmtID": "TFORM0000001",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GenData + "?pa=" + param);
            console.log("GetAccountList response=>>", response.data.data.jData);
            return response.data.data.jData;
        } catch (error) {
            return { error }
        }
    }

)

export const GetGeneralTMFormat = createAsyncThunk(
    "MailingLetter/GetGeneralTMFormat",
    async () => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cRepId": "P_LETTER",
                    "cFmtID": "TFORM0000001",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetTMFormat + "?pa=" + param);
            console.log("GetTMFormat response=>>", response.data.data);
            return response.data.data;
        } catch (error) {
            return { error }
        }
    }
)

export const GetMailingLatter = createAsyncThunk(
    "MailingLetter/GetMailingLatter",
    async ({ OBJ, MLF01 }) => {
        console.log(OBJ, MLF01, "MailingLetter");
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0,
                    "cCode": OBJ.p0 == "E" ? MLF01 : "",
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetMailingLatter + "?pa=" + param);
            console.log("GetMailingLatter response=>>", response.data.data);
            return response.data.data;
        } catch (error) {
            return { error }
        }
    }


)

export const GetMailingLatterDeleteApi = createAsyncThunk(
    "MailingLetter/GetMailingLatterDeleteApi",
    async (OBJ, thunkAPI) => {
        const state = thunkAPI.getState();
        const MLF01 = state.MailingLetter.MLF01;
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0,
                    "cCode": MLF01 ?? "",
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetMailingLatter + "?pa=" + param);
            console.log("GetMailingLatterDeleteApi response=>>", response.data.status);
            return response.data.data;
        } catch (error) {
            return { error }
        }
    }


)

const MailingLetter = createSlice({
    name: "MailingLetter",
    initialState: {
        GeneralData: [],
        TMFormatData: [],
        isLoading: false,
        isRefetching: false,
        ErrorMsg: false,
        MailingLetterData: [],
        MLF01: '',
        MailingLetterDeleteStatus: false,
    },
    reducers: {
        getMLF01: (state, action) => {
            console.log(action.payload, "reducer");
            state.MLF01 = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetGeneralData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetGeneralData.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.GeneralData = action?.payload
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = false;
                }
                else {
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetGeneralData.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetGeneralTMFormat.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetGeneralTMFormat.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.TMFormatData = action?.payload
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = false;
                }
                else {
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetGeneralTMFormat.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetMailingLatter.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetMailingLatter.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.MailingLetterData = action?.payload
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = false;
                }
                else {
                    state.isLoading = false;
                    state.isRefetching = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetMailingLatter.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetMailingLatterDeleteApi.pending, (state, action) => {
                // state.isLoading = true;
                // state.hasError = false;
            })
            .addCase(GetMailingLatterDeleteApi.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    if (action.payload == "SUCCESS") {
                        state.MailingLetterDeleteStatus = true
                    }
                    else {
                        state.MailingLetterDeleteStatus = true
                    }
                    console.log("MailingLetterDeleteStatus", state.MailingLetterDeleteStatus);
                    // state.isLoading = false;
                    // state.isRefetching = false;
                    // state.hasError = false;
                }
                else {
                    // state.isLoading = false;
                    // state.isRefetching = false;
                    // state.hasError = true;
                    // state.ErrorMsg = action.payload?.error
                }
            })
            .addCase(GetMailingLatterDeleteApi.rejected, (state, action) => {
                // state.hasError = true
                // state.isLoading = false;
            })

    }
})

export default MailingLetter.reducer;
export const { getMLF01 } = MailingLetter.actions;