import { Button, Grid, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import UserFieldAutoNumForm from './UserFieldAutoNumForm'
import { modals } from '@mantine/modals'
import Store from '../../../utils/store'
import { ModalFunction } from '../../../utils/slices/ModalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { GetAccountList, GetTMFormat } from '../../../utils/slices/AccountListSlice'
import { GetAutoNumGenData, GetAutoNumTMFormat } from '../../../utils/slices/CompanySetUpPopUpSlice'
import { createColumnHelper } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import GlobalClass from '../../../utils/GlobalClass'
import ButtonPanel from '../../../PlusComponent/ButtonPanel'

const UserFieldAutoNumModal = (props) => {
    const { data, obj } = props
    console.log('UserFieldAutoNumModal props', props)
    const isLoading = useSelector((state) => state.CompanySetupPopUp?.isLoading)
    const autoNumTmFormat = useSelector((state) => state.CompanySetupPopUp?.cmpSetUpTMData)
    const autoNumGenData = useSelector((state) => state.CompanySetupPopUp?.cmpSetUpGenData?.jData)

    const dispatch = useDispatch()
    useEffect(() => {
        if (data) {
            Promise.resolve(dispatch(GetAutoNumGenData(data))).then(() => dispatch(GetAutoNumTMFormat(data)))
        }
    }, [data])

    const [columns, setColumns] = useState([])
    const [PT95, setPT95] = useState([])
    const [rowSelection, setRowSelection] = useState({});
    // console.log('PT95===>>', PT95)

    useEffect(() => {
        if (autoNumGenData) {
            setPT95(autoNumGenData)
        }
    }, [autoNumGenData])

    const columnHelper = createColumnHelper()

    useEffect(() => {
        if (autoNumTmFormat && autoNumTmFormat?.oDCFrmt && autoNumTmFormat?.oDCFrmt?.aDCol) {
            var cols = [];
            autoNumTmFormat?.oDCFrmt?.aDCol?.map((v, i) => {
                cols.push(
                    columnHelper.accessor(v?.DispExpr, {
                        header: v?.ColCap,
                        enableColumnFilter: v.lSearchCol,
                        enableSorting: v.lColOrder,
                        size: v.ColWidth,
                        minSize: 0,
                        maxSize: v.ColWidth,
                        Cell: ({ cell }) => {
                            var val = cell.getValue()
                            return val
                        }
                    })
                )
            })
            setColumns(cols)
        }
    }, [autoNumTmFormat])

    const simulateEnterKeyPress = () => {
        // Get the row element using the rowIndex
        const rowElement = document.querySelector(`table`);
        if (rowElement) {

            // Create a new KeyboardEvent with the "Enter" key
            const event = new KeyboardEvent("keydown", {
                key: "Enter",
                bubbles: true,
                cancelable: true,
            });

            // Dispatch the event on the row element
            rowElement.dispatchEvent(event);
        }
    }

    const table = useMantineReactTable({
        data: PT95 ?? [],
        columns,
        enableColumnOrdering: true,
        enableGlobalFilter: false,
        enableDensityToggle: false,
        enableTopToolbar: false,
        enableRowDragging: false,
        enableStickyHeader: true,
        enableColumnDragging: false,
        mantineTableContainerProps: { sx: { maxHeight: '600px' } },
        enableSelectAll: false,
        enableMultiRowSelection: false,
        enableTableFooter: false,
        enableColumnActions: false,
        // initialState: {
        //     //   grouping,
        //     density: '0px',
        //     expanded: true
        // },
        state: {
            density: "0px",
            rowSelection
        },
        enableRowVirtualization: false,
        mantineTableBodyRowProps: ({ row }) => ({
            onClick: () =>
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                })),
            onDoubleClick: () => {
                Promise.resolve(
                    setRowSelection((prev) => ({
                        [row.id]: true,
                    }))
                ).then(() => {
                    simulateEnterKeyPress()
                })
            },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        })
    })

    useEffect(() => {

        console.log('autoNumTmFormat=>>', autoNumTmFormat)
        console.log('autoNumGenData=>>', autoNumGenData)
    }, [autoNumTmFormat, autoNumGenData])

    return (
        <>
            {
                isLoading == true ? GlobalClass.RenderLoader("dots") :
                    <>
                        <Grid gutter={4}>
                            <Grid.Col span={12}>
                                <MantineReactTable table={table} />
                            </Grid.Col>
                        </Grid>

                        <Grid gutter={4}>
                            <Grid.Col span={12}>
                                <ButtonPanel
                                    data={autoNumTmFormat?.oS60?.DTHKey}
                                    Skey={table?.getSelectedRowModel()?.flatRows[0]}
                                    from={props?.obj?.id}
                                />
                            </Grid.Col>
                        </Grid>
                    </>
            }
        </>
    )
}

export default UserFieldAutoNumModal