import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetBillType } from '../../utils/slices/CompanySetUpPopUpSlice'

const BillType = (props) => {
    const { obj } = props
    const BillTypeData = useSelector((state) => state.CompanySetupPopUp?.BillTypeData)
    const DBS53DT = useSelector((state) => state.CompanySetupPopUp?.BillTypeData?.DBS53DT)
    const POSBTDT = useSelector((state) => state.CompanySetupPopUp?.BillTypeData?.POSBTDT)

    console.log('BillType==>>', BillTypeData)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetBillType())
    }, [])

    return (
        <div>BillType</div>
    )
}

export default BillType