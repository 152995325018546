import React, { useState } from "react";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Loader, Text } from "@mantine/core";
import BillFormChallanModal from "./BillFormChallanModal";
import { TransactionDeleteApi } from "../../../utils/TransactionSlices/Transaction";
import { GetInvTypeDropDown } from "../../../utils/TransactionSlices/TSalesSlices";
import { RefreshIOrderPopUp, RefreshIssuePopUp } from "../../../utils/TransactionSlices/PendingIssueSlice";

export function VouEntAct(props) {
  console.log(props, "VouEntAct--");

  const data = JSON.parse(props);

  if (data?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null))
        },
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "80%",
        MBody: (i) => (
          <BillFormChallanModal
            obj={data}
            index={i}
            No="Voucher No"
            date="Voucher Date"
          />
       
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([Store.dispatch(RefreshIssuePopUp({})), Store.dispatch(RefreshIOrderPopUp({}))])
      }
      })
    );
  } else if (data?.id && data?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onClickCloseButton: () => {
          Store.dispatch(GetInvTypeDropDown(null))
        },
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "80%",
        MBody: (i) => <BillFormChallanModal obj={data} index={i} No="Voucher No"
        date="Voucher Date"/>,
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([Store.dispatch(RefreshIssuePopUp({})), Store.dispatch(RefreshIOrderPopUp({}))])
      }
      })
    );
  } else if (data.p0 === "D" && data.id) {
    if (window.confirm("Confirm to Delete ?")) {
      Store.dispatch(TransactionDeleteApi(data));
    }
  }
}
