import { Button, FileButton, FileInput, Grid, Group, Text } from '@mantine/core'
import React, { useState } from 'react'
import PLRadioButton from '../../PlusComponent/PLRadioButton'
import PLTextBox from '../../PlusComponent/PLTextBox'

const DataimportForm = (props) => {
  const { obj } = props

  const [RadioVal, setRadioVal] = useState('')
  const [file, setFile] = useState(null)

  const RadioData = [
    {
      value: "internal",
      label: "Internal"
    },
    {
      value: "other",
      label: "Other"
    },
  ]
  return (
    <>
      <Grid gutter={4}>
        <Text>Import Form</Text>

        <Grid.Col span={12} style={{ display: 'flex' }}>
          <Grid.Col span={3}>
            <PLRadioButton
              data={RadioData}
              value={RadioVal}
              onChangeVal={(e) => {
                setRadioVal(e)
              }}
            />
          </Grid.Col>

          <Grid.Col span={9}>
            <Grid.Col span={12}>
              <PLTextBox 
              setEdit={(e) => {
                        
              }}
              />
            </Grid.Col>
            <Grid.Col span={12} style={{ display: 'flex' }}>
              <Grid.Col span={11}>
              <FileInput />
              </Grid.Col>
              <Grid.Col span={1}>
                <Group>
                  <FileButton onChange={setFile}>
                    {(props) => <Button variant='light' size='xs' {...props}>...</Button>}
                  </FileButton>
                </Group>
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
        </Grid.Col>
        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Group>
            <Button>OK</Button>
            <Button>Cancel</Button>
          </Group>
        </Grid.Col>
      </Grid>
    </>
  )
}

export default DataimportForm