import { Button, Center, Grid, Text } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import PLDataGrid from '../../../PlusComponent/PLDataGrid'

const CDExpenseEntry = (props) => {
  const { dtExp_I, expenseArr, isExpense, M01PData, M01Format, T02ObjSend, setT02Objsend, DrawerOBJSend, setDrawerOBJSend, expenseArrDef } = props
// console.log('T02ObjSend=>>',T02ObjSend)
  const [M01D, setM01D] = useState([])
  const [M01F, setM01F] = useState(null)
  const [FieldObj, SetFieldObj] = useState(null)

  useEffect(() => {
    if (T02ObjSend) {
      SetFieldObj(T02ObjSend)
    }
  }, [T02ObjSend])

  useEffect(() => {
    if (M01PData && M01Format) {
      setM01D(M01PData)
      setM01F(M01Format)
    }
  }, [M01PData, M01Format])

  const SaveExpenseDetail = () => {
    console.log('FieldObj',FieldObj)
    Promise.resolve(setT02Objsend(FieldObj)).then(() => setDrawerOBJSend({...DrawerOBJSend, open:false}))
  }

  return (
    <>

      <Grid gutter={4}>
        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid.Col span={4}></Grid.Col>
          <Grid.Col span={4}></Grid.Col>
          <Grid.Col span={4}>
            <Text size={12} style={{ fontWeight: "bold" }}>Item Amount : {T02ObjSend?.FIELD08 + " DB"}</Text>
          </Grid.Col>
        </Grid.Col>
      </Grid>

      <Grid gutter={4}>

        <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
          <Grid.Col span={3} style={{ fontSize: 12 }}>Sales A/C.</Grid.Col>
          <Grid.Col span={5}>
            <PLDataGrid
              width={'500px'}
              TmData={M01F}
              data={M01D}
              setEdit={(e) => {
                // console.log(e)
                SetFieldObj({ ...FieldObj, FIELD16: e?.FIELD01 })
              }}
            />
          </Grid.Col>
        </Grid.Col>
      </Grid>

      <Grid gutter={4} >
        <Grid.Col span={12} style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
          <Grid.Col span={4}>

          </Grid.Col>
          <Grid.Col span={4}>
            <Button onClick={() => SaveExpenseDetail()}>OK</Button>
          </Grid.Col>
          <Grid.Col span={4}>
            <Text size={12} style={{ fontWeight: 'bold' }}>Total Amount : {T02ObjSend?.FIELD08 + " DB"}</Text>

          </Grid.Col>

        </Grid.Col>

      </Grid>
    </>
  )
}

export default CDExpenseEntry