import {
  ActionIcon,
  Button,
  Drawer,
  Flex,
  Grid,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import GlobalClass from "../../utils/GlobalClass";
import Store from "../../utils/store";
import { BatchLogAddRemoveFun } from "../../utils/TransactionSlices/Transaction";
import PLDateBox from "../../PlusComponent/PLDateBox";
import SITableUserField from "../SaleEntry/SITableUserField";
import { createColumnHelper } from "@tanstack/react-table";
import ExpenseEntryForm from "../SaleEntry/ExpenseEntryForm";
import { useSelector } from "react-redux";
import {
  GetJOrderData,
  GetJWListData,
  getPendingIssueData,
} from "../../utils/TransactionSlices/PendingIssueSlice";
import gensetting from "../../utils/gensetting";
import PendingIssue from "../JobWorkOut/RIOJobOutForm/PendingIssue";
import PendingOrder from "../JobWorkOut/RIOJobOutForm/PendingOrder";
import AssignItemDefaultFun, {
  AssignItemDefault,
  MidCalc,
  RecalcFun,
  RefMe,
  RetTaxAcc,
} from "../../Transaction/SaleEntry/AssignItemDefaultFun";

export default function T02Table(props) {
  let {
    accList,
    tmList,
    dataEnt,
    obj,
    setT02Data,
    t02Data,
    t02Obj,
    setT02Obj,
    t41: group,
    setGroup,
    setTableExpenseVal,
    ExpenseData,
    InvTypeDropDown,
    selectedProductObj,
    setSelectedProductObj,
    // NarrationBox,
    // JWListData,
    // JWOrderData,
    ExpenseObj,
  } = props;

  const oYear = useSelector(
    (state) => state?.Language?.General?.oYear?.OSC?._JYSUBJOB
  );
  const { JWListData, JWOrderData } = useSelector(
    (state) => state?.GetPendingIssue
  );

  //----Usestate Declaration--//
  // console.log("productObj", selectedProductObj);
  const [columns, setColumns] = useState([]);

  const [t02DataENT, setT02DataENT] = useState([]);
  const [batch, setBatch] = useState(false);
  const [tableEditingRow, setTableEditingRow] = useState(null);
  const [TableExpense, setTableExpense] = useState([]);
  const [FIELD01Value, setFIELD01value] = useState("");
  const [BatchIssueObj, setBatchIssueObj] = useState(null);
  const [orderPopUp, setOrderPopUp] = useState(false);
  const [flag, setFlag] = useState(false);
  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [LocationObj, setLocationObj] = useState(null);
  const [recIss, setRecIss] = useState("");
  const [pendingIssue, setPendingIssue] = useState([]);
  const [pendingOrder, setPendingOrder] = useState([]);
  const [cTypes, setcType] = useState("");
  const [orderArr, setOrderArr] = useState({
    productCode: "",
    partyCode: "",
    dateCode: "",
  });
  const [issueArr, setIssueArr] = useState({
    productCode: "",
    partyCode: "",
    processCode: "",
    dateCode: "",
  });
  const [ExpenseArrayT02, setExpenseArrayT02] = useState([]);
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
    onClickCloseButton: () => {},
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [rowSelection, setRowSelection] = useState({});
  const [createFlag, setCreateFlag] = useState(false);
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    Group: "",
    Catogary: "",
    Product: "",
  });
  const [batchTrue, setBatchTrue] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [empty, setEmpty] = useState(false);

  const prt = useRef(null);
  const prCt = useRef(null);
  // const addBtnref = useRef(null)

  // const jw = useSelector((state) => state?.GetPendingIssue?.JWOrderData);
  //--Variable Declaration stateless --//
  const p0 = obj.p0;
  const billPage = obj.id;
  const T41Obj = dataEnt?.T41;
  const T02 = dataEnt?.T02;
  const T02UF = dataEnt?.T02UF;
  const dtUFT11 = dataEnt?.dtUFT11;
  const PLDATA = dataEnt?.PLDATA;
  const OSV = dataEnt?.OSV;
  const OS11 = dataEnt?.OS11;
  let jwOrder, jwIssue;

  const page =
    billPage == "04080399" || billPage == "20452041" || billPage == "04290417"
      ? billPage
      : obj?.page;

  const columnHelper = createColumnHelper();
  useEffect(() => {
    if (T02) {
      if (obj.p0 == "E") {
        // setT02Obj({...T02?.[T02?.length - 1],ExpAmount:0});
        setT02Data(T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        // setT02Obj({...T02?.[0],ExpAmount:0})
        setT02Data(T02?.filter((t) => t?.FIELD03 != "" && t?.FIELD03 != null));
        setT02Obj(T02?.[0]);
      }
    }
  }, [T02]);
  // console.log(ExpenseObj, "ExpenseObj--")
  // useEffect(() => {
  //   if(!isLoading && !hasError){
  //     console.log(hasError, "hasError")
  //    Promise.resolve().then(()=>setOrderData(JWOrderData))
  //   }
  // }, [isLoading, hasError])

  function modeOn(arg) {
    if (
      props?.setCreatingModeOn &&
      typeof props?.setCreatingModeOn == "function"
    ) {
      props?.setCreatingModeOn(arg);
    }
  }
  function setIsVisible(arg) {
    if (props?.setIsVisible && typeof props?.setIsVisible == "function") {
      props?.setIsVisible(arg);
    }
  }
  const Summary = (obj, i, tag) => {
    // let stype = "sum";
    var value = "";
    switch (obj?.S53F02) {
      // case "E000T02F03":
      //   value = <>Total Entry: {t02Data?.length} </>;

      //   break;
      case "E00T02F08":
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD08), 0);

        break;
      case "E00T02F21":
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD21), 0);

        break;
      case "E00T02F06":
        value = t02Data?.reduce((a, b) => (a = a + b?.FIELD33), 0);

        break;
      default:
        value = null;
        break;
    }
    return value;
  };
  const NextIndexFun = (index) => {
    // console.log("NextIndexFun==", index)
    const nextInd = index + 1;
    const nextInput = document.getElementById(`T02Ent_${nextInd}`);
    if (nextInput) {
      nextInput?.focus();
    }
  };
  const seperate = (e) => {
    var paraArr = e.split("~C~");
    console.log("paraArr=>", paraArr);
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };
  function DateToString(e) {
    // console.log(e, "Datetostring");
    let year = e.toLocaleString("en-IN", { year: "numeric" });
    let month = e.toLocaleString("en-IN", {
      month: "2-digit",
    });
    let day = e.toLocaleString("en-IN", { day: "2-digit" });

    let formatedStartDate = day + "/" + month + "/" + year;
    let startDate = year + month + day;
    return { formatedStartDate, startDate };
  }
  function rowEditCancelHandler(props) {
    // modeOn(false);
    if (obj.p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
      if (setSelectedProductObj && typeof setSelectedProductObj == "function")
        setSelectedProductObj(null);
      setRecIss("");
      setBatchIssueObj(null);
      setLocationObj(null);
    }
    setGroupCatogaryProduct(null);
    setTableEditingRow(null);
  }
  // useEffect(() => {
  //   if (
  //     (selectedProductObj?.FIELD51 == "Y" && t02Obj?.FIELD41?.trim() == "") ||
  //     t02Obj?.FIELD03 == ""
  //   )
  //     setBatchTrue(true);
  // }, [selectedProductObj, t02Obj?.FIELD41, t02Obj?.FIELD03]);
  useEffect(() => {
    if (page == "20400399") setBatchTrue(true);
  }, [page]);

  function handleCreateUser(e) {
    console.log(pendingIssue, "EditSave", t02Obj);
    // console.log(
    //   t02Obj?.FIELD03,
    //   "Edit Product Call",
    //   selectedProductObj?.FIELD51,
    //   t02Obj?.FIELD41?.trim() == ""
    // );

    if (
      (selectedProductObj?.FIELD51 == "Y" &&
        t02Obj?.FIELD41?.trim() == "" &&
        !batchTrue) ||
      t02Obj?.FIELD03 == ""
    ) {
      GlobalClass.Notify(
        "warning",
        "Validation error",
        "Please fill out all the required fields"
      );
    } else {
      setGroupCatogaryProduct(null);
      let objChange = e ? e : t02Obj;

      let newObj;
      if (objChange?.FIELD08 <= 0) {
        newObj = {
          ...objChange,
          FIELD08: 0,
        };
      }

      let tObj;
      if (page == "03630342" && objChange?.FIELD04 == "") {
        if (objChange?.FIELD08 <= 0) {
          tObj = {
            ...objChange,
            FIELD04: OS11?.S11F22 === "R" || OS11?.S11F22 === "C" ? "C" : "D",
            FIELD05: OS11?.S11F21,
            FIELD08: 0,
          };
        } else {
          tObj = {
            ...objChange,
            FIELD04: OS11?.S11F22 === "R" || OS11?.S11F22 === "C" ? "C" : "D",
            FIELD05: OS11?.S11F21,
          };
        }
      }
      setT02Data([
        ...t02Data,
        page == "03630342" ? tObj : t02Obj?.FIELD08 <= 0 ? newObj : objChange,
      ]);
      // setGroupCatogaryProduct({ Group: "", Catogary: "", Product: "" });

      // }
      setIsVisible(true);
      // if (page == "04080399") {
      // console.log(obj, "ksetIsVisible");
      Store.dispatch(
        BatchLogAddRemoveFun({
          PageCode: OS11?.S11F01,
          T41Obj: group ?? {},
          T02Data: t02Data,
          Row: objChange,
          OBJ: { ...obj, page: page },
        })
      );
      if (obj.p0 == "E") {
        setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
      } else {
        setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
      }

      if (setSelectedProductObj && typeof setSelectedProductObj == "function") {
        setSelectedProductObj(null);
      }
      setBatchIssueObj(null);
      setRecIss("");
      setLocationObj(null);

      // modeOn(false);
      table.setCreatingRow(null);
      setCreateFlag(false);
    }
  }
  function handleSaveUser(e) {
    // modeOn(true);
    console.log(pendingIssue, "EditSave1", t02Obj);
    let objChange = e ? e : t02Obj;
    // console.log(t02Obj, "OBJcHANGE OF EDIT", objChange, e);
    // let row = props?.row;
    let EditT02Data = [...t02Data];
    EditT02Data[tableEditingRow?.index] = objChange;
    setT02Data(EditT02Data);
    setIsVisible(true);
    Store.dispatch(
      BatchLogAddRemoveFun({
        PageCode: OS11?.S11F01,
        T41Obj: group ?? {},
        T02Data: t02Data,
        Row: objChange,
        OBJ: { ...obj, page: page },
      })
    );

    setGroupCatogaryProduct(null);
    // modeOn(false);
    if (p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      // console.log(T02?.[0], "T02?.[0]")
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
    }
    if (setSelectedProductObj && typeof setSelectedProductObj == "function") {
      setSelectedProductObj(null);
    }
    setBatchIssueObj(null);
    setRecIss("");
    setLocationObj(null);
    table.setEditingRow(null); //exit editing mode
    setTableEditingRow(null);
    setRowSelection({});
  }
  function rowCancelHandler() {
    // modeOn(false);
    if (p0 == "E") {
      setT02Obj({ ...T02?.[T02?.length - 1], FIELD05: OS11?.S11F21 });
    } else {
      // console.log(T02?.[0], "T02?.[0]");
      setT02Obj({ ...T02?.[0], FIELD05: OS11?.S11F21 });
      if (setSelectedProductObj && typeof setSelectedProductObj == "function")
        setSelectedProductObj(null);
      setRecIss("");
      setBatchIssueObj(null);
      setLocationObj(null);
      setGroupCatogaryProduct(null);
    }
    setCreateFlag(false);
  }
  useEffect(() => {
    if (priceListParemeter?.priceListCode) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...t02Obj,
          FIELD27: priceListParemeter?.priceListCode ?? null,
        });
      });
    }
  }, [priceListParemeter?.priceListCode]);
  const HiddenButtonField = (uID, v, c, cellid, index, jwOrder, jwIssue) => {
    console.log(c.S53F02);
    switch (c.S53F02) {
      case "E00ORDDUM":
        NextIndexFun(index);
        // setDrawerObj({
        //   ...dra,
        //   title: <Text fw={700}>{c.S53F03}</Text>,
        //   body: (
        //     <>
        //       <Text>{c.S53F03}</Text>
        //       <div
        //         style={{
        //           display: "flex",
        //           alignItems: "center",
        //           justifyContent: "center",
        //           padding: "5px 0px",
        //         }}
        //       >
        //         <Button
        //           id={`T02Ent_${index}`}
        //           onClick={() => {
        //             // setDrawerObj({ ...DrawerObj, open: false })
        //             Promise.resolve(
        //               setDrawerObj({ ...DrawerObj, open: false })
        //             ).then(() => NextIndexFun(index));
        //           }}
        //         >
        //           Ok
        //         </Button>
        //       </div>
        //     </>
        //   ),
        //   open: true,
        //   size: "xs",
        //   position: "right",
        // });
        break;
      case "E00T02F11": //Batch Issue
        console.log(t02Obj?.FIELD05, "No Batch Issue", selectedProductObj);
        if (
          (selectedProductObj?.FIELD01 != "" || t02Obj?.FIELD03 != "") &&
          selectedProductObj?.FIELD51 != "N" &&
          t02Obj?.FIELD05 == "D"
        ) {
          // console.log("No Batch Issue3");

          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,

            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              <>
                <PLDataGrid
                  autoFocus={true}
                  // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                  // disabled={t02Obj?.FIELD05 == "C"}
                  id={`T02Ent_${index}`}
                  cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                  width={"500px"}
                  value={t02Obj?.[c?.S53F04]}
                  p1={seperate(c.S53F15)[1]}
                  setEdit={(e) => {
                    Promise.resolve(setBatchIssueObj(e));
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px 0px",
                  }}
                >
                  <Button
                    onClick={() => {
                      // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                      Promise.resolve(
                        setDrawerObj({ ...DrawerObj, open: false })
                      ).then(() => NextIndexFun(index));
                    }}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        Promise.resolve(
                          setDrawerObj({ ...DrawerObj, open: false })
                        ).then(() => NextIndexFun(index));
                      }
                    }}
                  >
                    OK
                  </Button>
                </div>
              </>
            ),
            open: true,
            size: "xs",
            position: "right",
          });
        } else {
          console.log("No Batch Issue");
          NextIndexFun(index);
        }
        break;
      case "E00USERFLD": //User Field
        // console.log(T02UF, "T02UF-");
        if (T02UF?.length > 0) {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              // TableUserFieldFun(c,index)
              <>
                <SITableUserField
                  T02UF={T02UF}
                  T02ObjSend={t02Obj}
                  PageObj={obj}
                  setT02Objsend={(e) => {
                    // setT02Obj(e);
                    Promise.resolve(setT02Obj(e)).then(() => {
                      // let passInd = t02Obj?.FIELD05 == "C" ? index : index +1
                      if (selectedProductObj?.FIELD59 == "N") {
                        NextIndexFun(index + 1);
                      } else {
                        NextIndexFun(index);
                      }
                    });
                  }}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    // console.log("setDrawerObj=>", e);
                    setDrawerObj(e);
                  }}
                />
              </>
            ),
            open: true,
            size: "xs",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00UFBAT":
        if (dtUFT11?.length > 0) {
          console.log(index, "index-");
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>Batch User Field</Text>,
            body: (
              <SITableUserField
                UFT11={dtUFT11}
                T02ObjSend={t02Obj}
                setT02Objsend={(e) => {
                  setT02Obj(e);
                  // Promise.resolve(setT02Obj(e))
                  // .then(() => {
                  //   // let passInd = page == "03630342" ? index : index + 1;
                  //   // NextIndexFun(passInd);
                  // });
                }}
                // drawerClose={DrawerObj?.open}
                DrawerOBJ={DrawerObj}
                setDrawerOBJ={(e) => {
                  Promise.resolve(setDrawerObj(e)).then(() => {
                    NextIndexFun(index);
                  });
                }}
              />
            ),
            open: true,
            size: "xs",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00T02F23": //Price List
        console.log(typeof PLDATA !== "string", "index-");
        if (typeof PLDATA !== "string") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => {
                  NextIndexFun(T02UF?.length == 0 ? index + 1 : index);
                }
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(T02UF?.length == 0 ? index + 1 : index)
              );
            },

            body: (
              <>
                <Grid>
                  <Grid.Col span={12}>
                    <PLComboBox
                      // onKeyDown={(e) => {
                      //   GlobalClass.formNextInput(e, formRef);
                      // }}
                      initiallyOpened={true}
                      cmbid={`T02Ent_${index}`}
                      data={typeof PLDATA == "string" ? [{}] : PLDATA}
                      value={t02Obj?.FIELD27}
                      dispexpr={"FIELD02"}
                      valexpr={"FIELD01"}
                      setEdit={(e) => {
                        console.log("Pricelist OBJ=>", e);
                        Promise.resolve(
                          setT02Obj({ ...t02Obj, [c?.S53F04]: e })
                        ).then(() => {
                          if (group.FIELD16 == "C") {
                            setPriceListParemeter({
                              ...priceListParemeter,
                              accountCode: group.FIELD31,
                              priceListCode: e,
                            });
                          } else {
                            setPriceListParemeter({
                              ...priceListParemeter,
                              accountCode: group.FIELD04,
                              priceListCode: e,
                            });
                          }
                        });

                        // Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                        //   if (T41Obj.FIELD16 == "C") {
                        //     setPriceListParemeter({
                        //       ...priceListParemeter,
                        //       accountCode: T41Obj.FIELD31,
                        //       priceListCode: e,
                        //     });
                        //   } else {
                        //     setPriceListParemeter({
                        //       ...priceListParemeter,
                        //       accountCode: T41Obj.FIELD04,
                        //       priceListCode: e,
                        //     });
                        //   }
                        // })
                        // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="filled"
                      size="sm"
                      onClick={() => {
                        Promise.resolve(
                          setDrawerObj({ ...DrawerObj, open: false })
                        ).then(() => NextIndexFun(index));
                      }}
                      onKeyDown={(e) => {
                        if (e.key == "Enter") {
                          Promise.resolve(
                            setDrawerObj({ ...DrawerObj, open: false })
                          ).then(() => NextIndexFun(index));
                        }
                      }}
                    >
                      Ok
                    </Button>
                  </Grid.Col>
                </Grid>
              </>
            ),
            open: true,
            size: "sm",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "E00EXPDUM":
        if (t02Obj.FIELD08 > 0 && InvTypeDropDown != null) {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>Expense Entry</Text>,
            body: (
              <>
                <ExpenseEntryForm
                  ProductObj={selectedProductObj}
                  isExpense={true}
                  expenseArr={ExpenseData?.dtExp_I}
                  expenseArrDef={ExpenseData?.dtExpDef_I}
                  M01PData={ExpenseData?.M01SPDT?.jData}
                  M01Format={ExpenseData?.M01SP}
                  valueSaveObj={t02Obj}
                  TableExpenseArr={TableExpense}
                  onChange={(e) => {
                    // console.log("TableExpenseEntry Object=>", e);
                    // setSelectedTaxAcc(e.FIELD16)

                    if (tableEditingRow == null) {
                      // console.log("Add Product Call");
                      if (
                        t02Obj?.FIELD03 == null ||
                        t02Obj?.FIELD03 == "" ||
                        (selectedProductObj?.FIELD51 == "Y" &&
                          t02Obj?.FIELD41?.trim() == "")
                      ) {
                        GlobalClass.Notify(
                          "warning",
                          "Warning",
                          "Some Field Require to Enter"
                        );
                      } else {
                        handleCreateUser(e);
                      }
                    } else {
                      // console.log("Edit Product Call");
                      if (
                        t02Obj?.FIELD03 == null ||
                        t02Obj?.FIELD03 == "" ||
                        (selectedProductObj?.FIELD51 == "Y" &&
                          t02Obj?.FIELD41?.trim() == "")
                      ) {
                        GlobalClass.Notify(
                          "warning",
                          "Warning",
                          "Some Field Require to Enter"
                        );
                      } else {
                        handleSaveUser(e);
                      }
                    }

                    setGroup((prevFieldObj) => {
                      // Use functional update to avoid overwriting previous state
                      return ExpenseData?.dtExp?.reduce(
                        (updatedFieldObj, d) => {
                          // Only update if the corresponding IA key exists in FieldObj
                          if (d?.FIELD54) {
                            return {
                              ...updatedFieldObj,
                              ["EA" + d.FIELD54]: e?.FIELD16,
                            };
                          } else {
                            return updatedFieldObj;
                          }
                        },
                        { ...prevFieldObj }
                      ); // Spread previous state to avoid modifying it directly
                    });
                  }}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => setDrawerObj(e)}
                />
              </>
            ),
            open: true,
            size: "50%",
            position: "right",
          });
        } else {
          NextIndexFun(index);
        }
        break;
      case "EJ0000000029": //Purchase Detail
        console.log("here", page);
        // if (selectedProductObj?.FIELD01 != "" || t02Obj?.FIELD03 != "") {
        if (jwIssue?.length > 0) {
          console.log("Inhere");
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>Pending Issues</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            onClose: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
                () => NextIndexFun(index)
              );
            },
            body: (
              <PendingIssue
                // data={data?.payload}
                setData={(e) => {
                  // console.log(e, "setPendingIssue");
                  setPendingIssue(e);
                }}
                DrawerOBJ={DrawerObj}
                setDrawerOBJ={(e) => {
                  setDrawerObj(e);
                  // Promise.resolve(setDrawerObj(e)).then(() => NextIndexFun(2));
                }}
              />
            ),
            open: true,
            size: "lg",
            position: "right",
          });
          // Promise.resolve(pendingIssuePopUpFn()).then(() => {
          //   NextIndexFun(index);
          // });
        } else {
          NextIndexFun(index);
        }
        // }
        break;
      case "E00FIELD86":
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          onClickCloseButton: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => {
                NextIndexFun(T02UF?.length == 0 ? index + 1 : index);
              }
            );
          },
          onClose: () => {
            // setDrawerObj(dra);
            Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(
              () => NextIndexFun(T02UF?.length == 0 ? index + 1 : index)
            );
          },
          body: (
            <>
              <Text>{c.S53F03}</Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // padding: "5px 0px",
                }}
              >
                <PLTextBox
                  id={`T02Ent_${index}`}
                  value={t02Obj?.[c?.S53F04]}
                  setEdit={(e) => {
                    setT02Obj({ ...t02Obj, [c?.S53F04]: e?.target?.value });
                  }}
                  // disabled={true}
                />
                <Button
                  onClick={() => {
                    // setDrawerObj({ ...DrawerObj, open: false })
                    Promise.resolve(
                      setDrawerObj({ ...DrawerObj, open: false })
                    ).then(() => NextIndexFun(index));
                  }}
                >
                  Ok
                </Button>
              </div>
            </>
          ),
          open: true,
          size: "xs",
          position: "right",
        });
        break;
      case "EJW000000001": //To Get List of JW Orders
        // console.log( "orderPopUp", orderData);

        if (
          // JWOrderData?.dtOrd?.length > 0 &&
          page === "04050399" ||
          page == "04110399"
        ) {
          console.log("orderPopUp", orderPopUp);
          if (jwOrder?.length > 0) {
            setDrawerObj({
              ...dra,
              title: <Text fw={700}>Pending Order</Text>,
              onClickCloseButton: () => {
                // setDrawerObj(dra);
                Promise.resolve(
                  setDrawerObj({ ...DrawerObj, open: false })
                ).then(() => {
                  NextIndexFun(index);
                  //   if(empty){
                  //     NextIndexFun(index)
                  // }
                });
              },
              onClose: () => {
                // setDrawerObj(dra);
                Promise.resolve(
                  setDrawerObj({ ...DrawerObj, open: false })
                ).then(() => NextIndexFun(index));
              },
              body: (
                <PendingOrder
                  // data={data?.payload}
                  setData={(e) => setPendingOrder(e)}
                  DrawerOBJ={DrawerObj}
                  setDrawerOBJ={(e) => {
                    setDrawerObj(e);
                    Promise.resolve(setDrawerObj(e));
                  }}
                  setEmpty={(e) => setEmpty(e)}
                />
              ),
              open: true,
              size: "lg",
              position: "right",
            });
          }
        } else {
          NextIndexFun(index);
          // NextIndexFun(
          //   T02UF?.length == 0 &&
          //     selectedProductObj?.FIELD01 == "" &&
          //     selectedProductObj?.FIELD51 == "N" &&
          //     t02Obj?.FIELD05 != "D"
          //     ? index + 1
          //     : index
          // );
        }
        break;
      default:
        break;
    }
  };

  const TableExpenseEntry = () => {
    setDrawerObj({
      ...dra,
      title: <Text fw={700}>Expense Entry</Text>,
      body: (
        <>
          <ExpenseEntryForm
            ProductObj={selectedProductObj}
            isExpense={true}
            expenseArr={ExpenseData?.dtExp_I}
            expenseArrDef={ExpenseData?.dtExpDef_I}
            M01PData={ExpenseData?.M01SPDT?.jData}
            M01Format={ExpenseData?.M01SP}
            valueSaveObj={t02Obj}
            TableExpenseArr={TableExpense}
            onChange={(e) => {
              // console.log("TableExpenseEntry Object=>", e);

              if (tableEditingRow == null) {
                console.log(
                  t02Obj?.FIELD41?.trim(),
                  "Edit Product Call",
                  selectedProductObj?.FIELD51
                );

                if (
                  t02Obj?.FIELD03 == null ||
                  t02Obj?.FIELD03 == "" ||
                  (selectedProductObj?.FIELD51 == "Y" &&
                    t02Obj?.FIELD41?.trim() == "")
                ) {
                  GlobalClass.Notify(
                    "warning",
                    "Warning",
                    "Some Field Require to Enter"
                  );
                } else {
                  handleCreateUser(e);
                }
              } else {
                console.log("Edit Product Call", selectedProductObj?.FIELD51);
                if (
                  t02Obj?.FIELD03 == null ||
                  t02Obj?.FIELD03 == "" ||
                  (selectedProductObj?.FIELD51 == "Y" &&
                    t02Obj?.FIELD41?.trim() == "")
                ) {
                  GlobalClass.Notify(
                    "warning",
                    "Warning",
                    "Some Field Require to Enter"
                  );
                } else {
                  handleSaveUser(e);
                }
              }
              setGroup((prevFieldObj) => {
                // Use functional update to avoid overwriting previous state
                return ExpenseData?.dtExp?.reduce(
                  (updatedFieldObj, d) => {
                    // Only update if the corresponding IA key exists in FieldObj
                    if (d?.FIELD54) {
                      return {
                        ...updatedFieldObj,
                        ["EA" + d.FIELD54]: e?.FIELD16,
                      };
                    } else {
                      return updatedFieldObj;
                    }
                  },
                  { ...prevFieldObj }
                ); // Spread previous state to avoid modifying it directly
              });
            }}
            DrawerOBJ={DrawerObj}
            setDrawerOBJ={(e) => setDrawerObj(e)}
          />
        </>
      ),
      open: true,
      size: "50%",
      position: "right",
    });
  };
  const openDeleteConfirmModal = (row) => {
    // console.log("openDeleteConfirmModal", row);
    let EditT02Data = [...t02Data];
    let newArray = EditT02Data.filter((d, i) => i != row.index);
    // setNarrationData(newArray)

    // EditT02Data[tableEditingRow?.index] = objChange;
    setT02Data(newArray);
  };

  //---------Useeffect Hooks-----//
  useEffect(() => {
    setT02DataENT(dataEnt?.T02ENT);
  }, [dataEnt]);
  // useEffect(() => {
  //   if (T02) {
  //     // console.log(T02, "InEdit--");
  //     setT02Data(T02);
  //     if (T02?.length > 0) setT02Obj({ ...T02?.[T02?.length - 1] });
  //     if (p0 == "E" && T02?.[T02?.length - 1]?.EXPDATE == "") {
  //       let { startDate } = DateToString(new Date());
  //       setT02Obj({
  //         ...T02?.[T02?.length - 1],
  //         EXPDATE: startDate,
  //       });
  //     }
  //   }
  // }, [T02]);
  // useEffect(() => {
  //   let bReceipt = t02DataENT?.find((v) => v?.S53F02 == "E00T02F41");
  //   if (bReceipt == undefined) {
  //     setBatch(true);
  //   } else {
  //     setBatch(false);
  //   }
  // }, [t02DataENT]);
  useEffect(() => {
    if (page == "04020399") {
      if (t02Obj?.FIELD07 > 0) {
        console.log(T02UF, "T02UF-");
        setT02Obj({ ...t02Obj, UISSUERA: t02Obj?.FIELD07 });
      }
    }
  }, [t02Obj?.FIELD07, page]);

  useEffect(() => {
    // console.log(t02Obj, "Int02Qty");
    // if (t02Obj?.FIELD22 == 0) {
    //   setT02Obj((prevObj) => ({ ...prevObj, FIELD22: 1 }));
    // }
    console.log(t02Obj?.FIELD22, "t02Obj?.FIELD22=");
    if (t02Obj?.FIELD21 > 0 && t02Obj?.FIELD22 > 0) {
      let qty = t02Obj?.FIELD21 * t02Obj?.FIELD22;
      setT02Obj((prevObj) => ({ ...prevObj, FIELD33: qty }));
    }
    //  else {
    //   if (t02Obj?.FIELD33 > 0) {
    //     setT02Obj((prevObj) => ({ ...prevObj, FIELD33: t02Obj?.FIELD33 }));
    //   } else setT02Obj((prevObj) => ({ ...prevObj, FIELD33: 0 }));
    // }
  }, [t02Obj?.FIELD22, t02Obj?.FIELD21]);

  useEffect(() => {
    if (page == "04050399" || page == "04110399" || page == "20400399") {
      if (t02Obj?.FIELD21 > 0 && t02Obj?.FIELD07 > 0) {
        let amount = t02Obj?.FIELD21 * t02Obj?.FIELD07;
        setT02Obj((prevObj) => ({ ...prevObj, FIELD08: amount }));
      }
      //  else {
      //   if (t02Obj?.FIELD08 <= 0)
      //     setT02Obj((prevObj) => ({ ...prevObj, FIELD08: 0 }));
      // }
    } else {
      if (t02Obj?.FIELD33 > 0 && t02Obj?.FIELD07 > 0) {
        let amount = t02Obj?.FIELD33 * t02Obj?.FIELD07;
        setT02Obj((prevObj) => ({ ...prevObj, FIELD08: amount }));
      }
      // else {
      //   if (t02Obj?.FIELD08 <= 0)
      //     setT02Obj((prevObj) => ({ ...prevObj, FIELD08: 0 }));
      // }
    }
  }, [t02Obj?.FIELD33, t02Obj?.FIELD07, t02Data, t02Obj?.FIELD21]);
  useEffect(() => {
    // console.log(t02Obj?.FIELD03, "t02Obj?.FIELD03");
    if (
      t02Obj?.FIELD03 != "" &&
      group?.FIELD04 != "" &&
      group?.FIELD34 != "" &&
      group?.FIELD02 != ""
    )
      setIssueArr({
        ...issueArr,
        // productCode: selectedProductObj?.FIELD01 ?? t02Obj?.FIELD03,
        productCode: t02Obj?.FIELD03,
        partyCode: group?.FIELD04,
        processCode: group?.FIELD34,
        dateCode: group?.FIELD02,
      });
  }, [
    t02Obj?.FIELD03,
    group?.FIELD04,
    group?.FIELD34,
    group?.FIELD02,
    // selectedProductObj,
  ]);
  useEffect(() => {
    if (priceListParemeter?.priceListCode) {
      Promise.resolve().then(() => {
        setT02Obj({ ...t02Obj, PRICELIST: priceListParemeter?.priceListCode });
      });
    }
  }, [priceListParemeter?.priceListCode]);
  useEffect(() => {
    if (
      t02Obj?.FIELD03 != "" &&
      group?.FIELD04 != "" &&
      // group?.FIELD34 != "" &&
      group?.FIELD02 != ""
    )
      setOrderArr({
        ...orderArr,
        productCode: t02Obj?.FIELD03,
        partyCode: group?.FIELD04,
        // processCode: group?.FIELD34,
        dateCode: group?.FIELD02,
      });
  }, [t02Obj?.FIELD03, group?.FIELD04, group?.FIELD02]);

  useEffect(() => {
    console.log(pendingIssue?.length, "pendingIssue", oYear);
    if (pendingIssue?.length > 0) {
      //  setTimeout(()=>{
      console.log(OS11?.S11F01, "OS11?.S11F01");
      switch (OS11?.S11F01) {
        case "JJ":
        case "JZ":
          let newArr = pendingIssue?.map((v) => {
            return {
              ...t02Obj,
              FIELD33:
                oYear !== "N"
                  ? v?.FIELD61 - v?.FIELD54
                  : v?.FIELD06 - v?.FIELD54,
              FIELD66:
                v?.FIELD66 == 0
                  ? oYear !== "N"
                    ? v?.FIELD61 - v?.FIELD54
                    : v?.FIELD06 - v?.FIELD54
                  : v?.FIELD66,
              FIELD06:
                oYear !== "N"
                  ? v?.FIELD61 - v?.FIELD54
                  : v?.FIELD06 - v?.FIELD54,
              FIELD02: t02Obj?.FIELD02 == "" ? v?.T41F02 : t02Obj?.FIELD02,
              FIELD21: v?.FIELD21 - v?.FIELD55,
              FIELD07: v?.FIELD07,
              FIELD64: v?.FIELD64 == 0 ? v?.FIELD21 - v?.FIELD55 : v?.FIELD64,
              // FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
              FIELD83: v?.FIELD01 + v?.FIELD09.toString().padStart(4, " "),
              FIELD88: v?.FIELD88,
              FIELD63: v?.FIELD63,
              FIELD65: OS11?.S11F01 == "JZ" ? v?.T41F12 : v?.T41F10,
              FIELD13:
                OS11?.S11F01 == "JJ" && v?.T41PVT == "JO"
                  ? v?.FIELD01 + v?.FIELD09 != null
                    ? v?.FIELD09
                    : 4
                  : t02Obj?.FIELD13,
            };
          });

          setT02Obj({ ...newArr[0] });
          let extraRow = newArr?.slice(1);
          setT02Data([...t02Data, ...extraRow]);
          break;
        case "JI":
        case "IN":
          console.log("Its Here ", pendingIssue);
          let newArr2 = pendingIssue?.map((v) => {
            return {
              ...t02Obj,
              FIELD06:
                t02Obj?.FIELD06 == 0
                  ? v?.FIELD06 - v?.FIELD54
                  : t02Obj?.FIELD06,
              FIELD21:
                t02Obj?.FIELD06 == 0
                  ? v?.FIELD21 - v?.FIELD55
                  : t02Obj?.FIELD06,
              FIELD33: t02Obj?.FIELD06 == 0 ? v?.FIELD06 : t02Obj?.FIELD06,
              FIELD63: v?.FIELD63,
              FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
              FIELD82: v?.FIELD02,
              FIELD65: v?.T41F10,
              FIELD61: v?.FIELD06,
              FIELD62: v?.FIELD21,
              FIELD41: v?.FIELD41,
              FIELD72: v?.FIELD12,
            };
          });
          setT02Obj({ ...newArr2[0] });
          let extraRow2 = newArr2?.slice(1);
          setT02Data([...t02Data, ...extraRow2]);
          break;
        case "JO":
        case "JY":
        case "JW":
          // console.log("Its Here ", OS11?.S11F01, pendingIssue);
          let newArr3 = pendingIssue?.map((v) => {
            return {
              ...t02Obj,
              FIELD33:
                cTypes == "JD"
                  ? v?.FIELD33 - v?.FIELD54
                  : v?.FIELD61 - v?.FIELD54,
              FIELD21: cTypes == "JD" ? "" : v?.FIELD62 - v?.FIELD55,
              FIELD06: v?.FIELD06,
              // FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
              FIELD83: v?.FIELD01 + v?.FIELD09.toString().padStart(4, " "),
              FIELD82: v?.FIELD02,
              FIELD65: v?.T41F10,
              FIELD63: v?.FIELD63,
              FIELD53: "P",
              FIELD41: v?.FIELD41,
              FIELD07: cTypes == "JD" ? t02Obj?.FIELD07 : v?.FIELD07,
              FIELD19: OS11?.S11F01 == "JW" ? "R" : "",
            };
          });
          setT02Obj({ ...newArr3[0] });
          let extraRow3 = newArr3?.slice(1);
          setT02Data([...t02Data, ...extraRow3]);

          break;

        case "JX":
          console.log(pendingIssue, "Its Here ", OS11?.S11F01);
          let newArr4 = pendingIssue?.map((v) => {
            console.log(v, "pendingIssue4", t02Obj);
            if (oYear == "Y") {
              console.log(oYear, "oYear-");
              return {
                ...t02Obj,
                FIELD21: v?.FIELD21 - v?.FIELD55,
                FIELD33: v?.FIELD33 - v?.FIELD54,
                FIELD06: v?.FIELD33 - v?.FIELD54,
                // FIELD83: v?.FIELD01 + v?.FIELD09 != null ? v?.FIELD09 : 4,
                FIELD83: v?.FIELD01 + v?.FIELD09.toString().padStart(4, " "),
                FIELD82: v?.FIELD02,
                FIELD65: v?.T41F10,
                FIELD63: v?.FIELD63,
                FIELD07: v?.FIELD07,
                FIELD53: "P",
                FIELD41: v?.FIELD41,
              };
            }
          });
          console.log(newArr4, "newArr4--");
          setT02Obj({ ...newArr4[0] });
          let extraRow4 = newArr4?.slice(1);
          setT02Data([...t02Data, ...extraRow4]);
          break;
        default:
          console.log(oYear, "Its Here ", OS11?.S11F01);
          break;
        // }},1000)
      }
    }
  }, [pendingIssue]);
  useEffect(() => {
    if (page === "20432041") setcType("JC");
    else if (page === "20422041") setcType("JD");
    else if (page === "20442041") setcType("SJ");
    else setcType("");
  }, []);

  useEffect(() => {
    console.log("t02Obj?.FIELD03-", oYear);
    // const pendingPopUp = async () => {
    if (oYear === "Y") {
      if (
        page === "04050399" || //jwO rec
        page === "20422041" || //sbJw Iss
        page === "20432041" || //sbJw rec
        page === "04230417" || //jwI Pro
        page === "04260417" || //jwI Rec
        page === "04320417" || //Bill
        page === "20442041" ||
        page === "04110399" ||
        page == "04020399"
      ) {
        // console.log(page , "page--", OS11?.S11F01)

        if (
          issueArr?.dateCode !== "" &&
          issueArr?.partyCode !== "" &&
          issueArr?.processCode !== "" &&
          issueArr?.productCode !== "" &&
          tableEditingRow == null
        ) {
          console.log(issueArr, "t02Obj?.FIELD03");
          let data = {
            CorpID: gensetting.getcorpid(),
            cUser: gensetting.getcuser(),
            cPass: gensetting.getcpass(),
            CmpNo: gensetting.getcmpno(),
            cAction: "",
            cCode: OS11?.S11F01,
            cSData: JSON.stringify({
              cPCode: issueArr?.productCode,
              cACode: issueArr?.partyCode,
              cPrcCode: issueArr?.processCode,
              cDate: issueArr?.dateCode,
              cType: cTypes,
            }),
          };
          console.log(issueArr, "issueArr", cTypes);
          Promise.resolve(Store.dispatch(GetJWListData(data)));
          // .then((data) => {
          //   // if (data?.payload?.T02JDT?.length > 0)
          //   // pendingIssuePopUpFn();
          // });
        } else {
          console.log("pendingIssuePopUpFn");
        }
      }
     
    }

    // };
  }, [issueArr, OS11?.S11F01, page, cTypes, oYear]);

  useEffect(() => {
    // InvTypeDropDown
    if (page == "04080399" || page == "04290417" || page == "20452041") {
      if (t02Obj?.FIELD03) {
        // console.log(
        //   ExpenseObj, 'RetTaxAcc("TS", false, "FIELD16", 0, "", T02Obj, false, ExpenseObj) =>',
        //   RetTaxAcc("TS", false, "FIELD16", 0, "", t02Obj, false, ExpenseObj)
        // );

        // Promise.resolve().then(()=> {
        //   return RetTaxAcc("TS", false, "FIELD16", 0, "",  {...t02Obj, VorC:group?.FIELD45}, false, ExpenseObj)
        // }).then((res)=> console.log(res, "RetTaxAcc--"))
        Promise.resolve(
          setT02Obj({
            ...t02Obj,
            FIELD16: RetTaxAcc(
              "TS",
              false,
              "FIELD16",
              0,
              "",
              t02Obj,
              false,
              ExpenseObj
            ),
          })
        ).then(() => {
          setTimeout(() => {
            Promise.resolve(
              setExpenseArrayT02(AssignItemDefault(ExpenseObj).dtExpArr)
            ).then(() => setT02Obj(AssignItemDefault(ExpenseObj).T02Object));
          }, 500);
        });
      }
    }
  }, [t02Obj?.FIELD03, t02Obj?.FIELD16, page]);
  useEffect(() => {
    var cols = [];
    // if (isLoading == false) {
    // cols.push(
    //   columnHelper.accessor("GRPNM", {
    //     header: "Product Group",
    //     id: "Product_Group",
    //     size: 150,
    //     Edit: ({ cell, row, index }) => {
    //       let val = cell.getValue();
    //       // console.log("Cell =>>", cell);
    //       // return <><Text>Test</Text></>
    //       // console.log("cell?.id==>", cell?.id);
    //       return p0 == "A" ? (
    //         <PLDataGrid
    //           autoFocus={true}
    //           width={"500px"}
    //           TmData={tmList["T_M32G"]?.cSTID}
    //           data={accList["T_M32G"]}
    //           value={t02Obj?.GRPNM}
    //           setEdit={(e) => {
    //             // console.log("Product Group Obj=>", e);
    //             // setFlag(true);
    //             setT02Obj({
    //               ...t02Obj,
    //               GRPNM: e?.FIELD01,
    //             });
    //           }}
    //         />
    //       ) : (
    //         t02Obj?.GRPNM
    //       );
    //     },
    //   })
    // );
    console.log(t02Obj, "t02Obj--rr");
    cols.push(
      columnHelper.accessor("CATCD", {
        header: "Product Catogary",
        id: "Product_Catogary",
        size: 150,
        Edit: ({ cell, row, index }) => {
          let val = cell.getValue();
          // console.log("Cell =>>", cell);
          // return <><Text>Test</Text></>
          // console.log("cell?.id==>", cell?.id);

          // p0 == "A" ? (
          return (
            <PLDataGrid
              ref={prCt}
              width={"500px"}
              TmData={tmList["T_M32C"]?.cSTID}
              // data={accList["T_M32C"]}
              value={{ value: t02Obj?.CATCD, label: t02Obj?.CATNM }}
              setEdit={(e) => {
                console.log("Product Category Obj=>", e);
                // setFlag(true);
                setGroupCatogaryProduct({
                  ...GroupCatogaryProduct,
                  Group: e?.FIELD01,
                });
                Promise.resolve(
                  setT02Obj({
                    ...t02Obj,
                    CATCD: e?.FIELD01,
                    CATNM: e?.FIELD02,
                  })
                ).then(() => {
                  setTimeout(() => {
                    prt?.current?.handlePopoverToggle();
                  }, 500);
                  
                });
              }}
            />
          );
          // )
          //  : (
          //   t02Obj?.CATNM
          // );
        },
        Cell: ({ cell, row }) => {
          let val = cell.getValue();
          return t02Data[row.id]?.CATNM;
        },
      })
    );
    if (t02DataENT && t02DataENT?.length > 0) {
      t02DataENT?.map((v, i) => {
        // console.log(v, "v?.S53F00");
        if (v?.S53F00 === "C") {
          cols.push(
            columnHelper.accessor(v?.S53F04, {
              header: v.S53F07 == 0 ? "" : v.S53F03,
              // v.S53F03 == "ItemWise Expense"
              //   ? ""
              //   : v.S53F03 == "User FIeld"
              //   ? ""
              //   : v.S53F03,
              enableHiding: true,
              id: v?.S53UID,
              size: v.S53F07 == 0 ? 0 : 150,
              // v.S53F03 == "ItemWise Expense"
              //   ? 0
              //   : v.S53F03 == "User FIeld"
              //   ? 0
              //   : 150,
              Edit: ({ cell, column, table, row }) => {
                let val = cell.getValue();
                return renderValue(v?.S53UID, val, v, cell?.id, i, column);
              },
              Footer: () => (
                <div>
                  {v.S53F02 == "E000T02F03"
                    ? `Total Entry :${
                        table?.getSelectedRowModel()?.flatRows.length > 0
                          ? parseInt(
                              table?.getSelectedRowModel()?.flatRows[0]?.index
                            ) +
                            1 +
                            "/"
                          : ""
                      }${t02Data?.length}`
                    : Summary(v, i, "v")}
                </div>
              ),
              Cell: ({ cell, row }) => {
                let val = cell?.getValue();

                if (v?.S53F02 == "E00T02F20") {
                  return t02Data[row.id]?.F20NM ?? t02Data[row.id]?.FIELD20;
                } else if (v?.S53F02 == "E000T02F03") {
                  // return t02Data[row?.id]?.F03NM;

                  return t02Data[row?.id]?.F03NM ?? t02Data[row.id]?.PrdNM;
                }
                return v.S53F07 == 0 ? "" : val;
                // return val;
              },
            })
          );
        }
      });
    }
    setColumns(cols);
  }, [t02DataENT, t02Obj, accList, tmList, t02Data, JWListData, JWOrderData]);

  useEffect(() => {
    if (pendingOrder?.length > 0) {
      let filteredArr = pendingOrder.map((v) => {
        if (v.FIELD06 == 0) {
          // console.log(pendingOrder, "pendingOrder");
          return {
            ...t02Obj,
            FIELD81: v.FIELD81,
            // FIELD05:recIss,
            FIELD06: v.FIELD06 - v.FIELD50,
            FIELD21: v.FIELD21 - v.FIELD52,
            FIELD33: t02Obj.FIELD06 - t02Obj.FIELD81,
            FIELD07: v.FIELD07,
            FIELD22: t02Obj.FIELD22 == 0 ? v.FIELD22 : t02Obj.FIELD22,
            FIELD88: t02Obj.FIELD01 + v.FIELD09.toString().padStart(4, " "),
          };
        } else {
          // console.log(t02Obj, "t02ObjV", v);
          return v;
        }
      });
      // console.log(filteredArr, "filteredArr");
      setT02Obj({ ...filteredArr[0] });
      let extraRow = filteredArr?.slice(1);
      setT02Data([...t02Data, ...extraRow]);
    }
  }, [pendingOrder]);
  useEffect(() => {
    if (LocationObj !== null) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...t02Obj,
          FIELD20: LocationObj?.FIELD01 ?? "",
          F20NM: LocationObj?.FIELD02,
        });
      });
    }
  }, [LocationObj, t02Obj?.FIELD20]);

  useEffect(() => {
    if (
      p0 === "A"
      // && flag==true
    )
      Promise.resolve().then(() => {
        // console.log(t02Obj.FIELD03, "selectedPO", selectedProductObj);
        // setFlag(false)
        setT02Obj({
          ...t02Obj,
          FIELD03: selectedProductObj?.FIELD01 ?? t02Obj.FIELD03,
          F03NM: selectedProductObj?.FIELD02 ?? t02Obj.F03NM,
          FIELD22:
            selectedProductObj?.FIELD28 == 0
              ? 1
              : selectedProductObj?.FIELD28 ?? t02Obj?.FIELD22,
          FIELD07:
            recIss == "C"
              ? selectedProductObj?.FIELD21 ?? t02Obj?.FIELD07
              : recIss == "D"
              ? selectedProductObj?.FIELD21 ?? t02Obj?.FIELD07
              : selectedProductObj?.FIELD22 ?? t02Obj?.FIELD07,
        });
      });
  }, [
    selectedProductObj,
    t02Obj.FIELD03,
    t02Obj.F03NM,
    t02Obj?.FIELD22,
    // t02Obj?.FIELD07,
    recIss,
    p0,
  ]);

  useEffect(() => {
    if (t02Obj?.FIELD05 == "D") {
      setT02Obj({
        ...t02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? "",
        FIELD41: BatchIssueObj?.FIELD02 ?? "",
      });
    } else {
      setT02Obj({
        ...t02Obj,
        FIELD11: BatchIssueObj?.FIELD01 ?? "",
      });
    }
  }, [t02Obj?.FIELD05, BatchIssueObj]);
  const [isClicked, setisClicked]= useState(false)
  useEffect(() => {
    if (
      (page === "04050399" || page == "04110399") &&
      orderArr?.dateCode != "" &&
      orderArr?.partyCode != "" &&
      orderArr?.productCode != ""
    ) {
      // let data = {
      //   CorpID: gensetting.getcorpid(),
      //   cUser: gensetting.getcuser(),
      //   cPass: gensetting.getcpass(),
      //   CmpNo: gensetting.getcmpno(),
      //   cAction: "",
      //   cCode: OS11?.S11F01,
      //   cSData: JSON.stringify({
      //     cPCode: orderArr?.productCode,
      //     cACode: orderArr?.partyCode,
      //     // cPrcCode: group?.FIELD34,
      //     cDate: orderArr?.dateCode,
      //     cType: "",
      //   }),
      // };
      Promise.resolve(
        Store.dispatch(
          GetJOrderData({
            cPCode: orderArr?.productCode,
            cACode: orderArr?.partyCode,
            cDate: orderArr?.dateCode,
            cCode: OS11?.S11F01,
          })
        )
      );
      // .then((data) => {
      //   // console.log("first", data?.payload);
      //   if (data?.payload?.status == "SUCCESS") {
      //     setOrderPopUp(true);
      //   } else {
      //     setOrderPopUp(false);
      //   }
      // });
    }
  }, [orderArr, page]);
  const table = useMantineReactTable({
    data: t02Data ? t02Data : [],
    columns: columns,
    enableFilters: false,
    // enableRowSelection: true,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: true,
    enableColumnActions: false,
    enableSorting: false,
    enableEditing: true,
    positionToolbarAlertBanner: false,
    // mantineTableBodyProps: {
    //   ref: tableRef,
    // },
    mantineTableContainerProps: {
      sx: { maxHeight: "350px", minHeight: "300px" },
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row, table),
      onClick: (e) => {
        // console.log([row?.id], "[row?.id]")
        setRowSelection((prev) => {
          // [row.id]: !prev[row.id],
          // console.log(prev, "[row?.id]", row.id)
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      // onKeyDown: (e) => handleRowKeyDown(e, row),
      // onKeyDown: (e) => {
      //   console.log(e, "OnkeyDown");
      //   if (e.key == "ArrowDown") {
      //     console.log(row, "row?.id", row);
      //     setRowSelection((prev) => ({
      //       // [row?.id]: !prev[row?.id],
      //     }));
      //   }
      // },

      selected: rowSelection[row?.id],
      sx: { cursor: "pointer" },
    }),
    onColumnVisibilityChange: (column) => {},
    enableHiding: false,
    createDisplayMode: "row",
    editDisplayMode: "row",
    initialState: {
      density: "0px",
    },
    onCreatingRowSave: () => {
      handleCreateUser();
      // console.log(t02Obj?.FIELD03,"Edit Product Call",selectedProductObj?.FIELD51)
      // if ((selectedProductObj?.FIELD51 == "Y" && t02Obj?.FIELD41?.trim() == "") ||
      // t02Obj?.FIELD03 == "") {
      //   GlobalClass.Notify(
      //     "warning",
      //     "Warning",
      //     "Some Fields are Require to Enter"
      //   );
      // } else {
      //   console.log(t02Obj?.FIELD03,"Edit Product Call",selectedProductObj?.FIELD51)
      //   handleCreateUser();
      // }
    },
    onEditingRowSave: () => {
      if (
        (selectedProductObj?.FIELD51 == "Y" && t02Obj?.FIELD41?.trim() == "") ||
        t02Obj?.FIELD03 == ""
      ) {
        GlobalClass.Notify(
          "warning",
          "Warning",
          "Some Fields are Require to Enter"
        );
      } else {
        handleSaveUser();
      }
    },
    onCreatingRowCancel: rowCancelHandler,
    onEditingRowCancel: rowEditCancelHandler,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              // console.log("row of tbl", row);
              // modeOn(true);
              Promise.resolve(table.setCreatingRow(false)).then(() => {
                table?.setEditingRow(row);
                setTableEditingRow(row);
                setCreateFlag(false);
                // table.setCreatingRow(false);
                setT02Obj(row?.original);
              });

              // modeOn(true);
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => openDeleteConfirmModal(row)}>
            <IconTrash size={"20px"} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    positionToolbarAlertBanner: false,
    renderTopToolbarCustomActions: (props) => {
      // console.log(props, "renderTopToolbarCustomActions");
      // return p0 !== "E" ?
      // let isClicked = false;
      return (
        <Button
          // disabled={}
          // ref={addBtnref}
          onClick={() => {
            setisClicked(true);
            console.log(props, "props?.setCreatingModeOn");
            setRowSelection({});
            if (tableEditingRow == null) {
             
                Promise.resolve(table.setCreatingRow(true)).then(() => {
                  setTimeout(() => {
                    setCreateFlag(true);
                    // prCt?.current?.handlePopoverToggle();
                   
                  }, 500);
               
          
              
                // setRecIss("R,C".includes(OS11?.S11F22) ? "C" : "D");
              });
              // .then(() => modeOn(true));
            } else {
              
              Promise.resolve(table.setCreatingRow(false)).then(() => {
                setCreateFlag(false);
              });
            }
          }}
          onFocus={() => {
            // if (spoilerControlRef?.current?.innerText == "Hide") {
            //   spoilerControlRef.current.click();
            // }
            console.log(tableEditingRow, "tableEditingRow-", isClicked );
              if(!isClicked)
            Promise.resolve(table.setCreatingRow(true)).then(() => {
              setTimeout(() => {
                setCreateFlag(true);
                prCt?.current?.handlePopoverToggle();
              }, 500);
            });
          }}
          size="xs"
          compact
        >
          Add New
        </Button>
      );
    },
    state: {
      density: "1.5px",
      rowSelection,
    },
  });

  const handleRowKeyDown = (e, row, tab) => {
    // const table = tableRef.current;
    // const rows = table.querySelectorAll("tr");
    // const currentRowIndex = Array.from(rows).indexOf(e.target);
    if (e?.key == "Enter") {
      console.log(batchTrue, "TABKEY", e.key, tab);
      // if(!batchTrue)
      {
        Promise.resolve(tab?.setCreatingRow(null)).then(() => {
          tab?.setEditingRow(row);
          setTableEditingRow(row);
          setCreateFlag(false);
          setT02Obj(row?.original);
        });
      }
      //  else{
      //   tab?.setCreatingRow(true)
      // }
    }
  };
  useEffect(() => {
    // console.log(tableEditingRow, "tableEditingRow", createFlag);
    if (tableEditingRow == null && createFlag) {
      modeOn(true);
    } else if (createFlag == false && tableEditingRow != null) {
      modeOn(true);
    } else {
      modeOn(false);
    }
  }, [tableEditingRow, createFlag]);
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e?.key == "Escape") {
        if (tableEditingRow == null) {
          Promise.resolve()
            .then(() => rowCancelHandler())
            .then(() => table.setCreatingRow(null));
        } else {
          Promise.resolve()
            .then(() => rowEditCancelHandler())
            .then(() => table.setEditingRow(null));
        }
      }
    });
  }, [tableEditingRow]);
  // useEffect(() => {
  //   t02Data?.map((t, index) => {
  //     if (index in rowSelection) {
  //       //  onkeydown=((e)=>{
  //       document.addEventListener("keydown", (e) => {
  //         if (e.key == "Enter") {
  //           // console.log("t of selected row", t);
  //           // modeOn(true);
  //           Promise.resolve(table.setCreatingRow(null)).then(() => {
  //             setCreateFlag(false)
  //             table.setEditingRow(table?.getSelectedRowModel()?.flatRows[0]);
  //             setT02Obj(t);
  //             setTableEditingRow(table?.getSelectedRowModel()?.flatRows[0]);

  //           });
  //         } else if (e.key == "Escape") {
  //           console.log(e.key, "e.key");
  //           rowCancelHandler();
  //           table.setCreatingRow(false);
  //         }
  //       });
  //       // })
  //     }
  //   });
  // }, [rowSelection, t02Data]);
  // useEffect(() => {
  //   const formElement = formRef.current;
  //   console.log(formElement, "formElement-")
  //   if (formElement) {
  //     formElement.addEventListener("keypress", (e) =>
  //       GlobalClass.formNextInput(e, formRef)
  //     );
  //   }
  //   return () => {
  //     if (formElement) {
  //       formElement.removeEventListener("keypress", (e) =>
  //         GlobalClass.formNextInput(e, formRef)
  //       );
  //     }
  //   };
  // }, [formRef]);
  function renderValue(uID, v, c, cellid, index, column) {
    // console.log(c?.S53F04, "renderValue");
    // if (c.S53F02 == "E000T02F03" && p0 == "E") {
    //   // console.log(t02Obj?.F03NM, "t02Obj?.F03NM")
    //   return t02Obj?.F03NM;
    // }
    console.log(JWListData, "PendingSliceData-");
    jwOrder = JWOrderData?.dtOrd;
    jwIssue = JWListData?.T02JDT;
    if (c?.S53F07 == 0) {
      return (
        <Button
          id={`T02Ent_${index}`}
          variant="default"
          onFocus={() => {
            document.activeElement.blur();
            // console.log("Test=>", `T02Ent_${index}`);
            setTimeout(() => {
              HiddenButtonField(uID, v, c, cellid, index, jwOrder, jwIssue);
            }, 1000);
          }}
          onClick={() => {
            setTimeout(() => {
              HiddenButtonField(uID, v, c, cellid, index, jwOrder, jwIssue);
            }, 1000);
          }}
          // style={{ padding: "0px", margin: "0px" }}
          style={{ height: 0, marginTop: 5, width: "0px", padding: "0px" }}
        />
      );
    } else {
      switch (c?.S53F14) {
        case "NUMBOX":
          if (c?.S53F02 == "E00EXPDUM") {
            return (
              <Button
                fullWidth
                // variant="default"
                style={{
                  height: 0,
                  marginTop: 5,
                  width: "0px",
                  padding: "0px",
                }}
                onFocus={() => {
                  document.activeElement.blur();
                  if (
                    t02Obj.FIELD08 > 0 &&
                    InvTypeDropDown != null &&
                    InvTypeDropDown !== undefined
                  ) {
                    TableExpenseEntry();
                  }
                }}
                id={`T02Ent_${index}`}
                onClick={() => {
                  if (
                    t02Obj?.FIELD08 > 0 &&
                    InvTypeDropDown !== null &&
                    InvTypeDropDown !== undefined
                  ) {
                    TableExpenseEntry();
                  }
                }}
              >
                Expense Entry
              </Button>
            );
          } else if (c?.S53F02 == "E00T02F08") {
            console.log(index, "AmountOO");
            return (
              <PLNumberBox
                decimalSeparator={true}
                precision={2}
                onKeyDown={(e) => {
                  console.log("Esc key Press", e);
                  if (e.key == "Enter" || e.key == "Tab") {
                    // console.log("Enter key Press",e)
                    if (tableEditingRow == null) {
                      console.log(InvTypeDropDown, "InvTypeDropDown=")
                      if (InvTypeDropDown == null) {
                        Promise.resolve(handleCreateUser())
                          .then(() => {
                            // if (!batchTrue){
                            //   console.log("Here In Amount",!batchTrue);

                            rowCancelHandler();
                          })
                          .then(() => {
                            //  Promise.resolve().then(()=>{
                            // if (!batchTrue) {
                            setCreateFlag(true);
                            table.setCreatingRow(true);
                            // } else{
                            //   setCreateFlag(false)
                            // }
                          });
                        // });
                      } else{
                        console.log(index, "index")
                        NextIndexFun(index)
                     }
                    } else {
                      console.log("Edit Product Call");
                      if (
                        (selectedProductObj?.FIELD51 == "Y" &&
                          t02Obj?.FIELD41?.trim() == "") ||
                        t02Obj?.FIELD03 == null ||
                        t02Obj?.FIELD03 == ""
                      ) {
                        GlobalClass.Notify(
                          "warning",
                          "Warning",
                          "Some Field Require to Enter"
                        );
                      } else {
                        Promise.resolve()
                          .then(() => handleSaveUser())
                          .then(() =>
                            setTimeout(() => {
                              if (obj.p0 == "E") {
                                setT02Obj({
                                  ...T02?.[T02?.length - 1],
                                  FIELD05: OS11?.S11F21,
                                });
                              } else {
                                setT02Obj({
                                  ...T02?.[0],
                                  FIELD05: OS11?.S11F21,
                                });
                              }
                              setCreateFlag(true);
                              table.setCreatingRow(true);
                            }, 500)
                          );

                        // Promise.resolve(handleSaveUser()).then(() => {
                        //   setTableEditingRow(
                        //     table?.getSelectedRowModel()?.flatRows[0]
                        //   );
                        //   setCreateFlag(true)
                        //   table.setCreatingRow(true);
                        //   // modeOn(true);
                        // });
                      }
                    }
                  } else if (e.key == "Escape") {
                    console.log("Esc key Press", e);
                    rowCancelHandler();
                    table.setCreatingRow(false);
                    setCreateFlag(false);
                  }
                }}
                id={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                }}
              />
            );
          } else {
            return (
              <PLNumberBox
                id={`T02Ent_${index}`}
                precision={c?.S53F02 == "E00T02F21" ? 2 : 3}
                disabled={
                  c?.S53F02 == "E00T02F21" && selectedProductObj?.FIELD59 == "N"
                }
                decimalSeparator={true}
                value={t02Obj?.[c?.S53F04]}
                setEdit={(e) => {
                  // console.log(e, "c?.S53F04");
                  if (e != 0) setT02Obj({ ...t02Obj, [c?.S53F04]: e });
                  else setT02Obj({ ...t02Obj, [c?.S53F04]: 0 });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // console.log("onKeyDown=>", index);
                    // let passInd = c?.S53F03 == "Rate" ? index : index
                    let ind1 = index - 1;
                    // let ind2 = index + 1;
                    // let ind = index;

                    NextIndexFun(ind1);
                  }
                }}
              />
            );
          }
          // }
          break;
        case "TEXTBOX":
          return (
            <PLTextBox
              id={`T02Ent_${index}`}
              disabled={
                c?.S53F02 == "E00T02F41" &&
                (selectedProductObj?.FIELD51 == "N" || t02Obj?.FIELD05 == "D")
                  ? true
                  : c?.S53F02 == "E00UFBAT" &&
                    selectedProductObj?.FIELD51 == "N"
                  ? true
                  : false
              }
              value={t02Obj?.[c?.S53F04]}
              onKeyDown={(e) => {
                if (e.key == "Tab") {
                  let ind1 = index;
                  let ind2 = index + 1;
                  let ind = index - 1;
                  // c?.S53F02 == "E00T02F41"
                  NextIndexFun(
                    c?.S53F02 == "E00T02F41" ? ind : ind1
                    // page == "04020399"
                    //   ? page == "04260417"
                    //     ? index + 2
                    //     : ind2
                    //   : ind1
                  );
                }
              }}
              setEdit={(e) => {
                setT02Obj({ ...t02Obj, [c?.S53F04]: e?.target?.value });
              }}
              // disabled={true}
            />
          );
          break;
        case "PLPOPUP":
          if (c.S53F02 == "E000T02F03") {
            //Product name
            // console.log(c.S53F02, "E000T02F03");
            // console.log("value,label",t02Obj?.[c?.S53F04],"    ",t02Obj?.F03NM,)
            return (
              <>
                <PLDataGrid
                  ref={prt}
                  id={`T02Ent_${index}`}
                  width={"500px"}
                  cWhere={`M21.FIELD11 LIKE '%${
                    t02Obj?.GRPNM ?? ""
                  }%' AND M21.FIELD12 LIKE '%${t02Obj?.CATCD ?? ""}%'`}
                  value={{
                    value: t02Obj?.[c?.S53F04],
                    label: t02Obj?.F03NM,
                  }}
                  // value={t02Obj?.[c?.S53F04]}
                  p1={seperate(c.S53F15)[1]}
                  setEdit={(e) => {
                    console.log("e off pname", e);
                    Promise.resolve(
                      setT02Obj({
                        ...t02Obj,
                        [c?.S53F04]: e?.FIELD01,
                        F03NM: e?.FIELD02,
                        FIELD22: e?.FIELD28 === 0 ? 1 : e?.FIELD28,
                      })
                    )
                      .then(() => {
                        if (
                          setSelectedProductObj &&
                          typeof setSelectedProductObj == "function"
                        ) {
                          setSelectedProductObj(e);
                          setFlag(true);
                        }
                      })
                      .then(() =>
                        setPriceListParemeter({
                          ...priceListParemeter,
                          productCode: e.FIELD01,
                        })
                      )
                      .then(() => setFIELD01value(e.FIELD01))
                      .then(() => NextIndexFun(index));
                  }}
                />
              </>
            );
          } else {
            // console.log(index, "index--");
            return (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                // value={t02Obj?.[c?.S53F04]}
                value={
                  c.S53F02 == "E00T02F20" //Location
                    ? {
                        value: t02Obj?.[c?.S53F04],
                        label: t02Obj?.F20NM ?? "",
                      }
                    : t02Obj?.[c?.S53F04]
                }
                p1={seperate(c?.S53F15)[1]}
                setEdit={(e) => {
                  if (c.S53F02 == "E00T02F20") {
                    // console.log("Location==>", e);
                    Promise.resolve(setLocationObj(e)).then(() => {
                      // let passInd = page == "03630342" ? index : index + 1;
                      NextIndexFun(index);
                    });
                  } else {
                    Promise.resolve(
                      setT02Obj({ ...t02Obj, [c?.S53F04]: e?.FIELD01 })
                    ).then(() => NextIndexFun(index + 1));
                  }
                  // setProdCode(e?.FIELD01)
                }}
              />
            );
          }
          break;
        case "COMBOBOX":
          if (c?.S53F02 === "E00T02RORI") {
            //Receipt/Issue
            return (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                copno={seperate(c?.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                // onKeyDown={(e) => {
                //   GlobalClass.formNextInput(e, formRef);
                // }}
                setEdit={(e) => {
                  // console.log(e.value, "ValueMember");
                  // setBatchIssueObj(null)
                  Promise.resolve(
                    setT02Obj({
                      ...t02Obj,
                      [c?.S53F04]: e.value,
                      FIELD41: "",
                      FIELD11: "",
                      // FIELD07:
                      //   e.value == "C"
                      //     ? selectedProductObj.FIELD21
                      //     : e.value == "R"
                      //     ? selectedProductObj.FIELD21
                      //     : selectedProductObj.FIELD22,
                    }),
                    setRecIss(e.value)
                  ).then(() => {
                    // let passInd =
                    //   page == "20400399" && T02UF.length < 1
                    //     ? index + 2
                    //     : page == "03630342" && T02UF.length < 1
                    //     ? index + 1
                    //     : index;
                    // let passInd2 = index;
                    NextIndexFun(index);
                  });

                  // FIELD07: t02Obj.FIELD05 == "C" ? M21.FIELD21 : t02Obj.FIELD05 == "R" ? M21.FIELD21 : M21.FIELD22
                }}
                dropdownPosition="Bottom"
                // defaultval={t02Obj?.[c?.S53F04]}
              />
            );
          } else {
            return (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                value={t02Obj?.[c?.S53F04]}
                copno={seperate(c?.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  Promise.resolve(
                    setT02Obj({ ...t02Obj, [c?.S53F04]: e.value })
                  ).then(() => {
                    // let ind = index;
                    NextIndexFun(index);
                  });
                }}
                // onKeyDown={(e) => {
                //   GlobalClass.formNextInput(e, formRef);
                // }}
                dropdownPosition="Bottom"
                defaultval={t02Obj?.[c?.S53F04]}
              />
            );
          }

          break;
        case "DATEPICKER":
          let { startDate } = DateToString(new Date());
          return (
            <PLDateBox
              setEdit={(e) => {
                let { startDate } = DateToString(e);
                if (startDate?.length == 8) {
                  setT02Obj({ ...t02Obj, [c?.S53F04]: startDate });
                }
              }}
              defaultval={
                typeof t02Obj?.[c?.S53F04] == "string" &&
                t02Obj?.[c?.S53F04] != "Invalid Date"
                  ? t02Obj?.[c?.S53F04] != ""
                    ? t02Obj?.[c?.S53F04]?.trim()
                    : startDate
                  : startDate
              }
              showClearButton={false}
              showDropDownButton={false}
              useMaskBehavior={true}
              dispformat="DD/MM/YYYY"
            />
          );

          break;
        default:
          return null;
          break;
      }
    }
  }
  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={true}
        fullScreen={false} //
        // overlayProps={{ opacity: 0.5, blur: 4 }}
        closeButtonProps={{
          onClick: (e) => {
            if (typeof DrawerObj?.onClickCloseButton == "function") {
              DrawerObj?.onClickCloseButton();
            } else {
              setDrawerObj(dra);
            }
          },
        }}
        title={DrawerObj?.title}
        size={DrawerObj?.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        // overlayProps={m.Overlay}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
        // scrollAreaComponent={ScrollArea.Autosize}
      >
        {DrawerObj.body}
      </Modal>

      <Grid gutter={4}>
        <Grid.Col span={12}>
          <MantineReactTable table={table} />
        </Grid.Col>
      </Grid>
    </>
  );
}
