import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Store from "../../utils/store";
import {
  GetVoucherSetup,
  getApiActionPara,
} from "../../utils/slices/VoucherSetupSlice";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { createColumnHelper } from "@tanstack/react-table";
import { Button, Grid, Group, Skeleton, Text } from "@mantine/core";
import ButtonPanel from "../../PlusComponent/ButtonPanel";
import EntryTypeForm from "./EntryTypeForm";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";
import { GetTMFormat } from "../../utils/slices/AccountListSlice";

const EntryType = (props) => {

  console.log("EntryType props =>", props);

  const VoucherSetupData = useSelector(
    (state) => state.VoucherSetup.VoucherSetupData
  );

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const M45 = useSelector((state) => state.VoucherSetup.VoucherSetupData?.M45);
  const M45DT = useSelector(
    (state) => state.VoucherSetup.VoucherSetupData?.M45DT
  );
  const COPER = useSelector(
    (state) => state.VoucherSetup.VoucherSetupData?.COPER
  );

  const { isLoading } = useSelector((state) => state.VoucherSetup);

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [sendProps, setSendProps] = useState(null);
  const [rowSelection, setRowSelection] = useState({});
  const [dataGridRow, setDataGridRow] = useState(null);
  const [ActionPara, setActionPara] = useState({
    action: null,
    para: null,
  });

  const dispatch = useDispatch();

  console.log("dataGridRow===>", dataGridRow);
  console.log("rowSelection===>", rowSelection);

  useEffect(() => {
    if (props?.data) {
      pageDataRender(props?.data?.F02F01);
      setSendProps(props?.data?.F02F01);
    }
  }, [props?.data]);

  useEffect(() => {
    if (M45DT) {
      setTableData(M45DT);
    }
  }, [M45DT]);

  const columnHelper = createColumnHelper();
  const { TMFormatData: tmList } = useSelector((state) => state.AccountList);
  useEffect(() => {
    if (tmList && M45 != "") {
      let col = [];
      tmList[M45?.toString().split("~C~")[0]]?.oDCFrmt?.aDCol?.map((c) => {
        col.push(
          columnHelper.accessor(c.DispExpr, {
            header: c.ColCap,
          })
        );
      });
      setColumns(col);
    }

    // if (M45DT) {
    //     let col = []
    //     Object.keys(M45DT[0]).map((key) => {
    //         col.push({
    //             accessorKey: key,
    //             header: key == "FIELD02" ? "Description" :
    //                 key == "M45F09COP" ? "Enable Disable" : key
    //         })
    //     })
    //     setColumns(col)
    // }
  }, [tmList,M45]);
  useEffect(() => {
    if (M45) {
      console.log("tmList", tmList);
      dispatch(
        GetTMFormat({
          id: M45.toString().split("~C~")[0],
          name: "",
          type: "A",
          p0: M45.toString().split("~C~")[0],
          p1: M45.toString().split("~C~")[0],
          p2: M45.toString().split("~C~")[1],
          p3: "",
        })
      );
    }

    // if (M45DT) {
    //     let col = []
    //     Object.keys(M45DT[0]).map((key) => {
    //         col.push({
    //             accessorKey: key,
    //             header: key == "FIELD02" ? "Description" :
    //                 key == "M45F09COP" ? "Enable Disable" : key
    //         })
    //     })
    //     setColumns(col)
    // }
  }, [M45]);

  const pageDataRender = (d) => {
    switch (d) {
      case "SPSS0001": //Sales invoice "TS"
        dispatch(GetVoucherSetup({ action: "T", para: "TS" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "TS" }));
        break;
      case "SPSS0002": //Sales invoice "TS"
        dispatch(GetVoucherSetup({ action: "E", para: "TS" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "TS" }));
        break;
      case "SPPPX001": //Purchase Invoice – ‘TP’
        dispatch(GetVoucherSetup({ action: "T", para: "TP" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "TP" }));
        break;
      case "SPPP0002": //Purchase Invoice – ‘TP’
        dispatch(GetVoucherSetup({ action: "E", para: "TP" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "TP" }));
        break;
      case "SPJJ0032": //Jobwork Bill(Out) – ‘JJ’ // Entry Type
        dispatch(GetVoucherSetup({ action: "T", para: "JJ" }));
        dispatch(getApiActionPara({ action: "T", para: "JJ" }));
        break;
        case "SPJJ0033": //Jobwork Bill(Out) – ‘JJ’ // Extra Detail
        dispatch(GetVoucherSetup({ action: "E", para: "JJ" }));
        dispatch(getApiActionPara({ action: "E", para: "JJ" }));
        break;
      case "SPJZ0032": //Jobwork Bill(In) – ‘JZ’ //  Entry Type
        dispatch(GetVoucherSetup({ action: "T", para: "JZ" }));
        dispatch(getApiActionPara({ action: "T", para: "JZ" }));
        break;
        case "SPJZ0033": //Jobwork Bill(In) – ‘JZ’ // Extra Detail
        dispatch(GetVoucherSetup({ action: "E", para: "JZ" }));
        dispatch(getApiActionPara({ action: "E", para: "JZ" }));
        break;
      // case "SPSS0001":   //Jobwork Bill(in) – ‘JZ’
      //     dispatch(GetVoucherSetup({ action: "T", para: "JZ" }))
      //     break;
      // case "SPSS0001":   //Receipt Note – ‘VM’
      //     dispatch(GetVoucherSetup({ action: "T", para: "VM" }))
      //     break;
      // case "SPSS0001":    //Issue Note – ‘VN’
      //     dispatch(GetVoucherSetup({ action: "T", para: "VN" }))
      //     break;
      // case "SPSS0001":    //Rent Bill – ‘RB’
      //     dispatch(GetVoucherSetup({ action: "T", para: "RB" }))
      //     break;
      case "SPHCX001": //Credit note – ‘HC’ //  Credit note into Expense Detail not found Credit note(stock) found
        dispatch(GetVoucherSetup({ action: "T", para: "HC" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "HC" }));
        break;
      case "SPHC0002": //Credit note – ‘HC’ //  Credit note into Expense Detail not found Credit note(stock) found
        dispatch(GetVoucherSetup({ action: "E", para: "HC" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "HC" }));
        break;
      case "SPHDX001": //Debit note – ‘HD’ //  Debit note into Expense Detail not found Debit note(stock) found
        dispatch(GetVoucherSetup({ action: "T", para: "HD" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "HD" }));
        break;
      case "SPHD0002": //Debit note – ‘HD’ //  Debit note into Expense Detail not found Debit note(stock) found
        dispatch(GetVoucherSetup({ action: "E", para: "HD" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "HD" }));
        break;
      case "SPBP0012": //Bank Payment – ‘BP’
        dispatch(GetVoucherSetup({ action: "T", para: "BP" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "BP" }));
        break;
      case "SPBP0011": //Bank Payment – ‘BP’
        dispatch(GetVoucherSetup({ action: "E", para: "BP" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "BP" }));
        break;
      case "SPBR0012": //Bank Receipt – ‘BR’
        dispatch(GetVoucherSetup({ action: "T", para: "BR" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "BR" }));
        break;
      case "SPBR0011": //Bank Receipt – ‘BR’
        dispatch(GetVoucherSetup({ action: "E", para: "BR" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "BR" }));
        break;
      case "SPCP0012": //Cash Payemnt -‘CP’
        dispatch(GetVoucherSetup({ action: "T", para: "CP" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "CP" }));
        break;
      case "SPCP0011": //Cash Payemnt -‘CP’
        dispatch(GetVoucherSetup({ action: "E", para: "CP" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "CP" }));
        break;
      case "SPCR0012": //Cash Receipt – ‘CR’
        dispatch(GetVoucherSetup({ action: "T", para: "CR" })); //Entry Type
        dispatch(getApiActionPara({ action: "T", para: "CR" }));
        break;
      case "SPCR0011": //Cash Receipt – ‘CR’
        dispatch(GetVoucherSetup({ action: "E", para: "CR" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "CR" }));
        break;
      // case "SPSS0001": //Share invoice – ‘SH’ // Found in menu but EntryType not found
      //     dispatch(GetVoucherSetup({ action: "T", para: "SH" })) //Entry Type
      //     break;
      case "SPSH0006": //Share invoice – ‘SH’
        dispatch(GetVoucherSetup({ action: "E", para: "SH" })); // Extra Detail
        dispatch(getApiActionPara({ action: "E", para: "SH" }));
        break;
      // case "SPSS0001": //Sub jobwork (In) – ‘SJ’  // not found in menu
      //     dispatch(GetVoucherSetup({ action: "T", para: "SJ" }))
      //     break;
      default:
        dispatch(GetVoucherSetup());
        break;
    }
  };

  console.log("VoucherSetupData===>", VoucherSetupData);

  console.log("EntryType props==>", props);

  const simulateEnterKeyPress = () => {
    // Get the row element using the rowIndex
    const rowElement = document.querySelector(`table`);
    if (rowElement) {
      // Create a new KeyboardEvent with the "Enter" key
      const event = new KeyboardEvent("keydown", {
        key: "Enter",
        bubbles: true,
        cancelable: true,
      });

      // Dispatch the event on the row element
      rowElement.dispatchEvent(event);
    }
  };

  const table = useMantineReactTable({
    data: tableData ? tableData : [],
    columns,
    enableTopToolbar: false,
    enableSorting: false,
    enableColumnActions: false,
    initialState: {
      density: "0px",
      // columnVisibility: {
      //     FIELD01: false, FIELD03: false, FIELD04: false, FIELD05: false, FIELD06: false, FIELD07: false, FIELD08: false, FIELD09: false, FIELD10: false, FIELD11: false, FIELD12: false, FIELD13: false, FIELD14: false, FIELD15: false, FIELD16: false, FIELD17: false, FIELD18: false, FIELD19: false, FIELD20: false, FIELD21: false, FIELD22: false, FIELD23: false, FIELD24: false, FIELD25: false, FIELD26: false, FIELD27: false, FIELD28: false, FIELD29: false, FIELD30: false, FIELD31: false, FIELD32: false, FIELD33: false, FIELD34: false, FIELD40: false, FIELD51: false, FIELD52: false, FIELD53: false, FIELD54: false, FIELD55: false, FIELD61: false, FIELD62: false, FIELD63: false, FIELD65: false, FIELD71: false, FIELD72: false, FIELD73: false, FIELD74: false, FIELD75: false, FIELD76: false, FIELD99: false, M45UID: false, FLDUNQ: false, FLDAED: false, FLDBRC: false, M01F02: false
      // },
    },
    state: {
      rowSelection,
    },
    mantineTableContainerProps: { sx: { maxHeight: "500px" } },
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      onClick: () => {
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
        setDataGridRow(row.original);
      },
      onDoubleClick: () => {
        Promise.resolve(
          setRowSelection((prev) => ({
            [row.id]: true,
          }))
        ).then(() => {
          simulateEnterKeyPress();
        });
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
  });

  // const OpenFormPopUp = (e) => {
  //     if (e == "A") {
  //         dispatch(
  //             ModalFunction({
  //                 onclose: () => { },
  //                 MTitle: <Text fw={700}>Add</Text>,
  //                 MAction: true,
  //                 MSize: "50%",
  //                 MBody: (i) => (<>
  //                     <EntryTypeForm index={i} data={sendProps} type={e} />
  //                 </>),
  //                 MClose: true,
  //                 //   MCentered:true,
  //                 Overlay: {
  //                     backgroundOpacity: 0.8,
  //                     blur: 5,
  //                 }
  //             })
  //         )
  //     } else {
  //         dispatch(
  //             ModalFunction({
  //                 onclose: () => { },
  //                 MTitle: <Text fw={700}>Add</Text>,
  //                 MAction: true,
  //                 MSize: "50%",
  //                 MBody: (i) => (<>
  //                     <EntryTypeForm index={i} data={sendProps} DataGridRow={dataGridRow} type={e} />
  //                 </>),
  //                 MClose: true,
  //                 //   MCentered:true,
  //                 Overlay: {
  //                     backgroundOpacity: 0.8,
  //                     blur: 5,
  //                 }
  //             })
  //         )
  //     }

  // }

  const editButtonDisable = table?.getSelectedRowModel()?.flatRows[0]?.original;
  console.log("editButtonDisable==>", editButtonDisable);

  return (
    <>
      {isLoading == true ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          <Grid gutter={4}>
            <Grid.Col span={12}>
              <MantineReactTable table={table} />
            </Grid.Col>
            <Grid.Col span={12}></Grid.Col>
          </Grid>
          <Group spacing={"xs"} position="center" display={"flex"}>
            <ButtonPanel
              data={M45 && tmList[M45.toString().split("~C~")[0]]?.oS60?.DTHKey}
              Skey={table.getSelectedRowModel().flatRows[0]}
              from={props?.obj?.id}
              VoucherFormType={props?.data?.F02F01}
              // sendActionPara={ActionPara}
            />

            {/* <Button size="sm" compact onClick={() => OpenFormPopUp("A")}>
                        Add
                    </Button> */}
          </Group>
        </>
      )}
    </>
  );
};

export default EntryType;
