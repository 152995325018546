import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import axios from "axios";
import GlobalClass from "../GlobalClass";
// import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { GetAccountList } from "./AccountListSlice";
import Store from "../store";

export const ProductListAPi = createAsyncThunk(
    'ProductList/ProductListAPi',
    async (OBJ) => {
        // console.log("api calling",OBJ);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0 == "E" ? "E" : "A",
                    "cCode": OBJ.p0 === "A" ? "" : OBJ.id,
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM21 + "?pa=" + param);
            // console.log("ProductListAPi response",response?.data?.data?.M21);
            return response.data.data;
        }
        catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }
)

export const ProductListDeleteAPi = createAsyncThunk(
    'ProductList/ProductListDeleteAPi',
    async (OBJ) => {
        GlobalClass.Notify(
            "info",
            "Deleting",
            "Please wait while we process your data"
        );
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0,
                    "cCode": OBJ.id,
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM21 + "?pa=" + param);
            console.log(response.data);
            if (response.data.status === 'SUCCESS') {
                modals.closeAll();
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
                let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj
                Store.dispatch(GetAccountList({
                    id: "00930001",
                    name: "Product List",
                    p0: "MT",
                    p1: "T_M21",
                    p2: "",
                    p3: "",
                    type: "A",
                    pagination: PaginationObj["00930001"]

                }))

            }
            else if (response.data.status === 'FAIL') {
                GlobalClass.Notify(
                    "error",
                    response?.data?.status,
                    response?.data?.message
                );
            }
        }
        catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
        }

    }
)

export const HSNCodeAPI = createAsyncThunk(
    'ProductList/HSNCodeAPI',
    async (OBJ) => {
        console.log("HSNCodeAPI API", OBJ);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0 == "E" ? "E" : "A",
                    "cCode": OBJ.p0 === "A" ? "" : OBJ.id,
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM35HSN + "?pa=" + param);
            // console.log("ProductListAPi response",response?.data?.data?.M21);
            return response.data.data;
        }
        catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }
)

export const HSNCodeDeleteAPI = createAsyncThunk(
    'ProductList/HSNCodeDeleteAPI',
    async (OBJ) => {
        GlobalClass.Notify("info", "Deleting", "Please wait while we process your data");
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "D",
                    "cCode": OBJ.id,
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM35HSN + "?pa=" + param);
            // console.log(response.data);
            if (response.data.status === 'SUCCESS') {
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
            }
            else if (response.data.status === 'FAIL') {
                GlobalClass.Notify("error", response?.data?.status, response?.data?.message);
            }
        }
        catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
        }

    }
)

export const HSNCodeHelpAPI = createAsyncThunk(
    'ProductList/HSNCodeHelpAPI',
    async (OBJ) => {
        console.log("HSNCodeHelpAPI", OBJ);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "A",
                    "cCode": "",
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GETHSNHELP + "?pa=" + param);
            // console.log("ProductListAPi response",response?.data?.data?.M21);
            return response.data.data;
        }
        catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }
)

export const SaleUnitAPI = createAsyncThunk(
    'ProductList/SaleUnitAPI',
    async (OBJ) => {
        console.log("SaleUnitAPI API", OBJ);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0 == "E" ? "E" : "A",
                    "cCode": OBJ.p0 === "A" ? "" : OBJ.id,
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM35SU + "?pa=" + param);
            // console.log("ProductListAPi response",response?.data?.data?.M21);
            return response.data.data;
        }
        catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }
)

export const PurchaseUnitAPI = createAsyncThunk(
    'ProductList/PurchaseUnitAPI',
    async (OBJ) => {
        console.log("PurchaseUnitAPI API", OBJ);
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": OBJ.p0 == "E" ? "E" : "A",
                    "cCode": OBJ.p0 === "A" ? "" : OBJ.id,
                    "cOPara": "",
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM35PU + "?pa=" + param);
            // console.log("ProductListAPi response",response?.data?.data?.M21);
            return response.data.data;
        }
        catch (error) {
            GlobalClass.Notify("error", "Error", error.message)
        }
    }
)

export const SalePurchaseDeleteAPI = createAsyncThunk(
    'ProductList/SalePurchaseDeleteAPI',
    async (OBJ) => {

        console.log("SalePurchaseDeleteAPI", OBJ);
        GlobalClass.Notify("info", "Deleting", "Please wait while we process your data");

        let response;

        try {

            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": "D",
                    "cCode": OBJ.id,
                    "cOPara": "",
                });


            if (OBJ.page == "P_UNTS") {
                response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM35SU + "?pa=" + param)
            }
            else if (OBJ.page == "P_UNTP") {
                response = await axios.get(GlobalClass.ApiUrl + GlobalClass.GetM35PU + "?pa=" + param)
            }

            if (response.data.status === 'SUCCESS') {
                GlobalClass.Notify("success", "Deleted", "Deleted successfully");
            }
            else if (response.data.status === 'FAIL') {
                GlobalClass.Notify("error", response?.data?.status, response?.data?.message);
            }
        }
        catch (e) {
            GlobalClass.Notify("error", "Error", `${e?.message}`);
        }

    }
)



const ProductList = createSlice({
    name: 'ProductList',
    initialState: {
        ProductListData: [],
        isLoading: false,
        hasError: false,
        ErrorMsg: {},
        HSNCodeData: [],
        HSNCodeIsLoading: false,
        HSNCodeHasError: false,
        HSNCodeErrorMsg: {},
        SaveLoading: false,
        SalePurchaseData: [],
        HSNCodeHelpData: [],
    },
    reducers: {
        DataSaveLoading: (state, action) => {
            console.log("action.payload", action?.payload);
            state.SaveLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(ProductListAPi.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(ProductListAPi.fulfilled, (state, action) => {

                if (!action.payload?.error) {
                    state.ProductListData = action.payload;
                    state.isLoading = false;
                    state.hasError = false;
                } else {
                    state.isLoading = false;
                    state.hasError = true;
                    state.ErrorMsg = action.payload?.error
                }


            })
            .addCase(ProductListAPi.rejected, (state, action) => {
                state.isLoading = false;
                state.hasError = true
            })
            .addCase(HSNCodeAPI.pending, (state, action) => {
                // console.log("pending");
                state.HSNCodeIsLoading = true;
                state.HSNCodeHasError = false;
            })
            .addCase(HSNCodeAPI.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = false;
                    state.HSNCodeData = action?.payload;
                } else {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = true;
                    state.HSNCodeErrorMsg = action.payload?.error
                }
            })
            .addCase(HSNCodeAPI.rejected, (state, action) => {
                // console.log("rejected");
                state.HSNCodeIsLoading = false;
                state.HSNCodeHasError = true
            })
            .addCase(HSNCodeHelpAPI.pending, (state, action) => {
                // console.log("pending");
                state.HSNCodeIsLoading = true;
                state.HSNCodeHasError = false;
            })
            .addCase(HSNCodeHelpAPI.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = false;
                    state.HSNCodeHelpData = action?.payload;
                } else {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = true;
                    state.HSNCodeErrorMsg = action.payload?.error
                }
            })
            .addCase(HSNCodeHelpAPI.rejected, (state, action) => {
                // console.log("rejected");
                state.HSNCodeIsLoading = false;
                state.HSNCodeHasError = true
            })
            .addCase(SaleUnitAPI.pending, (state, action) => {
                state.HSNCodeIsLoading = true;
                state.HSNCodeHasError = false;
            })
            .addCase(SaleUnitAPI.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = false;
                    state.SalePurchaseData = action?.payload;
                } else {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = true;
                    state.HSNCodeErrorMsg = action.payload?.error
                }
            })
            .addCase(SaleUnitAPI.rejected, (state, action) => {
                // console.log("rejected");
                state.HSNCodeIsLoading = false;
                state.HSNCodeHasError = true
            })
            .addCase(PurchaseUnitAPI.pending, (state, action) => {
                state.HSNCodeIsLoading = true;
                state.HSNCodeHasError = false;
            })
            .addCase(PurchaseUnitAPI.fulfilled, (state, action) => {
                if (!action.payload?.error) {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = false;
                    state.SalePurchaseData = action?.payload;
                } else {
                    state.HSNCodeIsLoading = false;
                    state.HSNCodeHasError = true;
                    state.HSNCodeErrorMsg = action.payload?.error
                }
            })
            .addCase(PurchaseUnitAPI.rejected, (state, action) => {
                state.HSNCodeIsLoading = false;
                state.HSNCodeHasError = true
            })
    }

})
export const { DataSaveLoading } = ProductList.actions
export default ProductList.reducer;