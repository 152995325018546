import React from "react";
import Store from "../../../utils/store";
import { ModalFunction } from "../../../utils/slices/ModalSlice";
import { Text } from "@mantine/core";
import GlobalClass from "../../../utils/GlobalClass";
import JobWorkOutORBForm from "../RIOJobOutForm/JobWorkOutORBForm";
import { TransactionDeleteApi } from "../../../utils/TransactionSlices/Transaction";
import { RefreshIOrderPopUp, RefreshIssuePopUp } from "../../../utils/TransactionSlices/PendingIssueSlice";

export function VouEntAct(props) {
  console.log(props, "JOBWORKOut--");
  const data = JSON.parse(props);

  if (data?.p0 == "A") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "80%",
        MBody: (i) => (
          <JobWorkOutORBForm
            obj={data}
            index={i}
            No={data?.page == "20400399" ? "Order No" : "Voucher No"}
            date={data?.page == "20400399" ? "Order Date" : "Voucher Date"}
          />
        ),
        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([Store.dispatch(RefreshIssuePopUp({})), Store.dispatch(RefreshIOrderPopUp({}))])
      }
      })
    );
  } else if (data?.id && data?.p0 == "E") {
    Store.dispatch(
      ModalFunction({
        onclose: () => {},
        MTitle: <Text fw={500}>{data?.text}</Text>,
        MAction: true,
        MSize: "80%",
        MBody: (i) => (
          <JobWorkOutORBForm
            obj={data}
            index={i}
            No={data?.page == "20400399" ? "Order No" : "Voucher No"} 
            date={data?.page == "20400399" ? "Order Date" : "Voucher Date"}
          />
        ),

        MClose: true,
        Overlay: {
          backgroundOpacity: 0.8,
          blur: 5,
        },
        onClickCloseButton: () => {
          Promise.all([Store.dispatch(RefreshIssuePopUp({})), Store.dispatch(RefreshIOrderPopUp({}))])
      }
      })
    );
  } else if (data.p0 === "D" && data.id) {
    if (window.confirm("Confirm to Delete ?")) {
       Store.dispatch(TransactionDeleteApi(data));
    }
  }
}
