import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetGSTRulesCheck } from '../../utils/slices/VoucherSetupPopUpSlice'
import PLComboBox from '../../PlusComponent/PLComboBox'
import GlobalClass from '../../utils/GlobalClass'

const GSTRulesCheck = (props) => {
    const { setEditSend, value } = props
    const isLoading = useSelector((state) => state.VoucherSetupPopUp?.isLoading)
    const GSTRulesCheckData = useSelector((state) => state.VoucherSetupPopUp?.GSTRulesCheckData)
    console.log("GSTRulesCheckData", GSTRulesCheckData)
    const dispatch = useDispatch()
    const [selectedItem, setSelectedItem] = useState([])

    useEffect(() => {
        dispatch(GetGSTRulesCheck())
    }, [])

    useEffect(() => {
        if (selectedItem && selectedItem.length > 0) {
            setEditSend(selectedItem.toString())
        }
    }, [selectedItem])

    const seperate = (a, b) => {
        let arr;
        if (value !== "") {
            arr = a.split(',')
            return arr
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {
                isLoading ? GlobalClass.RenderLoader('dots') :
                GSTRulesCheckData &&
                <PLComboBox
                dropdownPosition='top'
                setComboBox={() => props?.closeCombo(true)}
                    data={GSTRulesCheckData}
                    value={seperate(value)}
                    multiple={true}
                    dispexpr="SG1F02"
                    valexpr="SG1F01"
                    setEdit={(e) => {
                        // console.log('selected val',e)
                        let val = [];
                        if (e?.length > 0) {
                            e?.map((m) => {
                                val.push(m)
                                // console.log('val', val)
                            })
                            return setSelectedItem(val)
                        } else {
                            setEditSend("")
                        }
                    }}
                />
            }
        </div>
    )
}

export default GSTRulesCheck