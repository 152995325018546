import { Text } from "@mantine/core";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import TransactionSalesForm from "./TransactionSalesForm";
import GlobalClass from "../../utils/GlobalClass";
import { deleteUserData } from "../../utils/slices/CompanySetupSlice";
import { TransactionDeleteApi } from "../../utils/TransactionSlices/Transaction";
import SIDetailPage from "./SIDetailPage";
import { GetExpenseDataNull, GetInvTypeDropDown, GetPendingOrderDataNull, GetPendingQuotationDataNull, GetSingleOrderRowNull, GetSingleSelectedOrderRowNull } from "../../utils/TransactionSlices/TSalesSlices";
import { useMediaQuery } from "@mantine/hooks";
import { TM_PRINTVF } from "../../components/PrintVoucherReport";



export function VouEntAct(itemOBJ) {

    const OBJ = JSON.parse(itemOBJ);
    console.log('Transaction Sales Object===>', OBJ);
    if (OBJ.p0 === 'A') { // Add
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "98%",
            // position:'right',
            fullScreen: false,
            MBody: (i) => (<TransactionSalesForm obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { },
            onClickCloseButton: () => {
                Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(()=>Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(()=>Store.dispatch(GetPendingOrderDataNull([]))).then(()=>Store.dispatch(GetSingleOrderRowNull   ([]))).then(()=>Store.dispatch(GetExpenseDataNull([])))
                // GetPendingOrderDataNull, GetSingleOrderRowNull
            }
        }))
    } else if (OBJ.p0 === 'E' && OBJ?.id) { // Edit
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "98%",
            // position:'right',
            MBody: (i) => (<TransactionSalesForm obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { },
            onClickCloseButton: () => {
                Promise.resolve(Store.dispatch(GetInvTypeDropDown(null))).then(() => Store.dispatch(GetPendingQuotationDataNull([]))).then(()=>Store.dispatch(GetSingleSelectedOrderRowNull([]))).then(()=>Store.dispatch(GetPendingOrderDataNull([]))).then(()=>Store.dispatch(GetSingleOrderRowNull   ([]))).then(()=>Store.dispatch(GetExpenseDataNull([])))
            }
        }))
    } else if (OBJ.p0 === 'D' && OBJ?.id) { // Delete
        console.log("Delete Object==>", OBJ);
        // dispatch(TransactionApi(obj))
        if (window.confirm('Confirm to Delete ?')) {
            Store.dispatch(TransactionDeleteApi(OBJ))
        }
    } else if (OBJ.p0 === 'VD' && OBJ?.id) { // Detail
        Store.dispatch(ModalFunction({
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "50%",
            MBody: (i) => (<SIDetailPage obj={OBJ} index={i} />),
            MClose: true,
            onclose: () => { }
        }))
    } else if(OBJ.p0 === 'T' && OBJ?.id){ //Audit
        if (window.confirm('Confirm to Audit ?')) {
            Store.dispatch(TransactionDeleteApi(OBJ))
        }
    }
    else if(OBJ.id && OBJ.p0 ==="P"){
        // eval('TM_PRINTVF('+OBJ+')')
        TM_PRINTVF(itemOBJ);
    }


}