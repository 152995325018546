import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAutoNumGenData, GetUserFieldAutoNum } from '../../../utils/slices/CompanySetUpPopUpSlice'
import { Button, Grid, Paper, Text } from '@mantine/core'
import GlobalClass from '../../../utils/GlobalClass'
import PLDataGrid from '../../../PlusComponent/PLDataGrid'
import PLTextBox from '../../../PlusComponent/PLTextBox'
import PLNumberBox from '../../../PlusComponent/PLNumberBox'
import { DataSaveLoading } from '../../../utils/slices/DataSaveLoadingSlice'
import gensetting from '../../../utils/gensetting'
import { ModalDelete } from '../../../utils/slices/ModalSlice'

const UserFieldAutoNumForm = (props) => {
  const { obj } = props
  console.log('obj==>>', obj)
  const isLoading = useSelector((state) => state.CompanySetupPopUp?.isLoading)
  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );
  const UsrFldAutoNumData = useSelector((state) => state.CompanySetupPopUp?.UserFieldAutoNumData)
  const T95Data = useSelector((state) => state.CompanySetupPopUp?.UserFieldAutoNumData?.T95)
  console.log('T95Data', T95Data)

  const P_M41 = useSelector((state) => state.CompanySetupPopUp?.UserFieldAutoNumData?.P_M41)
  const P_M41DT = useSelector((state) => state.CompanySetupPopUp?.UserFieldAutoNumData?.P_M41DT)
  const dispatch = useDispatch()
  const [T95, setT95] = useState({
    FIELD01: "",
    FIELD02: "",
    FIELD03: "",
    FIELD04: 0,
    FIELD05: "",
    FIELD06: "",
    FIELD07: 0,
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD00: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    PREFIXLEN: 0,
    SUFFIXLEN: 0,
    NUMFIXLEN: 0,
    T95DISABLE: "PS",
    T95CONTCH: ""
  })
  const [prefixLength, setPrefixLength] = useState(null)
  const [suffixLength, setSuffixLength] = useState(null)
  console.log('prefixLength==>>', prefixLength)
  console.log('suffixLength==>>', suffixLength)

  useEffect(() => {
    if (obj?.p0 == "E") {
      console.log('objj=>>', obj)
      dispatch(GetUserFieldAutoNum({ action: "E", code: obj?.id }))
    } else {
      dispatch(GetUserFieldAutoNum())
    }
  }, [obj])

  useEffect(() => {
    if (T95Data) {
      setT95(T95Data)
    }
  }, [T95Data])

  const handleSave = () => {
    console.log('T95==>>', T95)
    if (T95?.FIELD03 == "") {
      GlobalClass.Notify("warning", "Please Select User Field")
    } else {
      dispatch(DataSaveLoading(true));
      GlobalClass.Notify("info", obj.p0 == "E" ? "Editing" : "Adding", "Please wait while we process your data")
      let jData = T95
      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0,
        cCode: obj?.p0 == "E" ? obj?.id : "",
        cSData: JSON.stringify(jData),
      };
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: obj?.p0,
        cCode: obj?.p0 == "E" ? obj?.id : "",
      });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      fetch(
        GlobalClass.ApiUrl + GlobalClass.PostT95UsrFildAutoNo + "?pa=" + param,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          // console.log('api data', data);
          // console.log('api data status', data?.status);
          if (data.status === "SUCCESS") {
            Promise.resolve(dispatch(GetAutoNumGenData()))
              .then(dispatch(DataSaveLoading(false)))
              .then(() => dispatch(ModalDelete(props.index)))
              .then(() =>
                GlobalClass.Notify(
                  "success",
                  obj.p0 == "E" ? "Edited" : "Added",
                  obj.p0 == "E" ? "Edited Successfully" : "Added Successfully"
                )
              );
          } else {
            Promise.response(dispatch(DataSaveLoading(false))).then(() =>
              GlobalClass.Notify(
                "error",
                data?.status,
                data?.message
              )
            );
          }
        })
        .catch((e) => {
          Promise.resolve(
            GlobalClass.Notify(
              "error",
              "Error",
              `${e?.message}`
            )).then(() => dispatch(DataSaveLoading(false)));
        });
    }
  }

  const seperate = (e) => {
    let arr;
    arr = e?.FIELD54?.split("#")
    // console.log('arr=>>', arr[4])
    setPrefixLength(arr[4])
    setSuffixLength(arr[5])
  }

  // console.log('t95===>', T95)
  return (
    <>
      {SaveLoading == true ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <>
          {isLoading == true ? GlobalClass.RenderLoader("dots") :
            UsrFldAutoNumData &&
            <>
              <Paper shadow="md" radius="md" p="xs" withBorder>
                <Grid gutter={4}>
                  <Grid.Col span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text size={12}>User Field</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLDataGrid
                        TmData={P_M41}
                        dispexpr={"FIELD03"}
                        data={P_M41DT?.jData}
                        setEdit={(e) => {
                          console.log('e======>', e)
                          setT95({ ...T95, FIELD03: e.FIELD01 })
                          seperate(e)
                        }}
                        value={T95?.FIELD03}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                <Grid gutter={4}>
                  <Grid.Col span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text size={12}>Prefix</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        //FIELD05
                        disabled={prefixLength > 0 ? false : true}
                        nmaxlength={prefixLength}
                        value={T95?.FIELD05}
                        setEdit={(e) => {
                          setT95({ ...T95, FIELD05: e.target.value })
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                <Grid gutter={4}>
                  <Grid.Col span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text size={12}>Suffix</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLTextBox
                        //FIELD06
                        disabled={suffixLength > 0 ? false : true}
                        nmaxlength={suffixLength}
                        setEdit={(e) => {
                          // console.log('eee=====>', e)
                          setT95({ ...T95, FIELD06: e.target.value })
                        }}
                        value={T95?.FIELD06}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                <Grid gutter={4}>
                  <Grid.Col span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text size={12}>Last No.</Text>
                    </Grid.Col>
                    <Grid.Col span={10}>
                      <PLNumberBox
                        // FIELD07
                        setEdit={(e) => {
                          setT95({ ...T95, FIELD07: e })
                        }}
                        value={T95?.FIELD07}
                      />
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
                <Grid gutter={4}>
                  <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button onClick={() => handleSave()}>OK</Button>
                  </Grid.Col>
                </Grid>
              </Paper>
            </>
          }
        </>
      )}
    </>
  )
}

export default UserFieldAutoNumForm