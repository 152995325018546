import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createColumnHelper } from "@tanstack/react-table";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import PLComboBox from "../../PlusComponent/PLComboBox";
import {
  ActionIcon,
  Button,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  Modal,
  Paper,
  Spoiler,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import PLDateBox from "../../PlusComponent/PLDateBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import JBUserField from "./JBUserField";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { GetAccountList, GetTMFormat } from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";
import {
  BatchLogAddRemoveFun,
  TransactionApi,
} from "../../utils/TransactionSlices/Transaction";
import PLNarration from "../../PlusComponent/PLNarration";
import JBUserFieldEntry from "./JBUserFieldEntry";
import PLTextarea from "../../PlusComponent/PLTextarea";

const JobWorkOpeningForm = (props) => {
  const { obj } = props;
  // console.log('JobWorkOpeningForm props=>>', obj)
  const formRef = useRef();
  const [T02Data, setT02Data] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [T41Obj, setT41Obj] = useState({
    T92F00: "",
    FIELD34NM: "",
    FIELD01: "",
    FIELD02: "",
    FIELD03: 0,
    FIELD04: "",
    FIELD05: "",
    FIELD06: "0",
    FIELD07: "0",
    FIELD08: 0,
    FIELD09: 0,
    FIELD10: "",
    FIELD11: "",
    FIELD12: "",
    FIELD13: "",
    FIELD14: "N",
    FIELD15: "",
    FIELD16: "",
    FIELD17: "",
    FIELD18: 0,
    FIELD21: "O",
    FIELD22: "",
    FIELD23: "",
    FIELD30: "",
    FIELD31: "",
    FIELD32: "",
    FIELD33: "",
    FIELD70: "",
    FIELD71: "",
    FIELD75: "0",
    FIELD99: "",
    FIELD72: "",
    FIELD20: 0,
    FIELD80: "",
    FIELD51: 0,
    FIELD34: "",
    FIELD35: "",
    FIELD36: "",
    FIELD37: "",
    FIELD38: "",
    FLDJIO: "",
    FIELD39: "",
    FIELD40: "",
    FIELD41: "",
    FIELD42: "",
    FIELD43: "",
    FIELD44: "",
    FIELD45: "",
    FIELD46: "",
    FIELD47: "",
    FIELD48: "",
    FIELD49: "",
    FIELD50: "",
    FIELD52: "",
    FIELD53: "",
    T41PVT: "JA",
    FIELD54: "",
    T41VNO: "",
    CMID: 0,
    ADDID: 0,
    PRESCPATH: "",
    OSTATUS: "",
    DTYPE: "",
    DELURL: "",
    DELMBNO: "",
    FIELD55: "",
    FIELD56: "",
    T41POS: "",
    UBILTO: "",
    UGSTR: "",
    USALM: "",
    UDISP: "",
    UPAYT: "",
    UPR: "",
    UNAME: "",
    UOP: "",
    UTRA: "",
    UODP: "",
    UREMA: "",
    UEMAIL: "",
    URA: "",
    FLDUNQ: 0,
    FLDAED: "A",
    FLDBRC: "",
    PA000001: "",
    PA000002: "",
    PD000001: 0,
    PD000002: 0,
    UUASDF05: "",
    UUASD09: "",
    UUBAER: "",
    UUUBAER: "",
    EA00000001: "",
    EA00000002: "",
    EA00000003: "",
    EA00000004: "",
    EA00000005: "",
    EA00000006: "",
    EA00000007: "",
    EA00000008: "",
    EA00000009: "",
    EA00000010: "",
    EA00000011: "",
    EA00000012: "",
    EA00000013: "",
    EA00000014: "",
    EA00000015: "",
    ED00000001: 0,
    ED00000002: 0,
    ED00000003: 0,
    ED00000004: 0,
    ED00000005: 0,
    ED00000006: 0,
    ED00000007: 0,
    ED00000008: 0,
    ED00000009: 0,
    ED00000010: 0,
    ED00000011: 0,
    ED00000012: 0,
    ED00000013: 0,
    ED00000014: 0,
    ED00000015: 0,
    EP00000001: 0,
    EP00000002: 0,
    EP00000003: 0,
    EP00000004: 0,
    EP00000005: 0,
    EP00000006: 0,
    EP00000007: 0,
    EP00000008: 0,
    EP00000009: 0,
    EP00000010: 0,
    EP00000011: 0,
    EP00000012: 0,
    EP00000013: 0,
    EP00000014: 0,
    EP00000015: 0,
    UUASWER: "",
    UUUASWER: "",
    UCRE: "",
    UCRE2: 0,
    UDCNO: "",
    UDE: "",
    UDEL: "",
    UDT: "",
    ULEN: "",
    ULRD: "",
    UOD: "",
    UODD: "",
    USAL: 0,
    UTR: "",
    UUUASD09: "",
    DSDATE: "2023-04-01T00:00:00+03:00",
    DEDATE: "2024-03-31T00:00:00+02:00",
    CACTION: "A",
    lInvType: false,
    lMTaxEnt: false,
    T92F02: "",
    CFRMTITLE: "",
    BTOBEFF: " ",
    M01BAL: "0",
    M01GRP: "",
    M01OPPGRP: "",
    M01CT: "",
    F07ACC: "",
    LBLF08AMT: "0.00   ",
    LBLF07AMT: "0.00   ",
    LBLF08SYMB: null,
    LBLF07SYMB: null,
  });
  const [T02Obj, setT02Obj] = useState(
    {
      GRPNM: "",
      CATNM: "",
      F03NM: "",
      F20NM: "",
      FIELD01: "",
      FIELD02: "",
      FIELD03: "",
      FIELD04: "",
      FIELD05: "",
      FIELD06: 0.0,
      FIELD07: 0.0,
      FIELD08: 0.0,
      FIELD09: 0.0,
      FIELD10: 0.0,
      FIELD11: "",
      FIELD12: "",
      FIELD56: "",
      FIELD13: "",
      FIELD14: "",
      FIELD15: "",
      FIELD16: "",
      FIELD17: "",
      FIELD18: "",
      FIELD19: "",
      FIELD20: "",
      FIELD21: 0.0,
      FIELD22: 0.0,
      FIELD23: 0.0,
      FIELD24: "",
      FIELD25: 0.0,
      FIELD26: "",
      FIELD27: "",
      FIELD28: false,
      FIELD29: 0.0,
      FIELD30: "",
      FIELD31: 0.0,
      FIELD32: 0.0,
      FIELD33: 0.0,
      FIELD41: "",
      FIELD50: 0.0,
      FIELD51: "",
      FIELD52: 0.0,
      FIELD71: "",
      FIELD81: 0.0,
      FIELD82: "",
      FIELD83: "",
      FIELD84: "",
      FIELD85: "",
      FIELD86: "",
      FIELD61: 0.0,
      FIELD62: 0.0,
      FIELD63: "",
      FIELD64: 0.0,
      FIELD65: "",
      FIELD66: 0.0,
      FIELD67: 0.0,
      FIELD72: "",
      FIELD68: "",
      FIELD69: "",
      FIELD53: "",
      FIELD54: 0.0,
      FIELD55: 0.0,
      FIELD091: 0.0,
      FIELD58: "",
      FIELD57: "",
      FIELD60: 0.0,
      T41PVT: "",
      FIELD87: 0.0,
      FIELD88: "",
      UGRO: 0.0,
      URATE: 0.0,
      UMUR: 0.0,
      UPE: 0.0,
      UAP: 0.0,
      URE: "",
      UISSUERA: 0.0,
      FLDUNQ: 0,
      FLDAED: "",
      FLDBRC: "",
      UUDIS: "",
      UUDISAAA: "",
      UUGROAAA: "",
      UUGR01: "",
      UUGRO: "",
      IA00000001: "",
      IA00000002: "",
      IA00000003: "",
      IA00000004: "",
      IA00000005: "",
      IA00000006: "",
      IA00000007: "",
      IA00000008: "",
      IA00000009: "",
      IA00000010: "",
      IA00000011: "",
      IA00000012: "",
      IA00000013: "",
      IA00000014: "",
      IA00000015: "",
      IA00000016: "",
      IA00000017: "",
      IA00000018: "",
      IA00000019: "",
      IA00000020: "",
      IA00000021: "",
      IA00000022: "",
      IA00000023: "",
      ID00000001: 0.0,
      ID00000002: 0.0,
      ID00000003: 0.0,
      ID00000004: 0.0,
      ID00000005: 0.0,
      ID00000006: 0.0,
      ID00000007: 0.0,
      ID00000008: 0.0,
      ID00000009: 0.0,
      ID00000010: 0.0,
      ID00000011: 0.0,
      ID00000012: 0.0,
      ID00000013: 0.0,
      ID00000014: 0.0,
      ID00000015: 0.0,
      ID00000016: 0.0,
      ID00000017: 0.0,
      ID00000018: 0.0,
      ID00000019: 0.0,
      ID00000020: 0.0,
      ID00000021: 0.0,
      ID00000022: 0.0,
      ID00000023: 0.0,
      IP00000001: 0.0,
      IP00000002: 0.0,
      IP00000003: 0.0,
      IP00000004: 0.0,
      IP00000005: 0.0,
      IP00000006: 0.0,
      IP00000007: 0.0,
      IP00000008: 0.0,
      IP00000009: 0.0,
      IP00000010: 0.0,
      IP00000011: 0.0,
      IP00000012: 0.0,
      IP00000013: 0.0,
      IP00000014: 0.0,
      IP00000015: 0.0,
      IP00000016: 0.0,
      IP00000017: 0.0,
      IP00000018: 0.0,
      IP00000019: 0.0,
      IP00000020: 0.0,
      IP00000021: 0.0,
      IP00000022: 0.0,
      IP00000023: 0.0,
      IT00000001: "",
      IT00000002: "",
      IT00000003: "",
      IT00000004: "",
      IT00000005: "",
      IT00000006: "",
      IT00000007: "",
      IT00000008: "",
      IT00000009: "",
      IT00000010: "",
      IT00000011: "",
      IT00000012: "",
      IT00000013: "",
      IT00000014: "",
      IT00000015: "",
      IT00000016: "",
      IT00000017: "",
      IT00000018: "",
      IT00000019: "",
      IT00000020: "",
      IT00000021: "",
      IT00000022: "",
      IT00000023: "",
      UDIS: 0.0,
      USERFLD: "",
      USERFLDB: "2",
      SRDETAIL: "",
      EXPDUMMY: 0.0,
      BARCODE: "",
      OLDPRD: "",
      OLDQTY: 0.0,
      OQTY2: 0.0,
      OLDRATE: 0.0,
      LADDNEW: "Y",
      PRICELIST: " ",
      _BATSTOCK: " ",
      _LOCWISE: " ",
      LDUALSTK: " ",
      SRNOSTK: " ",
      MRPSTK: " ",
      RORI: " ",
      ORDDUMMY: " ",
      SRIDUMMY: " ",
      SRRDUMMY: " ",
      SRIDUMMYF: " ",
      SRRDUMMYF: " ",
      SRISSCD: "",
      OLDSRNO: "",
      SCNOCL: "",
      SCNOCL1: "",
      _EXPTOTALAMT: 0.0,
    }
  );
  const [columns, setColumns] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [DateBoxValue, setDateBoxValue] = useState({
    DateOne: null,
    DateTwo: null,
  });
  const [recieptSum, setRecieptSum] = useState(0);
  const [issueSum, setIssueSum] = useState(0);
  const [FIELD01Value, setFIELD01value] = useState("");
  const [NarrationData, setNarrationData] = useState([]);
  const [SelectedNarrationData, setSelectedNarrationData] = useState([]);
  const [tableEditingRow, setTableEditingRow] = useState(null)
  const [LocationObj, setLocationObj] = useState(null)
  const pr = useRef();

  const [BillSeriesHideShow, setBillSeriesHideShow] = useState({
    BillNoVal: "",
    TextInput: false,
    DataGridInput: true
  })

  const dispatch = useDispatch();
  const { isLoading, hasError, ErrorMsg } = useSelector(
    (state) => state.Transaction
  );

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  const GetVouEntJobWorkFormData = useSelector(
    (state) => state.Transaction?.TransactionData
  );
  // console.log('GetVouEntJobWorkFormData',GetVouEntJobWorkFormData)

  const OSV = useSelector((state) => state.Transaction?.TransactionData?.OSV);
  const T92 = useSelector((state) => state.Transaction?.TransactionData?.T92);
  const P_M31J = useSelector((state) => state.Transaction?.TransactionData?.P_M31J);
  const P_M31JDT = useSelector((state) => state.Transaction?.TransactionData?.P_M31JDT);
  const OS11 = useSelector((state) => state.Transaction?.TransactionData?.OS11);
  const T02 = useSelector((state) => state.Transaction?.TransactionData?.T02);
  const T02ENT = useSelector((state) => state.Transaction?.TransactionData?.T02ENT);
  const T02UF = useSelector((state) => state.Transaction?.TransactionData?.T02UF);
  const UFT41H = useSelector((state) => state.Transaction?.TransactionData?.UFT41H);
  const T41 = useSelector((state) => state.Transaction?.TransactionData?.T41);
  const dtUFT11 = useSelector((state) => state.Transaction?.TransactionData?.dtUFT11);
  const PLDATA = useSelector((state) => state.Transaction?.TransactionData?.PLDATA);
  const lEditVou = useSelector((state) => state.Transaction?.TransactionData?.lEditVou);
  const { oYear } = useSelector((state) => state?.Language?.General);
  const {
    isLoading: AccountisLoading,
    AccountListData: accList,
    TMFormatData: tmList
  } = useSelector((state) => state.AccountList);

  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  }
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [GroupCatogaryProduct, setGroupCatogaryProduct] = useState({
    "Group": "",
    "Catogary": "",
    "Product": ""
  })
  const [priceListParemeter, setPriceListParemeter] = useState({
    priceListCode: "",
    productCode: "",
    accountCode: "",
  });
  const [selectedProductObj, setSelectedProductObj] = useState({});
  const [userFieldHideShow, setUserFieldHideShow] = useState("")
  const [BatchIssueObj, setBatchIssueObj] = useState(null)
  const [recieptIssueVal, setRecieptIssueVal] = useState()
  const [purchasePartyObj, setPurchasePartyObj] = useState(null)
  // console.log('T02Obj=>>>',T02Obj)

  // console.log('columns=>', columns)

  function parseDateString(dateString) {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // Months are 0-indexed
    const day = dateString.substring(6, 8);

    return new Date(year, month, day);
  }

  // Example date string
  const dateString = T41Obj?.FIELD02;
  const date = parseDateString(dateString);

  // Step 2: Format the Date object
  function formatDate(date) {
    return date.toString(); // Converts to a string in the format you need
  }

  // Get formatted date string
  const formattedDate = formatDate(date);
  // console.log("formattedDate",formattedDate);

  useEffect(() => {
    if (formattedDate) {
      setSelectedDay(formattedDate)
    }
  }, [formattedDate])

  // useEffect(() => {
  //   console.log('T41Obj', T41Obj)
  //   // console.log('T02Data', T02Data)
  //   console.log('BatchIssueObj', BatchIssueObj)
  //   console.log('recieptIssueVal', recieptIssueVal)
  //   console.log('T02Obj', T02Obj)
  //   console.log('selectedProductObj', selectedProductObj)
  //   // console.log('compare obj', T41Obj === T41)
  //   // console.log('compare T02', T02Data === T02)
  //   console.log('T41', T41)
  //   console.log('oYear', oYear)
  //   console.log('purchasePartyObj', purchasePartyObj)
  // }, [T41Obj, T02Data, T02, T41, selectedProductObj, recieptIssueVal, purchasePartyObj, T02Obj, BatchIssueObj, oYear])

  useEffect(() => {
    if (purchasePartyObj !== null) {
      Promise.resolve().then(() => {
        setT02Obj({ ...T02Obj, FIELD12: purchasePartyObj?.FIELD01 ?? '' })
      }).then(() => {
        if (tableEditingRow == null) {
          console.log("Add Product Call")
          if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
            console.log('if consition ater purchase party add mode')
            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
          } else {
            Promise.resolve(handleCreateRow())
              .then(() => table.setCreatingRow(true))
          }
        } else {
          console.log("Edit Product Call")
          if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
            console.log('if condition after purchase party edit mode')
            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
          } else {
            Promise.resolve()
              .then(() => handleEditSave())
              .then(() => {
                if (obj.p0 == "E") {
                  setT02Obj(T02?.[T02?.length - 1]);
                } else {
                  setT02Obj(T02?.[0])
                }
              }).then(() => table.setCreatingRow(true))
          }
        }
      })
    }
  }, [purchasePartyObj])

  useEffect(() => {
    if (obj) {
      Promise.resolve(dispatch(TransactionApi(obj)))
        .then(() => {
          Promise.resolve(dispatch(GetAccountList({
            "id": "T_M32G",
            "name": "",
            "type": "A",
            "p0": "T_M32G",
            "p1": "T_M32G",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetTMFormat({
            "id": "T_M32G",
            "name": "",
            "type": "A",
            "p0": "T_M32G",
            "p1": "T_M32G",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetAccountList({
            "id": "T_M32C",
            "name": "",
            "type": "A",
            "p0": "T_M32C",
            "p1": "T_M32C",
            "p2": "",
            "p3": ""
          }))).then(() => dispatch(GetTMFormat({
            "id": "T_M32C",
            "name": "",
            "type": "A",
            "p0": "T_M32C",
            "p1": "T_M32C",
            "p2": "",
            "p3": ""
          })))
        })
      // .then(() => dispatch(GetAccountList({
      //   "id": "P_M21",
      //   "name": "",
      //   "type": "A",
      //   "p0": "P_M21",
      //   "p1": "P_M21",
      //   "p2": "",
      //   "p3": ""
      // }))).then(() => dispatch(GetTMFormat({
      //   "id": "P_M21",
      //   "name": "",
      //   "type": "A",
      //   "p0": "P_M21",
      //   "p1": "P_M21",
      //   "p2": "",
      //   "p3": ""
      // })))
    }
  }, [obj]);

  useEffect(() => {
    if (hasError && !isLoading) {
      GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
    }
  }, [hasError]);

  useEffect(() => {
    if (T02) {
      // const updatedT02 = T02.slice(0, -1);
      const updatedT02 = T02?.filter((v, i) => v?.FIELD03 !== "")
      setT02Data(updatedT02);
      if (obj.p0 == "E") {
        setT02Obj(T02?.[T02?.length - 1]);
      } else {
        setT02Obj(T02?.[0])
      }
    }
  }, [T02]);

  useEffect(() => {
    if (T41) {
      setT41Obj(T41);
    }
  }, [T41]);

  useEffect(() => {
    if (oYear) {
      setUserFieldHideShow(oYear?.OSC?._USERFIELD)
    }
  }, [oYear])

  let stringOfNames;
  useEffect(() => {
    if (SelectedNarrationData.length >= 0) {
      const stringifyArray = SelectedNarrationData.map((a) => a.FIELD01);
      stringOfNames = stringifyArray.join(" ");
      console.log("stringOfNames", stringOfNames);
    }
    setT41Obj({ ...T41Obj, FIELD15: stringOfNames });
    // setT41Obj({...T41Obj,FIELD15:})
  }, [stringOfNames, SelectedNarrationData]);

  // useEffect(() => {
  //   if (OS11?.S11F22 == "I") {
  //     setT02Obj({
  //       ...T02Obj,
  //       FIELD05: "D"
  //     })
  //   } else {
  //     setT02Obj({
  //       ...T02Obj,
  //       FIELD05: "C"
  //     })
  //   }
  // }, [OS11?.S11F22])

  // pricelist value set
  useEffect(() => {
    setT02Obj({
      ...T02Obj,
      FIELD27: priceListParemeter?.priceListCode
    })
  }, [priceListParemeter])

  useEffect(() => {
    if (LocationObj) {
      Promise.resolve().then(() => {
        setT02Obj({
          ...T02Obj,
          FIELD20: LocationObj?.FIELD01 ?? "",
          F20NM: LocationObj?.FIELD02 ?? ""
        })
      })
    }
  }, [LocationObj, T02Obj?.FIELD20])

  useEffect(() => {
    Promise.resolve()
      .then(() => {
        const newT02Obj = {
          ...T02Obj,
          FIELD11: BatchIssueObj?.FIELD01 ?? "",
          // FIELD20: LocationObj?.FIELD01 ?? "",
          // F20NM: LocationObj?.FIELD02 ?? "",
          // FIELD07: (T02Obj?.FIELD05 == "C" ? selectedProductObj?.FIELD21 : T02Obj?.FIELD05 == "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22) ?? 0,
        }

        if (!newT02Obj.FIELD07 && selectedProductObj) {
          newT02Obj.FIELD07 = T02Obj?.FIELD05 === "C" ? selectedProductObj?.FIELD21 : T02Obj?.FIELD05 === "D" ? selectedProductObj?.FIELD21 : selectedProductObj?.FIELD22 ?? 0
        }

        if (!newT02Obj.FIELD41 && BatchIssueObj?.FIELD02) {
          newT02Obj.FIELD41 = BatchIssueObj.FIELD02 ?? ""
        }

        setT02Obj(newT02Obj);
      })
  }, [BatchIssueObj, selectedProductObj, T02Obj?.FIELD11])

  useEffect(() => {
    if (BillSeriesHideShow.BillNoVal.length <= 0) {
      setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
    }
  }, [BillSeriesHideShow.BillNoVal])

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    if (paraArr.length == 1) {
      paraArr[1] = paraArr[0];
    }
    return paraArr;
  };

  const handleNullT02Obj = () => {
    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    setBatchIssueObj(null)
    setSelectedProductObj({})
  };

  const DeleteRow = (row) => {

    console.log("DeleteRow", row);
    if (window.confirm('Confirm to Delete ?')) {
      let newArray = T02Data?.filter((d, i) => i != row?.index)
      setT02Data(newArray)
    }
  }

  // Amount Calculation
  useEffect(() => {
    if (T02Obj?.FIELD33 !== null && T02Obj?.FIELD07 !== null) {
      setT02Obj({ ...T02Obj, FIELD08: T02Obj?.FIELD33 * T02Obj?.FIELD07 });
    }
  }, [T02Obj?.FIELD33, T02Obj?.FIELD07]);

  // Quantity Calculation
  useEffect(() => {
    if (T02Obj?.FIELD21 !== null && T02Obj?.FIELD22 !== null) {
      setT02Obj({ ...T02Obj, FIELD33: T02Obj?.FIELD21 * T02Obj?.FIELD22 });
    }
  }, [T02Obj?.FIELD21, T02Obj?.FIELD22]);

  useEffect(() => {
    if (T02Obj?.FIELD33) {
      setT02Obj({ ...T02Obj, FIELD21: parseInt(T02Obj?.FIELD33) / parseInt(T02Obj?.FIELD22) })
    }
  }, [T02Obj?.FIELD33])

  const NextIndexFun = (index) => {
    const nextInd = index + 1;
    console.log("nextInd=>", nextInd)
    const nextInput = document.getElementById(`T02Ent_${nextInd}`)
    console.log("nextInput=>", nextInput)

    if (nextInput) {
      nextInput.focus()
    }
  }

  const columnHelper = createColumnHelper();

  const rowSum = T02Data.reduce((a, b) => {
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD08 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD08 || 0);
    }
    return a;
  }, 0)

  let QtySum = T02Data.reduce((a, b) => {
    // (a = a + b?.FIELD33)
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD33 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD33 || 0);
    }
    return a;
  }, 0);
  let Qty2Sum = T02Data.reduce((a, b) => {
    // (a = a + b?.FIELD21)
    if (b?.FIELD05 === "C") {
      a = a + (b?.FIELD21 || 0);
    } else if (b?.FIELD05 === "D") {
      a = a - (b?.FIELD21 || 0);
    }
    return a;
  }, 0);

  const Summary = (obj, i, tag) => {
    // let stype = "sum";
    var value = "";

    switch (obj?.S53F03) {
      case "Qty":
        value = QtySum;
        break;
      case "F. Qty":
        value = T02Data?.reduce((a, b) => (a = a + b?.FIELD81), 0);
        break;
      case "Amount":
        value = rowSum;
        break;
      case "Qty-2":
        value = Qty2Sum;
        break;
      default:
        value = null;
        break;
    }
    return value;
  };

  const renderInputBox = (uID, v, c, cellid, index) => {
    // console.log('uID=>', uID)
    // console.log('v=>', v)
    // console.log('c=>', c)
    if (c?.S53F07 == 0) {
      return <Button
        id={`T02Ent_${index}`}
        variant="default"
        onFocus={() => {
          document.activeElement.blur()
          console.log("Test=>", `T02Ent_${index}`)
          HiddenButtonField(uID, v, c, cellid, index)
        }}
        onClick={() => HiddenButtonField(uID, v, c, cellid, index)}
        style={{ padding: "0px", margin: "0px" }}
      />
    } else {
      let setInput;
      switch (c?.S53F14) {
        case "PLPOPUP":
          if (c.S53F02 == "E000T02F03") { // Product Name
            setInput = <PLDataGrid
              ref={pr}
              id={`T02Ent_${index}`}
              width={'500px'}
              // autoFocus={true}
              value={{ value: T02Obj?.[c?.S53F04], label: T02Obj?.F03NM == "" ? "Select" : T02Obj?.F03NM }}
              p1={seperate(c.S53F15)[1]}
              cWhere={`M21.FIELD11 LIKE '%${T02Obj?.GRPCD ?? ""}%' AND M21.FIELD12 LIKE '%${T02Obj?.CATCD ?? ""}%'`}
              // TmData={tmList["P_M21"]?.cSTID}
              // data={accList["P_M21"]}
              setEdit={(e) => {
                console.log("PLDataGrid==>", e);
                Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01, F03NM: e?.FIELD02, FIELD22: e?.FIELD28 == 0 ? 1 : e?.FIELD28 }))
                  .then(() => setSelectedProductObj(e))
                  .then(() => setFIELD01value(e.FIELD01))
                  .then(() => {
                    setTimeout(() => {
                      NextIndexFun(index)
                    }, 500)
                  })
              }}
            />
          } else if (c.S53F02 === "E00T02F20") { // Location
            setInput = (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                value={{ value: T02Obj[c?.S53F04], label: T02Obj?.F20NM == "" ? "Select" : T02Obj?.F20NM }}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log('DataGrid object=>>', e)
                  Promise.resolve(setLocationObj(e))
                    .then(() => {
                      setT02Obj({
                        ...T02Obj,
                        FEILD20: e?.FIELD01,
                        F20NM: e?.FIELD02
                      })
                    })
                    .then(() => {
                      setTimeout(() => {
                        if (dtUFT11 && dtUFT11.length > 0 && selectedProductObj?.FIELD51 === "N") {
                          NextIndexFun(index)
                        } else {
                          NextIndexFun(index + 1)
                        }
                      }, 500);
                    })
                }}
              />
            );
          } else {
            setInput = (
              <PLDataGrid
                id={`T02Ent_${index}`}
                width={"500px"}
                value={T02Obj[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                setEdit={(e) => {
                  // console.log('DataGrid object=>>', e)
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 }))
                    .then(() => setPurchasePartyObj(e))
                }}
              />
            );
          }
          break;
        case "COMBOBOX":
          if (c.S53F02 == "E00T02RORI") { // Receipt/Issue
            setInput = (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                // autoFocus={true}
                value={T02Obj[c?.S53F04]}
                copno={seperate(c.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  console.log('reciept/issue value', e)
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value }))
                    // .then(() => )
                    .then(() => setRecieptIssueVal(e))
                    .then(() => {
                      setTimeout(() => {
                        if (oYear?.OSC?._LOTNO == "Y") {
                          setTimeout(() => {
                            NextIndexFun(index)
                          }, 500)
                        } else {
                          if (selectedProductObj?.FIELD51 !== "N") {
                            console.log('if')
                            setTimeout(() => {
                              NextIndexFun(index + 1)
                            }, 500)
                          } else {
                            console.log('else')
                            NextIndexFun(index + 2)
                          }
                        }
                      }, 500)

                    })
                }}
              />
            );
          } else {
            setInput = (
              <PLComboBox
                cmbid={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                copno={seperate(c.S53F15)[1]}
                dispexpr={"DisplayMember"}
                valexpr={"ValueMember"}
                setEdit={(e) => {
                  console.log('combo val', e)
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e.value })).then(() => {
                    setTimeout(() => {
                      NextIndexFun(index)
                    }, 500)
                  })
                }}
              />
            );
          }
          break;
        case "TEXTBOX":
          if (c?.S53F02 == "E00T02F41") { // Batch Receipt
            setInput = (
              <>
                <PLTextBox
                  disabled={selectedProductObj?.FIELD51 === "N" || T02Obj?.FIELD05 == "D" ? true : false}
                  id={`T02Ent_${index}`}
                  value={T02Obj[c?.S53F04]}
                  onKeyDown={(e) => {
                    if (e.key == "Tab") {
                      // setTimeout(() => {
                      NextIndexFun(index)
                      // }, 500)
                    }
                  }}
                  setEdit={(e) => {
                    setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                  }}
                />
              </>
            );
          } else if (c?.S53F02 == "EJ0000000028") { // Lot No 
            setInput = (
              <PLTextBox
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 !== "N" && T02Obj?.FIELD05 == "D") {
                      console.log('if')
                      NextIndexFun(index)
                    } else if (T02UF?.length == 0) {
                      console.log('else if')
                      NextIndexFun(index + 3)
                    } else {
                      console.log('else')
                      NextIndexFun(index + 2)
                    }
                  }
                }}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                }}
              />
            );
          } else {
            setInput = (
              <PLTextBox
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // setTimeout(() => {
                    NextIndexFun(index)
                    // }, 500)
                  }
                }}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e.target.value });
                }}
              />
            );
          }
          break;
        case "NUMBOX":
          if (c?.S53F02 == "E00T02F07") { // Rate
            setInput = (
              <PLNumberBox
                decimalSeparator={true}
                precision={3}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // setTimeout(() => {
                    NextIndexFun(index - 1)
                    // }, 500)
                  }
                }}
              />
            );
          } else if (c.S53F02 == "E00T02F21") { // Qty - 2
            setInput = (
              <PLNumberBox
                disabled={selectedProductObj?.FIELD59 == "N" ? true : false} // Qty - 2
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // setTimeout(() => {
                    NextIndexFun(index - 1)
                    // }, 500)
                  }
                }}
              />
            );
          } else if (c?.S53F02 == "E00T02F22") { // conversion
            setInput = (
              <PLNumberBox
                disabled={selectedProductObj?.FIELD59 == "N" ? true : false}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    // setTimeout(() => {
                    NextIndexFun(index - 1)
                    // }, 500)
                  }
                }}
              />
            );
          } else if (c.S53F02 == "E00T02F06") { // Qty
            setInput = (
              <PLNumberBox
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab") {
                    if (selectedProductObj?.FIELD51 === "N" || T02Obj?.FIELD05 == "D") {
                      console.log('if test')
                      // setTimeout(() => {
                      NextIndexFun(index + 1)
                      // }, 500)
                    } else if (dtUFT11?.length == 0) {
                      // setTimeout(() => {
                      NextIndexFun(index + 2)
                      // }, 500)
                      console.log('test')
                    }
                  }
                }}
              />
            );
          } else {
            setInput = (
              <PLNumberBox
                decimalSeparator={c.S53F02 == "E00T02F08" ? true : false}
                precision={c.S53F02 == "E00T02F08" ? 3 : 0}
                id={`T02Ent_${index}`}
                value={T02Obj[c?.S53F04]}
                setEdit={(e) => {
                  setT02Obj({ ...T02Obj, [c?.S53F04]: e });
                }}
                onKeyDown={(e) => {
                  if (e.key == "Tab" || e.key == "Enter") {
                    // e.stopPropagation();
                    if (c.S53F02 == "E00T02F08") { // Amount
                      if (OSV?._PRCHPARTY == "N") {
                        if (tableEditingRow == null) {
                          console.log("Add Product Call")
                          if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                          } else {
                            Promise.resolve().then(() => handleCreateRow())
                              // .then(() => table.setEditingRow(null))
                              .then(() => {
                                setTimeout(() => {
                                  if (obj.p0 == "E") {
                                    setT02Obj(T02?.[T02?.length - 1]);
                                  } else {
                                    setT02Obj(T02?.[0])
                                  }
                                  table.setCreatingRow(true)
                                }, 500)
                              }
                              )
                          }
                        } else {
                          console.log("Edit Product Call")
                          if (T02Obj?.FIELD03 == null || T02Obj?.FIELD03 == "") {
                            GlobalClass.Notify("warning", "Warning", "Some Field Require to Enter")
                          } else {
                            Promise.resolve()
                              .then(() => handleEditSave())
                              .then(() =>
                                setTimeout(() => {
                                  if (obj.p0 == "E") {
                                    setT02Obj(T02?.[T02?.length - 1]);
                                  } else {
                                    setT02Obj(T02?.[0])
                                  }
                                  table.setCreatingRow(true)
                                }, 500)
                              )
                          }
                        }
                      } else {
                        NextIndexFun(index)
                      }
                    }
                  }
                }}
              />
            );
          }
          break;
        default:
          setInput = null;
          break;
      }
      return setInput;
    }
  };

  const HiddenButtonField = (uID, v, c, cellid, index) => {
    switch (c.S53F02) {
      case "E00ORDDUM": // Dummy Order
        console.log('Dummy Order')
        setDrawerObj({
          ...dra,
          title: <Text fw={700}>{c.S53F03}</Text>,
          body: <>
            <Text>{c.S53F03}</Text>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
              <Button
                onClick={() => {
                  // setDrawerObj({ ...DrawerObj, open: false })
                  Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                    .then(() => {
                      if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" && T02Obj?.FIELD05 == "D") {
                        NextIndexFun(index)
                      } else {
                        NextIndexFun(index + 1)
                      }
                    }
                    )
                }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                      .then(() => {
                        if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 != "N" && T02Obj?.FIELD05 == "D") {
                          NextIndexFun(index)
                        } else {
                          NextIndexFun(index + 1)
                        }
                      }
                      )
                  }
                }}
              >Ok</Button>
            </div>
          </>,
          open: true,
          size: "sm",
          position: "right"
        })
        break;
      case "E00T02F11":  // Batch Issue
        console.log('Batch Issue')
        if (T02Obj?.FIELD03 != "" && selectedProductObj?.FIELD51 !== "N" && T02Obj?.FIELD05 == "D") {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              // setDrawerObj(dra);
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              {/* <Grid gutter={4}>
                <Grid.Col span={12}> */}
              <PLDataGrid
                autoFocus={true}
                // setBlur={()=>T02UF?.length > 0 ? TableUserFieldFun() : null}
                id={`T02Ent_${index}`}
                cWhere={`T11.FIELD08 LIKE '%${FIELD01Value ?? ""}%'`}
                width={"500px"}
                value={T02Obj?.[c?.S53F04]}
                p1={seperate(c.S53F15)[1]}
                // p1={'P_M21'}
                setEdit={(e) => {
                  console.log("Batch Issue Object=>", e);
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e?.FIELD01 }))
                    .then(() => setBatchIssueObj(e))
                }}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                      .then(() => {
                        NextIndexFun(index)
                      })
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Enter") {
                      Promise.resolve()
                        .then(() => setDrawerObj({ ...DrawerObj, open: false }))
                        .then(() => {
                          setTimeout(() => {
                            NextIndexFun(index)
                          }, 500)
                        })
                    }
                  }}
                >OK</Button>
              </div>
            </>,
            open: true,
            size: "40%",
            position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00USERFLD": // User Field
        console.log('user field')
        if (T02UF && T02UF.length > 0) {
          setDrawerObj({
            ...dra, title: <Text fw={700}>User Field</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <JBUserFieldEntry
                T02UF={T02UF}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve(setT02Obj(e)).then(() => {
                    if (selectedProductObj?.FIELD59 == "N") {
                      setTimeout(() => {
                        NextIndexFun(index + 2)
                      }, 500)
                    } else {
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    }
                  })
                  // console.log("FormUserField=>",e);
                }}
                DrawerObjSend={DrawerObj}
                setDrawerObjSend={(e) => {
                  setDrawerObj(e)
                }}
              />
            </>, open: true, size: "sm", position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00UFBAT": // Batch User Field
        console.log('batch user field')
        if (dtUFT11 && dtUFT11.length > 0 && selectedProductObj?.FIELD51 === "N") {
          setDrawerObj({
            ...dra, title: <Text fw={700}>Batch User FIeld</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <JBUserField
                dtUFT11={dtUFT11}
                T02ObjSend={T02Obj}
                setT02Objsend={(e) => {
                  Promise.resolve()
                    .then(() => setT02Obj(e))
                    .then(() => {
                      setTimeout(() => {
                        NextIndexFun(index)
                      }, 500)
                    })
                  // console.log("FormUserField=>",e);
                }}
                DrawerObjSend={DrawerObj}
                setDrawerObjSend={(e) => {
                  setDrawerObj(e)
                }}
              />
            </>, open: true, size: "sm", position: "right"
          })
        } else {
          NextIndexFun(index)
        }
        break;
      case "E00T02F23": // PriceList
        // if (PLDATA && PLDATA?.length > 0) {
        console.log('priceList')
        if (selectedProductObj?.FIELD53 == "N") {
          // setTimeout(() => {
          NextIndexFun(index)
          // }, 500)
        } else {
          setDrawerObj({
            ...dra,
            title: <Text fw={700}>{c.S53F03}</Text>,
            onClickCloseButton: () => {
              Promise.resolve(setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
            },
            body: <>
              <PLComboBox
                initiallyOpened={true}
                cmbid={`T02Ent_${index}`}
                data={typeof PLDATA == "string" ? [{}] : PLDATA}
                value={T02Obj?.[c?.S53F04]}
                dispexpr={"FIELD02"}
                valexpr={"FIELD01"}
                setEdit={(e) => {
                  // console.log("Pricelist OBJ=>", e);
                  Promise.resolve(setT02Obj({ ...T02Obj, [c?.S53F04]: e })).then(() => {
                    if (T41Obj.FIELD16 == "C") {
                      setPriceListParemeter({
                        ...priceListParemeter,
                        accountCode: T41Obj.FIELD31,
                        priceListCode: e,
                      });
                    } else {
                      setPriceListParemeter({
                        ...priceListParemeter,
                        accountCode: T41Obj.FIELD04,
                        priceListCode: e,
                      });
                    }
                  })
                }}
              />
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "5px 0px" }}>
                <Button
                  onClick={() => {
                    // .then(() => setDrawerObj({ ...DrawerObj, open: false })).then(() => NextIndexFun(index))
                    Promise.resolve(setDrawerObj({ ...DrawerObj, open: false }))
                      .then(() => {
                        if (selectedProductObj?.FIELD59 == "N" && T02UF?.length == 0) {
                          // setTimeout(() => {
                          NextIndexFun(index + 3)
                          // }, 500)
                        } else if (T02UF?.length == 0 && selectedProductObj?.FIELD59 !== "N") {
                          NextIndexFun(index + 1)
                        } else {
                          NextIndexFun(index)
                        }
                      })
                  }}
                  onKeyDown={(e) => {
                    // e.stopPropagation();
                    if (e.key == "Enter") {
                      Promise.resolve()
                        .then(() => setDrawerObj({ ...DrawerObj, open: false }))
                        .then(() => {
                          setTimeout(() => {
                            NextIndexFun(index)
                          }, 500)
                        })
                    }
                  }}
                >OK</Button>
              </div>
            </>, open: true, size: "sm", position: "right"
          })
        }
        // }
        break;
      default:
        break;
    }
  }

  // Columns
  useEffect(() => {
    if (T02ENT) {
      let cols = [];
      // cols.push(
      //   columnHelper.accessor('GRPCD', {
      //     header: "Product Group",
      //     id: "Product_Group",
      //     size: 150,
      //     Edit: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       return <PLDataGrid
      //         autoFocus={true}
      //         width={"500px"}
      //         TmData={tmList["T_M32G"]?.cSTID}
      //         // data={accList["T_M32G"]}
      //         value={{ value: T02Obj?.GRPCD, label: T02Obj?.GRPNM }}
      //         setEdit={(e) => {
      //           console.log("Product Group Obj=>", e);
      //           setGroupCatogaryProduct({ ...GroupCatogaryProduct, Group: e?.FIELD01 })
      //           setT02Obj({ ...T02Obj, GRPCD: e?.FIELD01, GRPNM: e?.FIELD02 })
      //         }}
      //       />
      //     },
      //     Cell: ({ cell, row }) => {
      //       let val = cell.getValue();
      //       return T02Data[row.id]?.GRPNM
      //     },
      //   })
      // )
      cols.push(
        columnHelper.accessor('CATCD', {
          header: "Product Category",
          id: "Product_Category",
          size: 150,
          Edit: ({ cell, row }) => {
            let val = cell.getValue();
            return <PLDataGrid
              // autoFocus={true}
              id={"ID_123"}
              width={"500px"}
              TmData={tmList["T_M32C"]?.cSTID}
              // data={accList["T_M32C"]}
              value={{ value: T02Obj?.CATCD, label: T02Obj?.CATNM }}
              setEdit={(e) => {
                console.log("Product Category Obj=>", e);
                setGroupCatogaryProduct({ ...GroupCatogaryProduct, Catogary: e?.FIELD01 })
                Promise.resolve(setT02Obj({ ...T02Obj, CATCD: e?.FIELD01, CATNM: e?.FIELD02 }))
                  .then(() => {
                    pr?.current?.handlePopoverToggle();
                  });
              }}
            />
          },
          Cell: ({ cell, row }) => {
            let val = cell.getValue();
            return T02Data[row.id]?.CATNM
          },
        })
      )
      T02ENT?.filter((v, i) => v?.S53F00 === "C").map((v, i) => {
        cols.push(
          columnHelper.accessor(v.S53F04, {
            header: v.S53F07 == 0 ? "" : v.S53F03,
            id: v.S53F03,
            size: v.S53F07 == 0 ? 0 : 150,
            // enableEditing: v.S53F03 == "Product Name" ? false : true,
            Edit: ({ cell, row }) => {
              let val = cell.getValue();
              return renderInputBox(v.S53UID, val, v, cell?.id, i);
            },
            Cell: ({ cell, row }) => {
              // console.log("cell.getValue()", cell.getValue())
              let val = cell.getValue();
              // console.log("T02Data[i]=>",T02Data[row.id]);
              if (v.S53F03 == "Product Name") {
                return T02Data[row.id]?.F03NM ?? T02Data[row.id]?.PrdNM
                // return "Test"
              } else if (v.S53F02 == "E00T02F20") {
                return T02Data[row.id]?.F20NM
              }
              return v.S53F07 == 0 ? "" : val
            },
            Footer: () => <div>{v.S53F02 == "E000T02F03" ? `Total Entry :${table?.getSelectedRowModel()?.flatRows.length > 0 ? (parseInt(table?.getSelectedRowModel()?.flatRows[0]?.index) + 1) + "/" : ""}${T02Data?.length}` : Summary(v, i, "c")}</div>,
          })
        );
      });
      return setColumns(cols);
    }
  }, [T02ENT, T02Obj, accList, tmList, T02, GroupCatogaryProduct, selectedProductObj, T02Data]);

  // T41 UserField
  const UFT41HRenderField = (e) => {
    //S13F02 Save UserField
    if (e == "Header") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((h) => {
          // console.log("User Field=>", h?.S13F14, h?.S13F07, h?.S13F02);
          if (h?.S13F87 == "HEADER") {
            //b?.S13F14
            // console.log("UFT41HRenderHeader==>",h);
            return (
              <>
                {h?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLNumberBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDataGrid
                        width={"500px"}
                        name={h.S13F02}
                        value={T41Obj?.[h?.S13F02]}
                        p0={seperate(h.S13F09)[0]}
                        p1={seperate(h.S13F09)[1]}
                        setEdit={(e) => {
                          // console.log('header UserField=>>', e);
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e?.FIELD01 });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLComboBox
                        value={T41Obj?.[h?.S13F02]}
                        copno={seperate(h.S13F09)[1]}
                        dispexpr={"DisplayMember"}
                        valexpr={"ValueMember"}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        value={new Date(T41Obj?.[h?.S13F02]).getTime()}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLDateBox
                        value={new Date(T41Obj?.[h?.S13F02]).getTime()}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "N" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "MEMOBOX" && h.S13F21 === "H" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "I" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {h.S13F14 === "FILEUPLOAD" && h.S13F21 === "A" && (
                  <Grid.Col
                    span={4}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={3}>
                      <Text style={{ fontSize: 12 }}>{h?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <PLTextBox
                        value={T41Obj?.[h?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [h?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    } else if (e == "Footer") {
      return (
        UFT41H &&
        UFT41H?.length > 0 &&
        UFT41H.map((f) => {
          if (f?.S13F87 == "FOOTER") {
            //b?.S13F14
            return (
              <>
                {f?.S13F14 == "TEXTBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "NUMBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLNumberBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "PLPOPUP" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDataGrid
                        width={"500px"}
                        value={T41Obj?.[f?.S13F02]}
                        // p0={seprate(f.S13F09)[0]}
                        p1={seperate(f.S13F09)[1]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e?.FIELD01 });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "COMBOBOX" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLComboBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "DATEPICKER" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        value={new Date(T41Obj?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f?.S13F14 == "TIMEPICKER" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLDateBox
                        value={new Date(T41Obj?.[f?.S13F02]).getTime()}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "N" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "MEMOBOX" && f.S13F21 === "H" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "I" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}

                {f.S13F14 === "FILEUPLOAD" && f.S13F21 === "A" && (
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>{f?.S13F07}</Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                      <PLTextBox
                        value={T41Obj?.[f?.S13F02]}
                        setEdit={(e) => {
                          // console.log(e)
                          setT41Obj({ ...T41Obj, [f?.S13F02]: e.target.value });
                        }}
                      />
                    </Grid.Col>
                  </Grid.Col>
                )}
              </>
            );
          }
        })
      );
    }
  };
  // console.log('t41=>', T41Obj)

  // Final Submit
  const handleSubmit = () => {
    // console.log('T41Obj==>', T41Obj)
    // console.log('T02Data==>', T02Data)
    if (obj.p0 == "E" && ((JSON.stringify(T02) === JSON.stringify(T02Data)) && (JSON.stringify(T41) === JSON.stringify(T41Obj)))) {
      Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
      return;
    }
    dispatch(DataSaveLoading(true));
    GlobalClass.Notify(
      "info",
      obj.p0 == "A" ? "Adding" : "Editing",
      "Please wait while we process your data"
    );
    var JData = { T41: { ...T41Obj, FIELD07: rowSum, FIELD06: rowSum }, T02: T02Data };

    var data = {
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0,
      cCode: obj.p0 == "E" ? obj.id : "JAJA",
      cSData: JSON.stringify(JData),
    };
    let param = JSON.stringify({
      CorpID: gensetting.getcorpid(),
      cUser: gensetting.getcuser(),
      cPass: gensetting.getcpass(),
      CmpNo: gensetting.getcmpno(),
      cAction: obj?.p0,
      cCode: obj.p0 == "E" ? obj.id : "JAJA",
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      GlobalClass.ApiUrl + GlobalClass.PostVouEnt + "?pa=" + param,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log('api data', data);
        // console.log('api data status', data?.status);
        if (data.status == "SUCCESS") {
          let PaginationObj =
            Store.getState().DataSaveLoadingSlice.PaginationObj;
          Promise.resolve(dispatch(DataSaveLoading(false)))
            .then(() => {
              if(obj.p0 == "A") {
                Promise.resolve(dispatch(TransactionApi(obj)))
                .then(() => {
                  Promise.resolve(dispatch(GetAccountList({
                    "id": "T_M32G",
                    "name": "",
                    "type": "A",
                    "p0": "T_M32G",
                    "p1": "T_M32G",
                    "p2": "",
                    "p3": ""
                  }))).then(() => dispatch(GetTMFormat({
                    "id": "T_M32G",
                    "name": "",
                    "type": "A",
                    "p0": "T_M32G",
                    "p1": "T_M32G",
                    "p2": "",
                    "p3": ""
                  }))).then(() => dispatch(GetAccountList({
                    "id": "T_M32C",
                    "name": "",
                    "type": "A",
                    "p0": "T_M32C",
                    "p1": "T_M32C",
                    "p2": "",
                    "p3": ""
                  }))).then(() => dispatch(GetTMFormat({
                    "id": "T_M32C",
                    "name": "",
                    "type": "A",
                    "p0": "T_M32C",
                    "p1": "T_M32C",
                    "p2": "",
                    "p3": ""
                  })))
                })
              } else {
                dispatch(ModalDelete(props.index))
              }
            })
            .then(() =>
              dispatch(
                GetAccountList({
                  id: "03960002",
                  name: "Jobwork Opening",
                  p0: "MT",
                  p1: "TXNVOULISTJA",
                  p2: "",
                  p3: "",
                  type: "A",
                  pagination: PaginationObj["03960002"],
                })
              )
            )
            .then(() =>
              GlobalClass.Notify(
                "success",
                obj.p0 == "A" ? "Added" : "Edited",
                obj.p0 == "A" ? "Added successfully" : "Edited successfully"
              )
            );
        } else {
          Promise.resolve(dispatch(DataSaveLoading(false))).then(() =>
            console.log('test'),
            GlobalClass.Notify("error", data?.status, data?.message)
          );
        }
      })
      .catch((e) => {
        Promise.resolve(
          GlobalClass.Notify("error", "Error", `${e.message}`)
        ).then(() => dispatch(DataSaveLoading(false)));
      });
    // console.log('data', data)
  };

  // Update Row
  const handleEditSave = () => {
    console.log('edit T02Data', T02Data)
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
    dispatch(BatchLogAddRemoveFun({ PageCode: "JAJA", T02Data: T02Data, Row: T02Obj, OBJ: obj, T41Obj: T41Obj }));
    let EditT02Data = [...T02Data];
    EditT02Data[tableEditingRow.index] = T02Obj;
    setT02Data(EditT02Data);

    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }

    table.setEditingRow(null); //exit editing mode
    setTableEditingRow(null)
    setSelectedProductObj({})
    setLocationObj(null)
    setBatchIssueObj(null)
  };

  //Add New Row
  const handleCreateRow = () => {
    console.log("handleCreateUser==>", T02Obj);
    setGroupCatogaryProduct({
      "Group": "",
      "Catogary": "",
      "Product": ""
    })
    dispatch(BatchLogAddRemoveFun({ PageCode: "JAJA", T02Data: T02Data, Row: T02Obj, OBJ: obj, T41Obj: T41Obj }));

    setT02Data([...T02Data, T02Obj]);
    // console.log('T02Data handleCreateUser==>', T02Data)

    if (obj.p0 == "E") {
      setT02Obj(T02?.[T02?.length - 1]);
    } else {
      setT02Obj(T02?.[0])
    }
    table.setCreatingRow(null)
    setSelectedProductObj({})
    setLocationObj(null)
    setBatchIssueObj(null)
  };

  // T02 UserField Form
  const UserFieldForm = (index) => {
    // User Field
    return setDrawerObj({
      ...dra, title: <Text fw={700}>User Field</Text>, body: <>
        <JBUserField
          dtUFT11={dtUFT11}
          T02ObjSend={T02Obj}
          setT02Objsend={(e) => {
            Promise.resolve(setT02Obj(e)).then(() => {
              const nextInd = index + 1;
              console.log("nextInd=>", nextInd)
              const nextInput = document.getElementById(`T02Ent_${nextInd}`)
              console.log("nextInput=>", nextInput)

              if (nextInput) {
                nextInput.focus()
              }
            })
            // console.log("FormUserField=>",e);
          }}
          DrawerObjSend={DrawerObj}
          setDrawerObjSend={(e) => {
            setDrawerObj(e)
          }}
        />
      </>, open: true, size: "sm", position: "right"
    })
  };

  const handleRowKeyDown = (e, row) => {
    if (e?.key == "Enter") {
      if (row?.id == 'mrt-row-create') {
        return
      } else {
        table.setEditingRow(row)
        setTableEditingRow(row)
        setT02Obj(row?.original)
      }
    }
  }

  const handleRowCancel = (e, row) => {
    if (e.key == "Escape") {
      Promise.resolve()
        .then(() => handleNullT02Obj())
        .then(() => {
          setTimeout(() => {
            if (tableEditingRow == null) {
              table.setCreatingRow()
            } else {
              table.setEditingRow(null)
            }
          }, 500)
        })
    }
  }

  const table = useMantineReactTable({
    columns,
    data: T02Data,
    enableTopToolbar: true,
    enableHiding: false,
    enableEditing: true,
    mantineTableContainerProps: {
      sx: { maxHeight: "250px", minHeight: "300px" },
    },
    editDisplayMode: "row",
    createDisplayMode: "row",
    enableMultiRowSelection: false,
    enableSelectAll: false,
    enableColumnActions: false,
    enableSorting: false,
    enableFilters: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    positionToolbarAlertBanner: false,
    onCreatingRowSave: () => {
      if (
        // (T02Obj?.FIELD05 == "C" && T02Obj?.FIELD41 == "") || 
        T02Obj?.FIELD03 == "") {
        return GlobalClass.Notify(
          "warning",
          "Validation error",
          "Please fill out all the required fields"
        );
      } else {
        handleCreateRow();
      }
    },
    onEditingRowSave: handleEditSave,
    onCreatingRowCancel: handleNullT02Obj,
    onEditingRowCancel: handleNullT02Obj,
    mantineTableBodyRowProps: ({ row }) => ({
      tabIndex: 0,
      onKeyUp: (e) => handleRowKeyDown(e, row),
      // onKeyDown: (e) => handleRowCancel(e, row),
      onClick: () => {
        setRowSelection((prev) => {
          if (!prev[row.id]) {
            return { [row.id]: true };
          }
          return prev;
        });
      },
      selected: rowSelection[row.id],
      sx: { cursor: "pointer" },
    }),
    state: {
      density: "0px",
      rowSelection
    },
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon
            onClick={() => {
              table.setEditingRow(row);
              // console.log('table.setEditingRow=>', row);
              setT02Obj(row.original);
              setTableEditingRow(row)
            }}
          >
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => DeleteRow(row)}>
            <IconTrash size={'20px'} />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table }) => (
      <Button
        onFocus={() => {
          if (spoilerControlRef?.current?.innerText == "Hide") {
            spoilerControlRef.current.click()
          }
          Promise.resolve(table.setCreatingRow(true))
        }}
        onClick={() => {
          table.setCreatingRow(true);
        }}
        size="xs"
      >
        Add New
      </Button>
    ),
  });

  let ReceiptArray = T02Data.filter((d, i) => d.FIELD05 == "C");
  let IssueArray = T02Data.filter((d, i) => d.FIELD05 == "D");

  // reciept amount sum && issue amount sum
  useEffect(() => {
    if (ReceiptArray) {
      let recieptAmountSum = ReceiptArray.reduce(
        (a, b) => (a = a + b?.FIELD08),
        0
      );
      setRecieptSum(recieptAmountSum);
    }
  }, [ReceiptArray]);

  useEffect(() => {
    if (IssueArray) {
      let IssueAmountSum = IssueArray.reduce((a, b) => (a = a + b?.FIELD08), 0);
      setIssueSum(IssueAmountSum);
    }
  }, [IssueArray]);

  // reciept Qty sum && issue Qty sum
  let ReceiptQtySum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD33), 0);
  let IssueQtySum = IssueArray.reduce((a, b) => (a = a + b?.FIELD33), 0);

  // reciept Qty - 2 sum && issue Qty - 2 sum
  let RecieptQty2Sum = ReceiptArray.reduce((a, b) => (a = a + b?.FIELD21), 0);
  let IssueQty2Sum = IssueArray.reduce((a, b) => (a = a + b?.FIELD21), 0);

  const convertPositiveValue = (a) => {
    if (a < 0) {
      a = a * -1;
    }
    return a;
  };

  let totalSum = recieptSum - issueSum;
  // console.log('totalSum=>>', totalSum)

  totalSum = convertPositiveValue(totalSum);

  const spoilerControlRef = useRef();

  useEffect(() => {

    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener('keypress', (e) => GlobalClass.formNextInput(e, formRef));
      }
    };
  }, [formRef]);

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key == "Escape") {
        Promise.resolve()
          .then(() => handleNullT02Obj())
          .then(() => {
            setTimeout(() => {
              if (tableEditingRow == null) {
                table.setCreatingRow(false)
              } else {
                table.setEditingRow(null)
              }
            }, 500)
          })
      }
    })
  }, [tableEditingRow])

  return (
    <>
      {
        SaveLoading == true ?
          GlobalClass.RenderLoader("dots")
          :
          <>
            {
              isLoading == true
                ? GlobalClass.RenderLoader("dots")
                : GetVouEntJobWorkFormData &&
                <div ref={formRef}>
                  <Modal
                    opened={DrawerObj?.open}
                    // onClose={close}
                    withCloseButton={() => {

                    }}
                    fullScreen={false}
                    closeButtonProps={{
                      onClick: () => {
                        if (typeof DrawerObj?.onClickCloseButton == "function") {
                          DrawerObj?.onClickCloseButton();
                        }
                        else {
                          setDrawerObj(dra);
                        }
                      },
                    }}
                    title={DrawerObj.title}
                    size={DrawerObj.size}
                    // centered={.Centered}
                    closeOnClickOutside={true}
                    position={DrawerObj?.position ?? 'bottom'}
                    onClose={typeof DrawerObj?.onclose == "function" ? DrawerObj?.onclose : () => { setDrawerObj(dra); }}
                  >
                    {DrawerObj.body}
                  </Modal>
                  <Paper shadow="md" radius="md" p="xs" withBorder>
                    <Grid gutter={4}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                    >
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={1}>
                          <Text size={12}>Jobwork Process</Text>
                        </Grid.Col>

                        <Grid.Col span={4}>
                          <PLDataGrid
                            // autoFocus={true}
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            value={{ value: T41Obj?.FIELD34, label: T41Obj?.FIELD34NM }}
                            TmData={P_M31J}
                            data={P_M31JDT?.jData}
                            setEdit={(e) => {
                              // console.log('voucher datagrid value=>>', e)
                              setT41Obj({ ...T41Obj, FIELD34: e?.FIELD01, FIELD34NM: e?.FIELD02 });
                            }}
                          />
                        </Grid.Col>

                        <Grid.Col span={1}>
                          <Text size={12}>Voucher Date</Text>
                        </Grid.Col>

                        <Grid.Col span={2}>
                          <PLDateBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                              let year = e.toLocaleString("en-IN", {
                                year: "numeric",
                              });
                              let month = e.toLocaleString("en-IN", {
                                month: "2-digit",
                              });
                              let day = e.toLocaleString("en-IN", {
                                day: "2-digit",
                              });

                              let Date = year + month + day;

                              if (Date?.length == 8) {
                                setT41Obj({ ...T41Obj, FIELD02: Date });
                              }
                              // setSelectedDay(e);
                            }}
                            defaultval={T41Obj?.FIELD02}
                          // value = {new Date(T41Obj?.FIELD02).getTime()}
                          />
                        </Grid.Col>

                        <Grid.Col span={1}>
                          <Text size={12}>
                            {selectedDay.toString().slice(0, 3)}
                          </Text>
                        </Grid.Col>

                        <Grid.Col span={1}>
                          <Text size={12}>Doc. No</Text>
                        </Grid.Col>

                        <Grid.Col span={2}>
                          <PLTextBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            setEdit={(e) => {
                              setT41Obj({ ...T41Obj, FIELD10: e.target.value });
                            }}
                            value={T41Obj?.FIELD10}
                            disabled={OSV?._DOCDATE == "Y" ? false : true}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid>

                    <Grid gutter={4}
                      onKeyDown={(e) => {
                        GlobalClass.formNextInput(e, formRef);
                      }}
                    >
                      <Grid.Col
                        span={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={1}></Grid.Col>
                        <Grid.Col span={4}></Grid.Col>

                        <Grid.Col span={1}>
                          <Text size={12}>Voucher No.</Text>
                        </Grid.Col>
                        <Grid.Col span={2}>
                          {
                            obj?.p0 == "E" ?
                              <Grid.Col span={12}>
                                <PLTextBox
                                  onKeyDown={(e) => {
                                    GlobalClass.formNextInput(e, formRef);
                                  }}
                                  value={T41Obj?.FIELD12}
                                  setEdit={(e) => {
                                    console.log("setEdit=>", e);
                                    setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                  }}
                                />
                              </Grid.Col> :
                              <Grid.Col span={12}>
                                {
                                  T92 ? <>
                                    {
                                      BillSeriesHideShow?.DataGridInput == true && <>
                                        {
                                          !T41Obj?.T92F00 ?
                                            <PLDataGrid
                                              onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                              }}
                                              width={"400px"}
                                              position={"unset"}
                                              valexpr={"FIELD00"}
                                              // width={350} 
                                              value={BillSeriesHideShow?.BillNoVal}
                                              TmData={T92}
                                              // data={T92DT}
                                              setEdit={(e) => {
                                                console.log("Bill No.=>", e)
                                                // Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD01 })).then(()=>{
                                                //   setBillSeriesHideShow({...BillSeriesHideShow,DataGridInput:false,TextInput:true,BillNoVal:e.FIELD01})
                                                // })
                                                //"FIELD00"
                                                Promise.resolve(setT41Obj({ ...T41Obj, T92F00: e.FIELD00, FIELD12: "" })).then(() => {
                                                  if (e) {
                                                    var letr = e.FIELD02
                                                    console.log("num=>", num);
                                                    var num = parseInt(e.FIELD03) + 1
                                                    setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: false, TextInput: true, BillNoVal: letr + num })
                                                  }
                                                })

                                              }}
                                            /> : <PLTextBox
                                              onKeyDown={(e) => {
                                                GlobalClass.formNextInput(e, formRef);
                                              }}
                                              value={T41Obj?.FIELD12}
                                              // value={T41Obj?.T92F00}
                                              setEdit={(e) => {
                                                console.log("setEdit=>", e);
                                                // setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                                setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                              }}
                                            />
                                        }
                                      </>
                                    }
                                    {BillSeriesHideShow?.TextInput == true && <PLTextBox
                                      // setText={() => {
                                      //   setBillSeriesHideShow({ ...BillSeriesHideShow, DataGridInput: true, TextInput: false })
                                      // }}
                                      onKeyDown={(e) => {
                                        GlobalClass.formNextInput(e, formRef);
                                      }}
                                      value={BillSeriesHideShow?.BillNoVal}
                                      // value={T41Obj?.T92F00}
                                      setEdit={(e) => {
                                        console.log("setEdit=>", e);
                                        setBillSeriesHideShow({ ...BillSeriesHideShow, BillNoVal: e.target.value })
                                        setT41Obj({ ...T41Obj, T92F00: "", FIELD12: e.target.value })
                                      }}
                                    />
                                    }
                                  </> :
                                    < PLTextBox
                                      onKeyDown={(e) => {
                                        GlobalClass.formNextInput(e, formRef);
                                      }}
                                      disabled={true}
                                      placeholder={'Select'}
                                    />
                                }
                              </Grid.Col>
                          }
                        </Grid.Col>

                        <Grid.Col span={1}></Grid.Col>

                        <Grid.Col span={1}>
                          <Text size={12}>Doc Date</Text>
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <PLDateBox
                            onKeyDown={(e) => {
                              GlobalClass.formNextInput(e, formRef);
                            }}
                            dispformat="DD/MM/YYYY"
                            // defaultval={T41Obj?.FIELD11}
                            setEdit={(e) => {
                              let year = e.toLocaleString("en-IN", {
                                year: "numeric",
                              });
                              let month = e.toLocaleString("en-IN", {
                                month: "2-digit",
                              });
                              let day = e.toLocaleString("en-IN", {
                                day: "2-digit",
                              });

                              let Date = year + month + day;
                              if (Date?.length == 8) {
                                setT41Obj({ ...T41Obj, FIELD11: Date });
                              }
                              setDateBoxValue({ ...DateBoxValue, DateOne: e });
                            }}
                            // value={new Date(T41Obj?.FIELD02).getTime()}
                            // defaultval={T41Obj?.FIELD11}
                            // value={DateBoxValue.DateOne}
                            disabled={OSV?._DOCDATE == "Y" ? false : true}
                          />
                        </Grid.Col>
                      </Grid.Col>
                    </Grid>

                    {UFT41H?.length > 0 && userFieldHideShow == "Y" ? (
                      <>
                        <Divider
                          my="xs"
                          label="User Fields"
                          labelPosition="center"
                          labelProps={{ component: "strong" }}
                        />
                        {/* Header User Field  */}
                        <Spoiler
                          maxHeight={80}
                          showLabel="Show more"
                          hideLabel="Hide"
                          controlRef={spoilerControlRef}
                        >
                          <Grid gutter={4} mb={5}>
                            {UFT41HRenderField("Header")}
                          </Grid>
                        </Spoiler>
                      </>
                    ) : (
                      ""
                    )}

                    <Grid gutter={4}>
                      <Grid.Col span={12} style={{ padding: 0 }}>
                        <MantineReactTable table={table} />
                      </Grid.Col>

                      <Grid.Col span={12} style={{ display: "flex", margin: '5px', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="light" compact style={{ margin: '5px' }}
                          onClick={() => {
                            if (table?.getSelectedRowModel()?.flatRows?.length > 0) {
                              setT02Data([...T02Data, table.getSelectedRowModel()?.flatRows[0]?.original])
                            }
                          }}
                        >
                          Copy
                        </Button>
                        <Button variant="light" compact>
                          Serial No.
                        </Button>
                      </Grid.Col>
                    </Grid>

                    {/* Footer User Field  */}
                    <Grid gutter={4}>
                      <Grid.Col span={6}>{userFieldHideShow == "Y" && UFT41HRenderField("Footer")}</Grid.Col>
                    </Grid>

                    <Grid gutter={4}>
                      <Grid.Col span={5}>
                        <Grid.Col span={12}>
                          <Text size={12}>Narration</Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <PLTextarea
                            // variant='filled'
                            minRows={2}
                            value={T41Obj?.FIELD15}
                            onChange={(e) => {
                              // console.log(e)
                              setT41Obj({ ...T41Obj, FIELD15: e.target.value });
                            }}
                            onKeyDown={(e) => {
                              // console.log("Narration=>", e);
                              if (e.ctrlKey && e.key === "F1") {
                                //console.log("Narration call");

                                setDrawerObj({
                                  ...dra, title: <Text fw={700}>Naration</Text>, body: <>
                                    <PLNarration
                                      NarrationArray={NarrationData}
                                      selectedNarrationArray={
                                        SelectedNarrationData
                                      }
                                      // NarrationVal={T41Obj}
                                      setNarrationArray={(e) => {
                                        // setT41Obj(e)
                                        console.log("setNarrationArray", e);
                                        setNarrationData(e.NarrationData);
                                        setSelectedNarrationData(
                                          e.selectedNarration
                                        );
                                      }}
                                      DrawerOBJ={DrawerObj}
                                      setDrawerOBJ={(e) => setDrawerObj(e)}
                                    />
                                  </>, open: true, size: "50%", position: "right"
                                })
                              }
                            }}
                          />
                        </Grid.Col>
                      </Grid.Col>
                      <Grid.Col span={6}>
                        <Grid.Col
                          span={12}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Text style={{ fontSize: 12, fontWeight: "bold" }}>
                            Total Amount ₹
                            {" : " + totalSum == 0
                              ? 0
                              : " : " +
                              totalSum +
                              (issueSum > recieptSum
                                ? " DB"
                                : recieptSum > issueSum
                                  ? " CR"
                                  : "")}
                            {/* {rowSum} */}
                          </Text>
                        </Grid.Col>
                        <Grid.Col span={12}>
                          <Group
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <Button
                              style={{ height: "30px", width: "100px" }}
                              size="sm"
                              onClick={() => handleSubmit()}
                            // disabled={T02Data.length < 2 ? true : false}
                            >OK</Button>
                            <Button style={{ height: "30px", width: "100px" }} size="sm">Print</Button>
                          </Group>
                        </Grid.Col>
                      </Grid.Col>
                    </Grid>
                  </Paper>
                  {
                    lEditVou == false && GlobalClass.Alert('info', props.index)
                  }
                </div>
            }
          </>
      }
    </>
  );
};

export default JobWorkOpeningForm;
