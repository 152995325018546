import ECommerceOperatorForm from "./ECommerceOperatorForm";
import Store from "../../utils/store";
import { Text } from "@mantine/core";
import { ECommerceOperatorDeleteApi } from "../../utils/slices/ECommerceOperator";
import { ModalFunction } from "../../utils/slices/ModalSlice";
import GlobalClass from "../../utils/GlobalClass";

export function MSAED(data) {
   const OBJ = JSON.parse(data);

   if (OBJ.p0 === 'A') {
      Store.dispatch(
         ModalFunction({
             onclose: (e) => {},
             MTitle: <Text fw={700}>{OBJ?.text}</Text>,
             MAction: true,
             MSize: "sm",
             position:'right',
             MBody: (i) => <ECommerceOperatorForm OBJ={OBJ} index={i} />,
             MClose: true,
             Overlay: {
               backgroundOpacity: 0.8,
               blur: 5,
             },
         })
     )
      //=======================================================
      // modals.open({
      //    title: <Text style={{ fontWeight: 700 }}>{OBJ?.text}</Text>,
      //    size: '50%',
      //    children: (
      //       <>
      //          <ECommerceOperatorForm OBJ={OBJ} />
      //       </>
      //    )
      // })
   }

   else if (OBJ.G02F01 && OBJ.p0 === 'E') {
      Store.dispatch(
         ModalFunction({
            onclose: () => { },
            MTitle: <Text fw={700}>{OBJ?.text}</Text>,
            MAction: true,
            MSize: "sm",
            position:'right',
            MBody: (i) => (
               <ECommerceOperatorForm OBJ={OBJ} index={i} />
            ),
            MClose: true,
            Overlay: {
               backgroundOpacity: 0.8,
               blur: 5,
            }
         })
      )
      //============================================
      // modals.open({
      //    title: <Text style={{ fontWeight: 700 }}>{OBJ?.text}</Text>,
      //    size: '50%',
      //    children: (
      //       <>
      //          <ECommerceOperatorForm OBJ={OBJ} />
      //       </>
      //    ),
      // })
   } else if (OBJ.G02F01 && OBJ.p0 === 'D') {
      const confirm = window.confirm("Are you sure ?");
      if (confirm) {
         Store.dispatch(ECommerceOperatorDeleteApi(OBJ))
      }
   }


}