import { Button, Grid, Group, Text } from '@mantine/core'
import React, { useState } from 'react'
import PLNumberBox from '../../PlusComponent/PLNumberBox'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLComboBox from '../../PlusComponent/PLComboBox'

const styles = {
    FontStyle: {
        fontSize: "12px"
    },
    displayStyle: {
        display: "flex"
    }
}

const UFExtraDetails = (props) => {

    const { DrawerOBJ, setDrawerOBJ, valueSaveObj, onChangeFun } = props;

    const [valueSave, setValueSave] = useState(valueSaveObj)

    const ExtraDetailSubmit = () => {
        Promise.resolve(onChangeFun(valueSave)).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
    }

    return (<>
        <Grid gutter={4}>
            <Grid.Col span={12} style={styles?.displayStyle}>
                <Grid.Col span={6} style={styles?.displayStyle}>
                    <Grid.Col span={6}>
                        <Text style={styles?.FontStyle}>Prefix Length</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <PLNumberBox
                            value={valueSave?.M41PRELEN}
                            setEdit={(e) => {
                                setValueSave({ ...valueSave, M41PRELEN: e })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={6} style={styles?.displayStyle}>
                    <Grid.Col span={6}>
                        <Text style={styles?.FontStyle}>Suffix Length</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <PLNumberBox
                            value={valueSave?.M41SUELEN}
                            setEdit={(e) => {
                                setValueSave({ ...valueSave, M41SUELEN: e })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={styles?.displayStyle}>
                <Grid.Col span={6} style={styles?.displayStyle}>
                    <Grid.Col span={6}>
                        <Text style={styles?.FontStyle}>Padding Character</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <PLTextBox
                            nmaxlength={1}
                            value={valueSave?.M41PADCHR}
                            setEdit={(e) => {
                                setValueSave({ ...valueSave, M41PADCHR: e?.target?.value })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
                <Grid.Col span={6} style={styles?.displayStyle}>
                    <Grid.Col span={6}>
                        <Text style={styles?.FontStyle}>Length</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <PLNumberBox
                            disabled={true}
                            value={valueSave?.FIELD05}
                            setEdit={(e) => {
                                setValueSave({ ...valueSave, FIELD05: e })
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={styles?.displayStyle}>
                <Grid.Col span={3}>
                    <Text style={styles?.FontStyle}>Reset in new year</Text>
                </Grid.Col>
                <Grid.Col span={7}>
                    <PLComboBox
                        copno={1}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={valueSave?.M41RNEWYR}
                        setEdit={(e) => {
                            // console.log("Reset in new year=>",e);
                            setValueSave({ ...valueSave, M41RNEWYR: e?.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={styles?.displayStyle}>
                <Grid.Col span={3}>
                    <Text style={styles?.FontStyle}>Allow to Edit</Text>
                </Grid.Col>
                <Grid.Col span={7}>
                    <PLComboBox
                        copno={1}
                        dispexpr="DisplayMember"
                        valexpr="ValueMember"
                        value={valueSave?.M41EDTALW}
                        setEdit={(e) => {
                            // console.log("Allow to Edit=>",e);
                            setValueSave({ ...valueSave, M41EDTALW: e?.value })
                        }}
                    />
                </Grid.Col>
            </Grid.Col>

            <Grid.Col span={12} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {/* <Group spacing={"xs"} position="center"> */}
                <Button
                    size="sm"
                    id="okbutton"
                    onClick={() => ExtraDetailSubmit()}
                >
                    Ok
                </Button>
                {/* </Group> */}
            </Grid.Col>
        </Grid>
    </>)
}

export default UFExtraDetails