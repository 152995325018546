import { ScrollArea, Tabs } from "@mantine/core";
import {
  IconPhoto,
  IconMessageCircle,
  IconSettings,
  IconX,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabAdd, TabRemove, UpdateSelected } from "../utils/slices/TabList";
import DataGrid from "../components/DataGrid";
import MyComponent from "../MyComponent/MyComponent";
import { TDSMaster } from "../FunctionsCall/TDSMaster/TDSMaster";
import SalesSetup from "../FunctionsCall/SalesAndPurchaseSetup/SalesSetup";
import StockRate from "../FunctionsCall/StockRate/StockRatePage";
import { CashBank } from "../Transaction/QuickEntry/CashBank";
// import ItemWiseExpense from "../FunctionsCall/OtherInfo/ItemWiseExpense";
import ItemwiseExpenseList2 from "../FunctionsCall/ItemWiseExpense/ItemwiseExpenseList2";
// import OpeningStock from "../Transaction/StockEntry/OpeningStock";
import VoucherNumberGrid from "../SetupComponent/VoucherNumber/VoucherNumberGrid";
import NewDataGrid from "../components/NewDataGrid";
import ItemWiseExpenseTableOne from "../FunctionsCall/ItemWiseExpense/ItemWiseExpenseTableOne";
import SalesPurchaseSetup from "../FunctionsCall/SalesAndPurchaseSetup/SalesPurchaseSetup";
import SalesSetupNew from "../FunctionsCall/SalesAndPurchaseSetup/SalesSetupNew";
import { GetCmpSetUpObj } from "../utils/slices/DataSaveLoadingSlice";

export default function TabView(props) {
  const TabList = useSelector((state) => state.TabList.value);
  const selected = useSelector((state) => state.TabList.selected);
  const dispatch = useDispatch();
  // console.log(Object.keys(TabList) , "TabList")
  useEffect(() => {
    // console.log("tablog",TabList[selected]);
  }, []);

  const TabSwitch = (tab, item) => {
    // console.log(tab , "tab")
    switch (tab) {
      // case "01980001":
      //   return <DataGrid tag="PM" obj={item} />
      //   break;
      // case "03600342":
      //   return <StockJournal tag="M" obj={item}/>
      case "13800005":
        return <VoucherNumberGrid tag="M" obj={item} />;
        break;
      case "01380123":
        return <StockRate tag="M" obj={item} />;
        break;
      case "00630057": //VAT/TDS > Sales Setup
      case "00660057": //VAT/TDS > Purchase Setup
      case "00780072": //GST > Sales Setup
      case "00810072": //GST > Purchase Setup
      case "00840072": //GST > J/W In Setup
      case "00870072": //GST > J/W Out Setup
        return <SalesSetupNew tag="M" obj={item} />;
        // return <SalesPurchaseSetup tag="M" obj={item} />
        break;
      case "00690057":
        return <TDSMaster tag="M" obj={item} />;
        break;
      case "01410123":
        // return <ItemwiseExpenseList2 tag="M" obj={item} />;
        return <ItemWiseExpenseTableOne obj={item} />
        break;
      case "04500447":
      case "04560447":
        return <CashBank tag="M" obj={item} />
        break;
      // case "03450342":
      //   return <OpeningStock tag="M" obj={item} />;
      case "_MNU0114":
      case "_MNU0115":
        return <MyComponent obj={item?.p0} />
      default:
        return <DataGrid tag="M" obj={item} />//<NewDataGrid tag="M" obj={item} />//<DataGrid tag="M" obj={item} />;
        break;
    }
  };

  return (
    <Tabs
      value={selected}
      onTabChange={(value) => dispatch(UpdateSelected(value))}
      keepMounted={false}
      variant="outline"
      defaultValue="Dashboard"
    >
      <Tabs.List>
        <Tabs.Tab
          value="Dashboard"
          key={"dash"}
          icon={<IconPhoto size="0.8rem" />}
        >
          Dashboard
        </Tabs.Tab>
        
        {TabList &&
          Object.keys(TabList).map((i) => {
            // console.log(TabList[i] , "inmap")
            var item = TabList[i];
            return (
              <Tabs.Tab
                key={i}
                rightSection={
                  <IconX
                    onClick={() => {
                      dispatch(TabRemove(i));
                      if(item.id=="_MNU0114" || item.id == "_MNU0115"){
                        dispatch(GetCmpSetUpObj(null))
                      }
                    }}
                  />
                }
                value={i}

              >
                {item.name}
              </Tabs.Tab>
            );
          })}
      </Tabs.List>

      <Tabs.Panel key={"DashPanel"} value="Dashboard" pt="xs">
        Dashboard tab content<br/>
        
      </Tabs.Panel>
      {TabList &&
        Object.keys(TabList).map((i) => {
          var item = TabList[i];
          return (
            <Tabs.Panel key={i + "Panel"} value={i} pt="xs">
              {TabSwitch(item.id, item)}

            </Tabs.Panel>
          );
        })}
    </Tabs>
  );
}
