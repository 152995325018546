import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import { useDispatch, useSelector } from 'react-redux'
import { GetAlertMsgVouchers, GetBarcodeType, GetEInvType, GetF02MsgType, GetF02ReqQtyDetails, GetMultiBarCode } from '../../utils/slices/CompanySetUpPopUpSlice'
import GlobalClass from '../../utils/GlobalClass'

const CmpSetupComboBox = (props) => {
  console.log('props==>>', props)
  const { setEditSend, value, DefineCol, closeCombo } = props
  console.log('setEditSend', setEditSend)

  const isLoading = useSelector((state) => state.CompanySetupPopUp?.isLoading)
  const AlertMsgData = useSelector((state) => state.CompanySetupPopUp?.AlertMsgData)
  console.log('AlertMsgData',AlertMsgData)
  const ReqQtyDetailData = useSelector((state) => state.CompanySetupPopUp?.ReqQtyDetailsData)
  const AlertMsgVouData = useSelector((state) => state.CompanySetupPopUp?.AlertMsgVouData)
  const EInvBalData = useSelector((state) => state.CompanySetupPopUp?.EInvBillTypeData)
  const BarCodeTypeData = useSelector((state) => state.CompanySetupPopUp?.BarcodeTypeData)
  const MultiBarCodeData = useSelector((state) => state.CompanySetupPopUp?.MultiBarCodeSetUpData)
  const MBCDDT = useSelector((state) => state.CompanySetupPopUp?.MultiBarCodeSetUpData?.MBCDDT)
  const MBRDT = useSelector((state) => state.CompanySetupPopUp?.MultiBarCodeSetUpData?.MBRDT)

  const dispatch = useDispatch()

  const [ComboData, setComboData] = useState([])
  const [ComboObj, setComboObj] = useState({
    value: "",
    label: ""
  })
  const [selectedItem, setSelectedItem] = useState([])
  console.log('selectedItem',selectedItem)

  useEffect(() => {
    if (DefineCol == "GET00246" /* AMSGTYPE */) {
      dispatch(GetF02MsgType())
    } else if (DefineCol == "RGQD0001" /* Required Quantity Details */) {
      dispatch(GetF02ReqQtyDetails())
    } else if (DefineCol == "GE000245" /* Alert SMS for Vouchers */) {
      dispatch(GetAlertMsgVouchers())
    } else if (DefineCol == "GST00039" /* EINVATYP */) {
      dispatch(GetEInvType())
    } else if (DefineCol == "GEBC0015" /* Barcode Type */) {
      dispatch(GetBarcodeType())
    } else if (DefineCol == "GEBC0016" /* Multi Barcode Setup */) {
      dispatch(GetMultiBarCode())
    }
  }, [DefineCol])

  useEffect(() => {
    if (DefineCol == "GET00246") {
      Promise.resolve(setComboData(AlertMsgData)).then(() => setComboObj({ value: "FIELD01", label: "FIELD02" }))
    } else if (DefineCol == "RGQD0001") {
      Promise.resolve(setComboData(ReqQtyDetailData)).then(() => setComboObj({ value: "S11F01", label: "S11F02" }))
    } else if (DefineCol == "GE000245") {
      Promise.resolve(setComboData(AlertMsgVouData)).then(() => setComboObj({ value: "S11F01", label: "S11F02" }))
    } else if (DefineCol == "GST00039") {
      Promise.resolve(setComboData(EInvBalData)).then(() => setComboObj({ value: "Code", label: "Value" }))
    } else if (DefineCol == "GEBC0015") {
      Promise.resolve(setComboData(BarCodeTypeData)).then(() => setComboObj({ value: "BRCD02", label: "BRCD01" }))
    } else if (DefineCol == "GEBC0016") {
      Promise.resolve(setComboData(MBRDT)).then(() => setComboObj({ value: "MBRF01", label: "MBRF02" }))
    }
  }, [DefineCol, AlertMsgData, ReqQtyDetailData, AlertMsgVouData, EInvBalData, MBRDT])

  useEffect(() => {
    if (selectedItem && selectedItem.length > 0) {
      setEditSend(selectedItem.toString())
    }
  }, [selectedItem])

  const seperate = (a, b) => {
    return a ? a.split(',') : []
  }

  console.log("closeCombo", typeof closeCombo)

  return (<>
    {
      isLoading ? GlobalClass.RenderLoader('dots') :
        ComboData && ComboData?.length > 0 &&
        <PLComboBox
          dropdownPosition='top'
          data={ComboData}
          value={
            (DefineCol == "GE000245") ||
              (DefineCol == "RGQD0001") ||
              (DefineCol == "GST00039") ||
              (DefineCol == "GEBC0015") ||
              (DefineCol == "GET00246") ||
              (DefineCol == "GEBC0016") ?
              seperate(value) :
              value
          }
          multiple={
            (DefineCol == "GE000245") ||
              (DefineCol == "RGQD0001") ||
              (DefineCol == "GST00039") ||
              (DefineCol == "GEBC0015") ||
              (DefineCol == "GET00246") ||
              (DefineCol == "GEBC0016") ? true :
              false
          }
          setComboBox={() => closeCombo(true)}
          dispexpr={ComboObj?.label}
          valexpr={ComboObj?.value}
          setEdit={(e) => {
            console.log('e========>', e)
            let val = [];
            if (
              (DefineCol == "GE000245") ||
              (DefineCol == "RGQD0001") ||
              (DefineCol == "GST00039") ||
              (DefineCol == "GEBC0015") ||
              (DefineCol == "GET00246") ||
              (DefineCol == "GEBC0016") 
            ) {
              if (e?.length > 0) {
                e?.map((m) => {
                  val.push(m)
                  // console.log('val', val)
                })
                return setSelectedItem(val)
              } else {
                setEditSend("")
              }
            } else {
              setEditSend(e)
            }
          }}
        />
    }
  </>)
}

export default CmpSetupComboBox