import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";

export const GetItemwiseExpenseListEnt = createAsyncThunk(
  "ItemwiseExpenseListEnt/GetItemwiseExpenseListEnt",
  async (cOPara) => {
    // console.log(cOPara, " =>cOPara");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: cOPara ? "E" : "A",
        cCode: "",
        cOPara: cOPara ? cOPara : "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetM46Ent + "?pa=" + param
      );
      // console.log("response", response);
      return response;
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

const ItemwiseExpenseListEntSlice = createSlice({
  name: "ItemwiseExpenseListEnt",
  initialState: {
    ItemwiseExpenseListEntData: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    ExpenseDropdown:{
      DropdownValue:"",
      DropdownObj:""
    }
  },
  reducers: {
    SetExpenseDropdown:(state, action)=>{
      state.ExpenseDropdown = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetItemwiseExpenseListEnt.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.ItemwiseExpenseListEntData = [];
      })
      .addCase(GetItemwiseExpenseListEnt.fulfilled, (state, action) => {
        // console.log("action.payload", action.payload?.error);
        if(!action.payload?.error){
        state.ItemwiseExpenseListEntData = action.payload;
        // console.log("ItemwiseExpenseListData", state.ItemwiseExpenseListEntData);
        state.isLoading = false;
        state.hasError = false;
        }else{
          state.ItemwiseExpenseListEntData = action.payload;
        // console.log("ItemwiseExpenseListData", state.ItemwiseExpenseListEntData);
        state.isLoading = false;
        state.hasError = true;
        state.ErrorMsg=action.payload?.error;
        }
      })
      .addCase(GetItemwiseExpenseListEnt.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
        state.ErrorMsg=action.payload?.error;
      });
  },
});

export const{ SetExpenseDropdown } = ItemwiseExpenseListEntSlice.actions
export default ItemwiseExpenseListEntSlice.reducer;
