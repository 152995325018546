import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GetReminder, getData } from '../../utils/TransactionSlices/Reminder';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { ActionIcon, Box, Button, Checkbox, Flex, Grid, Paper, Tooltip } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLDateBox from '../../PlusComponent/PLDateBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLComboBox from '../../PlusComponent/PLComboBox';
import GlobalClass from '../../utils/GlobalClass';
import gensetting from '../../utils/gensetting';
import { Row } from 'jspdf-autotable';

const Reminder = (props) => {
  const { obj } = props;

  const dispatch = useDispatch();
  const columnHelper = createColumnHelper()
  const { ReminderData, isLoading, isDeleted } = useSelector(store => store?.Reminder);
  const oREMData = useSelector(store => store?.Reminder?.oREMData);


  const [rowSelection, setRowSelection] = useState({});
  const [selectedRow, setSelectedRow] = useState({})
  // const [rowSelection1, setRowSelection1] = useState({});
  // const [selectedRow1, setSelectedRow1] = useState({})
  // const [checked, setChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortByField01, setSortByField01] = useState(true);
  const [columnvalue, setColumnValue] = useState("A")


  const [oREM, setoREM] = useState();
  const [REMGRD, setREMGRD] = useState();
  const [REMDCAT, setREMDCAT] = useState();
  const [GRDDT, setGRDDT] = useState();
  const [columns, setColumns] = useState([]);
  const [columns1, setColumns1] = useState([]);
  const [tableData, setTableData] = useState();
  const [tableDataObj, settableDataObj] = useState([])
  const [oREMObj, setoREMObj] = useState();

  useEffect(() => {
    dispatch(GetReminder(obj))
  }, [])

  useEffect(() => {
    console.log("ReminderData", ReminderData);
    if (ReminderData?.oREM?.FIELD01) {

    }
    else {
      dispatch(getData(ReminderData?.oREM))
    }
    setoREM(ReminderData?.oREM);
    setREMGRD(ReminderData?.REMGRD);
    setREMDCAT(ReminderData?.REMDCAT)
    setGRDDT(ReminderData?.GRDDT);
    setoREMObj(ReminderData?.oREM);
  }, [ReminderData])

  const seperate = (e) => {
    var paraArr = e.split("~C~");
    paraArr[1] = paraArr[1] + "";
    var paraArrSep = paraArr[1].split("~R~");
    return paraArrSep;
  };

  const seperate1 = (e) => {
    var paraArr = e.split('~C~');
    return paraArr
  }

  const renderValue = (cellValue, row, column) => {
    var editor;
    REMGRD?.map((item) => {
      if (item.S53F03 == column?.columnDef?.header) {
        switch (item?.S53F14) {
          case "PLPOPUP":
            editor = (
              <PLDataGrid
                // position="bottom"
                width={"270px"}
                // p1={seperate(item.S53F15)[0]}
                value={oREM?.[item.S53F04]}
                setEdit={(e) => {
                  setoREM({ ...oREM, [item.S53F04]: e.FIELD01 })
                }}
              />
            );
            break;
          case "DATEPICKER":
            editor = (
              <PLDateBox
                dispformat="DD/MM/YYYY"
                defaultval={oREM?.[item.S53F04]}
                setEdit={(e) => {
                  let year = e.toLocaleString('en-IN', { year: 'numeric' })
                  let month = e.toLocaleString('en-IN', { month: '2-digit' })
                  let day = e.toLocaleString('en-IN', { day: '2-digit' })
                  let startDate = year + month + day;
                  if(startDate?.length == 8){
                    setoREM({ ...oREM, [item.S53F04]: startDate })
                  }
                }}
              />
            );
            break;
          case "NUMBOX":
            editor = (
              <PLNumberBox
                value={oREM?.[item.S53F04]}
                setEdit={(e) => {
                  setoREM({ ...oREM, [item.S53F04]: e })
                }}
              />
            );
            break;
          case "TEXTBOX":
            // if (item.S53F03 == "Day") {
            //     editor = (<PLTextBox value={weekday} disabled />)
            // }
            // else {
            editor = (
              <PLTextBox
                value={oREM?.[item.S53F04]}
                setEdit={(e) => {
                  setoREM({ ...oREM, [item.S53F04]: e.target.value })
                }}
              />
            );

            // }

            break;
          case "COMBOBOX":
            editor = (
              <PLComboBox
                copno={seperate1(item.S53F15)[1]}
                value={oREM?.[item.S53F04]}
                dispexpr='DisplayMember'
                valexpr='ValueMember'
                setEdit={(e) => {
                  setoREM({ ...oREM, [item.S53F04]: e.value })
                }}
              />
            );
            break;
          case "CHECKBOX":
            editor = (
              <Checkbox
              // checked={T41?.[item.S53F04] == "A" ? true : false}
              // onChange={(event) => {
              //     setT41((prevT41) => ({
              //         ...prevT41,
              //         [item.S53F04]: event.target.checked == true ? "A" : "N",
              //     }))
              // }}

              />
            );
            break;
          default:
            break;
        }
      }
    });

    return editor;
  };

  useEffect(() => {
    if (REMGRD) {
      var cols = [];
      REMGRD.filter(ele => ele.S53F00 !== "H").map((item, index) => {
        cols.push(
          columnHelper.accessor(item?.S53F04, {
            header: item?.S53F03,
            id: item?.S53UID,
            size: item?.S53F03 == "Particular" ? 160 : 120,
            // enableSorting: true,
            // sortingFn: () => return console.log("test"),
            Edit: ({ cell, column, table, row }) =>
              renderValue(cell.getValue(), row, column),
          })
        );
      });
      setColumns(cols);
    }
  }, [REMGRD, oREM])

  useEffect(() => {
    if (REMDCAT) {
      var cols1 = [];
      cols1.push(
        columnHelper.accessor("FIELD01", {
          header: "Category",
          // id : item?.S53UID
        })
      );
      setColumns1(cols1);
    }
  }, [REMDCAT])

  useEffect(() => {
    const isSelected = Object.values(rowSelection).join();
    if (isSelected === "false") {
      console.log("rowSelection", rowSelection);
      setSelectedRow({})
      setTableData([])
      settableDataObj([])
    }
  }, [rowSelection])

  useEffect(() => {
    if (selectedRow?.FIELD02) {
      let ary = GRDDT?.filter((item) => item.FIELD04 === selectedRow?.FIELD02);
      setTableData(ary);
      settableDataObj(ary);
    }
    setColumnValue('A')
  }, [selectedRow, GRDDT])

  // useEffect(() => {
  //   let id = Object.keys(rowSelection1).join();
  //   let RowSelected = Object.values(rowSelection1).join()

  //   if (RowSelected == "false") {
  //     setSelectedRow1({})
  //   }
  //   else {
  //     tableData?.filter((item, index) => {
  //       if (id && index == id) {
  //         setSelectedRow1(item)
  //       }
  //     })
  //   }
  // }, [rowSelection1])

  useEffect(() => {
    console.log("isDeleted", isDeleted);
    if (isDeleted) {
      dispatch(GetReminder(obj))
    }
  }, [isDeleted])

  const deleteBtn = () => {
    if (selectedRows.length > 0) {
      selectedRows.map(item => {
        dispatch(GetReminder({ ...obj, type: "D", rowId: item }))
      })
    }
    else {
      GlobalClass.Notify("warning", "Please Select a Row")
    }

    // if (selectedRow1?.FIELD00) {
    //   dispatch(GetReminder({ ...obj, type: "D", rowId: selectedRow1?.FIELD00 }))
    // }
    // else {
    //   GlobalClass.Notify("warning", "Please Select a Row")
    // }
  }

  console.log("tableData", tableData);
  console.log("tableDataObj", tableDataObj);
  console.log("columnvalue", columnvalue);

  useEffect(() => {
    const isSelected = Object.values(rowSelection).join();
    if (isSelected === "true") {
      if (columnvalue === "C" || columnvalue === "S") {
        const filterData = tableDataObj.filter((item) => item.FIELD06 === columnvalue);
        filterData && setTableData(filterData)
      } else if (columnvalue === "A") {
        setTableData(tableDataObj)
      }
    }

  }, [columnvalue])

  const allCompanySystemBtn = () => {
    if (columnvalue === "A") {
      setColumnValue("C");
    }
    else if (columnvalue === "C") {
      setColumnValue("S");
    }
    else {
      setColumnValue("A")
    }
  }

  useEffect(() => {
    let result = tableData?.map(item => {
      let obj = { ...item, FIELD05: item.FIELD05.toUpperCase() }
      return obj
    })
    if (sortByField01 === false) {
      // console.log("FIELD05 wise sorting");
      let data = result?.sort((a, b) => a.FIELD05.localeCompare(b.FIELD05))
      setTableData(data)
    }
    else {
      // console.log("FIELD01 & FIELD05 wise sorting");
      let data = result?.sort((a, b) => a.FIELD01 - b.FIELD01 || a.FIELD05.localeCompare(b.FIELD05));
      setTableData(data)
    }
  }, [sortByField01])

  const orderBtn = () => {
    setSortByField01((prev) => !prev); // Toggle sorting condition on button click
  };

  //CREATE action
  const handleCreateUser = async ({ values, exitCreatingMode }) => {

    GlobalClass.Notify("info", "Adding", "Please wait while we process your data");
    // dispatch(getSaveBtnLoader(true))

    try {
      // let T41Obj = { T41: T41 };
      // let cCode = T41?.T41PVT == "BR" ? "BRBR" : T41?.T41PVT == "BP" ? "BPBP" : T41?.T41PVT == "CR" ? "CRCR" : T41?.T41PVT == "CP" ? "CPCP" : T41?.T41PVT == "NC" ? "NCNC" : T41?.T41PVT == "ND" ? "NDND" : T41?.T41PVT == "JR" ? "JRJR" : "";

      let oREM1 = { ...oREM, FIELD04: selectedRow?.FIELD02 }

      var data2 = {
        "CorpID": gensetting.getcorpid(),
        "cUser": gensetting.getcuser(),
        "cPass": gensetting.getcpass(),
        "CmpNo": gensetting.getcmpno(),
        "cAction": "A",
        "cCode": "",
        // "cOPara": "",
        "cSData": JSON.stringify(oREM1)
      };

      let param =
        JSON.stringify({
          "CorpID": gensetting.getcorpid(),
          "cUser": gensetting.getcuser(),
          "cPass": gensetting.getcpass(),
          "CmpNo": gensetting.getcmpno(),
          "cAction": "A",
          "cCode": "",
          // "cOPara": "",
        });

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data2),
      };

      const response = await fetch(GlobalClass.ApiUrl + GlobalClass.PostReminder + "?pa=" + param, requestOptions);
      const responseData = await response.json();

      if (responseData.status === 'SUCCESS') {
        setTableData([...tableData, oREM])
        GlobalClass.Notify("success", "Added", "Added successfully");
        // dispatch(getFlagStatus(true))
        // console.log("obj", obj);
        Promise.resolve(dispatch(GetReminder(obj)))
          .then(() => {
            // setSelectedRow({})
            // setTableData([])
          })
          .catch((error) => {
            GlobalClass.Notify("error", "Error", error?.message);
          })
      }
      else if (responseData.status === 'FAIL') {
        // setT41List(T41List)
        GlobalClass.Notify("error", responseData?.status, responseData?.message);
        // dispatch(getSaveBtnLoader(false))

      }
    }
    catch (error) {
      // setT41List(T41List)
      GlobalClass.Notify("error", "Error ", error.message);
      // dispatch(getSaveBtnLoader(false))

    }
    finally {
      // if (!saveBtnLoader) {
      // funEnable(true)
      // setWeekDay(WDAY)
      // dispatch(setToggleButton(false))
      // setValidationErrors({});
      // setRowSelection({})
      exitCreatingMode();
      setoREM(oREMData)
      // }
    };
  }
  //UPDATE action
  const handleSaveUser = async ({ values, table, row }) => {

    GlobalClass.Notify("info", "Editing", "Please wait while we process your data");

    if (JSON.stringify(oREM) === JSON.stringify(oREMObj)) {
      GlobalClass.Notify("success", "Edited", "Edited successfully");
      // funEnable(true)
      // setValidationErrors({})
      // dispatch(setToggleButton(false))
      // setWeekDay(WDAY)
      table.setEditingRow(null);
      setoREM(oREMData);
      // setRowSelection({})
    }
    else {
      try {

        var data1 = {
          "CorpID": gensetting.getcorpid(),
          "cUser": gensetting.getcuser(),
          "cPass": gensetting.getcpass(),
          "CmpNo": gensetting.getcmpno(),
          "cAction": "E",
          "cCode": oREM?.FIELD00,
          "cOPara": "",
          "cSData": JSON.stringify(oREM)
        };

        let param =
          JSON.stringify({
            "CorpID": gensetting.getcorpid(),
            "cUser": gensetting.getcuser(),
            "cPass": gensetting.getcpass(),
            "CmpNo": gensetting.getcmpno(),
            "cAction": "E",
            "cCode": oREM?.FIELD00,
            "cOPara": "",
          });

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(data1),
        };

        const response = await fetch(GlobalClass.ApiUrl + GlobalClass.PostReminder + "?pa=" + param, requestOptions);
        const responseData = await response.json();

        if (responseData.status === 'SUCCESS') {
          GlobalClass.Notify("success", "Edited", "Edited successfully");
          Promise.resolve(dispatch(GetReminder(obj)))
            .then()
            .catch((error) => {
              GlobalClass.Notify("error", "Error", error?.message);
            })
          // var tempoREM = [...tableData];
          // tempoREM[row.index] = oREM;
          // setTableData(tempoREM)


        }
        else if (responseData.status === 'FAIL') {
          GlobalClass.Notify("error", responseData?.status, responseData?.message);
          // setT41List(T41List)
        }
      }
      catch (e) {
        GlobalClass.Notify("error", "Error", `${e.message}`);
        // setT41List(T41List)
      }
      finally {
        // funEnable(true)
        // setWeekDay(WDAY)
        // dispatch(setToggleButton(false))
        table.setEditingRow(null);
        setoREM(oREMData);
        // setValidationErrors({})
        // setRowSelection({})
      };
    }

  };

  const table = useMantineReactTable({
    data: tableData ?? [],
    columns,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: true,
    enablePagination: true,
    enableColumnActions: false,
    enableSorting: true,
    enableColumnFilters: false,
    enableColumnDragging: false,
    enableHiding: false,
    enableRowNumbers: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    // enableGlobalFilterModes: true,
    mantineTableContainerProps: { sx: { minHeight: "400px" } },
    initialState: {
      density: "xs"
    },
    state: {
      // globalFilter: search,

      // rowSelection1,
      density: 'xs'
    },
    // mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
    //   onClick: () => {
    //     let rowId = row?.original?.FIELD00
    //     console.log("rowId", row);
    //     if(rowId)
    //     {
    //       setSelectedRow1(row?.original)
    //     }
    //     else{
    //       setSelectedRow1({})
    //     }
    //     setRowSelection1((prev) => ({
    //       [row.id]: !prev[row.id],
    //     }));
    //   },
    //   selected: rowSelection1[row.id],
    // }),
    enableEditing: true,
    editDisplayMode: "row",
    createDisplayMode: 'row',
    // mantineTableContainerProps: { sx: { height: '300px' } },
    // enableSelectAll: false,
    // enableMultiRowSelection: false,
    // enableTableFooter: false,
    onCreatingRowCancel: () => { setRowSelection({}); setoREM(oREMData) },
    onCreatingRowSave: handleCreateUser,
    onEditingRowCancel: () => { setRowSelection({}); setoREM(oREMData) },
    onEditingRowSave: handleSaveUser,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => {
            table.setEditingRow(row);
            let rowId = row?.original?.FIELD00;
            dispatch(GetReminder({ ...obj, type: "E", rowId }))
          }}>
            <IconEdit size={"20px"} />
          </ActionIcon>
        </Tooltip>
        {/* <Tooltip label="Delete">
          <ActionIcon color="red" onClick={() => ""}>
            <IconTrash />
          </ActionIcon>
        </Tooltip> */}
        <Tooltip label="Checkbox">
          <ActionIcon color="red" onClick={() => ""}>
            <Checkbox onChange={(event) => {
              console.log("event", event)
              setSelectedRows(
                event.currentTarget.checked
                  ? [...selectedRows, row?.original?.FIELD00]
                  : selectedRows.filter((position) => position !== row?.original?.FIELD00)
              )
            }} />

          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbarCustomActions: ({ table, row }) => (
      <Button
        // disabled={toggleButton}
        onClick={() => {
          table.setCreatingRow(true);
          // setVisible(true);
          // funEnable(false);
          // dispatch(setToggleButton(true));
        }}
        size='xs'
      >
        Add New
      </Button>


    ),

  });

  const table1 = useMantineReactTable({
    data: REMDCAT ?? [],
    columns: columns1,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableTopToolbar: false,
    enablePagination: true,
    enableColumnActions: false,
    enableSorting: false,
    enableColumnFilters: false,
    enableColumnDragging: false,
    // enableGlobalFilter: true,
    // // enableGlobalFilterModes: true,
    mantineTableContainerProps: { sx: { minHeight: "460px" } },
    state: {
      // globalFilter: search,
      rowSelection
    },
    mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
      onClick: () => {
        setSelectedRow(row?.original)
        setRowSelection((prev) => ({
          [row.id]: !prev[row.id],
        }));
      },
      selected: rowSelection[row.id],
    }),
  });

  return (
    <>
      {
        isLoading ? GlobalClass.RenderLoader("dots") :
          <Grid>
            {/* <Paper> */}
            <Grid.Col span={12} style={{ display: 'flex' }}>
              <Grid.Col span={5}>
                <MantineReactTable table={table1} />
              </Grid.Col>
              <Grid.Col span={7}>
                <MantineReactTable table={table} />
              </Grid.Col>
            </Grid.Col>
            {/* </Paper> */}

            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant='light' m={10}>Print</Button>
              <Button variant='light' m={10} onClick={deleteBtn}>Delete</Button>
              <Button variant='light' m={10} onClick={allCompanySystemBtn}>All/Company/System</Button>
              <Button variant='light' m={10} onClick={orderBtn}>Order</Button>
            </Grid.Col>
          </Grid>
      }
    </>
  )
}

export default Reminder






















// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { GetReminder, getData } from '../../utils/TransactionSlices/Reminder';
// import { createColumnHelper } from '@tanstack/react-table';
// import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
// import { ActionIcon, Box, Button, Checkbox, Flex, Grid, Paper, Tooltip } from '@mantine/core';
// import { IconEdit, IconTrash } from '@tabler/icons-react';
// import PLDataGrid from '../../PlusComponent/PLDataGrid';
// import PLDateBox from '../../PlusComponent/PLDateBox';
// import PLNumberBox from '../../PlusComponent/PLNumberBox';
// import PLTextBox from '../../PlusComponent/PLTextBox';
// import PLComboBox from '../../PlusComponent/PLComboBox';
// import GlobalClass from '../../utils/GlobalClass';
// import gensetting from '../../utils/gensetting';
// import { Row } from 'jspdf-autotable';

// const Reminder = (props) => {
//   const { obj } = props;

//   const dispatch = useDispatch();
//   const columnHelper = createColumnHelper()
//   const { ReminderData, isLoading,isDeleted} = useSelector(store => store?.Reminder);
//   const oREMData = useSelector(store => store?.Reminder?.oREMData);


//   const [rowSelection, setRowSelection] = useState({});
//   const [rowSelection1, setRowSelection1] = useState({});
//   const [selectedRow, setSelectedRow] = useState({})
//   const [selectedRow1, setSelectedRow1] = useState({})

//   const [oREM, setoREM] = useState();
//   const [REMGRD, setREMGRD] = useState();
//   const [REMDCAT, setREMDCAT] = useState();
//   const [GRDDT, setGRDDT] = useState();
//   const [columns, setColumns] = useState([]);
//   const [columns1, setColumns1] = useState([]);
//   const [tableData, setTableData] = useState();
//   const [oREMObj, setoREMObj] = useState();

//   useEffect(() => {
//     dispatch(GetReminder(obj))
//   }, [])

//   useEffect(() => {
//     console.log("ReminderData", ReminderData);
//     if (ReminderData?.oREM?.FIELD01) {

//     }
//     else {
//       dispatch(getData(ReminderData?.oREM))
//     }
//     setoREM(ReminderData?.oREM);
//     setREMGRD(ReminderData?.REMGRD);
//     setREMDCAT(ReminderData?.REMDCAT)
//     setGRDDT(ReminderData?.GRDDT);
//     setoREMObj(ReminderData?.oREM);
//   }, [ReminderData])

//   const seperate = (e) => {
//     var paraArr = e.split("~C~");
//     paraArr[1] = paraArr[1] + "";
//     var paraArrSep = paraArr[1].split("~R~");
//     return paraArrSep;
//   };

//   const seperate1 = (e) => {
//     var paraArr = e.split('~C~');
//     return paraArr
//   }

//   const renderValue = (cellValue, row, column) => {
//     var editor;
//     REMGRD?.map((item) => {
//       if (item.S53F03 == column?.columnDef?.header) {
//         switch (item?.S53F14) {
//           case "PLPOPUP":
//             editor = (
//               <PLDataGrid
//                 // position="bottom"
//                 width={"270px"}
//                 // p1={seperate(item.S53F15)[0]}
//                 value={oREM?.[item.S53F04]}
//                 setEdit={(e) => {
//                   setoREM({ ...oREM, [item.S53F04]: e.FIELD01 })
//                 }}
//               />
//             );
//             break;
//           case "DATEPICKER":
//             editor = (
//               <PLDateBox
//                 dispformat="DD/MM/YYYY"
//                 defaultval={oREM?.[item.S53F04]}
//                 setEdit={(e) => {
//                   let year = e.toLocaleString('en-IN', { year: 'numeric' })
//                   let month = e.toLocaleString('en-IN', { month: '2-digit' })
//                   let day = e.toLocaleString('en-IN', { day: '2-digit' })
//                   let startDate = year + month + day;
//                   setoREM({ ...oREM, [item.S53F04]: startDate })
//                 }}
//               />
//             );
//             break;
//           case "NUMBOX":
//             editor = (
//               <PLNumberBox
//                 value={oREM?.[item.S53F04]}
//                 setEdit={(e) => {
//                   setoREM({ ...oREM, [item.S53F04]: e })
//                 }}
//               />
//             );
//             break;
//           case "TEXTBOX":
//             // if (item.S53F03 == "Day") {
//             //     editor = (<PLTextBox value={weekday} disabled />)
//             // }
//             // else {
//             editor = (
//               <PLTextBox
//                 value={oREM?.[item.S53F04]}
//                 setEdit={(e) => {
//                   setoREM({ ...oREM, [item.S53F04]: e.target.value })
//                 }}
//               />
//             );

//             // }

//             break;
//           case "COMBOBOX":
//             editor = (
//               <PLComboBox
//                 copno={seperate1(item.S53F15)[1]}
//                 value={oREM?.[item.S53F04]}
//                 dispexpr='DisplayMember'
//                 valexpr='ValueMember'
//                 setEdit={(e) => {
//                   setoREM({ ...oREM, [item.S53F04]: e.value })
//                 }}
//               />
//             );
//             break;
//           case "CHECKBOX":
//             editor = (
//               <Checkbox
//               // checked={T41?.[item.S53F04] == "A" ? true : false}
//               // onChange={(event) => {
//               //     setT41((prevT41) => ({
//               //         ...prevT41,
//               //         [item.S53F04]: event.target.checked == true ? "A" : "N",
//               //     }))
//               // }}

//               />
//             );
//             break;
//           default:
//             break;
//         }
//       }
//     });

//     return editor;
//   };

//   useEffect(() => {
//     if (REMGRD) {
//       var cols = [];
//       REMGRD.filter(ele => ele.S53F00 !== "H").map((item, index) => {
//         // console.log("item",item.S53F03,item.S53F04);
//         cols.push(
//           columnHelper.accessor(item?.S53F04, {
//             header: item?.S53F03,
//             id: item?.S53UID,
//             size: item?.S53F03 == "Particular" ? 160 : 140,
//             Edit: ({ cell, column, table, row }) =>
//               renderValue(cell.getValue(), row, column),
//           })
//         );
//       });
//       setColumns(cols);
//     }
//   }, [REMGRD, oREM])

//   useEffect(() => {
//     if (REMDCAT) {
//       var cols1 = [];
//       cols1.push(
//         columnHelper.accessor("FIELD01", {
//           header: "Category",
//           // id : item?.S53UID
//         })
//       );
//       setColumns1(cols1);
//     }
//   }, [REMDCAT])

//   useEffect(() => {
//     console.log("rowSelection", rowSelection);
//     const isSelected = Object.values(rowSelection).join();
//     if (isSelected === "false") {
//       setSelectedRow({})
//       setTableData([])
//     }
//   }, [rowSelection])

//   useEffect(() => {
//     if (selectedRow?.FIELD02) {
//       // console.log("GRDDT", GRDDT);
//       let ary = GRDDT?.filter((item) => item.FIELD04 === selectedRow?.FIELD02);
//       setTableData(ary)
//     }
//     setSelectedRow1({})
//   }, [selectedRow, GRDDT])

//   useEffect(() =>{
//     let id = Object.keys(rowSelection1).join();
//     let RowSelected = Object.values(rowSelection1).join()

//     if (RowSelected == "false") {
//       setSelectedRow1({})
//     }
//     else {
//       tableData?.filter((item, index) => {
//         if (id && index == id) {
//           setSelectedRow1(item)
//         }
//       })
//     }
//   },[rowSelection1])

//   useEffect(() => {
//     console.log("isDeleted",isDeleted);
//     if(isDeleted)
//     {
//         dispatch(GetReminder(obj))
//     }
//   },[isDeleted])

//   const deleteBtn = () => {
//    if(selectedRow1?.FIELD00)
//    {
//        dispatch(GetReminder({...obj,type:"D",rowId:selectedRow1?.FIELD00}))
//    }
//    else{
//     GlobalClass.Notify("warning","Please Select a Row")
//    }
//  }

//   //CREATE action
//   const handleCreateUser = async ({ values, exitCreatingMode }) => {

//     GlobalClass.Notify("info", "Adding", "Please wait while we process your data");
//     // dispatch(getSaveBtnLoader(true))

//     try {
//       // let T41Obj = { T41: T41 };
//       // let cCode = T41?.T41PVT == "BR" ? "BRBR" : T41?.T41PVT == "BP" ? "BPBP" : T41?.T41PVT == "CR" ? "CRCR" : T41?.T41PVT == "CP" ? "CPCP" : T41?.T41PVT == "NC" ? "NCNC" : T41?.T41PVT == "ND" ? "NDND" : T41?.T41PVT == "JR" ? "JRJR" : "";

//       let oREM1 = { ...oREM, FIELD04: selectedRow?.FIELD02 }

//       var data2 = {
//         "CorpID": gensetting.getcorpid(),
//         "cUser": gensetting.getcuser(),
//         "cPass": gensetting.getcpass(),
//         "CmpNo": gensetting.getcmpno(),
//         "cAction": "A",
//         "cCode": "",
//         // "cOPara": "",
//         "cSData": JSON.stringify(oREM1)
//       };

//       let param =
//         JSON.stringify({
//           "CorpID": gensetting.getcorpid(),
//           "cUser": gensetting.getcuser(),
//           "cPass": gensetting.getcpass(),
//           "CmpNo": gensetting.getcmpno(),
//           "cAction": "A",
//           "cCode": "",
//           // "cOPara": "",
//         });

//       const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(data2),
//       };

//       const response = await fetch(GlobalClass.ApiUrl + GlobalClass.PostReminder + "?pa=" + param, requestOptions);
//       const responseData = await response.json();

//       if (responseData.status === 'SUCCESS') {
//         setTableData([...tableData, oREM])
//         GlobalClass.Notify("success", "Added", "Added successfully");
//         // dispatch(getFlagStatus(true))
//         // console.log("obj", obj);
//         Promise.resolve(dispatch(GetReminder(obj)))
//           .then(() => {
//             // setSelectedRow({})
//             // setTableData([])
//           })
//           .catch((error) => {
//             GlobalClass.Notify("error", "Error", error?.message);
//           })
//       }
//       else if (responseData.status === 'FAIL') {
//         // setT41List(T41List)
//         GlobalClass.Notify("error", responseData?.status, responseData?.message);
//         // dispatch(getSaveBtnLoader(false))

//       }
//     }
//     catch (error) {
//       // setT41List(T41List)
//       GlobalClass.Notify("error", "Error ", error.message);
//       // dispatch(getSaveBtnLoader(false))

//     }
//     finally {
//       // if (!saveBtnLoader) {
//       // funEnable(true)
//       // setWeekDay(WDAY)
//       // dispatch(setToggleButton(false))
//       // setValidationErrors({});
//       // setRowSelection({})
//       exitCreatingMode();
//       setoREM(oREMData)
//       // }
//     };
//   }
//   //UPDATE action
//   const handleSaveUser = async ({ values, table, row }) => {

//     GlobalClass.Notify("info", "Editing", "Please wait while we process your data");

//     if (JSON.stringify(oREM) === JSON.stringify(oREMObj)) {
//       GlobalClass.Notify("success", "Edited", "Edited successfully");
//       // funEnable(true)
//       // setValidationErrors({})
//       // dispatch(setToggleButton(false))
//       // setWeekDay(WDAY)
//       table.setEditingRow(null);
//       setoREM(oREMData);
//       // setRowSelection({})
//     }
//     else {
//       try {

//         var data1 = {
//           "CorpID": gensetting.getcorpid(),
//           "cUser": gensetting.getcuser(),
//           "cPass": gensetting.getcpass(),
//           "CmpNo": gensetting.getcmpno(),
//           "cAction": "E",
//           "cCode": oREM?.FIELD00,
//           "cOPara": "",
//           "cSData": JSON.stringify(oREM)
//         };

//         let param =
//           JSON.stringify({
//             "CorpID": gensetting.getcorpid(),
//             "cUser": gensetting.getcuser(),
//             "cPass": gensetting.getcpass(),
//             "CmpNo": gensetting.getcmpno(),
//             "cAction": "E",
//             "cCode": oREM?.FIELD00,
//             "cOPara": "",
//           });

//         const requestOptions = {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify(data1),
//         };

//         const response = await fetch(GlobalClass.ApiUrl + GlobalClass.PostReminder + "?pa=" + param, requestOptions);
//         const responseData = await response.json();

//         if (responseData.status === 'SUCCESS') {
//           GlobalClass.Notify("success", "Edited", "Edited successfully");
//           Promise.resolve(dispatch(GetReminder(obj)))
//             .then()
//             .catch((error) => {
//               GlobalClass.Notify("error", "Error", error?.message);
//             })
//           // var tempoREM = [...tableData];
//           // tempoREM[row.index] = oREM;
//           // setTableData(tempoREM)


//         }
//         else if (responseData.status === 'FAIL') {
//           GlobalClass.Notify("error", responseData?.status, responseData?.message);
//           // setT41List(T41List)
//         }
//       }
//       catch (e) {
//         GlobalClass.Notify("error", "Error", `${e.message}`);
//         // setT41List(T41List)
//       }
//       finally {
//         // funEnable(true)
//         // setWeekDay(WDAY)
//         // dispatch(setToggleButton(false))
//         table.setEditingRow(null);
//         setoREM(oREMData);
//         // setValidationErrors({})
//         // setRowSelection({})
//       };
//     }

//   };

//   const table = useMantineReactTable({
//     data: tableData ?? [],
//     columns,
//     enableFullScreenToggle: false,
//     enableDensityToggle: false,
//     enableTopToolbar: true,
//     enablePagination: true,
//     enableColumnActions: false,
//     enableSorting: false,
//     enableColumnFilters: false,
//     enableColumnDragging: false,
//     enableHiding: false,
//     enableRowNumbers: false,
//     enableColumnOrdering: false,
//     enableGlobalFilter: false,
//     // enableGlobalFilterModes: true,
//     mantineTableContainerProps: { sx: { minHeight: "400px" } },
//     initialState: {
//       density: "xs",
//     },
//     state: {
//       // globalFilter: search,
//       rowSelection1,
//       density: 'xs'
//     },
//     mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
//       onClick: () => {
//         let rowId = row?.original?.FIELD00
//         console.log("rowId",row);
//         // if(rowId)
//         // {
//         //   setSelectedRow1(row?.original)
//         // }
//         // else{
//         //   setSelectedRow1({})
//         // }
//         setRowSelection1((prev) => ({
//           [row.id]: !prev[row.id],
//         }));
//       },
//       selected: rowSelection1[row.id],
//     }),
//     enableEditing: true,
//     editDisplayMode: "row",
//     createDisplayMode: 'row',
//     // mantineTableContainerProps: { sx: { height: '300px' } },
//     // enableSelectAll: false,
//     // enableMultiRowSelection: false,
//     // enableTableFooter: false,
//     onCreatingRowCancel: () => { setRowSelection({}); setoREM(oREMData) },
//     onCreatingRowSave: handleCreateUser,
//     onEditingRowCancel: () => { setRowSelection({}); setoREM(oREMData) },
//     onEditingRowSave: handleSaveUser,
//     renderRowActions: ({ row, table }) => (
//       <Flex gap="md">
//         <Tooltip label="Edit">
//           <ActionIcon onClick={() => {
//             table.setEditingRow(row);
//             let rowId = row?.original?.FIELD00;
//             dispatch(GetReminder({ ...obj, type: "E", rowId }))
//           }}>
//             <IconEdit size={"20px"} />
//           </ActionIcon>
//         </Tooltip>
//         <Tooltip label="Delete">
//           <ActionIcon color="red" onClick={() => ""}>
//             <IconTrash />
//           </ActionIcon>
//         </Tooltip>
//       </Flex>
//     ),
//     renderTopToolbarCustomActions: ({ table,row }) => (
//       <Button
//         // disabled={toggleButton}
//         onClick={() => {
//           table.setCreatingRow(true);
//           // setVisible(true);
//           // funEnable(false);
//           // dispatch(setToggleButton(true));
//         }}
//         size='xs'
//       >
//         Add New
//       </Button>


//     ),

//   });

//   const table1 = useMantineReactTable({
//     data: REMDCAT ?? [],
//     columns: columns1,
//     enableFullScreenToggle: false,
//     enableDensityToggle: false,
//     enableTopToolbar: false,
//     enablePagination: true,
//     enableColumnActions: false,
//     enableSorting: false,
//     enableColumnFilters: false,
//     enableColumnDragging: false,
//     // enableGlobalFilter: true,
//     // // enableGlobalFilterModes: true,
//     mantineTableContainerProps: { sx: { minHeight: "460px" } },
//     state: {
//       // globalFilter: search,
//       rowSelection
//     },
//     mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
//       onClick: () => {
//         setSelectedRow(row?.original)
//         console.log("row",row);
//         setRowSelection((prev) => ({
//           [row.id]: !prev[row.id],
//         }));
//       },
//       selected: rowSelection[row.id],
//     }),
//   });

//   return (
//     <>
//       {
//         isLoading ? GlobalClass.RenderLoader("dots") :
//           <Grid>
//             {/* <Paper> */}
//             <Grid.Col span={12} style={{ display: 'flex' }}>
//               <Grid.Col span={4}>
//                 <MantineReactTable table={table1} />
//               </Grid.Col>
//               <Grid.Col span={8}>
//                 <MantineReactTable table={table} />
//               </Grid.Col>
//             </Grid.Col>
//             {/* </Paper> */}

//             <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
//               <Button variant='light' m={10}>Print</Button>
//               <Button variant='light' m={10} onClick={deleteBtn}>Delete</Button>
//               <Button variant='light' m={10}>All/Company/System</Button>
//               <Button variant='light' m={10}>Order</Button>
//             </Grid.Col>
//           </Grid>
//       }
//     </>
//   )
// }

// export default Reminder