import { Button, Grid, Group } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import PLTextBox from '../../PlusComponent/PLTextBox';
import PLNumberBox from '../../PlusComponent/PLNumberBox';

const SITableBatchUserField = (props) => {
    const { DTUFT11, T02ObjSend, setT02Objsend, DrawerOBJ, setDrawerOBJ,PageObj } = props;

    const [batchUFObj, setBatchUFObj] = useState({})

    useEffect(() => {
        if (T02ObjSend) {
            setBatchUFObj(T02ObjSend)
        }
    }, [T02ObjSend])

    const seperate = (e) => {
        var paraArr = e?.split("~C~");
        console.log("paraArr=>", paraArr);
        return paraArr;
    };

    const BatchUserFieldRender = () => {
        let InputField;
        return (
            DTUFT11?.map((t) => {
                // console.log("t?.S13F14", t?.S13F14);
                // console.log([t?.S13F02], "UserFieldObj?.[t?.S13F02]")

                switch (t?.S13F14) {
                    case "NUMBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLNumberBox
                                    value={batchUFObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e })
                                    }}

                                />
                            </Grid.Col>
                        </Grid.Col>

                        break;
                    case "TEXTBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLTextBox
                                    setOnFocus={() => {
                                            if (PageObj?.page == "02530249") {
                                                // ResolveObj = { ...e, ULO: e?.UBA }
                                                setBatchUFObj({ ...batchUFObj, ULO: T02ObjSend?.UBA })
                                            }
                                    }}
                                    value={batchUFObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e.target.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "PLPOPUP":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLDataGrid
                                    value={batchUFObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        console.log(e?.FIELD01, "e?.FIELD01")
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e?.FIELD01 })
                                    }}
                                    p1={seperate(t?.S13F09)[1]}

                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    case "COMBOBOX":
                        InputField = <Grid.Col span={12} style={{ display: "flex", padding: 0 }}>
                            <Grid.Col span={4} style={{ fontSize: 12 }}>{t?.S13F07}</Grid.Col>
                            <Grid.Col span={8}>
                                <PLComboBox
                                    value={batchUFObj?.[t?.S13F02]}
                                    setEdit={(e) => {
                                        setBatchUFObj({ ...batchUFObj, [t?.S13F02]: e?.value })
                                    }}
                                />
                            </Grid.Col>
                        </Grid.Col>
                        break;
                    default:
                        InputField = null
                        break;
                }
                return InputField
            })
        )
    }

    const SaveBatchUserField = () => {
        Promise.resolve(setT02Objsend(batchUFObj)).then(() => setDrawerOBJ({ ...DrawerOBJ, open: false }))
    }

    return (<>
        <Grid gutter={4}>
            {
                BatchUserFieldRender()
            }
            <Grid.Col span={12}>
                <Group spacing={'xs'} position='center'>
                    <Button
                        onKeyDown={(e) => {
                            if (e.key == "Enter") {
                                SaveBatchUserField()
                            }
                        }}
                        onClick={() => SaveBatchUserField()}>Save</Button>
                </Group>
            </Grid.Col>
        </Grid>

    </>)
}

export default SITableBatchUserField