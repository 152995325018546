import { ActionIcon, Button, Flex, Grid, Tooltip } from '@mantine/core'
import { IconEdit } from '@tabler/icons-react'
import { createColumnHelper } from '@tanstack/react-table'
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table'
import React, { useEffect, useState } from 'react'
import PLDateBox from '../../../PlusComponent/PLDateBox'
import PLTextBox from '../../../PlusComponent/PLTextBox'

const OriginalInvoiceDetailTable = (props) => {
    // console.log('props=>>', props)
    const { T41ObjSend, setT41ObjSend, InvTableData, setInvTableData, DrawerObjSend, setDrawerObjSend, textBox, setTextBoxSend, OBJ } = props

    const [columns, setColumns] = useState([])
    const [InvoiceTableObj, setInvoiceTableObj] = useState({
        VouNo: '',
        Date: ''
    })
    const [tData, setTData] = useState([])
    const [finalObj, setFinalObj] = useState(null)
    const [rowSelection, setRowSelection] = useState({})

    useEffect(() => {
        console.log('tData', tData)
        console.log('T41ObjSend', T41ObjSend)
        console.log('InvTableData',InvTableData)
        console.log('OBJ',OBJ)
    }, [T41ObjSend, tData, InvTableData,OBJ])

    useEffect(() => {
        if (T41ObjSend) {
            setFinalObj(T41ObjSend)
        }
    }, [T41ObjSend])

    const SaveInvoiceDetails = () => {
        // console.log('finalObj=>>', finalObj)
    
        if(tData.length == 0) {
            console.log('if call')
            setTextBoxSend(false)
            setDrawerObjSend({ ...DrawerObjSend, open: false })
        } else {
            const formateVal = tData.map((d) => {
                const formattedValues = Object.values(d).join(`''~C~''`);
                console.log('formattedValues',formattedValues)
                return `'   ''~C~''${formattedValues}'`;
            }).join(`'~R~'`);
            // const formattedOutput = formateVal;
            console.log('formateVal=>>', formateVal)
            Promise.resolve(setInvTableData(tData))
            .then(() => setT41ObjSend({ ...finalObj, FIELD55: formateVal, FIELD48: '', FIELD33: tData[0]?.Date, FIELD40: tData[0]?.VouNo }))
            .then(() => setDrawerObjSend({ ...DrawerObjSend, open: false }))
            .then(() => setTextBoxSend(true))
        }
    }

    const columnHelper = createColumnHelper()

    useEffect(() => {
        if (InvTableData) {
            setTData(InvTableData)
        }
    }, [InvTableData])

    useEffect(() => {
        let col = [];
        col.push(
            columnHelper.accessor("VouNo", {
                header: 'Voucher No',
                Edit: ({ cell, column, table, row }) => {
                    let val = cell.getValue()
                    return <PLTextBox
                        value={val ?? InvoiceTableObj.VouNo}
                        setEdit={(e) => {
                            setInvoiceTableObj({ ...InvoiceTableObj, VouNo: e.target.value })
                        }}
                    />
                }
            }),
        )
        col.push(
            columnHelper.accessor("Date", {
                header: "Date",
                Edit: ({ cell, column, table, row }) => {
                    // console.log('row===>?',row)
                    let val = cell.getValue()
                    return <PLDateBox
                        dispformat="DD/MM/YYYY"
                        defaultval={InvoiceTableObj.Date}
                        // value={dateBoxValue}
                        setEdit={(e) => {
                            let year = e.toLocaleString('en-IN', { year: 'numeric' });
                            let month = e.toLocaleString('en-IN', { month: '2-digit' });
                            let day = e.toLocaleString('en-IN', { day: '2-digit' });

                            let Date = year + month + day;
                            if(Date?.length == 8){
                                setInvoiceTableObj({ ...InvoiceTableObj, Date: Date })
                            }
                        }}
                    />
                }
            }),
        )
        setColumns(col)
    }, [InvoiceTableObj])

    // useEffect(() => {
    //     if (tData && tData.length > 0) {
    //         const formateVal = tData?.map((d) => Object.values(d).join('~C~')).join("~R~")
    //         console.log('formateVal=>>', formateVal)
    //         setFinalObj({ ...finalObj, FIELD55: formateVal })
    //     }
    // }, [tData])

    // Add new row
    const handleCreateRowSave = ({ exitCreatingMode }) => {
        setTData([...tData, InvoiceTableObj])
        exitCreatingMode();
        setInvoiceTableObj({
            VouNo: '',
            Date: ''
        })
    }

    const handleEditSave = ({ values, table, row }) => {
        // console.log('row=>',row)
        let EditInvoiceTableData = [...tData]
        EditInvoiceTableData[row.index] = InvoiceTableObj
        setTData(EditInvoiceTableData)
        setInvoiceTableObj({
            VouNo: '',
            Date: ''
        })
        table.setEditingRow(null); //exit editing mode
    }

    const handleCancelRow = () => {
        setInvoiceTableObj({
            VouNo: '',
            Date: ''
        })
    }

    const table = useMantineReactTable({
        columns,
        data: tData,
        positionToolbarAlertBanner: "bottom",
        enableColumnActions: false,
        enableHiding: false,
        enableSorting: false,
        enableFilters: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        editDisplayMode: "row",
        createDisplayMode: 'row',
        enableEditing: true,
        mantineTableContainerProps: { sx: { maxHeight: "200px", minHeight: '200px' } },
        // enableRowSelection: (row) => {
        //     console.log(row)
        //     if (row.id == 'mrt-row-create') {
        //         return false
        //     } else {
        //         return true
        //     }
        // },
        // onRowSelectionChange: setRowSelection,
        // state: {
        //     rowSelection
        // },
        initialState: {
            density: "0px"
        },
        onCreatingRowSave: handleCreateRowSave,
        onCreatingRowCancel: handleCancelRow,
        onEditingRowSave: handleEditSave,
        onEditingRowCancel: handleCancelRow,
        renderRowActions: ({ row, table }) => (
            <Flex gap="md">
                <Tooltip label="Edit">
                    <ActionIcon onClick={() => {
                        table.setEditingRow(row)
                        // console.log('table.setEditingRow=>', row);
                        setInvoiceTableObj(row.original);
                    }}>
                        <IconEdit size={'20px'} />
                    </ActionIcon>
                </Tooltip>
            </Flex>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                onClick={() => {
                    table.setCreatingRow(true);
                }}
                size='xs'
            >
                Add New
            </Button>
        ),
    })
    return (
        <>
            {
                tData && tData.length >= 0 &&
                <MantineReactTable table={table} />
            }

            <Grid gutter={4}>
                <Grid.Col my={5} span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        onClick={() => SaveInvoiceDetails()}
                    >
                        OK
                    </Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default OriginalInvoiceDetailTable