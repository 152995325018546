import { Button, Center, Grid, Text, Skeleton } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import { useSelector, useDispatch } from "react-redux";
// import Store from "../../utils/store";
import { GetAccountGroup } from "../../utils/slices/AccountGroupSlice";
import PLComboBox from "../../PlusComponent/PLComboBox";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { modals } from "@mantine/modals";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { notifications } from "@mantine/notifications";
import PLDataGrid from "../../PlusComponent/PLDataGrid";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import PLDateBox from "../../PlusComponent/PLDateBox";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

const AccountGroupForm = (props) => {
  const dispatch = useDispatch();
  // console.log("Props", props?.OBJ);
  const { OBJ } = props;
  const formRef = useRef(null);
  const [group, setGroup] = useState({
    FIELD02: "",
    FIELD04: "",
    FIELD11: "",
    FIELD21: "",
  });
  const [dataEdit, setDataEdit] = useState();
  const AccountGroupData = useSelector(
    (state) => state.AccountGroup.AccountGroupData
  );
  const isLoading = useSelector((state) => state.AccountGroup?.isLoading);

  const SaveLoading = useSelector(
    (state) => state?.DataSaveLoadingSlice?.SaveLoading
  );

  useEffect(() => {
    if (OBJ?.p0 == "E") {
      dispatch(GetAccountGroup({ cCode: OBJ.id, cAction: OBJ.p0 }));
    } else {
      dispatch(GetAccountGroup());
    }
  }, []);
  useEffect(() => {
    if (AccountGroupData) {
      setGroup(AccountGroupData?.M11);
      setDataEdit(AccountGroupData?.M11);
    }
    // console.log("AccountGroupData=>", AccountGroupData);
  }, [AccountGroupData]);

  useEffect(() => {
    const formElement = formRef.current;
    if (formElement) {
      formElement.addEventListener("keypress", (e) =>
        GlobalClass.formNextInput(e, formRef)
      );
    }
    return () => {
      if (formElement) {
        formElement.removeEventListener("keypress", (e) =>
          GlobalClass.formNextInput(e, formRef)
        );
      }
    };
  }, [formRef]);

  // let jData = {...group,}

  const handleOkButton = () => {
    if (group !== dataEdit || OBJ?.p0 == "A") {
      dispatch(DataSaveLoading(true));
      if (
        group?.FIELD02.trim() === "" ||
        group?.FIELD04.trim() === "" ||
        group?.FIELD11.trim() === "" ||
        group?.FIELD04 == "G000030G" ||
        group?.FIELD04 == "G0000030"
          ? group?.FIELD21.trim() === ""
          : false
      ) {
        GlobalClass.Notify(
          "warning",
          "Validation error",
          "Please fill out all the required fields"
        );
        dispatch(DataSaveLoading(false));
      } else {
        GlobalClass.Notify(
          "info",
          OBJ.p0 == "A" ? "Adding" : "Editing",
          "Please wait while we process your data"
        );
        var data = {
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: "",
          cSData: JSON.stringify(group),
        };

        let param = JSON.stringify({
          CorpID: gensetting.getcorpid(),
          cUser: gensetting.getcuser(),
          cPass: gensetting.getcpass(),
          CmpNo: gensetting.getcmpno(),
          cAction: "A",
          cCode: "",
          cSData: JSON.stringify(group),
        });

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(
          GlobalClass.ApiUrl + GlobalClass.PostM11Ent + "?pa=" + param,
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            // console.log("api data", data);
            // console.log("api data status", data?.status);
            if (data.status === "SUCCESS") {
              // modals.closeAll();
              // props.ModalFunctionClose();

              // dispatch(ModalDelete(props.index))

              GlobalClass.Notify(
                "success",
                OBJ.p0 == "A" ? "Added" : "Edited",
                OBJ.p0 == "A" ? "Added successfully" : "Edited successfully"
              );
              let PaginationObj =
                Store.getState().DataSaveLoadingSlice.PaginationObj;
              Promise.resolve(dispatch(DataSaveLoading(false)))
                .then(() => dispatch(ModalDelete(props.index)))
                .then(() =>
                  dispatch(
                    GetAccountList({
                      id: "00540001",
                      name: "A/c. Group",
                      p0: "MT",
                      p1: "T_M11",
                      p2: "",
                      p3: "",
                      type: "A",
                      pagination: PaginationObj["00540001"],
                    })
                  )
                );
            } else {
              GlobalClass.Notify("error", data?.status, data?.message);
              dispatch(DataSaveLoading(false));
            }
          })
          .catch((e) => {
            GlobalClass.Notify("error", "Error", `${e.message}`);
            dispatch(DataSaveLoading(false));
          });
      }
    } else {
      dispatch(ModalDelete(props.index));
      return;
    }
  };

  return (
    <div ref={formRef}>
      {SaveLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : isLoading ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Grid gutter={4}>
          <Grid.Col
            span={12}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Group Name</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              {OBJ?.p0 == "E" ? (
                <PLTextBox
                  disabled={OBJ?.DataGridRow?.FIELD14 == "S"}
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  autoFocus={true}
                  value={group?.FIELD02}
                  setEdit={(e) => {
                    setGroup({ ...group, FIELD02: e.target.value });
                  }}
                />
              ) : (
                <PLTextBox
                  onKeyDown={(e) => {
                    GlobalClass.formNextInput(e, formRef);
                  }}
                  autoFocus={true}
                  value={group?.FIELD02}
                  setEdit={(e) => {
                    setGroup({ ...group, FIELD02: e.target.value });
                  }}
                  setText={(e) => {
                    setGroup({ ...group, FIELD11: e.target.value });
                  }}
                />
              )}
            </Grid.Col>
          </Grid.Col>

          <Grid.Col
            span={12}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Under</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLDataGrid
                disabled={OBJ?.DataGridRow?.FIELD14 == "S" && OBJ?.p0 == "E"}
                TmData={AccountGroupData?.P_M11}
                data={AccountGroupData?.P_M11DT?.jData}
                width={"500px"}
                value={group?.FIELD04}
                setEdit={(e) => {
                  setGroup({ ...group, FIELD04: e?.FIELD01 });
                }}
              />
            </Grid.Col>
          </Grid.Col>
          {(group?.FIELD04 == "G000030G" || group?.FIELD04 == "G0000030") && (
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center", padding: 0 }}
            >
              <Grid.Col span={2}>
                <Text style={{ fontSize: 12 }}>Short Name</Text>
              </Grid.Col>
              <Grid.Col span={10}>
                <PLTextBox
                  autoFocus={
                    (group?.FIELD04 == "G000030G" ||
                      group?.FIELD04 == "G0000030") &&
                    OBJ?.p0 == "E" &&
                    OBJ?.DataGridRow?.FIELD14 == "S"
                  }
                  value={group?.FIELD21}
                  setEdit={(e) => {
                    setGroup({ ...group, FIELD21: e?.target.value });
                  }}
                />
              </Grid.Col>
            </Grid.Col>
          )}

          <Grid.Col
            span={12}
            style={{ display: "flex", alignItems: "center", padding: 0 }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Group Header</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox
                autoFocus={
                  !(
                    group?.FIELD04 == "G000030G" || group?.FIELD04 == "G0000030"
                  ) &&
                  OBJ?.p0 == "E" &&
                  OBJ?.DataGridRow?.FIELD14 == "S"
                }
                value={group?.FIELD11}
                setEdit={(e) => {
                  setGroup({ ...group, FIELD11: e.target.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col span={12}>
            <Center>
              <Button
                style={{ width: "130px" }}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    e.preventDefault();
                    handleOkButton();
                  }
                }}
                onClick={handleOkButton}
              >
                Ok
              </Button>
            </Center>
          </Grid.Col>
        </Grid>
      )}
    </div>
  );
};

export default AccountGroupForm;
