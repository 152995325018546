import { Button, Divider, Grid, Paper, Text } from '@mantine/core'
import React from 'react'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLDataGrid from '../../PlusComponent/PLDataGrid'

const AutoVatSetUpForm = (props) => {
    return (
        <Paper shadow="md" radius="md" p="xs" withBorder>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Multiple Tax</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid

                            setEdit={(e) => {

                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Divider
                my="xs"
                label="Sales VAT Configuration"
                labelPosition="center"
                labelProps={{ component: "strong" }}
            />

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Other Expenses</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid

                            setEdit={(e) => {

                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>VAT Setup</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid

                            setEdit={(e) => {

                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Divider
                my="xs"
                label="Purchase VAT Configuration"
                labelPosition="center"
                labelProps={{ component: "strong" }}
            />

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Other Expenses</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid

                            setEdit={(e) => {

                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>VAT Setup</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid

                            setEdit={(e) => {

                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button>OK</Button>
                </Grid.Col>
            </Grid>

        </Paper>
    )
}

export default AutoVatSetUpForm