import {
  Button,
  Center,
  Checkbox,
  Divider,
  Drawer,
  FileButton,
  Grid,
  Group,
  Modal,
  Paper,
  Text,
  Textarea,
} from "@mantine/core";
import React, { useState } from "react";
import PLComboBox from "../../PlusComponent/PLComboBox";
import { IconFolder } from "@tabler/icons-react";
import WaterMarkEntryForm from "./WaterMarkEntryForm";
import AdvanceBtn from "./AdvanceBtn";

export default function PrintReportForm() {
  const [reportOn, setReportOn] = useState({ repField: "S" });
  // const [opened, setOpened] = useState(false);
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [file, setFile] = useState(null);

  const data = [
    {
      Field01: "Screen",
      Field02: "S",
    },
    {
      Field01: "Printer",
      Field02: "P",
    },
    {
      Field01: "File",
      Field02: "F",
    },
    {
      Field01: "Email",
      Field02: "E",
    },
  ];
  function handleWaterMark() {
    // setOpened(true);
    return setDrawerObj({
      ...dra,
      title: <Text fw={700}>Water Mark Entry</Text>,
      body: (
        <>
          <WaterMarkEntryForm
            // waterMarkObjSend={waterMark}
            // setWaterMarkObjSend={(e) => {
            //     console.log('e==>', e)
            //     setWaterMark(e)
            // }}
            DrawerObjSend={DrawerObj}
            setDrawerObjSend={(e) => {
              setDrawerObj(e);
            }}
          />
        </>
      ),
      open: true,
      size: "lg",
      position: "right",
    });
  }
  function AdvanceForm() {
    return setDrawerObj({
      ...dra,
      title: <Text fw={700}>Report Print</Text>,
      body: (
        <>
          <AdvanceBtn
            // waterMarkObjSend={waterMark}
            // setWaterMarkObjSend={(e) => {
            //     console.log('e==>', e)
            //     setWaterMark(e)
            // }}
            DrawerObjSend={DrawerObj}
            setDrawerObjSend={(e) => {
              setDrawerObj(e);
            }}
          />
        </>
      ),
      open: true,
      size: "lg",
      position: "right",
    });
  }
  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
      >
        {DrawerObj.body}
      </Modal>

      <Paper shadow="md" radius="md" p="xs" withBorder>
        <Grid gutter={4}>
          {/* <Paper shadow="xs" radius="md" p="xs" withBorder> */}
          <Grid.Col span={12} style={{ display: "flex" }}>
            <Grid.Col
              span={4}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Grid.Col span={12}>
                <Divider
                  my="xs"
                  label="Format"
                  labelPosition="left"
                  labelProps={{ component: "strong" }}
                />

                <PLComboBox
                // size="lg"
                />
              </Grid.Col>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Grid.Col span={4}>
                  <Text style={{ fontSize: 12 }}>WaterMark</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  {/* <PLComboBox disabled /> */}
                  <Button size="xs" compact onClick={handleWaterMark}>
                    Add
                  </Button>
                </Grid.Col>
              </Grid.Col>
            </Grid.Col>

            <Grid.Col span={8} style={{ display: "flex", flexWrap: "wrap" }}>
              <Grid.Col
                span={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid.Col span={2}>
                  <Text style={{ fontSize: 12 }}>Report On</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <PLComboBox
                    value={reportOn.repField}
                    data={data}
                    dispexpr="Field01"
                    valexpr="Field02"
                    setEdit={(e) => {
                      setReportOn({ ...reportOn, repField: e });
                    }}
                  />
                </Grid.Col>
              </Grid.Col>

              {(reportOn.repField == "F" || reportOn.repField == "E") && (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>Type</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <PLComboBox disabled />
                    </Grid.Col>
                  </Grid.Col>
                  {reportOn.repField == "F" ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>File Name</Text>
                      </Grid.Col>
                      <Grid.Col
                        span={8}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={9}>
                          <PLComboBox disabled />
                        </Grid.Col>
                        <Grid.Col
                          span={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <Checkbox />
                          <Text mx={5} size={12}>
                            Open File
                          </Text>
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                  ) : reportOn.repField == "E" ? (
                    <Grid.Col
                      span={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: 0,
                      }}
                    >
                      <Grid.Col span={2}>
                        <Text style={{ fontSize: 12 }}>E-Mail</Text>
                      </Grid.Col>
                      <Grid.Col
                        span={8}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Grid.Col span={9}>
                          <PLComboBox disabled />
                        </Grid.Col>
                        <Grid.Col
                          span={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <Button size="xs" compact>
                            E-Mail Book
                          </Button>
                        </Grid.Col>
                      </Grid.Col>
                    </Grid.Col>
                  ) : (
                    ""
                  )}
                  <Grid.Col
                    span={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={2}>
                      <Text style={{ fontSize: 12 }}>File Path</Text>
                    </Grid.Col>
                    <Grid.Col
                      span={8}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Grid.Col span={10}>
                        {/* <Textarea value={file.name}/> */}
                        {/* {file && ( */}
                          {/* // <Text size="sm" ta="center" mt="sm">
                          //   {file.name}
                          // </Text> */}
                          <Textarea value={file?.name} disabled  />
                        {/* )} */}
                      </Grid.Col>
                      <Grid.Col
                        span={4}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "2px",
                        }}
                      >
                        {/* <Button size="xs" compact>
                          ...
                        </Button> */}
                        <FileButton
                          onChange={setFile}
                          // accept="image/png,image/jpeg"
                        >
                          {(props) => (
                            <Button {...props} size="xs" compact>
                              ...
                            </Button>
                          )}
                        </FileButton>
                        <Button size="xs" compact>
                          <IconFolder />
                        </Button>
                      </Grid.Col>
                    </Grid.Col>
                  </Grid.Col>
                </Grid.Col>
              )}

              <Grid.Col
                style={{
                  display: "flex",
                  flexDirection:
                    reportOn.repField === "S" ? "row-reverse" : "row",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                {reportOn.repField !== "S" ? (
                  <Grid.Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <Grid.Col span={4}>
                      <Text style={{ fontSize: 12 }}>Copies</Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <PLComboBox disabled />
                    </Grid.Col>
                  </Grid.Col>
                ) : (
                  ""
                )}
                <Grid.Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={4}>
                    <Text style={{ fontSize: 12 }}>Print Sr.No.</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLComboBox disabled />
                  </Grid.Col>
                </Grid.Col>
                <Grid.Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={4}>
                    <Text style={{ fontSize: 12 }}>No. Of Column</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLComboBox disabled />
                  </Grid.Col>
                </Grid.Col>
              </Grid.Col>
              {reportOn.repField == "P" ? (
                <Grid.Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                  }}
                >
                  <Grid.Col span={2}>
                    <Text style={{ fontSize: 12 }}>Printer</Text>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <PLComboBox disabled />
                  </Grid.Col>
                </Grid.Col>
              ) : (
                ""
              )}
            </Grid.Col>
          </Grid.Col>

          {/* </Paper> */}
        </Grid>
        <Divider size="xs" my={2} />
        <Grid>
          <Grid.Col
            span={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Button
                size="xs"
                // mr={4}
              >
                Windows Mode
              </Button>
              {/* <Button
                size="xs"

              
            >
              Graph
            </Button> */}
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "4px",
              }}
            >
              <Button size="xs" onClick={AdvanceForm}>
                Advance
              </Button>
              <Button size="xs">Ok</Button>
            </Grid.Col>
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
}
