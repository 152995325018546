import { Button, Grid, Group, Text } from '@mantine/core'
import React from 'react'
import PLComboBox from '../../PlusComponent/PLComboBox'
import PLDateBox from '../../PlusComponent/PLDateBox'

const NewFinYearForm = (props) => {
    const {obj} = props
    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Type</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLComboBox 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>From</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLDateBox
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                        
                            }}
                        />
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <Text size={12} style={{ display: 'flex', justifyContent: 'center' }}>To</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLDateBox
                            dispformat="DD/MM/YYYY"
                            setEdit={(e) => {
                        
                            }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Group>
                        <Button>OK</Button>
                        <Button>Cancel</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default NewFinYearForm