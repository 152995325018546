import { Button, Center, FileButton, Grid, Group, Text } from '@mantine/core'
import React, { useState } from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLTextBox from '../../PlusComponent/PLTextBox'
import PLNumberBox from '../../PlusComponent/PLNumberBox'

const DataImportForm = () => {
  const [file, setFile] = useState(null)
  return (
    <>
      <Grid gutter={4}>
        <Grid.Col span={12} style={{ display: 'flex', alignItems:'center' }}>
          <Grid.Col span={2}>
            <Text size={12}>Import Type</Text>
          </Grid.Col>
          <Grid.Col span={6}>
            <PLDataGrid />
          </Grid.Col>
        </Grid.Col>
      </Grid>

      <Grid gutter={4}>
        <Grid.Col span={12} style={{ display: 'flex' }}>
          <Grid.Col span={2}>
            <Text size={12}>File Name</Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <PLTextBox

            />
          </Grid.Col>

          <Grid.Col span={2} style={{alignItems:'center'}}>
            <Group>
              <FileButton onChange={setFile}>
                {(props) => <Button variant='light' compact size='md' {...props}>...</Button>}
              </FileButton>
            </Group>
          </Grid.Col>

        </Grid.Col>
      </Grid>

      <Grid gutter={4}>
        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid.Col span={2}>
            <Text size={12}>Starting Row</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLNumberBox />
          </Grid.Col>

          <Grid.Col span={2}>
            <Text size={12}>Search On</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLDataGrid />
          </Grid.Col>

        </Grid.Col>
      </Grid>

      <Grid gutter={4}>
        <Grid.Col span={2}>
          <Text size={12}>Item Name</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <PLTextBox />
        </Grid.Col>
      </Grid>

      <Grid gutter={4}>
        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid.Col span={2}>
            <Text size={12}>Quantity</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLTextBox />
          </Grid.Col>

          <Grid.Col span={2}>
            <Text size={12}>Rate</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLTextBox />
          </Grid.Col>
        </Grid.Col>
      </Grid>

      <Grid gutter={4}>
        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid.Col span={2}>
            <Text size={12}>Serial No.</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLTextBox />
          </Grid.Col>

          <Grid.Col span={2}>
            <Text size={12}>Batch Name</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLTextBox />
          </Grid.Col>
        </Grid.Col>
      </Grid>

      <Grid gutter={4}>
        <Grid.Col span={2}>
          <Text size={12}>Location</Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <PLTextBox />
        </Grid.Col>
      </Grid>

      <Grid gutter={4}>
        <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid.Col span={2}>
            <Text size={12}>Location</Text>
          </Grid.Col>
          <Grid.Col span={4}>
            <PLTextBox />
          </Grid.Col>
          <Grid.Col span={6}>
            <PLTextBox />
          </Grid.Col>
        </Grid.Col>
      </Grid>

      <Center>
          <Button>OK</Button>
      </Center>

    </>
  )
}

export default DataImportForm