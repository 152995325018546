import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import GlobalClass from "../GlobalClass";
import gensetting from "../gensetting";
// import { notifications } from "@mantine/notifications";

export const GetOpeningStockEnt = createAsyncThunk(
  "OpeningStockEnt/GetOpeningStockEnt",
  async (obj) => {
    // console.log(obj, " =>Obj");
    try {
      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction:
          obj?.data?.data?.p0 == "E" || obj?.data?.data?.p0 == "D"
            ? obj?.data?.data?.p0
            : "A",
        cCode:
          obj?.data?.data?.p0 == "E" || obj?.data?.data?.p0 == "D"
            ? obj?.data?.data?.DataGridRow?.FIELD01
            : "HOHO",
        cOPara: "",
      });
      const response = await axios.get(
        GlobalClass.ApiUrl + GlobalClass.GetVouEnt + "?pa=" + param
      );
      if (obj?.data?.p0 == "D") {
        return response;
      } else {
        return response.data;
      }
    } catch (error) {
      console.error(error);
      return { error };
    }
  }
);

const OpeningStockSliceEnt = createSlice({
  name: "OpeningStockEnt",
  initialState: {
    OpeningStockDataEnt: [],
    isLoading: false,
    hasError: false,
    ErrorMsg: {},
    toggleButton: false,
  },
  reducers: {
    setToggleButton: (state, action) => {
      state.toggleButton = action.payload;
  },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetOpeningStockEnt.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
        state.OpeningStockDataEnt = [];
      })
      .addCase(GetOpeningStockEnt.fulfilled, (state, action) => {
        // console.log("GetStockRate=>", action.payload?.error);
        if (!action.payload?.error) {
          state.OpeningStockDataEnt = action.payload;
          state.isLoading = false;
          state.hasError = false;
        } else {
          state.OpeningStockDataEnt = action.payload;
          state.isLoading = false;
          state.hasError = true;
          state.ErrorMsg = action.payload?.error;
        }
      })
      .addCase(GetOpeningStockEnt.rejected, (state, action) => {
        state.hasError = true;
        state.isLoading = false;
        state.ErrorMsg = action.payload?.error;
      });
  },
});
export const { setToggleButton} = OpeningStockSliceEnt.actions;
export default OpeningStockSliceEnt.reducer;
