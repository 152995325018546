import {
  Button,
  Center,
  Checkbox,
  Chip,
  ColorInput,
  ColorPicker,
  Divider,
  Drawer,
  FileInput,
  Grid,
  Group,
  Image,
  Modal,
  Paper,
  Popover,
  Text,
  Textarea,
} from "@mantine/core";
import { useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import PLComboBox from "../../PlusComponent/PLComboBox";
import PLNumberBox from "../../PlusComponent/PLNumberBox";
import FontModal from "../FontModal";
import { IconPhotoOff } from "@tabler/icons-react";
export default function WaterMarkEntryForm() {
  let dra = {
    title: "",
    size: "md",
    body: "",
    open: false,
  };
  const [DrawerObj, setDrawerObj] = useState(dra);
  const [value, onChange] = useState("#fff");
  const [opened, setOpened] = useState(false);
  const [color, setColor] = useState("#ffffff");
  const [base64URL, setBase64URL] = useState("");
  const [file, setFile] = useState(null);
  // const [checked, setChecked] = useState(false)

  const handleColorChange = (value) => {
    setColor(value);
  };
  const getBase64 = (e) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(e);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const OpenFontModalFun = () => {
    return setDrawerObj({
      ...dra,
      title: <Text fw={700}>Report Font</Text>,
      body: (
        <>
          <FontModal
            // waterMarkObjSend={waterMark}
            // setWaterMarkObjSend={(e) => {
            //     console.log('e==>', e)
            //     setWaterMark(e)
            // }}
            DrawerObjSend={DrawerObj}
            setDrawerObjSend={(e) => {
              setDrawerObj(e);
            }}
          />
        </>
      ),
      open: true,
      size: "md",
      position: "right",
    });
  };
  return (
    <>
      <Modal
        opened={DrawerObj?.open}
        // onClose={close}
        withCloseButton={() => {}}
        fullScreen={false}
        closeButtonProps={{
          onClick: () => {
            setDrawerObj(dra);
          },
        }}
        title={DrawerObj.title}
        size={DrawerObj.size}
        // centered={.Centered}
        closeOnClickOutside={true}
        position={DrawerObj?.position ?? "bottom"}
        onClose={
          typeof DrawerObj?.onclose == "function"
            ? DrawerObj?.onclose
            : () => {
                setDrawerObj(dra);
              }
        }
      >
        {DrawerObj.body}
      </Modal>
      <Paper shadow="md" radius="md" p="xs" withBorder>
        <Grid gutter={4} mb={3}>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Profile Name</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox />
            </Grid.Col>
          </Grid.Col>
          <Checkbox mb={2} />
          <Text mx={5} size={12}>
            Text Water Mark
          </Text>
        </Grid>
        <Divider size="xs" />
        <Grid gutter={4} mb={3} mt={3}>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Text</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Font</Text>
            </Grid.Col>
            <Grid.Col span={10} style={{ display: "flex" }}>
              <Grid.Col span={10}>
                <PLTextBox />
              </Grid.Col>
              <Grid.Col span={2}>
                <Button size="xs" onClick={OpenFontModalFun}>
                  ...
                </Button>
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{ display: "flex", gap: "2px", alignItems: "center" }}
          >
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Forecolor</Text>
            </Grid.Col>
            <Button
              style={{ backgroundColor: color, color: "#fff" }}
              size="xs"
            />

            <Popover
              opened={opened}
              onClose={() => setOpened(false)}
              position="bottom"
              withArrow
            >
              <Popover.Target>
                <Button onClick={() => setOpened((o) => !o)} size="xs">
                  ...
                </Button>
              </Popover.Target>
              <Popover.Dropdown>
                <ColorPicker
                  format="hex"
                  value={color}
                  onChange={handleColorChange}
                  withPicker={false}
                  // fullWidth
                  swatches={[
                    "#2e2e2e",
                    "#868e96",
                    "#fa5252",
                    "#e64980",
                    "#be4bdb",
                    "#7950f2",
                    "#4c6ef5",
                    "#228be6",
                    "#15aabf",
                    "#12b886",
                    "#40c057",
                    "#82c91e",
                    "#fab005",
                    "#fd7e14",
                  ]}
                />
              </Popover.Dropdown>
            </Popover>
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Opacity</Text>
            </Grid.Col>

            <Grid.Col span={5}>
              <PLTextBox />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Layout</Text>
            </Grid.Col>

            <Grid.Col span={5}>
              <PLComboBox />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Horizontal</Text>
            </Grid.Col>

            <Grid.Col span={5}>
              <PLComboBox />
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Vertical</Text>
            </Grid.Col>

            <Grid.Col span={5}>
              <PLComboBox />
            </Grid.Col>
          </Grid.Col>
          <Checkbox />
          <Text mx={5} size={12}>
            Picture Water Mark
          </Text>
        </Grid>
        <Divider size="xs" />
        <Grid gutter={4} mb={3} mt={3}>
          <Grid.Col span={12} style={{ display: "flex", alignItems: "center" }}>
            {/* <Grid gutter={4}> */}
            {/* <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
            <Grid.Col span={2}>
              <Text size={12}>Picture</Text>
            </Grid.Col>
            {/* <Grid.Col span={9}>
                                                      
 </Grid.Col> */}
            <Grid.Col span={10}>
              <FileInput
                onChange={(e) => {
                  // console.log('e====>', e)
                  setFile(e);
                  getBase64(e)
                    .then((result) => {
                      let RemoveStr = "data:image/jpeg;base64,";
                      setBase64URL(result.substring(RemoveStr.length));
                      // setWaterMark({ ...waterMark, WMF22: result.substring(RemoveStr.length) })
                    })
                    .catch((err) => {
                      // console.log("err=>", err)
                    });
                }}
                accept="image/png,image/jpeg"
                // disabled={checked == true ? true : false}
                value={file}
              ></FileInput>
            </Grid.Col>
            {/* </Grid.Col> */}
            {/* </Grid> */}
          </Grid.Col>
          <Grid.Col span={6}>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Scale</Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <PLNumberBox />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Layout</Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Horizontal</Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col
              span={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid.Col span={4}>
                <Text style={{ fontSize: 12 }}>Vertical</Text>
              </Grid.Col>

              <Grid.Col span={6}>
                <PLComboBox />
              </Grid.Col>
            </Grid.Col>
          </Grid.Col>
          <Grid.Col span={6}>
            {/* {
                                                        waterMark.WMF22 === "" ? */}
            <>
              <IconPhotoOff size={125} color="gray" />
            </>
            {/* : */}
            {/* <>
              <Image
                radius="md"
                height={120}
                fit="contain"
                // src={`data:image/jpeg;base64,` + waterMark.WMF22}
              />
            </> */}
            {/* // } */}
          </Grid.Col>
        </Grid>
        <Divider size="xs" />

        <Grid mt={3}>
          <Grid.Col
            span={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button size="xs">Ok</Button>
          </Grid.Col>
        </Grid>
      </Paper>
    </>
  );
}
