import { Button, Center, Grid, Skeleton, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import PLTextBox from "../../PlusComponent/PLTextBox";
import {
  ECommerceOperatorApi,
  ECommerceOperatorPostApi,
} from "../../utils/slices/ECommerceOperator";
import { useDispatch, useSelector } from "react-redux";
import gensetting from "../../utils/gensetting";
import GlobalClass from "../../utils/GlobalClass";
import { GetAccountList } from "../../utils/slices/AccountListSlice";
import { ModalDelete } from "../../utils/slices/ModalSlice";
import Store from "../../utils/store";
import { DataSaveLoading } from "../../utils/slices/DataSaveLoadingSlice";

function ECommerceOperatorForm(props) {
  const { OBJ } = props;

  const dispatch = useDispatch();
  const ECommerceOperatorData = useSelector((store) => store?.ECommerceOperator?.ECommerceOperatorData);
  const isLoading = useSelector((store) => store?.ECommerceOperator?.isLoading);
  const SaveLoading = useSelector((store) => store?.DataSaveLoadingSlice?.SaveLoading);

  const [state, setState] = useState();
  const [dataObj, setDataObj] = useState();

  useEffect(() => {
    dispatch(ECommerceOperatorApi(OBJ));
  }, []);

  useEffect(() => {
    setState(ECommerceOperatorData);
    setDataObj(ECommerceOperatorData)
  }, [ECommerceOperatorData]);

  const submit = () => {

    if (state?.G02F02.trim() !== "") {

      var data = {
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: OBJ.p0,
        cCode: "",
        cOPara: "",
        cSData: JSON.stringify(state),
      };

      let param = JSON.stringify({
        CorpID: gensetting.getcorpid(),
        cUser: gensetting.getcuser(),
        cPass: gensetting.getcpass(),
        CmpNo: gensetting.getcmpno(),
        cAction: "A",
        cCode: "",
        cOPara: "",
      });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      if (OBJ.p0 == "E" && JSON.stringify(state) === JSON.stringify(dataObj)) {
        Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
        // GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

        // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
        // dispatch(GetAccountList({
        //   id: "14950123",
        //   name: "E-Commerce Operator",
        //   p0: "MT",
        //   p1: "T_G02",
        //   p2: "",
        //   p3: "",
        //   type: "A",
        //   pagination: PaginationObj["14950123"],
        // })
        // );

      }
      else {

        dispatch(DataSaveLoading(true));
        GlobalClass.Notify("info", OBJ.p0 == "A" ? "Adding" : "Editing", "Please wait while we process your data");

        fetch(GlobalClass.ApiUrl + GlobalClass.PostG02Ent + "?pa=" + param, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "SUCCESS") {
              Promise.resolve(dispatch(DataSaveLoading(false))).then(() => dispatch(ModalDelete(props.index)));
              GlobalClass.Notify("success", OBJ.p0 == "A" ? "Added" : "Edited", OBJ.p0 == "A" ? "Added successfully" : "Edited successfully");

              let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
              dispatch(GetAccountList({
                id: "14950123",
                name: "E-Commerce Operator",
                p0: "MT",
                p1: "T_G02",
                p2: "",
                p3: "",
                type: "A",
                pagination: PaginationObj["14950123"],
              })
              );
            } else if (data.status === "FAIL") {
              Promise.resolve(dispatch(DataSaveLoading(false)));
              GlobalClass.Notify("error", data?.status, data?.message);
            }
          })
          .catch((e) => {
            Promise.resolve(dispatch(DataSaveLoading(false)));
            GlobalClass.Notify("error", "Error", `${e?.message}`);
          });
      }
    } else {
      GlobalClass.Notify("warning", "Validation error", "Please fill out all the required fields");
    }
  };

  return (
    <>
      {isLoading == true || SaveLoading == true ? (
        GlobalClass.RenderLoader("dots")
      ) : (
        <Grid gutter={4}>
          <Grid.Col span={12} style={{ display: "flex", padding: "0" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Operator Name</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox
                value={state?.G02F02}
                setEdit={(e) => {
                  setState({ ...state, G02F02: e.target.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col span={12} style={{ display: "flex", padding: "0" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>GSTIN No.</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox
                value={state?.G02F03}
                setEdit={(e) => {
                  setState({ ...state, G02F03: e.target.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col span={12} style={{ display: "flex", padding: "0" }}>
            <Grid.Col span={2}>
              <Text style={{ fontSize: 12 }}>Merchant ID</Text>
            </Grid.Col>
            <Grid.Col span={10}>
              <PLTextBox
                value={state?.G02F04}
                setEdit={(e) => {
                  setState({ ...state, G02F04: e.target.value });
                }}
              />
            </Grid.Col>
          </Grid.Col>

          <Grid.Col span={12}>
            <Center>
              <Button onClick={submit}>Ok</Button>
            </Center>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
}
export default ECommerceOperatorForm;
