import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import gensetting from "../gensetting";
import GlobalClass from "../GlobalClass";
import axios from "axios";
// import Store from "../store";

export const GetUserMasterData = createAsyncThunk(
    "UserMasterSlice/GetUserMasterData",
    async () => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": 'A',
                    "cCode": "",
                    "cOPara": ""
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.M41UMList + "?pa=" + param);
            // console.log("UserMasterSlice/GetUserMasterData response=>>", response.data.data);
            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    }
);

export const GetUserMasterEntData = createAsyncThunk(
    "UserMasterSlice/GetUserMasterEntData",
    async () => {
        try {
            let param =
                JSON.stringify({
                    "CorpID": gensetting.getcorpid(),
                    "cUser": gensetting.getcuser(),
                    "cPass": gensetting.getcpass(),
                    "CmpNo": gensetting.getcmpno(),
                    "cAction": 'A',
                    "cCode": "",
                    "cOPara": ""
                });
            const response = await axios.get(GlobalClass.ApiUrl + GlobalClass.M41UMFieldEnt + "?pa=" + param);
            // console.log("UserMasterSlice/GetUserMasterData response=>>", response.data.data);
            return response.data.data;
        } catch (error) {
            console.error(error);
        }
    }
);


// let Data = Store.getState().UserMasterSlice.UserMasterTableData
const UserMasterSlice = createSlice({
    name: 'UserMasterSlice',
    initialState: {
        UserMasterData: [],
        UserMasterEntData: [],
        UserMasterOBJ: {},
        SaveUserMasterTableData:[],
        // UserMasterTableData: [],
        UserMasterTableData:{
            M41UM:[],
            M41UF:[]
        },
        UserMasterListTableData:[],
        UserMasterFlag:{
            Flag1 : true
        },
        isLoading: false,
        hasError: false,
        SaveLoading : false

    },
    reducers: {
        SaveUserMasterOBJ: (state, action) => {
            state.UserMasterOBJ = action.payload
        },
        GetUserMasterFlag: (state, action) => {
            console.log("GetUserMasterFlag", action.payload);
            state.UserMasterFlag = action.payload
        },
        GetUserMasterTableData: (state, action) => {
            console.log("GetUserMasterTableData", action.payload);
            state.UserMasterTableData =action.payload
        },
        SetSaveLoading: (state, action) => {
            console.log("GetUserMasterTableData", action.payload);
            state.SaveLoading =action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserMasterData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetUserMasterData.fulfilled, (state, action) => {
                // console.log("GetUserMasterData.fulfilled=>",action.payload);
                state.UserMasterData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetUserMasterData.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
            .addCase(GetUserMasterEntData.pending, (state, action) => {
                state.isLoading = true;
                state.hasError = false;
            })
            .addCase(GetUserMasterEntData.fulfilled, (state, action) => {
                // console.log("GetUserMasterEntData.fulfilled=>",action.payload);
                state.UserMasterEntData = action.payload;
                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(GetUserMasterEntData.rejected, (state, action) => {
                state.hasError = true
                state.isLoading = false;
            })
    }

})
export const { SaveUserMasterOBJ,GetUserMasterFlag,GetUserMasterTableData,GetUserMasterTableDataUpdate,SetSaveLoading } = UserMasterSlice.actions
export default UserMasterSlice.reducer