import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetCmpSetUpPopUp } from '../../utils/slices/CompanySetUpPopUpSlice'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import GlobalClass from '../../utils/GlobalClass'

const ProductPopUpType = (props) => {
    const { obj, value, setEditSend } = props
    const ProductPopTypeData = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData)
    const {isLoading} = useSelector((state) => state.CompanySetupPopUp)
    // const P_APPOPTYPE = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_APPOPTYPE)
    // const P_APPOPTYPEDT = useSelector((state) => state.CompanySetupPopUp?.CmpSetUpPopUpData?.P_APPOPTYPEDT)

    const dispatch = useDispatch()

    const [multiSelectVal, setMultiSelectVal] = useState([])

    useEffect(() => {
        if (obj) {
            dispatch(GetCmpSetUpPopUp(obj))
        }
    }, [obj])

    useEffect(() => {
        if (multiSelectVal && multiSelectVal.length > 0) {
            let Arr = [];
            Arr = multiSelectVal.map((m) => {
                return m.S16F03
            })
            return setEditSend(Arr.toString())
        }
    }, [multiSelectVal])

    return (
        <div style={{ width: "100%" }}>
        {
            isLoading ? GlobalClass.RenderLoader("dots") :
            ProductPopTypeData &&
            <PLDataGrid
                // width={"500px"}
                dropdownPosition={true}
                TmData={ProductPopTypeData?.P_APPOPTYPE}
                data={ProductPopTypeData?.P_APPOPTYPEDT}
                valexpr={"S16F03"}
                dispexpr={"S16F35"}
                value={value}
                isMulti={true}
                setEdit = {(e) => {
                    // console.log('e==>>',e)
                    // setEditSend(e?.S16F03)
                    let val = [];
                    if (e?.length > 0) {
                        e?.map((m) => {
                            val.push(m.original)
                        })
                        // console.log('val=>', val);
                        return setMultiSelectVal(val)
                    }
                }}
            />
        }
        </div>
    )
}

export default ProductPopUpType