import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GetAddressBook, GetAddressBookDeleteAPI, setIsDeleted, setoM02obj } from '../../utils/TransactionSlices/AddressBook';
import { createColumnHelper } from '@tanstack/react-table';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { Box, Button, Grid, Paper, Text, Textarea } from '@mantine/core';
import PLTextBox from '../../PlusComponent/PLTextBox';
import GlobalClass from '../../utils/GlobalClass';
import PLComboBox from '../../PlusComponent/PLComboBox';
import gensetting from '../../utils/gensetting';
import PLDataGrid from '../../PlusComponent/PLDataGrid';
import { UserField } from '../../FunctionsCall/UserField/UserField';
import Store from '../../utils/store';
import { ModalFunction } from '../../utils/slices/ModalSlice';
import Modal from './ViewMode';
import ViewMode from './ViewMode';
import { GetTMFormat } from '../../utils/slices/AccountListSlice';

export default function AddressBook(props) {

    const { obj, index } = props
    console.log("obj", obj);

    const columnHelper = createColumnHelper();
    const dispatch = useDispatch();

    const AddressBookData = useSelector(store => store?.AddressBook?.AddressBookData);
    const isLoading = useSelector(store => store?.AddressBook?.isLoading);
    const isDeleted = useSelector(store => store?.AddressBook?.isDeleted);
    const data = useSelector(store => store?.AddressBook?.data);
    const selectedFormat = useSelector(store => store?.AddressBook?.format)
    const { hasError, ErrorMsg, isRefetching, TMFormatData } = useSelector((state) => state.AccountList);

    const [rowSelection, setRowSelection] = useState({});
    const [columns, setColumns] = useState([]);
    const [columns1, setColumns1] = useState([]);
    // const [columns2, setColumns2] = useState([]);

    const [P_M02DT, setP_M02DT] = useState();
    const [oM02, setoM02] = useState();
    // const [oM02Obj, setoM02Obj] = useState();
    const [oM02EditObj, setoM02EditObj] = useState();
    const [format, setFormat] = useState();
    const [isAPICalled, setIsAPICalled] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [search, setSearch] = useState();
    const [rowId, setRowId] = useState();
    const [state, setState] = useState();

    useEffect(() => {
        if (hasError && !isLoading) {
            GlobalClass.Notify("error", "Error: " + ErrorMsg.code, ErrorMsg.message);
        }
    }, [hasError]);

    useEffect(() => {
        dispatch(GetAddressBook(obj))

        if(selectedFormat)
        {
            setFormat(selectedFormat)
        }
        else{
            setFormat("E")
        }
    }, [])

    useEffect(() => {
        if (isAPICalled === false && AddressBookData?.P_M02) {
            dispatch(GetTMFormat({
                ...obj,
                id: AddressBookData?.P_M02?.split("~C~")[0],
                p0: AddressBookData?.P_M02?.split("~C~")[0],
                p1: AddressBookData?.P_M02?.split("~C~")[0],
                p2: AddressBookData?.P_M02?.split("~C~")[1],
            }))
        }
        !AddressBookData?.oM02?.FIELD01 && setToggle(true);
        setP_M02DT(AddressBookData?.P_M02DT?.jData)
        setoM02(AddressBookData?.oM02);
        setoM02EditObj(AddressBookData?.oM02);
        setIsDeleted(false);
        setState(AddressBookData)
    }, [AddressBookData])

    useEffect(() => {
        if (isAPICalled === false && TMFormatData?.P_M02 && TMFormatData?.P_M02?.oDCFrmt && TMFormatData?.P_M02?.oDCFrmt?.aDCol) {
            var cols = [];
            TMFormatData?.P_M02?.oDCFrmt?.aDCol?.map((item, index) => {
                cols.push(
                    columnHelper.accessor(item?.DispExpr, {
                        header: item?.ColCap,
                    })
                );
            });
            setColumns(cols);
            setIsAPICalled(true)
        }
    }, [TMFormatData])

    useEffect(() => {

        // console.log("rowSelection", rowSelection);
        const isSelected = Object.values(rowSelection)

        if (isSelected[0] === true) {
            let FIELD01 = table?.getSelectedRowModel()?.flatRows[0]?.original?.FIELD01;
            if (FIELD01) {
                let result = P_M02DT?.find(item => item.FIELD01 === FIELD01);
                result && setRowId(result?.FIELD01)
                setToggle(false)
                if (format === "E") {
                    dispatch(GetAddressBook({ ...obj, cCode: FIELD01 }))
                }
                else if (format === "V" || format === "P") {
                    Store.dispatch(
                        ModalFunction({
                            MTitle: <Text fw={700}>Address Detail</Text>,
                            MAction: true,
                            MSize: "100%",
                            fullScreen: false,
                            MBody: (i) => <ViewMode index={i} FIELD01={FIELD01} format={format} />,
                            MClose: true,
                            onclose: () => { },
                        })
                    );
                }
            }
        }
        else {
            // console.log("data",data);
            setoM02(data);
            setRowId();
        }
        // console.log("rowId", rowId);

    }, [rowSelection])

    useEffect(() => {

        console.log("selectedFormat",selectedFormat,format);
        setSearch('')
        setRowSelection({})
        setRowId()
        var clm = []

        if (format === "V" ) {
            clm.push(
                {
                    accessorFn: (row) => `${row.FIELD61} ${row.FIELD02} ${row.FIELD04} ${row.FIELD15}`,
                    header: 'Address',
                    id: 'vFIELD61',
                    Cell: ({ cols, cell, row }) => {
                        return <>
                            <p>{row.original.FIELD61}</p>
                            <p>{row.original.FIELD02}</p>
                            <p>{row.original.FIELD04}</p>
                            <p>{row.original.FIELD15}</p>
                        </>
                    },
                },
            )
            clm.push(
                {
                    accessorFn: (row) => `${row.FIELD08} ${row.FIELD07} ${row.FIELD09}`,
                    header: 'City/Area',
                    id: 'City/Area',
                    Cell: ({ cols, cell, row }) => {
                        return <>
                            <p>{row.original.FIELD08}</p>
                            <p>{row.original.FIELD07}</p>
                            <p>{row.original.FIELD09}</p>
                        </>
                    },
                },
            )
            clm.push(
                {
                    accessorFn: (row) => `Off:${row.FIELD21},${row.FIELD22} Fac:${row.FIELD41} Mob:${row.FIELD35} Fax:${row.FIELD31} Res:${row.FIELD23} `,
                    id: 'Phone No',
                    header: 'Phone No',
                    Cell: ({ cols, cell, row }) => {
                        return <>
                            <p>Off : {row.original.FIELD21},{row.original.FIELD22}</p>
                            <p>Fac : {row.original.FIELD41}</p>
                            <p>Mob : {row.original.FIELD35}</p>
                            <p>Fax : {row.original.FIELD31}</p>
                            <p>Res : {row.original.FIELD23}</p>
                        </>
                    },

                },
            )
        }
        else if (format === "P" ) {
            clm.push(
                {
                    accessorFn: (row) => `${row.FIELD61} ${row.FIELD50} ${row.FIELD08}`,
                    header: 'Address',
                    id: 'pFIELD61',
                    Cell: ({ cols, cell, row }) => {
                        return <>
                            <p>{row.original.FIELD61}</p>
                            <p>{row.original.FIELD50}</p>
                            <p>{row.original.FIELD08}</p>
                        </>
                    },

                },
            )
            clm.push(
                {
                    accessorFn: (row) => `Off:${row.FIELD21},${row.FIELD22} Fac:${row.FIELD41}`,
                    id: 'Office',
                    header: 'Office',
                    Cell: ({ cols, cell, row }) => {
                        return <>
                            <p>off&nbsp; : {row.original.FIELD21} </p>
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {row.original.FIELD22}</p>
                            <p>Fac : {row.original.FIELD41}</p>
                        </>
                    },
                },
            )
            clm.push(
                {
                    accessorFn: (row) => `Mob:${row.FIELD35} Fax:${row.FIELD31} Res:${row.FIELD23} `,
                    id: 'Other',
                    header: 'Other',
                    Cell: ({ cols, cell, row }) => {
                        return <>
                            <p>Mob : {row.original.FIELD35}</p>
                            <p>Fax : {row.original.FIELD31}</p>
                            <p>Res : {row.original.FIELD23}</p>
                        </>
                    },
                },
            )

        }
        setColumns1(clm)

        // var cols = [];
        // AddressBookData?.P_M02?.oDCFrmt?.aDCol?.map((item, index) => {
        // cols.push({
        //     //accessorFn function that combines multiple data together
        //     accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        //     id: 'name',
        //     header: 'Name',
        // }),
        //     cols.push(
        //         columnHelper.accessor("FIELD01", {
        //             header: "City/Area",
        //         })
        //     );
        // cols.push(
        //     columnHelper.accessor("FIELD01", {
        //         header: "Phone No",
        //     })
        // );
        // });
        // setColumns1(cols)
    }, [format])

    useEffect(() => {
        if (isDeleted) {
            // alert("isDeleted")
            dispatch(setIsDeleted(false))
            dispatch(GetAddressBook(obj))
            setRowSelection({})
        }
    }, [isDeleted])

    useEffect(() => {
        console.log("oM02", oM02);
    }, [oM02])

    const addBtn = () => {
        setToggle(true)
        setoM02(data)
        setRowId()
        setRowSelection({})
    }

    const saveBtn = () => {
        // console.log("oM02", oM02)
        if (oM02?.FIELD61?.trim() == "") {
            GlobalClass.Notify("warning","Blank Name is not allowed")
        }
        else {
            // dispatch(DataSaveLoading(true));
            GlobalClass.Notify("info", toggle ? "Adding" : "Editing", "Please wait while we process your data");

            var data1 = {
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: "",
                cOPara: "",
                cSData: JSON.stringify(oM02),
            };

            let param = JSON.stringify({
                CorpID: gensetting.getcorpid(),
                cUser: gensetting.getcuser(),
                cPass: gensetting.getcpass(),
                CmpNo: gensetting.getcmpno(),
                cAction: "A",
                cCode: "",
                cOPara: "",
            });

            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(data1),
            };

            // if (OBJ.p0 == "E" && JSON.stringify(data) === JSON.stringify(dataObj)) {
            //     GlobalClass.Notify("success", "Edited", "Edited successfully");
            //     Promise.resolve(dispatch(DataSaveLoading(false)))
            //         .then(() => dispatch(ModalDelete(props.index)));
            // }
            // else {
            fetch(GlobalClass.ApiUrl + GlobalClass.PostAddressBook + "?pa=" + param, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === "SUCCESS") {
                        // Promise.resolve(dispatch(DataSaveLoading(false)));
                        GlobalClass.Notify("success", toggle ? "Added" : "Edited", toggle ? "Added successfully" : "Edited successfully");
                        if (toggle == false) {
                            setRowSelection({})
                        }
                        dispatch(GetAddressBook(obj))

                        // setData(dataObj)
                        // if (OBJ.p0 == "E") {
                        //     dispatch(ModalDelete(props.index))
                        // }

                        // // let PaginationObj = Store.getState().DataSaveLoadingSlice.PaginationObj;
                        // // dispatch(GetAccountList({
                        // //   id: "14950123",
                        // //   name: "E-Commerce Operator",
                        // //   p0: "MT",
                        // //   p1: "T_G02",
                        // //   p2: "",
                        // //   p3: "",
                        // //   type: "A",
                        // //   pagination: PaginationObj["14950123"],
                        // // })
                        // // );
                    }
                    else if (data.status === "FAIL") {
                        // Promise.resolve(dispatch(DataSaveLoading(false)));
                        GlobalClass.Notify("error", data?.status, data?.message);
                    }
                })
                .catch((e) => {
                    // Promise.resolve(dispatch(DataSaveLoading(false)));
                    GlobalClass.Notify("error", "Error", `${e?.message}`);
                });
            // } 
        }
    }

    const deleteBtn = () => {
        // dispatch(GetAddressBook(obj))
        if (rowId) {
            GlobalClass.Notify("warning","CanNot Delete Address Detail, Account Exist")
        }
        else {
            if(oM02?.FIELD01)
            {
                const confirm = window.confirm("Are you sure you want to delete this row?");
                if (confirm && oM02?.FIELD01) {
                    dispatch(GetAddressBookDeleteAPI({ ...obj, cAction: 'D', cCode: oM02?.FIELD01 }))
                }
            }
            else{
                GlobalClass.Notify("warning","Please Select a Row")
            }
        }

    }

    const cancelBtn = () => {
        // setToggle(false)
        setoM02(oM02EditObj)
    }

    const table = useMantineReactTable({
        data: P_M02DT ?? [],
        columns,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableTopToolbar: false,
        enablePagination: true,
        enableColumnActions: false,
        enableSorting: false,
        enableColumnFilters: false,
        enableColumnDragging: false,
        enableGlobalFilter: true,
        // enableGlobalFilterModes: true,
        state: {
            globalFilter: search,
            rowSelection
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            onClick: () => {
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
            },
            selected: rowSelection[row.id],
        }),
    });

    const table1 = useMantineReactTable({
        data: P_M02DT ?? [],
        columns: columns1,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableTopToolbar: false,
        enablePagination: true,
        enableColumnActions: false,
        enableSorting: false,
        enableGlobalFilter: true,
        enableGlobalFilterModes: true,
        enableColumnFilters: false,
        enableColumnDragging: false,
        // initialState : {
        //   globalFilter : search,
        // },
        state: {
            globalFilter: search,
            rowSelection
        },
        mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
            onClick: () => {
                setRowSelection((prev) => ({
                    [row.id]: !prev[row.id],
                }));
            },
            // onDoubleClick: () => {
            //     modalOpen()
            // },
            selected: rowSelection[row.id],
            sx: { cursor: "pointer" },
        }),
    });

    // const table2 = useMantineReactTable({
    //     data: format === "V" ? P_M02DT : P_M02DT ?? [],
    //     columns: format === "V" ? columns1 : columns2,
    //     enableFullScreenToggle: false,
    //     enableDensityToggle: false,
    //     enableTopToolbar: false,
    //     enablePagination: true,
    //     enableColumnActions: false,
    //     enableSorting: false,
    //     enableGlobalFilter: true,
    //     enableGlobalFilterModes: true,
    //     enableColumnFilters: false,
    //     enableColumnDragging: false,
    //     // initialState : {
    //     //   globalFilter : search,
    //     // },
    //     state: {
    //         globalFilter: search,
    //         rowSelection
    //     },
    //     mantineTableBodyRowProps: ({ row, staticRowIndex }) => ({
    //         onClick: () => {
    //             setRowSelection((prev) => ({
    //                 [row.id]: !prev[row.id],
    //             }));
    //         },
    //         // onDoubleClick: () => {
    //         //   // if (acTM.TMFormatData.oS60.cRecType !== 'P') {
    //         //   Promise.resolve(
    //         //     setRowSelection((prev) => ({
    //         //       [row.id]: true,
    //         //     }))
    //         //   ).then(() => {
    //         //     if (!form) {
    //         //       simulateEnterKeyPress();
    //         //     } else {
    //         //       props.ValueEdit(
    //         //         props.IsMultiSelect
    //         //           ? tabled.getSelectedRowModel().flatRows
    //         //           : tabled.getSelectedRowModel().flatRows[0].original
    //         //       );
    //         //       // props.form = false
    //         //       props.setPopOver(false);
    //         //     }
    //         //   });
    //         //   // }
    //         //   // else {
    //         //   //   console.log("dbl CLick ", acTM.TMFormatData.oS60.cRecType);
    //         //   // }
    //         // },
    //         selected: rowSelection[row.id],
    //         sx: { cursor: "pointer" },
    //     }),
    // });

    // console.log("oM02", oM02);
    // console.log("oM02Obj",oM02Obj);
    // console.log("data", data);
    // console.log("rowId", rowId);

    // console.log("columns1", columns1);
    // console.log("columns2", columns2);

    return (
        <>
            {
                isLoading == true ? GlobalClass.RenderLoader("dots") :
                    format === "E" ?
                        <Paper shadow="md" radius="md" p="xs" withBorder>

                            <Grid style={{ marginTop: '1px', padding: '0px' }}>
                                <Grid.Col span={12} style={{ display: 'flex' }}>
                                    <Grid.Col span={5} >
                                        <Grid.Col span={12}>
                                            <PLTextBox value={search}
                                                placeholder="Search Here"
                                                setEdit={(e) => {
                                                    setSearch(e.target.value)
                                                }}
                                            />
                                        </Grid.Col>

                                        <Grid.Col span={12}>
                                            <MantineReactTable table={table} />
                                        </Grid.Col>
                                    </Grid.Col>
                                    <Grid.Col span={7}>

                                        <Grid gutter={4}>
                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3}>
                                                    <Text>Name</Text>
                                                </Grid.Col>
                                                <Grid.Col span={8}>

                                                    <PLTextBox disabled={rowId ? true : false} value={oM02?.FIELD61}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD61: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>
                                            <Grid.Col span={12}></Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3}>
                                                    <Text>Contact Person</Text>
                                                </Grid.Col>
                                                <Grid.Col span={8}>
                                                    <PLTextBox value={oM02?.FIELD50}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD50: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3} />
                                                <Grid.Col span={8}>
                                                    <PLTextBox value={oM02?.FIELD51}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD51: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3}>
                                                    <Text>Address</Text>
                                                </Grid.Col>
                                                <Grid.Col span={8}>
                                                    <PLTextBox value={oM02?.FIELD02}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD02: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3} />
                                                <Grid.Col span={8}>
                                                    <PLTextBox value={oM02?.FIELD03}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD03: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3} />
                                                <Grid.Col span={8}>
                                                    <PLTextBox value={oM02?.FIELD04}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD04: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3} />
                                                <Grid.Col span={8} >
                                                    <PLTextBox value={oM02?.FIELD15}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD15: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col gutter={1} span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>City</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLDataGrid value={oM02?.FIELD08 ?? ""}
                                                            width={"500px"}
                                                            valexpr="FIELD01"
                                                            dispexpr="FIELD02"
                                                            data={state?.P_M31TDT?.jData}
                                                            TmData={state?.P_M31T}
                                                            setEdit={(e) => {
                                                                console.log("e", e);
                                                                setoM02({ ...oM02, FIELD08: e.FIELD01,FIELD05:e.FIELD02 })
                                                            }}
                                                        />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6}></Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col gutter={1} span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Area</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLDataGrid value={oM02?.FIELD09}
                                                            width={"500px"}
                                                            valexpr="FIELD01"
                                                            dispexpr="FIELD02"
                                                            data={state?.P_M31ADT?.jData}
                                                            TmData={state?.P_M31A}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD09: e.FIELD01,FIELD06:e.FIELD02 })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6}></Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col gutter={1} span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col gutter={1} span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Category</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLDataGrid value={oM02?.FIELD10}
                                                            width={"500px"}
                                                            valexpr="FIELD01"
                                                            dispexpr="FIELD02"
                                                            data={state?.P_M31RDT?.jData}
                                                            TmData={state?.P_M31R}
                                                            setEdit={(e) => {
                                                                // console.log("e", e);
                                                                setoM02({ ...oM02, FIELD10: e.FIELD01,FIELD11: e.FIELD02 })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>

                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>PinCode</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD07}
                                                            setEdit={(e) => {
                                                                // console.log("e", e);
                                                                setoM02({ ...oM02, FIELD07: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col gutter={1} span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Phone(O)</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD21}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD21: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Phone(O)</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD22}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD22: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col gutter={1} span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>{/*FIELD23*/}
                                                        <Text>Phone(R)</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD23}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD23: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>{/*FIELD24*/}
                                                        <Text>Phone(R)</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD24}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD24: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Phone(F)</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD41}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD41: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Mobile</Text>{/*FIELD35*/}
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD35}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD35: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Fax</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD31}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD31: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                                <Grid.Col span={6} style={{ display: 'flex' }}>
                                                    <Grid.Col span={6}>
                                                        <Text>Fax</Text>
                                                    </Grid.Col>
                                                    <Grid.Col span={6}>
                                                        <PLTextBox value={oM02?.FIELD32}
                                                            setEdit={(e) => {
                                                                setoM02({ ...oM02, FIELD32: e.target.value })
                                                            }} />
                                                    </Grid.Col>
                                                </Grid.Col>
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3}>
                                                    <Text>E-Mail-ID</Text>
                                                </Grid.Col>
                                                <Grid.Col span={8}>
                                                    <PLTextBox value={oM02?.FIELD43}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD43: e.target.value })
                                                        }}
                                                    />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', padding: 0 }}>
                                                <Grid.Col span={3}>
                                                    <Text>Website</Text>
                                                </Grid.Col>
                                                <Grid.Col span={8}>
                                                    <PLTextBox value={oM02?.FIELD42}
                                                        setEdit={(e) => {
                                                            setoM02({ ...oM02, FIELD42: e.target.value })
                                                        }} />
                                                </Grid.Col>
                                                <Grid.Col span={1} />
                                            </Grid.Col>

                                            <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button size='compact-md' m={10} onClick={saveBtn}>Save</Button>
                                                <Button size='compact-md' m={10} onClick={cancelBtn}>Cancel</Button>
                                            </Grid.Col>
                                        </Grid>

                                    </Grid.Col>
                                </Grid.Col>
                            </Grid>
                            <Grid>
                                <Grid.Col span={12} style={{ display: 'flex' }}>
                                    <Grid.Col span={4} style={{ display: 'flex', justifyContent: 'space-between',padding:'0px 30px' }}>
                                        <Text>Format</Text>
                                        <PLComboBox
                                            value={format}
                                            data={AddressBookData?.CBOT2}
                                            dispexpr="DisplayMember"
                                            valexpr="ValueMember"
                                            setEdit={(e) => {
                                                setFormat(e)
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={8} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button size='compact-md' mx={5}>User Field</Button>
                                        <Button size='compact-md' mx={5}>Filter</Button>
                                        <Button size='compact-md' mx={5} onClick={() => addBtn()}>Add</Button>
                                        <Button size='compact-md' mx={5} onClick={deleteBtn}>Delete</Button>
                                        <Button size='compact-md' mx={5}>Print</Button>
                                        <Button size='compact-md' mx={5}>SMS</Button>
                                    </Grid.Col>
                                </Grid.Col>
                            </Grid>
                        </Paper>
                        :
                        <Paper shadow="md" radius="md" p="xs" withBorder>
                            <Grid style={{ marginTop: '1px', padding: '0px' }}>
                                <Grid.Col span={8}>
                                    <PLTextBox value={search}
                                        placeholder="Search Here"
                                        setEdit={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                    />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <MantineReactTable table={table1} />
                                </Grid.Col>
                            </Grid>
                            <Grid>
                                <Grid.Col span={12} style={{ display: 'flex' }}>
                                    <Grid.Col span={4} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Text>Format</Text>
                                        <PLComboBox value={format}
                                            data={AddressBookData?.CBOT2}
                                            dispexpr="DisplayMember"
                                            valexpr="ValueMember"
                                            setEdit={(e) => {
                                                setFormat(e)
                                            }}
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={8} style={{ display: 'flex', justifyContent: 'space-around' }}>
                                        <Button size='compact-md' mx={5}>User Field</Button>
                                        <Button size='compact-md' mx={5}>Filter</Button>
                                        <Button size='compact-md' mx={5} onClick={() => addBtn()}>Add</Button>
                                        <Button size='compact-md' mx={5} onClick={deleteBtn}>Delete</Button>
                                        <Button size='compact-md' mx={5}>Print</Button>
                                        <Button size='compact-md' mx={5}>SMS</Button>
                                    </Grid.Col>
                                </Grid.Col>
                            </Grid>
                        </Paper>
            }
        </>
    )
}



