import { Button, FileInput, Grid, Text } from '@mantine/core'
import React from 'react'
import PLDataGrid from '../../PlusComponent/PLDataGrid'
import PLComboBox from '../../PlusComponent/PLComboBox'

const FinYearBackupForm = (props) => {
    const { obj } = props
    return (
        <>
            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Financial Year</Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <PLDataGrid 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Grid.Col span={3}>
                        <Text size={12}>Backup</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <PLComboBox 
                        setEdit={(e) => {
                        
                        }}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <FileInput />
                    </Grid.Col>
                </Grid.Col>
            </Grid>

            <Grid gutter={4}>
                <Grid.Col span={12} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <Button>OK</Button>
                </Grid.Col>
            </Grid>
        </>
    )
}

export default FinYearBackupForm